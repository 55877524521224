import React, { Component } from "react";
import SearchWidget from "../components/search/search-widget";
import CallCenter from "../components/call-center/call-center";
import * as Global from "../helpers/global";
import CartIcon from "../components/common/cart-icon";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let isPersonateEnabled = Global.getEnvironmetKeyValue("isPersonateEnabled");
    return (
      <React.Fragment>
        {Global.getEnvironmetKeyValue("isCart") !== null &&
          Global.getEnvironmetKeyValue("isCart") && (
            <CartIcon
              key={this.props.isLoggedIn}
              isDisplay={1}
              {...this.props}
            />
          )}
        {isPersonateEnabled && (
          <div className="mt-4">
            <CallCenter />
          </div>
        )}
        <SearchWidget
          history={this.props.history}
          match={this.props.match}
          mode={"home"}
        />
      </React.Fragment>
    );
  }
}

export default Home;
