import React, { Component } from "react";
import Form from "../common/form";
import { Trans } from "../../helpers/translate";
import DateRangePicker from "../common/date-range";
import moment from "moment";
import * as Global from "../../helpers/global";
import TimeField from 'react-simple-timefield';
import ModelPopup from "../../helpers/model";

class QuotationAddOffline extends Form {
  state = {
    popupContent: "",
    popupTitle: "",
    showPopup: false,
    data: {
      business: this.props.business,
      fromLocation: "",
      fromLocationName: "",
      fromLocationCity: "",
      toLocation: "",
      toLocationName: "",
      toLocationCity: "",
      name: "",
      startDate: "",
      endDate: "",
      costPrice: "0",
      sellPrice: "0",
      vendor: "",
      brn: "",
      itemType: "",
      noRooms: "",
      rating: "",
      duration: "",
      guests: "",
      adult: "",
      child: "",
      infant: "",
      pickupType: "",
      dropoffType: "",
      pickupTime: "",
      departStartDate: "",
      departEndDate: "",
      departStartTime: "",
      departEndTime: "",
      departAirlineName: "",
      departFlightNumber: "",
      departClass: "",
      departStops: "",
      departDurationH: "",
      departDurationM: "",
      totaldepartduration: "",
      returnStartDate: "",
      returnEndDate: "",
      returnStartTime: "",
      returnEndTime: "",
      returnAirlineName: "",
      returnFlightNumber: "",
      returnClass: "",
      returnStops: "",
      totalreturnDuration: "",
      returnDurationH: "",
      returnDurationM: "",
      isRoundTrip: true,
      uuid: "",
      day: 1,
      toDay: 1,
      nights: 1,
      dayDepart: 1,
      dayDepartEnd: 1,
      dayReturn: 1,
      dayReturnEnd: 1,
      dates: "",
      datesIsValid: "valid",
      cutOfDays: 3,
      stayInDays: 1,
      description: "",
      supplierCurrency: "",
      conversionRate: "",
      bookBefore: "",
      supplierCostPrice: "",
      supplierTaxPrice: "",
      markupPrice: "",
      discountPrice: "",
      CGSTPrice: "",
      SGSTPrice: "",
      IGSTPrice: "",
      mealType: "",
      isShowTax: false,
      isSellPriceReadonly: false,
      tpExtension: [],
    },
    errors: {},
    staticBRN: "",
  };

  validate = () => {
    const errors = {};
    const { data } = this.state;

    if (this.props.business === "hotel" || this.props.business === "activity") {
      if (!this.validateFormData(data.toLocation, "require"))
        errors.toLocation = "Location required";
      if (!this.validateFormData(data.name, "require")) errors.name = "Name required";
      if (!this.validateFormData(data.startDate, "require")) errors.startDate = "Check In required";
    }

    if (this.props.business === "hotel") {
      if (!this.validateFormData(data.endDate, "require")) errors.endDate = "Check Out required";
      if (!this.validateFormData(data.itemType, "require")) errors.itemType = "Room Type required";
      if (!this.validateFormData(data.noRooms, "require")) errors.noRooms = "No of Rooms required";
    }

    if (this.props.business === "activity") {
      if (!this.validateFormData(data.duration, "require")) errors.duration = "Duration required";
      if (!this.validateFormData(data.guests, "require")) errors.guests = "Guests required";
      if (!this.validateFormData(data.itemType, "require"))
        errors.itemType = "Activity Type required";
    }

    if (this.props.business === "transfers") {
      if (!this.validateFormData(data.fromLocation, "require"))
        errors.fromLocation = "Pickup Location required";
      if (!this.validateFormData(data.pickupType, "require")) errors.pickupType = "Type required";
      if (!this.validateFormData(data.toLocation, "require"))
        errors.toLocation = "Dropoff Location required";
      if (!this.validateFormData(data.dropoffType, "require")) errors.dropoffType = "Type required";
      if (!this.validateFormData(data.startDate, "require")) errors.startDate = "Check In required";
      if (!this.validateFormData(data.duration, "require")) errors.duration = "Start Time required";
      if (!this.validateFormData(data.guests, "require")) errors.guests = "Guests required";
      if (!this.validateFormData(data.itemType, "require"))
        errors.itemType = "Transfers Type required";
    }

    if (!this.validateFormData(data.costPrice, "require")) errors.costPrice = "Cost Price required";
    if (!this.validateFormData(data.sellPrice, "require")) errors.sellPrice = "Sell Price required";

    if (isNaN(Number(data.costPrice)) || Number(data.costPrice) <= 0) {
      errors.costPrice = "Cost Price should not be less than or equal to 0";
    }
    if (isNaN(Number(data.sellPrice)) || Number(data.sellPrice) <= 0) {
      errors.sellPrice = "Sell Price should not be less than or equal to 0";
    }
    return Object.keys(errors).length === 0 ? null : errors;
  };


  handleAddItem = (Editmode, isVendorvalidate) => {
    let { data, errors } = this.state;

    if (data.isSellPriceReadonly && (isNaN(Number(data.costPrice)) || !isNaN(Number(data.costPrice)) && Number(data.costPrice) === 0)) {
      errors.costPrice = "Cost Price should not be 0";
    }
    else if (!isNaN(Number(data.costPrice)) && Number(data.costPrice) < 0) {
      errors.costPrice = "Cost Price should not be less than 0";
    }
    else {
      delete Object.assign(errors)["costPrice"];
    }
    if (!isNaN(Number(data.sellPrice)) && Number(data.sellPrice) < 0) {
      errors.sellPrice = "Sell Price should not be less than 0";
    }
    else {
      delete Object.assign(errors)["sellPrice"];
    }

    if (this.state.errors && Object.keys(this.state.errors).length > 0) {
      this.setState({
        errors,
        showPopup: false,
        popupContent: "",
        popupTitle: ""
      });
      return;
    }

    this.setState({
      errors,
      showPopup: false,
      popupContent: "",
      popupTitle: ""
    });

    if (!isVendorvalidate && !data.vendor) {
      this.handlePopup();
      return;
    }

    if (!Editmode)
      data.uuid = this.generateUUID();

    if (!Editmode) {
      if (this.props.type === "Itinerary") {
        data.startDate = moment(data.startDate)
          .add(data.day - 1, "days")
          .format("MM/DD/YYYY");
        data.endDate = moment(data.startDate).add(data.nights, "days").format("MM/DD/YYYY");


        if (moment(data.departStartDate).format("MM/DD/YYYY") === moment().format("MM/DD/YYYY")) {
          data.departStartDate = moment(data.startDate)
            .add(data.dayDepart - 1, "days")
            .format("MM/DD/YYYY");
          data.departEndDate = moment(data.startDate)
            .add(data.dayDepartEnd - 1, "days")
            .format("MM/DD/YYYY");

          data.returnStartDate = moment(data.startDate)
            .add(data.dayReturn - 1, "days")
            .format("MM/DD/YYYY");
          data.returnEndDate = moment(data.startDate)
            .add(data.dayReturnEnd - 1, "days")
            .format("MM/DD/YYYY");
        } else {
          data.departStartDate = moment(data.departStartDate)
            .add(data.dayDepart - 1, "days")
            .format("MM/DD/YYYY");
          data.departEndDate = moment(data.departStartDate)
            .add(data.dayDepartEnd - 1, "days")
            .format("MM/DD/YYYY");

          data.returnStartDate = moment(data.departStartDate)
            .add(data.dayReturn - 1, "days")
            .format("MM/DD/YYYY");
          data.returnEndDate = moment(data.startDate)
            .add(data.dayReturnEnd - 1, "days")
            .format("MM/DD/YYYY");
        }

      }
    }

    if (this.props.business === "air") {
      if (data.departDurationH !== "" && data.departDurationM !== "") {
        data.departDuration = data.departDurationH + "h " + data.departDurationM + "m";
      }
      else {
        data.departDuration = "0h 0m";
      }

      data.totaldepartDuration =
        (data.departDurationH !== "" ? parseInt(data.departDurationH) : 0) * 60 +
        (data.departDurationM !== "" ? parseInt(data.departDurationM) : 0);

      if (data.returnDurationH && data.returnDurationM) {
        data.returnDuration = data.returnDurationH + "h " + data.returnDurationM + "m";
        data.totalreturnDuration = (data.returnDuration !== "" ? parseInt(data.returnDurationH) : 0) * 60 + (data.returnDuration !== "" ? parseInt(data.returnDurationM) : 0);
      }
      else {
        data.returnDuration = "0h 0m";
        data.totalreturnDuration = (data.returnDuration !== "" ? parseInt(data.returnDurationH) : 0) * 60 + (data.returnDuration !== "" ? parseInt(data.returnDurationM) : 0);
      }
      if (data.adult === "0" || data.adult === "") {
        data.adult = "1";
      }
    }

    let quotationInfo = JSON.parse(localStorage.getItem("quotationDetails"));
    if (Editmode && this.props.type === "Itinerary") {
      if (quotationInfo.startDate) {
        data.startDate = moment(quotationInfo.startDate).add(parseInt(data.day) - 1, "days").format('YYYY-MM-DD');
      }
      if (quotationInfo.endDate) {
        data.endDate = moment(data.startDate).add(parseInt(data.nights), "days").format('YYYY-MM-DD');
      }

      if (quotationInfo.startDate) {
        data.departStartDate = moment(quotationInfo.startDate).add(parseInt(data.dayDepart) - 1, "days").format('YYYY-MM-DD');
      }

      if (quotationInfo.endDate) {
        data.departEndDate = moment(quotationInfo.startDate).add(parseInt(data.dayDepartEnd) - 1, "days").format('YYYY-MM-DD');
      }

      if (quotationInfo.startDate) {
        data.returnStartDate = moment(quotationInfo.endDate).format('YYYY-MM-DD');
      }

      if (quotationInfo.endDate) {
        data.returnEndDate = moment(quotationInfo.startDate).add(parseInt(data.dayReturnEnd) - 1, "days").format('YYYY-MM-DD');
      }
    }

    if (isNaN(parseFloat(data.costPrice)) || parseFloat(data.costPrice) === 0) data.costPrice = 1;
    if (isNaN(parseFloat(data.sellPrice)) || parseFloat(data.sellPrice) === 0) data.sellPrice = 1;

    this.props.handleOffline(data);
    if (this.props.type === "Itinerary") {
      this.setDates();
    }
  };

  setDate = (startDate, endDate) => {
    let newData = { ...this.state.data };
    newData.dates = { checkInDate: startDate, checkOutDate: endDate };
    newData.datesIsValid = "valid";
    newData.startDate = startDate;
    newData.endDate = endDate;
    this.setState({ data: newData });
  };

  changeairTripType = () => {
    let newData = { ...this.state.data };
    newData.isRoundTrip = !this.state.data.isRoundTrip;
    this.setState({ data: newData });
  };

  generateUUID = () => {
    let dt = new Date().getTime();
    let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  };

  setDates = () => {
    let newData = { ...this.state.data };
    let quotationInfo = JSON.parse(localStorage.getItem("quotationDetails"));

    if (!quotationInfo.startDate) {
      newData.startDate = new Date();
      //newData.bookBefore = new Date();
    } else {
      newData.startDate = quotationInfo.startDate;
      //newData.bookBefore = quotationInfo.startDate;
    }

    if (!quotationInfo.endDate) {
      newData.endDate = new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000);
    } else {
      newData.endDate = quotationInfo.endDate;
    }

    if (!quotationInfo.departStartDate) {
      newData.departStartDate = new Date();
    } else {
      newData.departStartDate = quotationInfo.departStartDate;
    }

    if (!quotationInfo.departEndDate) {
      newData.departEndDate = new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000);
    } else {
      newData.departEndDate = quotationInfo.departEndDate;
    }

    if (!quotationInfo.returnStartDate) {
      newData.returnStartDate = new Date();
    } else {
      newData.returnStartDate = quotationInfo.returnStartDate;
    }

    if (!quotationInfo.returnEndDate) {
      newData.returnEndDate = new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000);
    } else {
      newData.returnEndDate = quotationInfo.returnEndDate;
    }
    let count = quotationInfo.duration && Math.ceil(quotationInfo.duration);
    let day = moment(newData.endDate).diff(moment(newData.startDate), "days") + 1;
    if (count < day) {
      count += 1;
    }
    const duration = [...Array(count).keys()];
    let days = [];
    duration.map((item) => {
      days.push({
        name:
          "Day " +
          (item + 1) +
          " - " +
          moment(quotationInfo.startDate).add(item, "days").format("MMM DD"),
        value: item + 1,
      });
    });
    newData.dayReturn = days[days.length - 1].value
    newData.dayReturnEnd = days[days.length - 1].value

    this.setState({ data: newData });
  };

  setImportData = () => {
    let data = { ...this.state.data };
    let item = this.props.importItem;

    if (!item.editMode) {
      item.sellPrice = "";
      item.costPrice = "";
      item.bookBefore = "";
    }



    item.departStops = item.departStops === "" ? "0" : item.departStops;
    if (item.business === "air") {
      item.child = item.child === "" ? "0" : item.child;
      item.infant = item.infant === "" ? "0" : item.infant;
    }

    let quotationInfo = JSON.parse(localStorage.getItem("quotationDetails"));
    /* if (!quotationInfo.startDate) {
      item.bookBefore = new Date();
    } else {
      item.bookBefore = quotationInfo.startDate;
    } */

    if (item.departDuration && item.departDuration.length > 0) {
      item.departDurationH = item.departDuration.replace("h", "").replace("m", "").split(' ')[0];
      item.departDurationM = item.departDuration.replace("h", "").replace("m", "").split(' ')[1];
    }
    else {
      item.departDurationH = "00";
      item.departDurationM = "00";
    }
    if (item.returnDuration && item.returnDuration !== "" && item.returnDuration.length > 0) {
      item.returnDurationH = item.returnDuration.replace("h", "").replace("m", "").split(' ')[0];
      item.returnDurationM = item.returnDuration.replace("h", "").replace("m", "").split(' ')[1];
    }
    else {
      item.returnDurationH = "00";
      item.returnDurationM = "00";
    }

    if (item.editMode) {
      item.isSellPriceReadonly = (item.conversionRate || item.supplierCostPrice || item.supplierTaxPrice || item.markupPrice || item.discountPrice || item.CGSTPrice || item.SGSTPrice || item.IGSTPrice) ? true : false;
    }

    data = item;

    this.setState({ data, editMode: item.editMode });
  };

  validateBRN = tmpBRN => {
    const errors = {};
    const { data } = this.state;

    if (data.brn) {
      const quotationInfo = JSON.parse(localStorage.getItem("quotationItems"));
      if (quotationInfo && quotationInfo.filter(x => x.offlineItem.uuid !== this.state.data.uuid
        && x.offlineItem.brn
        && x.offlineItem.brn.toLowerCase() === tmpBRN.toLowerCase()).length > 0)
        errors.brn = "Confirmation Number should be unique per " + (this.props.type === "Itinerary" ? "itinerary" : "quotation") + ".";
    }
    this.setState({ errors });
    return Object.keys(errors).length === 0 ? null : errors;
  }

  componentDidMount() {
    this.setDates();
    this.props.importItem && this.setImportData();
    this.props.importItem && window.scrollTo(0, 206);
  }

  handleShowHideTax = () => {
    var data = this.state.data;
    data.isShowTax = !data.isShowTax;
    this.setState({ data });
  }

  handleAmountFields = (value, e) => {
    let data = this.state.data;
    let costPrice = 0;
    let tmpSellPrice = data.sellPrice;

    let conversionRate = isNaN(Number(data.conversionRate)) ? 1 : data.conversionRate === "" ? 1 : Number(data.conversionRate);
    let supplierTaxPrice = isNaN(Number(data.supplierTaxPrice)) ? 0 : Number(data.supplierTaxPrice) * conversionRate;
    let supplierCostPrice = isNaN(Number(data.supplierCostPrice)) ? 0 : Number(data.supplierCostPrice) * conversionRate;

    if (e.target.name === "conversionRate" || e.target.name === "supplierTaxPrice" || e.target.name === "supplierCostPrice") {
      costPrice = supplierCostPrice + supplierTaxPrice;
      data.costPrice = costPrice;
    }
    if (e.target.name !== "sellPrice" && supplierTaxPrice === 0 && supplierCostPrice === 0) {
      data.costPrice = 0;
      costPrice = 0;
    }

    let markupPrice = isNaN(Number(data.markupPrice)) ? 0 : Number(data.markupPrice);
    let discountPrice = isNaN(Number(data.discountPrice)) ? 0 : Number(data.discountPrice);
    let CGSTPrice = isNaN(Number(data.CGSTPrice)) ? 0 : Number(data.CGSTPrice);
    let SGSTPrice = isNaN(Number(data.SGSTPrice)) ? 0 : Number(data.SGSTPrice);
    let IGSTPrice = isNaN(Number(data.IGSTPrice)) ? 0 : Number(data.IGSTPrice);
    let sellPrice = isNaN(Number(data.costPrice)) ? 0 : Number(data.costPrice) + markupPrice;
    sellPrice = sellPrice - discountPrice;
    sellPrice = sellPrice + CGSTPrice + SGSTPrice + IGSTPrice;
    data.sellPrice = sellPrice;

    data.isSellPriceReadonly = (data.conversionRate || data.supplierCostPrice || data.supplierTaxPrice || data.markupPrice || data.discountPrice || data.CGSTPrice || data.SGSTPrice || data.IGSTPrice) ? true : false;
    if (!data.isSellPriceReadonly) {
      data.sellPrice = tmpSellPrice;
      data.costPrice = tmpSellPrice;
      costPrice = tmpSellPrice;
    }

    if (e.target.name === "sellPrice")
      data.costPrice = data.sellPrice;
    this.setState({ data });
  }
  setFocusOnVendor = () => {
    this.setState({
      showPopup: false,
      popupContent: "",
      popupTitle: ""
    });
    //this.inputRef['vendor'].current && this.references['vendor'].current.focus();
  }

  handlePopup = () => {
    this.setState({
      popupTitle: this.state.showPopup ? "" : "Vendor/Supplier",
      showPopup: !this.state.showPopup,
      popupContent: this.state.showPopup ? "" : <div>
        <div>Add item without Vendor/Supplier?</div>
        <button
          className="btn btn-primary pull-right m-1 "
          onClick={() => this.handleAddItem(this.state.editMode, true)}
        >
          {Trans("_yes")}
        </button>

        <button
          className="btn btn-primary pull-right m-1 "
          onClick={() => this.setFocusOnVendor()}
        >
          {Trans("_no")}
        </button>
      </div>

    });
  };

  render() {
    const { business, type } = this.props;
    const quotationInfo = JSON.parse(localStorage.getItem("quotationDetails"));

    let count = quotationInfo.duration && Math.ceil(quotationInfo.duration);
    let day = moment(quotationInfo.endDate).diff(moment(quotationInfo.startDate), "days") + 1;
    if (count < day) {
      count += 1;
    }
    const duration = [...Array(count).keys()];
    let days = [];
    let nights = [];
    duration.map((item) => {
      days.push({
        name:
          "Day " +
          (item + 1) +
          " - " +
          moment(quotationInfo.startDate).add(item, "days").format("MMM DD"),
        value: item + 1,
      });
      nights.push({ name: item + 1, value: item + 1 });
    });
    //nights.pop();

    let returnDays = [...Array(4).keys()];
    let daysReturn = [];
    returnDays.map((item) => {
      daysReturn.push({
        name:
          "Day " +
          (item + count) +
          " - " +
          moment(quotationInfo.endDate).add(item, "days").format("MMM DD"),
        value: item + count,
      });
    });

    let departStartdays = [];
    let departEnddays = [];
    let returndepartStartdays = [];
    let returnEnddays = [];
    departStartdays = days;
    //returndepartStartdays=days;
    if (business === "air") {
      days.map((item) => {
        if (parseInt(item.value) === parseInt(this.state.data.dayDepart) || parseInt(item.value) > parseInt(this.state.data.dayDepart)) {
          departEnddays.push(item);
        }
      });

      daysReturn.map((item, cnt) => {
        if (cnt === 0) {
          returndepartStartdays.push(item);
        }
      });

      daysReturn.map((item) => {
        if (parseInt(item.value) === parseInt(this.state.data.dayReturn) || parseInt(item.value) > parseInt(this.state.data.dayReturn)) {
          returnEnddays.push(item);
        }
      });
      daysReturn = returnEnddays;
    }

    const starRating = [
      { name: "Select", value: "" },
      { name: "1", value: "1" },
      { name: "2", value: "2" },
      { name: "3", value: "3" },
      { name: "4", value: "4" },
      { name: "5", value: "5" },
    ];

    const mealSelection = [
      { name: "Select", value: "" },
      { name: "Vegetarian", value: "Vegetarian" },
      { name: "Non-Vegetarian", value: "Non-Vegetarian" },
      { name: "Jain", value: "Jain" },
      { name: "European Plan", value: "European Plan" },
      { name: "Continental Plan", value: "Continental Plan" },
      { name: "American Plan", value: "American Plan" },
      { name: "Modified American Plan", value: "Modified American Plan" },
    ];

    const PickupStartTime = [
      { value: "", name: "Select" },
      { value: "00:00", name: "12:00 am" },
      { value: "12:30", name: "12:30 am" },
      { value: "12:59", name: "12:59 am" },
      { value: "01:00", name: "01:00 am" },
      { value: "01:30", name: "01:30 pm" },
      { value: "01:59", name: "01:59 am" },
      { value: "02:00", name: "02:00 am" },
      { value: "02:30", name: "02:30 am" },
      { value: "02:59", name: "02:59 am" },
      { value: "03:00", name: "03:00 am" },
      { value: "03:30", name: "03:30 am" },
      { value: "03:59", name: "03:59 am" },
      { value: "04:00", name: "04:00 am" },
      { value: "04:30", name: "04:30 am" },
      { value: "04:59", name: "04:59 am" },
      { value: "05:00", name: "05:00 am" },
      { value: "05:30", name: "05:30 am" },
      { value: "05:59", name: "05:59 am" },
      { value: "06:00", name: "06:00 am" },
      { value: "06:30", name: "06:30 am" },
      { value: "06:59", name: "06:59 am" },
      { value: "07:00", name: "07:00 am" },
      { value: "07:30", name: "07:30 am" },
      { value: "07:59", name: "07:59 am" },
      { value: "08:00", name: "08:00 am" },
      { value: "08:30", name: "08:30 am" },
      { value: "08:59", name: "08:59 am" },
      { value: "09:00", name: "09:00 am" },
      { value: "09:30", name: "09:30 am" },
      { value: "09:59", name: "09:59 am" },
      { value: "10:00", name: "10:00 am" },
      { value: "10:30", name: "10:30 am" },
      { value: "10:59", name: "10:59 am" },
      { value: "11:00", name: "11:00 am" },
      { value: "11:30", name: "11:30 am" },
      { value: "11:59", name: "11:59 am" },
      { value: "12:00", name: "12:00 pm" },
      { value: "12:30", name: "12:30 pm" },
      { value: "12:59", name: "12:59 pm" },
      { value: "13:00", name: "01:00 pm" },
      { value: "13:30", name: "01:30 pm" },
      { value: "13:59", name: "01:59 pm" },
      { value: "14:00", name: "02:00 pm" },
      { value: "14:30", name: "02:30 pm" },
      { value: "14:59", name: "02:59 pm" },
      { value: "15:00", name: "03:00 pm" },
      { value: "15:30", name: "03:30 pm" },
      { value: "15:59", name: "03:59 pm" },
      { value: "16:00", name: "04:00 pm" },
      { value: "16:30", name: "04:30 pm" },
      { value: "16:59", name: "04:59 pm" },
      { value: "17:00", name: "05:00 pm" },
      { value: "17:30", name: "05:30 pm" },
      { value: "17:59", name: "05:59 pm" },
      { value: "18:00", name: "06:00 pm" },
      { value: "18:30", name: "06:30 pm" },
      { value: "18:59", name: "06:59 pm" },
      { value: "19:00", name: "07:00 pm" },
      { value: "19:30", name: "07:30 pm" },
      { value: "19:59", name: "07:59 pm" },
      { value: "20:00", name: "08:00 pm" },
      { value: "20:30", name: "08:30 pm" },
      { value: "20:59", name: "08:59 pm" },
      { value: "21:00", name: "09:00 pm" },
      { value: "21:30", name: "09:30 pm" },
      { value: "21:59", name: "09:59 pm" },
      { value: "22:00", name: "10:00 pm" },
      { value: "22:30", name: "10:30 pm" },
      { value: "22:59", name: "10:59 pm" },
      { value: "23:00", name: "11:00 pm" },
      { value: "23:30", name: "11:30 pm" },
      { value: "23:59", name: "11:59 pm" }
    ];

    const durationList = [
      { name: "Select", value: "" },
      { name: "30 minutes", value: "30 minutes" },
      { name: "1 hour", value: "1 hour" },
      { name: "1 hour 30 minutes", value: "1 hour 30 minutes" },
      { name: "2 hours", value: "2 hours" },
      { name: "2 hours 30 minutes", value: "2 hours 30 minutes" },
      { name: "3 hours", value: "3 hours" },
      { name: "3 hours 30 minutes", value: "3 hours 30 minutes" },
      { name: "4 hours", value: "4 hours" },
      { name: "4 hours 30 minutes", value: "4 hours 30 minutes" },
      { name: "5 hours", value: "5 hours" },
      { name: "5 hours 30 minutes", value: "5 hours 30 minutes" },
      { name: "6 hours", value: "6 hours" },
      { name: "6 hours 30 minutes", value: "6 hours 30 minutes" },
      { name: "7 hours", value: "7 hours" },
      { name: "7 hours 30 minutes", value: "7 hours 30 minutes" },
      { name: "8 hours", value: "8 hours" },
      { name: "8 hours 30 minutes", value: "8 hours 30 minutes" },
      { name: "9 hours", value: "9 hours" },
      { name: "9 hours 30 minutes", value: "9 hours 30 minutes" },
      { name: "10 hours", value: "10 hours" },
      { name: "10 hours 30 minutes", value: "10 hours 30 minutes" },
      { name: "11 hours", value: "11 hours" },
      { name: "11 hours 30 minutes", value: "11 hours 30 minutes" },
      { name: "12 hours", value: "12 hours" },
      { name: "12 hours 30 minutes", value: "12 hours 30 minutes" },
      { name: "13 hours", value: "13 hours" },
      { name: "13 hours 30 minutes", value: "13 hours 30 minutes" },
      { name: "14 hours", value: "14 hours" },
      { name: "14 hours 30 minutes", value: "14 hours 30 minutes" },
      { name: "15 hours", value: "15 hours" },
      { name: "15 hours 30 minutes", value: "15 hours 30 minutes" },
      { name: "16 hours", value: "16 hours" },
      { name: "16 hours 30 minutes", value: "16 hours 30 minutes" },
      { name: "17 hours", value: "17 hours" },
      { name: "17 hours 30 minutes", value: "17 hours 30 minutes" },
      { name: "18 hours", value: "18 hours" },
      { name: "18 hours 30 minutes", value: "18 hours 30 minutes" },
      { name: "19 hours", value: "19 hours" },
      { name: "19 hours 30 minutes", value: "19 hours 30 minutes" },
      { name: "20 hours", value: "20 hours" },
      { name: "20 hours 30 minutes", value: "20 hours 30 minutes" },
      { name: "21 hours", value: "21 hours" },
      { name: "21 hours 30 minutes", value: "21 hours 30 minutes" },
      { name: "22 hours", value: "22 hours" },
      { name: "22 hours 30 minutes", value: "22 hours 30 minutes" },
      { name: "23 hours", value: "23 hours" },
      { name: "23 hours 30 minutes", value: "23 hours 30 minutes" },
      { name: "1 day", value: "1 day" },
    ];

    let currencyList = [{ name: "Select", value: "" }];
    Global.getEnvironmetKeyValue("availableCurrencies").map((x) =>
      currencyList.push({
        name: x.isoCode + " (" + x.symbol + ")",
        value: x.isoCode + " (" + x.symbol + ")",
      })
    );

    let portalCurrency = Global.getEnvironmetKeyValue("portalCurrencyCode");

    return (
      <div className="p-3">
        <div className="row">
          {(business === "hotel" || business === "activity") && (
            <React.Fragment>
              <div className="col-lg-4">
                {this.renderInput("name", business === "hotel" ? "Hotel Name" : "Activity Name")}
              </div>
              <div className="col-lg-4">{this.renderInput("toLocation", "Address / Location")}</div>
            </React.Fragment>
          )}

          {business === "hotel" && (
            <React.Fragment>
              {type === "Quotation" && (
                <div className="col-lg-4">
                  <DateRangePicker
                    isValid={this.props.datesIsValid}
                    cutOfDays={this.state.data.cutOfDays}
                    stayInDays={this.state.data.stayInDays}
                    minDays={localStorage.getItem("isUmrahPortal") ? 5 : 1}
                    maxDays={localStorage.getItem("isUmrahPortal") ? 30 : 5}
                    isSingleDateRangePicker={false}
                    handleDateChange={this.setDate}
                    dates={this.state.data.dates !== "" ? this.state.data.dates : undefined}
                    business={business}
                  />
                </div>
              )}

              {type === "Itinerary" && (
                <div className="col-lg-2">{this.renderSelect("day", "Itinerary Day", days)}</div>
              )}

              {type === "Itinerary" && (
                <div className="col-lg-2">
                  {this.renderSelect("nights", "No of Nights", nights)}
                </div>
              )}

              <div className="col-lg-2">
                {this.renderSelect("rating", "Star Rating", starRating)}
              </div>

              <div className="col-lg-2 text-nowrap">
                {this.renderInput("noRooms", "No of Rooms")}
              </div>
              <div className="col-lg-2">{this.renderInput("itemType", "Room Type")}</div>
              <div className="col-lg-2">{this.renderSelect("mealType", "Meal Type", mealSelection)}</div>
            </React.Fragment>
          )}

          {business === "activity" && (
            <React.Fragment>
              {type === "Quotation" && (
                <div className="col-lg-2">
                  {this.renderSingleDate("startDate", "Date", this.state.data.startDate, moment().format('YYYY-MM-DD'))}
                </div>
              )}

              {type === "Itinerary" && (
                <div className="col-lg-2">{this.renderSelect("day", "Itinerary Day", days)}</div>
              )}

              <div className="col-lg-2">
                {!this.props.importItem && this.renderSelect("duration", "Duration", durationList)}
                {this.props.importItem && this.renderInput("duration", "Duration")}
              </div>

              <div className="col-lg-2">{this.renderInput("guests", "No of Guests")}</div>

              <div className="col-lg-4">{this.renderInput("itemType", "Activity Type")}</div>
            </React.Fragment>
          )}

          {business === "air" && (
            <React.Fragment>
              <div className="col-lg-2">
                <label className="d-block" style={{ marginBottom: "14px" }}>
                  Trip Type
                </label>
                <div className="BE-Search-Radio">
                  <ul>
                    <li className="checked">
                      <input
                        checked={this.state.data.isRoundTrip}
                        value="RoundTrip"
                        name="Direction"
                        type="radio"
                        onChange={() => this.changeairTripType()}
                      />
                      <label>{Trans("_airTripDirection_Roundtrip")}</label>
                    </li>
                    <li>
                      <input
                        value="OneWay"
                        name="Direction"
                        type="radio"
                        checked={!this.state.data.isRoundTrip}
                        onChange={() => this.changeairTripType()}
                      />
                      <label>{Trans("_airTripDirection_Oneway")}</label>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-5">{this.renderInput("fromLocation", "From")}</div>

              <div className="col-lg-5">{this.renderInput("toLocation", "To")}</div>

              <div className="col-lg-12">
                {this.state.data.isRoundTrip && (
                  <span className="d-block text-primary mb-2">Enter Departure Flight Details</span>
                )}
                <div className="row">
                  <div className="col-lg-3">
                    <div className="row">
                      {type === "Quotation" && (
                        <div className="col-lg-7">
                          {this.renderSingleDate(
                            "departStartDate",
                            "Depart Date",
                            this.state.data.departStartDate,
                            moment().format('YYYY-MM-DD')
                          )}
                        </div>
                      )}

                      {type === "Itinerary" && (
                        <div className="col-lg-7">
                          {this.renderSelect("dayDepart", "Depart Day", days)}
                        </div>
                      )}

                      {/* <div className="col-lg-5">
                        {this.renderInput("departStartTime", "Depart Time")}
                      </div> */}
                      <div className="col-lg-5">
                        <div class="form-group departStartTime">
                          <label for="departStartTime">Depart Time</label>
                          <TimeField
                            class="form-control w-100"
                            name="departStartTime"
                            value={this.state.data.departStartTime}
                            onChange={e => this.handleChange({ currentTarget: e.target })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="row">
                      {type === "Quotation" && (
                        <div className="col-lg-7">
                          {this.renderSingleDate(
                            "departEndDate",
                            "Arrival Date",
                            this.state.data.departEndDate,
                            moment().format('YYYY-MM-DD')
                          )}
                        </div>
                      )}

                      {type === "Itinerary" && (
                        <div className="col-lg-7">
                          {this.renderSelect("dayDepartEnd", "Arrival Day", departEnddays)}
                        </div>
                      )}

                      {/* <div className="col-lg-5">
                        {this.renderInput("departEndTime", "Arrival Time")}
                      </div> */}
                      <div className="col-lg-5">
                        <div class="form-group departEndTime">
                          <label for="departEndTime">Arrival Time</label>
                          <TimeField
                            class="form-control w-100"
                            name="departEndTime"
                            value={this.state.data.departEndTime}
                            onChange={e => this.handleChange({ currentTarget: e.target })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="row">
                      <div className="col-lg-6">
                        {this.renderInput("departAirlineName", "Airline Name")}
                      </div>
                      <div className="col-lg-6">
                        {this.renderInput("departFlightNumber", "Flight Number")}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="row">
                      <div className="col-lg-5">{this.renderInput("departClass", "Class")}</div>
                      <div className="col-lg-4">
                        {this.renderDuration(["departDurationH", "departDurationM"], "Duration")}
                      </div>
                      <div className="col-lg-3">{this.renderInput("departStops", "Stops")}</div>
                    </div>
                  </div>
                </div>
              </div>

              {this.state.data.isRoundTrip && (
                <div className="col-lg-12">
                  <span className="d-block text-primary mb-2">Enter Return Flight Details</span>
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="row">
                        {type === "Quotation" && (
                          <div className="col-lg-7">
                            {this.renderSingleDate(
                              "returnStartDate",
                              "Depart Date",
                              this.state.data.returnStartDate,
                              moment().format('YYYY-MM-DD')
                            )}
                          </div>
                        )}

                        {type === "Itinerary" && (
                          <div className="col-lg-7">
                            {this.renderSelect("dayReturn", "Depart Day", returndepartStartdays)}
                          </div>
                        )}

                        {/* <div className="col-lg-5">
                          {this.renderInput("returnStartTime", "Depart Time")}
                        </div> */}
                        <div className="col-lg-5">
                          <div class="form-group returnStartTime">
                            <label for="returnStartTime">Depart Time</label>
                            <TimeField
                              class="form-control w-100"
                              name="returnStartTime"
                              value={this.state.data.returnStartTime}
                              onChange={e => this.handleChange({ currentTarget: e.target })}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="row">
                        {type === "Quotation" && (
                          <div className="col-lg-7">
                            {this.renderSingleDate(
                              "returnEndDate",
                              "Arrival Date",
                              this.state.data.returnEndDate,
                              moment().format('YYYY-MM-DD')
                            )}
                          </div>
                        )}

                        {type === "Itinerary" && (
                          <div className="col-lg-7">
                            {this.renderSelect("dayReturnEnd", "Arrival Day", daysReturn)}
                          </div>
                        )}

                        {/* <div className="col-lg-5">
                          {this.renderInput("returnEndTime", "Arrival Time")}
                        </div> */}
                        <div className="col-lg-5">
                          <div class="form-group returnEndTime">
                            <label for="returnEndTime">Depart Time</label>
                            <TimeField
                              class="form-control w-100"
                              name="returnEndTime"
                              value={this.state.data.returnEndTime}
                              onChange={e => this.handleChange({ currentTarget: e.target })}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="row">
                        <div className="col-lg-6">
                          {this.renderInput("returnAirlineName", "Airline Name")}
                        </div>
                        <div className="col-lg-6">
                          {this.renderInput("returnFlightNumber", "Flight Number")}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="row">
                        <div className="col-lg-5">{this.renderInput("returnClass", "Class")}</div>
                        <div className="col-lg-4">
                          {this.renderDuration(["returnDurationH", "returnDurationM"], "Duration")}
                        </div>
                        <div className="col-lg-3">{this.renderInput("returnStops", "Stops")}</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="col-lg-3">
                <div className="row">
                  <div className="col-lg-4">{this.renderInput("adult", "Adult")}</div>
                  <div className="col-lg-4">{this.renderInput("child", "Child")}</div>
                  <div className="col-lg-4">{this.renderInput("infant", "Infant")}</div>
                </div>
              </div>
            </React.Fragment>
          )}

          {business === "transfers" && (
            <React.Fragment>
              {!this.props.importItem && (
                <React.Fragment>
                  <div className="col-lg-4">
                    <div className="row">
                      <div className="col-lg-12">
                        {this.renderInput("fromLocation", "Pick-up Location")}
                      </div>
                      <div className="col-lg-6 text-nowrap d-none">
                        {this.renderInput("pickupType", "Type (eg. Airport, Hotel)")}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="row">
                      <div className="col-lg-12">
                        {this.renderInput("toLocation", "Drop-off Location")}
                      </div>
                      <div className="col-lg-6 text-nowrap d-none">
                        {this.renderInput("dropoffType", "Type (eg. Airport, Hotel)")}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}

              {this.props.importItem && (
                <React.Fragment>
                  <div className="col-lg-4">
                    <div className="row">
                      <div className="col-lg-12">
                        {this.renderInput("fromLocation", "Pick-up Location")}
                      </div>
                      <div className="col-lg-6 text-nowrap d-none">
                        {this.renderInput("pickupType", "Type (eg. Airport, Hotel)")}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="row">
                      <div className="col-lg-12">
                        {this.renderInput("toLocation", "Drop-off Location")}
                      </div>
                      <div className="col-lg-6 text-nowrap d-none">
                        {this.renderInput("dropoffType", "Type (eg. Airport, Hotel)")}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}

              {/* {this.props.importItem && (
                <div className="col-lg-8">{this.renderInput("name", "Transfer Name")}</div>
              )} */}

              <div className="col-lg-4">
                <div className="row">
                  {type === "Quotation" && (
                    <div className="col-lg-6">
                      {this.renderSingleDate(
                        "startDate",
                        "Transfers Date",
                        this.state.data.startDate,
                        moment().format('YYYY-MM-DD')
                      )}
                    </div>
                  )}

                  {type === "Itinerary" && (
                    <div className="col-lg-6">
                      {this.renderSelect("day", "Itinerary Day", days)}
                    </div>
                  )}

                  {/* {this.props.importItem && (
                    <div className="col-lg-6 text-nowrap">
                      {this.renderInput("duration", "Duration")}
                    </div>
                  )} */}

                  {!this.props.importItem && (
                    <div className="col-lg-6 text-nowrap">
                      {this.renderSelect("pickupTime", "Pick-up Time", PickupStartTime)}
                    </div>
                  )}

                  {this.props.importItem && (
                    <div className="col-lg-6 text-nowrap">
                      {this.renderSelect("pickupTime", "Pick-up Time", PickupStartTime)}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-lg-4">
                <div className="row">
                  <div className="col-lg-8 text-nowrap">
                    {this.renderInput("itemType", "Vehicle Type (eg. Bus, Sedan)")}
                  </div>
                  <div className="col-lg-4">{this.renderInput("guests", "No of Guests")}</div>
                </div>
              </div>
            </React.Fragment>
          )}

          {business === "custom" && (
            <React.Fragment>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-4">{this.renderInput("toLocation", "Location")}</div>
                  <div className="col-lg-5">{this.renderInput("name", "Item Name")}</div>
                  {type === "Quotation" && (
                    <div className="col-lg-3">
                      {this.renderSingleDate("startDate", "Date", this.state.data.startDate, moment().format('YYYY-MM-DD'))}
                    </div>
                  )}
                  {type === "Itinerary" && (
                    <div className="col-lg-3">
                      {this.renderSelect("day", "Itinerary Day", days)}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-lg-2">
                {this.renderInput("itemType", "Item Type (eg. Restaurant)")}
              </div>
            </React.Fragment>
          )}

          <div className={business === "custom" ? "col-lg-4" : "col-lg-4"}>
            <div className="row">
              <div className="col-lg-6">{this.renderInput("vendor", "Vendor/Supplier")}</div>

              <div className="col-lg-6">
                {this.renderInput("sellPrice", "Sell Price (" + portalCurrency + ")", "text", this.state.data.isSellPriceReadonly, this.handleAmountFields)}
              </div>

              {/* <div className="col-lg-2">
                {this.renderInput("costPrice", "Cost Price (" + portalCurrency + ")")}
              </div>
              <div className="col-lg-2">
                {this.renderInput("sellPrice", "Sell Price (" + portalCurrency + ")")}
              </div> */}
            </div>
          </div>

          <div className="col-lg-12">
            <div className="row pull-right mt-2 mb-2">
              <div className="col-lg-12">
                <button class="btn btn-link p-0 m-0 text-primary" onClick={this.handleShowHideTax}>
                  {this.state.data.isShowTax ? "Hide" : "Show"} more
                </button>
              </div>
            </div>
          </div>

          {this.state.data.isShowTax &&
            <div className="col-lg-12">
              <div className="row">

                {/* <div className="col-lg-2">{this.renderInput("vendor", "Vendor/Supplier")}</div>

                <div className="col-lg-2">{this.renderInput("brn", "Confirmation Number","text", false, this.validateBRN)}</div> */}

                <div className="col-lg-2">
                  {this.renderSelect("supplierCurrency", "Supplier Currency", currencyList)}
                </div>

                <div className="col-lg-2">
                  {this.renderInput("conversionRate", "Conversion Rate", "text", false, this.handleAmountFields)}
                </div>

                <div className="col-lg-2">
                  {this.renderInput("supplierCostPrice", "Supplier Cost Price", "text", false, this.handleAmountFields)}
                </div>

                <div className="col-lg-2">
                  {this.renderInput("supplierTaxPrice", "Supplier Tax", "text", false, this.handleAmountFields)}
                </div>

                <div className="col-lg-2">
                  {this.renderInput("costPrice", "Agent Cost Price (" + portalCurrency + ")", "text", true)}
                </div>

                <div className="col-lg-2">
                  {this.renderInput("markupPrice", "Agent Markup", "text", false, this.handleAmountFields)}
                </div>

                <div className="col-lg-2">
                  {this.renderInput("discountPrice", "Discount", "text", false, this.handleAmountFields)}
                </div>

                <div className="col-lg-2">
                  {this.renderInput("CGSTPrice", "CGST", "text", false, this.handleAmountFields)}
                </div>

                <div className="col-lg-2">
                  {this.renderInput("SGSTPrice", "SGST", "text", false, this.handleAmountFields)}
                </div>

                <div className="col-lg-2">
                  {this.renderInput("IGSTPrice", "IGST", "text", false, this.handleAmountFields)}
                </div>

                <div className="col-lg-2">{this.renderInput("brn", "Confirmation Number", "text", false, this.validateBRN)}</div>

                <div className="col-lg-2">
                  {this.renderCurrentDateWithDuration(
                    "bookBefore",
                    "Book Before (Supplier)", moment().format('YYYY-MM-DD')
                  )}
                </div>
              </div>
            </div>
          }

          <div className="col-lg-10">
            {this.renderTextarea(
              "description",
              "Description",
              "Add your own description or notes about the item you would like to share with the client."
            )}
          </div>

          <div className="col-lg-2">
            <div className="form-group">
              <label className="d-block">&nbsp;</label>
              <button
                id="AddItem"
                onClick={() => this.handleAddItem(this.state.editMode)}
                className="btn btn-primary w-100 text-capitalize"
              >
                {this.state.editMode ? "Edit" : "Add"}{" "}
                {type === "Quotation" && business !== "custom" && "Offline"} {business === "air" ? "Flight" : business}
                {business === "custom" && " Item"}
              </button>
            </div>
          </div>
        </div>
        {this.state.showPopup ? (
          <ModelPopup
            header={this.state.popupTitle}
            content={this.state.popupContent}
            handleHide={this.handlePopup}
          />
        ) : null}
      </div>
    );
  }
}

export default QuotationAddOffline;
