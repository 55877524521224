var appPath = "https://unified-api.dev.tourwizonline.com/";

// API Requester for Reports
export function apiRequester_unified_api(reqURL, reqOBJ, callback, reqMethod) {
  let userToken = localStorage.getItem("userToken");
  /*
  if (window.location.host.indexOf("localhost") === 0) {
    if (reqURL.startsWith('/'))
      reqURL = reqURL.substring(1);
    appPath = "http://localhost:5000/";
    reqURL = reqURL.replace("tw/", "");
  }
  */
  var xhttp;
  xhttp = new XMLHttpRequest();
  xhttp.open(reqMethod === undefined ? "POST" : reqMethod, appPath + reqURL, true);
  xhttp.setRequestHeader("Content-Type", "application/json");
  xhttp.setRequestHeader("Authorization", "Bearer " + userToken);
  xhttp.send(JSON.stringify(reqOBJ));
  xhttp.onreadystatechange = function () {
    if (this.readyState === 4 && this.status === 200) {
      callback(this.responseText ? JSON.parse(this.responseText) : null);
    }
  };
}