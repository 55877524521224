import React from "react";

const Stops = props => {
  const count = Math.ceil(props[0]);
  const stops = [...Array(count).keys()];

  return (
    <React.Fragment>
      <div className="stops-cont">
        {stops.map(stop => (
          <span key={stop}></span>
        ))}
      </div>
    </React.Fragment>
  );
};

export default Stops;
