import React from "react";

const AuthorizeComponent = (props) => {
  /*
  PageName-elementName
  itinerary-list~delete-itinerary
  itinerary-list~delete-quotation
  itinerary-details~edit-itinerary
  itinerary-details~edit-quotation
  itinerary-details~search
  itinerary-details~book-itinerary-manually
  itinerary-details~book-itinerary
  itinerary-details~send-itinerary
  itinerary-details~book-quotation
  itinerary-details~send-quotation
  itinerary-details~edit-item
  itinerary-details~delete-item
  itinerary-details~amount-popup
  */
  if (localStorage.getItem('portalType') === 'B2C')
    return "";
  else
    return props.children
}
/*
const AuthorizeComponent = (props) => {
  const permissions = ['inquiries', 'itineraries', 'quotations', 'itinerary-add', 'quotations-create'];
  
  let isPermission = permissions.indexOf(props.title) > -1
  return (
    <React.Fragment>
      {isPermission && props.children}
      {!isPermission && props.type === "page" &&
        <center>
          <div className="mt-5 m-3 p-0 m-0 text-secondary">
            Unauthorised access!
            <br />
            <button
              className="mt-2 btn btn-link p-0 m-0 text-secondary"
              onClick={() => props.history.push("/")}>
              Click here for dashboard.
            </button>
          </div>
        </center>
      }

    </React.Fragment>
  );
};
*/
export default AuthorizeComponent;
