import * as Global from "../helpers/global";
export const Language = {
  _HotDeals: { "en-US": "Hot Deals", "ar-AE": "صفقات ساخنة", "gu-IN": "હોટ ડીલ્સ" },
  _activityLocationSearching: {
    "en-US": "Searching...",
    "ar-AE": "يبحث...",
    "gu-IN": "શોધી રહ્યું છે ...",
  },
  _activityDate: { "en-US": "Activity Date", "ar-AE": "تاريخ النشاط", "gu-IN": "પ્રવૃત્તિ તારીખ" },
  _transfersDate: { "en-US": "Transfer Date", "ar-AE": "تاريخ التحويل", "gu-IN": "પરિવહન તારીખ" },
  _transfersreturnDate: {
    "en-US": "Transfer Return Date",
    "ar-AE": "تاريخ إرجاع التحويل",
    "gu-IN": "પરિવહન રીટર્ન તારીખ",
  },
  _pickuphoteldetails: {
    "en-US": "Pickup Hotel Details",
    "ar-AE": "تفاصيل الفندق",
    "gu-IN": "દુકાન હોટેલ વિગતો",
  },
  _returnpickuphoteldetails: {
    "en-US": "Return Pickup Hotel Details",
    "ar-AE": "العودة تفاصيل الفندق",
    "gu-IN": "રીટર્ન પિકઅપ હોટલની વિગતો",
  },
  _dropoffhoteldetails: {
    "en-US": "Dropoff Hotel Details",
    "ar-AE": "تفاصيل الفندق",
    "gu-IN": "હોટેલ વિગતો",
  },
  _returndropoffhoteldetails: {
    "en-US": "Return Dropoff Hotel Details",
    "ar-AE": "انخفاض العودة تفاصيل عن الفندق",
    "gu-IN": "રીટર્ન ડ્રોપઓફ હોટેલ વિગતો",
  },
  _arrivingairlinedetails: {
    "en-US": "Arriving Airline Details",
    "ar-AE": "وصول تفاصيل شركة الطيران",
    "gu-IN": "એરલાઇન વિગતો આવી રહી છે",
  },
  _returnarrivingairlinedetails: {
    "en-US": "Return Arriving Airline Details",
    "ar-AE": "عودة تفاصيل الخطوط الجوية القادمة",
    "gu-IN": "રીટર્ન આગમન એરલાઇન વિગતો",
  },
  _departingairlinedetails: {
    "en-US": "Departing Airline Details",
    "ar-AE": "مغادرة تفاصيل شركة الطيران",
    "gu-IN": "પ્રસ્થાન એરલાઇન વિગતો",
  },
  _returndepartingairlinedetails: {
    "en-US": "Return Departing Airline Details",
    "ar-AE": "عودة تفاصيل شركة الطيران المغادرة",
    "gu-IN": "રીટર્ન રવાના થતી એરલાઇન વિગતો",
  },
  _otherdetails: { "en-US": "Other Details", "ar-AE": "تفاصيل أخرى", "gu-IN": "અન્ય વિગતો" },
  _pickuphotel: { "en-US": "Pick Up Hotel", "ar-AE": "فندق بيك اب", "gu-IN": "હોટેલ ચૂંટો" },
  _returnpickuphotel: {
    "en-US": "Return Pick Up Hotel",
    "ar-AE": "فندق العودة بيك اب",
    "gu-IN": "રીટર્ન પિક અપ હોટેલ",
  },
  _pickupairport: { "en-US": "Pick Up Airport", "ar-AE": "تلتقط المطار", "gu-IN": "એરપોર્ટ ચૂંટો" },
  _returnpickupairport: {
    "en-US": "Return Pick Up Airport",
    "ar-AE": "العودة بيك اب المطار",
    "gu-IN": "રીટર્ન પીક અપ એરપોર્ટ",
  },
  _dropoffhotel: { "en-US": "Dropoff Hotel", "ar-AE": "فندق دروبوف", "gu-IN": "ડ્રોપઓફ હોટેલ" },
  _returndropoffhotel: {
    "en-US": "Return Dropoff Hotel",
    "ar-AE": "فندق العودة المنسدلة",
    "gu-IN": "રીટર્ન ડ્રોપઓફ હોટેલ",
  },
  _dropoffairport: { "en-US": "Dropoff Airport", "ar-AE": "مطار دروب", "gu-IN": "ડ્રોપઓફ એરપોર્ટ" },
  _returndropoffairport: {
    "en-US": "Return Dropoff Airport",
    "ar-AE": "مطار العودة",
    "gu-IN": "રીટર્ન ડ્રોપઓફ એરપોર્ટ",
  },
  _activitySearchPlaceholder: {
    "en-US": "Choose City",
    "ar-AE": "اختر المدينة",
    "gu-IN": "શહેર પસંદ કરો",
  },
  _chooseFile: { "en-US": "Choose File", "ar-AE": "اختر ملف", "gu-IN": "ફાઇલ પસંદ કરો" },
  _activityTypeFilter: {
    "en-US": "Activity Type",
    "ar-AE": "نوع النشاط",
    "gu-IN": "પ્રવૃત્તિ પ્રકાર",
  },
  _transfersTypeFilter: {
    "en-US": "Transfers Type",
    "ar-AE": "نوع التحويلات",
    "gu-IN": "સ્થાનાંતરણનો પ્રકાર",
  },
  _addCoTraveler: {
    "en-US": "Add Co-Traveller",
    "ar-AE": "أضف مسافر ",
    "gu-IN": "સહ-મુસાફરો ઉમેરો",
  },
  _lblCusstomeClass: {
    "en-US": "Customer Class",
    "ar-AE": "فئة العميل",
    "gu-IN": "સહ-મુસાફરો ઉમેરો",
  },
  _lblBranch: { "en-US": "Branch", "ar-AE": "فرع", "gu-IN": "સહ-મુસાફરો ઉમેરો" },
  _addCustomer: { "en-US": "Add Customer", "ar-AE": "إضافة العملاء", "gu-IN": "સહ-મુસાફરો ઉમેરો" },
  _widgetPaxMessage: {
    "en-US": "Maximum 9 traveller allowed.",
    "ar-AE": "Maximum 9 traveller allowed.",
    "gu-IN": "Maximum 9 traveller allowed.",
  },
  _addMoreItems: {
    "en-US": "Add more items to Cart",
    "ar-AE": "أضف المزيد من العناصر إلى سلة التسوق",
    "gu-IN": "વધુ વસ્તુઓ ઉમેરો",
  },
  _addToCart: { "en-US": "Select", "ar-AE": "تحديد", "gu-IN": "સૂચી માં સામેલ કરો" },
  _additionalFacilities: {
    "en-US": "Additional Facilities",
    "ar-AE": "مرافق إضافية",
    "gu-IN": "વધારાની સુવિધાઓ",
  },
  _lbladditionalServices: {
    "en-US": "Additional Services",
    "ar-AE": "خدمات إضافية",
    "gu-IN": "વધારાની સુવિધાઓ",
  },
  _lbladditionalInformations: {
    "en-US": "Additional Information",
    "ar-AE": "معلومة اضافية",
    "gu-IN": "વધારાની માહિતી",
  },
  _additionalFacilitiesHeader: {
    "en-US": "Additional Facilities",
    "ar-AE": "مرافق إضافية",
    "gu-IN": "વધારાની સુવિધાઓ",
  },
  _address: { "en-US": "Address", "ar-AE": "العنوان ", "gu-IN": "સરનામું" },
  _adultsWithBraces: { "en-US": "Adult(s)", "ar-AE": "البالغين", "gu-IN": "પુખ્ત વયના લોકો" },
  _adultsWithBracesSmall: { "en-US": "adult(s)", "ar-AE": "البالغين", "gu-IN": "પુખ્ત વયના લોકો" },
  _age: { "en-US": "Age", "ar-AE": "صورة", "gu-IN": "ઉંમર" },
  _airDeparture: { "en-US": "Departure", "ar-AE": "مغادرة", "gu-IN": "પ્રસ્થાન" },
  _airLocationSearching: {
    "en-US": "Searching...",
    "ar-AE": "يبحث...",
    "gu-IN": "શોધી રહ્યું છે ...",
  },
  _airNonStop: { "en-US": "non stop", "ar-AE": "بدون توقف", "gu-IN": "કોઈપણ વિરામ વગર" },
  _airReturn: { "en-US": "Return", "ar-AE": "إرجاع", "gu-IN": "પાછું આવવું" },
  _airSearchPlaceholder: {
    "en-US": "Enter Origin",
    "ar-AE": "أدخل المنشأ",
    "gu-IN": "પ્રસ્થાન સ્થાન દાખલ કરો",
  },
  _vehicleSearchPlaceholder: {
    "en-US": "Enter Pick Up",
    "ar-AE": "أدخل بيك اب",
    "gu-IN": "પીક અપ દાખલ કરો",
  },
  _vehicleToSearchPlaceholder: {
    "en-US": "Enter Drop off",
    "ar-AE": "أدخل الإنزال",
    "gu-IN": "છોડો બંધ દાખલ કરો",
  },
  _busSearchPlaceholder: {
    "en-US": "Enter Origin",
    "ar-AE": "أدخل المنشأ",
    "gu-IN": "પ્રસ્થાન સ્થાન દાખલ કરો",
  },
  _transfersLocationSearching: {
    "en-US": "Searching...",
    "ar-AE": "يبحث",
    "gu-IN": "શોધી રહ્યું છે ...",
  },
  _transfersSearchPlaceholder: {
    "en-US": "Enter Pickup Location",
    "ar-AE": "أدخل موقع الالتقاط",
    "gu-IN": "પસંદ સ્થાન દાખલ કરો",
  },
  _transfersToSearchPlaceholder: {
    "en-US": "Enter Dropoff Location",
    "ar-AE": "أدخل موقع الانسحاب",
    "gu-IN": "ડ્રોપ સ્થાન દાખલ કરો",
  },
  _airStop: { "en-US": "stop", "ar-AE": "توقف", "gu-IN": "રોકાણ" },
  _airStops: { "en-US": "stops", "ar-AE": "توقف", "gu-IN": "રોકાણ" },
  _airToSearchPlaceholder: {
    "en-US": "Enter Destination",
    "ar-AE": "أدخل الوجهة",
    "gu-IN": "લક્ષ્યસ્થાન દાખલ કરો",
  },
  _airTripDirection_Oneway: { "en-US": "One way", "ar-AE": "ذهاب فقط", "gu-IN": "એકતરફી મુસાફરી" },
  _airTripDirection_Roundtrip: {
    "en-US": "Round trip",
    "ar-AE": "ذهاب وعودة",
    "gu-IN": "ભ્રમણકક્ષાની મુસાફરી",
  },
  _airTripType_Domestic: { "en-US": "Domestic", "ar-AE": "محلي", "gu-IN": "રાષ્ટ્રીય" },
  _airTripType_International: {
    "en-US": "International",
    "ar-AE": "دولي",
    "gu-IN": "આંતરરાષ્ટ્રીય",
  },
  _alert: { "en-US": "Alert", "ar-AE": "إنذار", "gu-IN": "સજાગ" },
  _amount: { "en-US": "Total Amount", "ar-AE": "المبلغ الإجمالي", "gu-IN": "રકમ" },
  _anniversarydate: {
    "en-US": "Anniversary Date",
    "ar-AE": "تاريخ الذكرى",
    "gu-IN": "વર્ષગાંઠની તારીખ",
  },
  _areYouSureConfirmation: {
    "en-US": "Are you sure ?",
    "ar-AE": "هل أنت واثق ؟",
    "gu-IN": "શું તમને ખાતરી છે?",
  },
  _areYouSureWishToRemove: {
    "en-US": "Are you sure? You wish to remove the",
    "ar-AE": "هل أنت واثق؟ كنت ترغب في إزالة",
    "gu-IN": "શું તમને ખાતરી છે? તમે દૂર કરવા માંગો છો",
  },
  _availability: { "en-US": "Availability", "ar-AE": "البحث عن الإمكانية", "gu-IN": "ઉપલભ્યતા" },
  _birthdate: { "en-US": "Birth Date", "ar-AE": "تاريخ الميلاد", "gu-IN": "જન્મતારીખ" },
  _boardTypes: { "en-US": "Board types", "ar-AE": "أنواع المجلس", "gu-IN": "બોર્ડ પ્રકારો" },
  _bookNow: { "en-US": "Book Now", "ar-AE": "احجز الآن", "gu-IN": "બુક કરો" },
  _bookingDate: { "en-US": "Booking Date", "ar-AE": "تاريخ الحجز", "gu-IN": "બુકિંગ તારીખ" },
  _bookingReferenceNumber: {
    "en-US": "Booking Reference Number",
    "ar-AE": "رقم مرجع الحجز",
    "gu-IN": "બુકિંગ સંદર્ભ નંબર",
  },
  _bookingStatus: { "en-US": "Booking Status", "ar-AE": "وضع الحجز", "gu-IN": "બુકિંગ સ્થિતિ" },
  _bookingStatusBooked: { "en-US": "Booked", "ar-AE": "في انتظار الدفع", "gu-IN": "બુક કરાવ્યું" },
  _bookingStatusConfirmed: { "en-US": "Confirmed", "ar-AE": "وأكد", "gu-IN": "પુષ્ટિ મળી" },
  _bookingStatusCancel: { "en-US": "Cancel", "ar-AE": "إلغاء", "gu-IN": "" },
  _bookingStatusModifiedSucessfully: {
    "en-US": "Modified Successfully",
    "ar-AE": "تم التعديل بنجاح",
    "gu-IN": "",
  },
  _bookingStatusTicketOnProcess: {
    "en-US": "Ticket On Process",
    "ar-AE": "فشل تذكرة إلكترونية",
    "gu-IN": "બુક કરાવ્યું",
  },
  _bookingStatusTicketNotGenerated: {
    "en-US": "Ticket On Process",
    "ar-AE": "فشل تذكرة إلكترونية",
    "gu-IN": "બુક કરાવ્યું",
  },
  _bookingStatusFailed: { "en-US": "Failed", "ar-AE": "فشل", "gu-IN": "નિષ્ફળ" },
  _bookingStatusCancelRequest: {
    "en-US": "Cancel Request",
    "ar-AE": "إلغاء الطلب",
    "gu-IN": "નિષ્ફળ",
  },
  _bookingStatusAmendRequest: { "en-US": "Amend Request", "ar-AE": "تعديل طلب", "gu-IN": "નિષ્ફળ" },
  _bookingStatusRequestInProcess: {
    "en-US": "Request In Process",
    "ar-AE": "طلب في العملية",
    "gu-IN": "વિનંતી પ્રક્રિયામાં છે",
  },
  _bookingStatusOnRequest: { "en-US": "On Request", "ar-AE": "تحت الطلب", "gu-IN": "વિનંતી પર" },
  _bookingStatusExpiredRequest: {
    "en-US": "Expired Request",
    "ar-AE": "طلب منتهية الصلاحية",
    "gu-IN": "સમાપ્ત વિનંતી",
  },
  _bookingStatusDeniedRequest: {
    "en-US": "Denied Request",
    "ar-AE": "طلب مرفوض",
    "gu-IN": "વિનંતી નામંજૂર",
  },
  _bookingStatusCancelled: { "en-US": "Cancelled", "ar-AE": "ملغي", "gu-IN": "રદ કર્યું" },
  _bookingStepCustomerInformation: {
    "en-US": "Customer Information",
    "ar-AE": "معلومات العميل",
    "gu-IN": "ગ્રાહક માહિતી",
  },
  _bookingStepPaymentInformation: {
    "en-US": "Payment Information",
    "ar-AE": "معلومات الدفع",
    "gu-IN": "ચુકવણીની માહિતી",
  },
  _bookingStepReview: { "en-US": "Review", "ar-AE": "مراجعة", "gu-IN": "સમીક્ષા" },
  _bookingTerms: { "en-US": "Booking Policies", "ar-AE": "سياسات الحجز", "gu-IN": "બુકિંગની શરતો" },
  _bookingTermsPopupTitle: {
    "en-US": "Booking Policies",
    "ar-AE": "سياسات الحجز",
    "gu-IN": "બુકિંગની શરતો",
  },
  _browseForFile: {
    "en-US": "Browse for file...",
    "ar-AE": "تصفح للبحث عن ملف ...",
    "gu-IN": "ફાઇલ માટે બ્રાઉઝ કરો ...",
  },
  _btnBack: { "en-US": "Back", "ar-AE": "عودة", "gu-IN": "પાછું" },
  _btnConfirmBooking: {
    "en-US": "Complete Booking",
    "ar-AE": "إتمام الحجز",
    "gu-IN": "બુકિંગની પુષ્ટિ કરો",
  },
  _btnLogin: { "en-US": "Sign-in", "ar-AE": "تسجيل الدخول", "gu-IN": "પ્રવેશ કરો" },
  _btnPrint: { "en-US": "Print", "ar-AE": "طباعة", "gu-IN": "છાપવું" },
  _btnSignUp: { "en-US": "Sign Up", "ar-AE": "سجل", "gu-IN": "સાઇન અપ કરો" },
  _businessName: { "en-US": "Business Name", "ar-AE": "الاسم التجاري", "gu-IN": "વ્યવસાયનું નામ" },
  _cancel: { "en-US": "Cancel", "ar-AE": "الغاء", "gu-IN": "રદ કરો" },
  _changeRoom: { "en-US": "Change Room", "ar-AE": "غرفة التغيير", "gu-IN": "ઓરડો બદલો" },
  _childrenWithBracesSmall: { "en-US": "child(ren)", "ar-AE": "الأطفال", "gu-IN": "બાળકો" },
  _city: { "en-US": "City", "ar-AE": "المدينة", "gu-IN": "શહેર" },
  _coTravellertConfirmationOnDelete: {
    "en-US": "Are you sure you want to delete this Co-Traveller?",
    "ar-AE": "هل أنت متأكد أنك تريد حذف هذا البند؟",
    "gu-IN": "શું તમે ખરેખર આ સહ-પ્રવાસીને કા deleteી નાખવા માંગો છો?",
  },
  _customerConfirmationOnDelete: {
    "en-US": "Are you sure you want to delete this Customer?",
    "ar-AE": "هل أنت متأكد أنك تريد حذف هذا البند؟",
    "gu-IN": "શું તમે ખરેખર આ સહ-પ્રવાસીને કા deleteી નાખવા માંગો છો?",
  },
  _confirmed: { "en-US": "Confirmed", "ar-AE": "وأكد", "gu-IN": "પુષ્ટિ મળી" },
  _contactPhone: { "en-US": "Contact Phone", "ar-AE": "رقم التواصل", "gu-IN": "ફોન સંપર્ક" },
  _continueBooking: {
    "en-US": "Proceed to Payment",
    "ar-AE": "الشروع في دفع",
    "gu-IN": "બુકિંગ ચાલુ રાખો",
  },
  _copyRightsText: {
    "en-US": "Copyright 2021-2023 by travelcarma",
    "ar-AE": "حقوق الطبع والنشر 2023-2021 بواسطة ترافيلكارما",
    "gu-IN": "Travelcarma દ્વારા કૉપિરાઇટ 2021-2023",
  },
  _copyRightsByText: {
    "en-US": "Copyright 2021-2023 by ",
    "ar-AE": "حقوق النشر 2021-2023 بواسطة ",
    "gu-IN": "કૉપિરાઇટ 2021-2023 દ્વારા ",
  },
  _confirmcancel: {
    "en-US": "Confirm Cancel",
    "ar-AE": "أكد إلغاء",
    "gu-IN": "રદ કરવાની પુષ્ટિ કરો",
  },
  _country: { "en-US": "Country", "ar-AE": "البلد", "gu-IN": "દેશ" },
  _datePickerApply: { "en-US": "Apply", "ar-AE": "تطبيق", "gu-IN": "લાગુ કરો" },
  _datePickerCancel: { "en-US": "Cancel", "ar-AE": "الغاء", "gu-IN": "રદ કરો" },
  _datePickerCustom: { "en-US": "Custom", "ar-AE": "العادة", "gu-IN": "કસ્ટમ" },
  _datePickerDayFr: { "en-US": "Fr", "ar-AE": "الجمعة", "gu-IN": "શુક્ર" },
  _datePickerDayMo: { "en-US": "Mo", "ar-AE": "الإثنين", "gu-IN": "સોમ" },
  _datePickerDaySa: { "en-US": "Sa", "ar-AE": "السبت", "gu-IN": "શનિ" },
  _datePickerDaySu: { "en-US": "Su", "ar-AE": "شمس", "gu-IN": "રવિ" },
  _datePickerDayTh: { "en-US": "Th", "ar-AE": "الخميس", "gu-IN": "ગુરુ" },
  _datePickerDayTu: { "en-US": "Tu", "ar-AE": "الثلاثاء", "gu-IN": "મંગળ" },
  _datePickerDayWe: { "en-US": "We", "ar-AE": "تزوج", "gu-IN": "બુધ" },
  _datePickerFrom: { "en-US": "From", "ar-AE": "من", "gu-IN": "થી" },
  _datePickerMonthApril: { "en-US": "April", "ar-AE": "أبريل", "gu-IN": "એપ્રિલ" },
  _datePickerMonthAugust: { "en-US": "August", "ar-AE": "أغسطس", "gu-IN": "ઓગસ્ટ" },
  _datePickerMonthDecember: { "en-US": "December", "ar-AE": "ديسمبر", "gu-IN": "ડિસેમ્બર" },
  _datePickerMonthFebruary: { "en-US": "February", "ar-AE": "فبراير", "gu-IN": "ફેબ્રુઆરી" },
  _datePickerMonthJanuary: { "en-US": "January", "ar-AE": "يناير", "gu-IN": "જાન્યુઆરી" },
  _datePickerMonthJuly: { "en-US": "July", "ar-AE": "يوليو", "gu-IN": "જુલાઈ" },
  _datePickerMonthJune: { "en-US": "June", "ar-AE": "يونيو", "gu-IN": "જૂન" },
  _datePickerMonthMarch: { "en-US": "March", "ar-AE": "مارس", "gu-IN": "માર્ચ" },
  _datePickerMonthMay: { "en-US": "May", "ar-AE": "مايو", "gu-IN": "મે" },
  _datePickerMonthNovember: { "en-US": "November", "ar-AE": "تشرين الثاني", "gu-IN": "નવેમ્બર" },
  _datePickerMonthOctober: { "en-US": "October", "ar-AE": "أكتوبر", "gu-IN": "ઓક્ટોબર" },
  _datePickerMonthSeptember: { "en-US": "September", "ar-AE": "سبتمبر", "gu-IN": "સપ્ટેમ્બર" },
  _datePickerTo: { "en-US": "To", "ar-AE": "إلى", "gu-IN": "પ્રતિ" },
  _detailsAndPrintView: {
    "en-US": "Details & Print view",
    "ar-AE": "تفاصيل ونسخة للطباعة",
    "gu-IN": "વિગતો અને છાપવાનું દૃશ્ય",
  },
  _documentNumber: {
    "en-US": "Document Number",
    "ar-AE": "رقم الوثيقة",
    "gu-IN": "દસ્તાવેજ ક્રમાંક",
  },
  _documentType: { "en-US": "Document Type", "ar-AE": "نوع الوثيقة", "gu-IN": "દસ્તાવેજ પ્રકાર" },
  _duration: { "en-US": "Duration", "ar-AE": "مدة الرحلة", "gu-IN": "સમયગાળો" },
  _editCoTraveler: {
    "en-US": "Edit Co-Traveller",
    "ar-AE": "تحرير المسافرين المشاركين",
    "gu-IN": "સંપાદિત કરો સહ-મુસાફરો",
  },
  _editCustomer: {
    "en-US": "Edit Customer",
    "ar-AE": "تحرير العملاء",
    "gu-IN": "સંપાદિત કરો સહ-મુસાફરો",
  },
  _editProfile: {
    "en-US": "Edit Profile",
    "ar-AE": "تعديل الملف الشخصي",
    "gu-IN": "સંપાદિત કરો પ્રોફાઇલ",
  },
  _email: { "en-US": "Email", "ar-AE": "الايميل", "gu-IN": "સંદેશો" },
  _expiryDate: { "en-US": "Expiry Date", "ar-AE": "تاريخ الانتهاء", "gu-IN": "અંતિમ તારીખ" },
  _facilities: { "en-US": "Facilities", "ar-AE": "التسهيلات", "gu-IN": "સુવિધાઓ" },
  _failed: { "en-US": "Failed", "ar-AE": "فشل", "gu-IN": "નિષ્ફળ" },
  _fareSummary: { "en-US": "Fare Summary", "ar-AE": "ملخص الرسوم", "gu-IN": "ભાડાનો સારાંશ" },
  _faretypeFilter: { "en-US": "Fare Type", "ar-AE": "نوع الأجرة", "gu-IN": "ભાડાનો પ્રકાર" },
  _filters: { "en-US": "Filters", "ar-AE": "مرشحات", "gu-IN": "ફિલ્ટરો: " },
  _applyFilter: { "en-US": "Apply Filter", "ar-AE": "تطبيق المرشحات", "gu-IN": "ફિલ્ટર લાગુ કરો" },
  _filterBy: { "en-US": "Filter By", "ar-AE": "رشح ب", "gu-IN": "દ્વારા ફિલ્ટર કરો: " },
  _filterClear: { "en-US": "Clear Filters", "ar-AE": "مرشحات واضحة", "gu-IN": "ગાળકો સાફ કરો" },
  _filterPrice: { "en-US": "Price", "ar-AE": "سعر", "gu-IN": "કિંમત" },
  _filterShowLess: { "en-US": "Show Less", "ar-AE": "إظهار أقل", "gu-IN": "ઓછું બતાવો" },
  _filterShowMore: { "en-US": "Show More", "ar-AE": "أظهر المزيد", "gu-IN": "વધુ બતાવો" },
  _filteramenity: { "en-US": "Amenities", "ar-AE": "وسائل الراحة", "gu-IN": "સગવડતા" },
  _filtercategory: { "en-US": "Category", "ar-AE": "فئة", "gu-IN": "વર્ગ" },
  _filtercurrency: { "en-US": "Currency", "ar-AE": "العملة", "gu-IN": "ચલણ" },
  _filterhotelName: { "en-US": "Hotel Name", "ar-AE": "اسم الفندق", "gu-IN": "હોટેલનું નામ" },
  _filterlocation: { "en-US": "Location", "ar-AE": "الموقع", "gu-IN": "સ્થાન" },
  _filtermealtype: { "en-US": "Meal Type(s)", "ar-AE": "نوع الوجبة", "gu-IN": "ભોજનનો પ્રકાર" },
  _filtername: { "en-US": "Name", "ar-AE": "الاسم", "gu-IN": "નામ" },
  _filternearby: { "en-US": "Nearby", "ar-AE": "مجاور", "gu-IN": "નજીકમાં" },
  _filterpackageName: { "en-US": "Package Name", "ar-AE": "اسم الحزمة", "gu-IN": "પેકેજ નામ" },
  _filteractivityName: {
    "en-US": "Activity Name",
    "ar-AE": "اسم النشاط",
    "gu-IN": "પ્રવૃત્તિ નામ",
  },
  _filtertransfersName: {
    "en-US": "Transfers Name",
    "ar-AE": "اسم التحويل",
    "gu-IN": "નામ સ્થાનાંતરણ",
  },
  _filtergroundserviceName: {
    "en-US": "Ground Service Name",
    "ar-AE": "اسم الخدمة الأرضية",
    "gu-IN": "ગ્રાઉન્ડ સર્વિસ નામ",
  },
  _filterpricerange: { "en-US": "Price Range", "ar-AE": "مدى الاسعار", "gu-IN": "કિંમત શ્રેણી" },
  _filterstarrating: { "en-US": "Star Rating", "ar-AE": "تصنيف النجوم", "gu-IN": "સ્ટાર રેટિંગ" },
  _filterdeparturestarttime: {
    "en-US": "Departure Time from ",
    "ar-AE": "وقت المغادرة من",
    "gu-IN": "પ્રસ્થાન પ્રારંભ સમય",
  },
  _filterdepartureendtime: {
    "en-US": "Arrival Time at ",
    "ar-AE": "وقت الوصول الساعة",
    "gu-IN": "પ્રસ્થાન સમાપ્ત સમય",
  },
  _filterstops: { "en-US": "Stops", "ar-AE": "توقف", "gu-IN": "રોકાણ" },
  _filterarrivalstarttime: {
    "en-US": "Departure Time from ",
    "ar-AE": "وقت المغادرة من",
    "gu-IN": "આગમન પ્રારંભ સમય",
  },
  _filterarrivalendtime: {
    "en-US": "Arrival Time at ",
    "ar-AE": "وقت الوصول الساعة",
    "gu-IN": "આગમન સમાપ્ત સમય",
  },
  _filterairline: { "en-US": "Airline", "ar-AE": "ب شركة الخطوط", "gu-IN": "એરલાઇન" },
  _filterserviceclass: { "en-US": "Service Class", "ar-AE": "فئة الخدمة", "gu-IN": "સેવા વર્ગ" },
  _filterfaretype: { "en-US": "Fare Type", "ar-AE": "نوع الأجرة", "gu-IN": "ભાડાનો પ્રકાર" },
  _filterpassengers: { "en-US": "Passengers", "ar-AE": "ركاب", "gu-IN": "મુસાફરો" },
  _filterdoors: { "en-US": "Doors", "ar-AE": "أبواب", "gu-IN": "દરવાજા" },
  _filtertransmissiontype: {
    "en-US": "Transmission Type",
    "ar-AE": "نوع التحويل",
    "gu-IN": "ટ્રાન્સમિશન પ્રકાર",
  },
  _filteroptionalextras: {
    "en-US": "Optional Extras",
    "ar-AE": "الإضافات الاختيارية",
    "gu-IN": "વૈકલ્પિક એક્સ્ટ્રાઝ",
  },
  _filtercarType: { "en-US": "Car Type", "ar-AE": "نوع السيارة", "gu-IN": "કારનો પ્રકાર" },
  _firstName: { "en-US": "First Name", "ar-AE": "الاسم الاول", "gu-IN": "પ્રથમ નામ" },
  _foundMatchingYourSearch: {
    "en-US": "Found Matching Your Search",
    "ar-AE": "تم العثور على تطابق بحثك",
    "gu-IN": "તમારી શોધ સાથે મેળ ખાતું મળ્યું",
  },
  _gender: { "en-US": "Gender", "ar-AE": "جنس", "gu-IN": "લિંગ" },
  _guestFirstName: { "en-US": "First Name", "ar-AE": "الاسم الاول", "gu-IN": "પ્રથમ નામ" },
  _guestLastName: { "en-US": "Last Name", "ar-AE": "الاسم الاخير", "gu-IN": "અટક" },
  _guestName: { "en-US": "Guest Name", "ar-AE": "اسم الضيف", "gu-IN": "અતિથિનું નામ" },
  _hideDetails: { "en-US": "Hide Details", "ar-AE": "أخف التفاصيل", "gu-IN": "વિગતો છુપાવો" },
  _homePhone: { "en-US": "Home Phone", "ar-AE": "هاتف المنزل", "gu-IN": "ઘર નો ફોને" },
  _hotDeals: { "en-US": "Hot Deal", "ar-AE": "صفقة حاسمة", "gu-IN": "હોટ ડીલ્સ" },
  _hotelDetails: { "en-US": "Hotel Details", "ar-AE": "تفاصيل الفندق", "gu-IN": "હોટેલ વિગતો" },
  _hotelLocationSearching: {
    "en-US": "Searching...",
    "ar-AE": "يبحث...",
    "gu-IN": "શોધી રહ્યું છે ...",
  },
  _hotelName: { "en-US": "Hotel Name", "ar-AE": "اسم فندق", "gu-IN": "હોટેલનું નામ" },
  _hotelSearchPlaceholder: {
    "en-US": "Enter Area, Landmark, City or Hotel",
    "ar-AE": "أدخل المنطقة أو المعالم أو المدينة أو الفندق",
    "gu-IN": "સ્થાન, લેન્ડમાર્ક, શહેર અથવા હોટેલ દાખલ કરો",
  },
  _incorrectUserNamePassword: {
    "en-US": "User Name or password incorrect.",
    "ar-AE": "اسم المستخدم او الرقم السري غير صحيح.",
    "gu-IN": "વપરાશકર્તા નામ અથવા પાસવર્ડ ખોટો છે.",
  },
  _incorrectEmailPassword: {
    "en-US": "Email or password incorrect.",
    "ar-AE": "البريد الإلكتروني أو كلمة المرور غير صحيحة.",
    "gu-IN": "વપરાશકર્તા નામ અથવા પાસવર્ડ ખોટો છે.",
  },
  _infoBookingFailed: {
    "en-US": "Your booking has been failed",
    "ar-AE": "فشل الحجز الخاص بك",
    "gu-IN": "તમારું બુકિંગ નિષ્ફળ થયું છે",
  },
  _infoBookingSuccessfully: {
    "en-US":
      "Thank you for booking your travel with us. You will receive your confirmation by email in the next few minutes. If you do not receive any confirmation email in the following minutes, please contact our Customer Support representatives.",
    "ar-AE":
      "شكرا لحجز سفرك معنا. ستتلقى تأكيدًا عبر البريد الإلكتروني في الدقائق القليلة القادمة. إذا لم تتلق أي بريد إلكتروني للتأكيد في الدقائق التالية ، يرجى الاتصال بممثلي دعم العملاء.",
    "gu-IN":
      "અમારી સાથે તમારી મુસાફરી બુક કરવા બદલ આભાર. તમને આગલી થોડીવારમાં ઇમેઇલ દ્વારા તમારી પુષ્ટિ પ્રાપ્ત થશે. જો તમને નીચેની મિનિટોમાં કોઈ પુષ્ટિ ઇમેઇલ પ્રાપ્ત થતો નથી, તો કૃપા કરીને અમારા ગ્રાહક સપોર્ટ પ્રતિનિધિઓનો સંપર્ક કરો.",
  },
  _infoItemIsNotBooked: {
    "en-US": "One of your item is not booked.Please contact our customer care",
    "ar-AE": "لم يتم حجز أحد أغراضك.يرجى الاتصال بخدمة العملاء",
    "gu-IN": "તમારી આઇટમમાંથી એક પણ બુક કરાઈ નથી. કૃપા કરી અમારી ગ્રાહક સંભાળનો સંપર્ક કરો",
  },
  _infoItineraryNumber1: {
    "en-US": "A mail has been sent at your registered email address with the transaction details.",
    "ar-AE": "تم إرسال بريد على عنوان بريدك الإلكتروني المسجل مع تفاصيل المعاملة.",
    "gu-IN": "કૃપા કરીને આ બુકિંગથી સંબંધિત વધુ સંપર્ક માટે ઇટિનરરી નંબરનો ઉપયોગ કરો",
  },
  _infoItineraryNumber: {
    "en-US": "Please use above Itinerary Number for further communication related to this booking",
    "ar-AE": "يرجى استخدام رقم خط سير الرحلة أعلاه لمزيد من الاتصالات المتعلقة بهذا الحجز",
    "gu-IN": "કૃપા કરીને આ બુકિંગથી સંબંધિત વધુ સંપર્ક માટે ઇટિનરરી નંબરનો ઉપયોગ કરો",
  },
  _infoTravellerDetails: {
    "en-US":
      "Please fill the names of the Guest(s) as they officially appear on Identification Documents. Your booking information will be sent here.",
    "ar-AE":
      "يرجى ملء أسماء الضيوف (الضيوف) كما تظهر رسميًا في وثائق الهوية. سيتم إرسال معلومات الحجز الخاصة بك هنا.",
    "gu-IN":
      "કૃપા કરીને મુસાફરોના નામ ભરો કારણ કે તેઓ ઓળખ અથવા પાસપોર્ટ પર officiallyપચારિક રીતે દેખાય છે.",
  },
  _infoTravellerDetailsAir: {
    "en-US":
      "Please fill the names of the Traveller(s) as they officially appear on identities or passports. You can enter up to 23 characters. Last names should be given whole. Neither special characters nor spaces are allowed. Passenger name changes are not allowed.",
    "ar-AE":
      "يرجى تعبئة أسماء المسافرين (المسافرين) كما تظهر رسميًا على الهويات أو جوازات السفر. يمكنك إدخال ما يصل إلى 23 حرفًا. يجب إعطاء الأسماء الأخيرة بالكامل. لا يسمح بأحرف خاصة ولا مسافات. لا يسمح بتغيير اسم المسافر.",
    "gu-IN":
      "કૃપા કરીને મુસાફરોના નામ ભરો કારણ કે તેઓ ઓળખ અથવા પાસપોર્ટ પર officially પચારિક રીતે દેખાય છે.",
  },
  _infoTravellerDetailsAirMutamer1: {
    "en-US":
      "Citizens aged between 18 years to 39 years from countries (India, Bangladesh, Nigeria, Chad, Pakistan and Yemen) should have immediate relative co-traveler of opposite gender (Male with a Female & Vice a Versa).",
    "ar-AE":
      "يجب أن يكون للمواطنين الذين تتراوح أعمارهم بين 18 عامًا و 39 عامًا من دول (الهند وبنغلاديش ونيجيريا وتشاد وباكستان واليمن) مسافر قريب مباشر من الجنس الآخر (ذكر مع أنثى والعكس بالعكس).",
    "gu-IN":
      "ભારત, બાંગ્લાદેશ, નાઇજિરીયા, ચાડ, પાકિસ્તાન અને યમન દેશો ના 18 વર્ષથી 39 વર્ષની વયના નાગરિકોમાં વિરોધી લિંગ (તાત્કાલિક સ્ત્રી અને વાઇસ સાથેનો પુરુષ) ની સંબંધિત તાત્કાલિક સંબંધિત મુસાફરી હોવી જોઈએ.",
  },
  _infoTravellerDetailsAirMutamer2: {
    "en-US":
      "Female citizens other than countries (India, Bangladesh, Nigeria, Chad, Pakistan and Yemen) and having age less than 45 years needs to have an immediate relative male co-traveler.",
    "ar-AE":
      "المواطنات من غير الدول (الهند وبنغلاديش ونيجيريا وتشاد وباكستان واليمن) اللائي تقل أعمارهن عن 45 عامًا يحتاجن إلى شريك مسافر من الذكور.",
    "gu-IN":
      "ભારત, બાંગ્લાદેશ, નાઇજિરીયા, ચાડ, પાકિસ્તાન અને યમન સિવાયના મહિલા નાગરિકો અને 45 years વર્ષથી ઓછી વય ધરાવતા મહિલાઓ પાસે તાત્કાલિક સંબંધિત પુરુષ સહ-પ્રવાસી હોવું જરૂરી છે.",
  },
  _issuingCountry: {
    "en-US": "Issuing Country",
    "ar-AE": "بلد الاصدار",
    "gu-IN": "જારી કરનાર દેશ",
  },
  _itinerary: { "en-US": "Itinerary", "ar-AE": "مسار الرحلة", "gu-IN": "પ્રવાસ" },
  _itineraryAmount: {
    "en-US": "Itinerary Amount",
    "ar-AE": "مبلغ خط سير الرحلة",
    "gu-IN": "પ્રવાસ રકમ",
  },
  _itineraryName: {
    "en-US": "Itinerary Name",
    "ar-AE": "اسم خط سير الرحلة",
    "gu-IN": "પ્રવાસ નામ",
  },
  _itineraryNumber: {
    "en-US": "Itinerary Number",
    "ar-AE": "عدد مخطط الرحلة",
    "gu-IN": "પ્રવાસ નંબર",
  },
  _jumpTo: { "en-US": "Jump to", "ar-AE": "اقفز إلى", "gu-IN": "સીધા આના પર જાઓ" },
  _jumpToAvailability: { "en-US": "Availability", "ar-AE": "التوفر", "gu-IN": "ઉપલભ્યતા" },
  _jumpToFacilities: { "en-US": "Facilities", "ar-AE": "مرافق", "gu-IN": "સુવિધાઓ" },
  _jumpToInclusionsExclusions: {
    "en-US": "Inclusions & Exclusions",
    "ar-AE": "الادراج والاستثناءات",
    "gu-IN": "સમાવેશ અને બાકાત",
  },
  _jumpToItineraryDetails: {
    "en-US": "Itinerary Details",
    "ar-AE": "تفاصيل خط سير الرحلة",
    "gu-IN": "પ્રવાસ વિગતો",
  },
  _jumpToMap: { "en-US": "Map", "ar-AE": "خريطة", "gu-IN": "નકશો" },
  _jumpToOverview: { "en-US": "Overview", "ar-AE": "نظرة عامة", "gu-IN": "સમગ્ર ર્દષ્ટિ" },
  _jumpToRoom: { "en-US": "Room", "ar-AE": "الغرفة", "gu-IN": "ઓરડો" },
  _jumpToTermsAndConditions: {
    "en-US": "Terms & Conditions",
    "ar-AE": "البنود و الظروف",
    "gu-IN": "શરતો અને નિયમો",
  },
  _lastName: { "en-US": "Last Name", "ar-AE": "الاسم الاخير", "gu-IN": "અટક" },
  _lazyLoadingShowMore: { "en-US": "Show More", "ar-AE": "أظهر المزيد", "gu-IN": "વધુ બતાવો" },
  _lblUserProfile: {
    "en-US": "Profile Picture",
    "ar-AE": "الصوره الشخصيه",
    "gu-IN": "પ્રોફાઇલ ચિત્ર",
  },
  _lblAddress: { "en-US": "Address", "ar-AE": "عنوان", "gu-IN": "સરનામું" },
  _lblAddressWithStar: { "en-US": "Address *", "ar-AE": "عنوان *", "gu-IN": "સરનામું *" },
  _lblAdult: { "en-US": "Adult", "ar-AE": "بالغ", "gu-IN": "પુખ્તવયી" },
  _lblAdults: { "en-US": "Adults", "ar-AE": "البالغين", "gu-IN": "પુખ્ત વયના લોકો" },
  _lblAdultsWithStar: { "en-US": "Adults *", "ar-AE": "الكبار *", "gu-IN": "પુખ્ત વયના લોકો *" },
  _lblAnniversarydate: {
    "en-US": Global.getEnvironmetKeyValue("DisplayDateFormate")
      ? "Anniversary Date " + "(" + Global.getEnvironmetKeyValue("DisplayDateFormate") + ")"
      : "Anniversary Date ",
    "ar-AE": Global.getEnvironmetKeyValue("DisplayDateFormate")
      ? "تاريخ الذكرى " + "(" + Global.getEnvironmetKeyValue("DisplayDateFormate") + ")"
      : "تاريخ الذكرى ",
    "gu-IN": Global.getEnvironmetKeyValue("DisplayDateFormate")
      ? "વર્ષગાંઠની તારીખ " + "(" + Global.getEnvironmetKeyValue("DisplayDateFormate") + ")"
      : "વર્ષગાંઠની તારીખ ",
  },
  _lblAnniversarydateWithStar: {
    "en-US": Global.getEnvironmetKeyValue("DisplayDateFormate")
      ? "Anniversary Date " + "(" + Global.getEnvironmetKeyValue("DisplayDateFormate") + ") *"
      : "Anniversary Date " + " *",
    "ar-AE": Global.getEnvironmetKeyValue("DisplayDateFormate")
      ? "تاريخ الذكرى " + "(" + Global.getEnvironmetKeyValue("DisplayDateFormate") + ") *"
      : "تاريخ الذكرى " + " *",
    "gu-IN": Global.getEnvironmetKeyValue("DisplayDateFormate")
      ? "વર્ષગાંઠની તારીખ " + "(" + Global.getEnvironmetKeyValue("DisplayDateFormate") + ") *"
      : "વર્ષગાંઠની તારીખ " + " *",
  },
  _lblBRN: { "en-US": "BRN", "ar-AE": "BRN", "gu-IN": "BRN" },
  _lblBasePrice: { "en-US": "Base Price", "ar-AE": "السعر الأساسي", "gu-IN": "આધાર ભાવ" },
  _lblBirthdate: {
    "en-US": Global.getEnvironmetKeyValue("DisplayDateFormate")
      ? "Birth Date " + "(" + Global.getEnvironmetKeyValue("DisplayDateFormate") + ")"
      : "Birth Date ",
    "ar-AE": Global.getEnvironmetKeyValue("DisplayDateFormate")
      ? "تاريخ الميلاد " + "(" + Global.getEnvironmetKeyValue("DisplayDateFormate") + ")"
      : "تاريخ الميلاد ",
    "gu-IN": Global.getEnvironmetKeyValue("DisplayDateFormate")
      ? "જન્મતારીખ " + "(" + Global.getEnvironmetKeyValue("DisplayDateFormate") + ")"
      : "જન્મતારીખ ",
  },
  _lblBirthdateWithStar: {
    "en-US": Global.getEnvironmetKeyValue("DisplayDateFormate")
      ? "Birth Date " + "(" + Global.getEnvironmetKeyValue("DisplayDateFormate") + ") *"
      : "Birth Date " + " *",
    "ar-AE": Global.getEnvironmetKeyValue("DisplayDateFormate")
      ? "تاريخ الميلاد " + "(" + Global.getEnvironmetKeyValue("DisplayDateFormate") + ") *"
      : "تاريخ الميلاد " + " *",
    "gu-IN": Global.getEnvironmetKeyValue("DisplayDateFormate")
      ? "જન્મતારીખ " + "(" + Global.getEnvironmetKeyValue("DisplayDateFormate") + ") *"
      : "જન્મતારીખ " + " *",
  },
  _mealTypes: { "en-US": "Meal types", "ar-AE": "السعر الأساسي", "gu-IN": "Meal પ્રકારો" },
  _lblBoardTypesWithStar: {
    "en-US": "Board types *",
    "ar-AE": "أنواع اللوحات *",
    "gu-IN": "બોર્ડ પ્રકારો *",
  },
  _lblChangeDatesandOccupancy: {
    "en-US": "Change Dates & Occupancy",
    "ar-AE": "تغيير التواريخ والإشغال",
    "gu-IN": "તારીખ અને વ્યવસાય બદલો",
  },
  _lblChild: { "en-US": "Child", "ar-AE": "طفل", "gu-IN": "બાળક" },
  _lblCity: { "en-US": "City", "ar-AE": "مدينة", "gu-IN": "શહેર" },
  _lblCityWithStar: { "en-US": "City *", "ar-AE": "مدينة *", "gu-IN": "શહેર *" },
  _lblConfirmPassword: {
    "en-US": "Confirm Password *",
    "ar-AE": "تأكيد كلمة المرور *",
    "gu-IN": "પાસવર્ડની પુષ્ટિ કરો *",
  },
  _lblContactPhone: { "en-US": "Contact Phone", "ar-AE": "هاتف الاتصال", "gu-IN": "ફોન સંપર્ક" },
  _lblContactPhoneWithStar: {
    "en-US": "Contact Phone *",
    "ar-AE": "هاتف الاتصال *",
    "gu-IN": "ફોન સંપર્ક *",
  },
  _lblCountry: { "en-US": "Country", "ar-AE": "بلد", "gu-IN": "દેશ" },
  _lblCountryWithStar: { "en-US": "Country *", "ar-AE": "بلد *", "gu-IN": "દેશ *" },
  _lblDetails: { "en-US": "Details", "ar-AE": "تفاصيل", "gu-IN": "વિગતો" },
  _lblDocumentNumber: {
    "en-US": "Document Number",
    "ar-AE": "رقم المستند",
    "gu-IN": "દસ્તાવેજ ક્રમાંક",
  },
  _lblDocumentNumberWithStar: {
    "en-US": "Document Number *",
    "ar-AE": "رقم المستند *",
    "gu-IN": "દસ્તાવેજ ક્રમાંક *",
  },
  _lblDocumentType: {
    "en-US": "Document Type",
    "ar-AE": "نوع الوثيقة",
    "gu-IN": "દસ્તાવેજ પ્રકાર",
  },
  _lblDocumentTypeWithStar: {
    "en-US": "Document Type *",
    "ar-AE": "نوع الوثيقة *",
    "gu-IN": "દસ્તાવેજ પ્રકાર *",
  },
  _lblSocialSecurityNumberWithStar: {
    "en-US": "Social Security Number *",
    "ar-AE": "رقم الحماية الاجتماعية *",
    "gu-IN": "સામાજિક સુરક્ષા નંબર *",
  },
  _error_socialSecurityNumber_require: {
    "en-US": "Social Security Number required",
    "ar-AE": "رقم الضمان الاجتماعي مطلوب",
    "gu-IN": "સામાજિક સુરક્ષા નંબર આવશ્યક છે",
  },
  _lblEmail: { "en-US": "Email", "ar-AE": "البريد الإلكتروني", "gu-IN": "સંદેશો" },
  _lblEmailWithStar: { "en-US": "Email *", "ar-AE": "البريد الإلكتروني *", "gu-IN": "સંદેશો *" },
  _lblExpiryDate: {
    "en-US": Global.getEnvironmetKeyValue("DisplayDateFormate")
      ? "Expiry Date " + "(" + Global.getEnvironmetKeyValue("DisplayDateFormate") + ")"
      : "Expiry Date ",
    "ar-AE": Global.getEnvironmetKeyValue("DisplayDateFormate")
      ? "تاريخ الانتهاء " + "(" + Global.getEnvironmetKeyValue("DisplayDateFormate") + ")"
      : "تاريخ الانتهاء ",
    "gu-IN": Global.getEnvironmetKeyValue("DisplayDateFormate")
      ? "અંતિમ તારીખ " + "(" + Global.getEnvironmetKeyValue("DisplayDateFormate") + ")"
      : "અંતિમ તારીખ ",
  },
  _lblExpiryDateWithStar: {
    "en-US": Global.getEnvironmetKeyValue("DisplayDateFormate")
      ? "Expiry Date " + "(" + Global.getEnvironmetKeyValue("DisplayDateFormate") + ") *"
      : "Expiry Date " + " *",
    "ar-AE": Global.getEnvironmetKeyValue("DisplayDateFormate")
      ? "تاريخ الانتهاء " + "(" + Global.getEnvironmetKeyValue("DisplayDateFormate") + ") *"
      : "تاريخ الانتهاء " + " *",
    "gu-IN": Global.getEnvironmetKeyValue("DisplayDateFormate")
      ? "અંતિમ તારીખ " + "(" + Global.getEnvironmetKeyValue("DisplayDateFormate") + ") *"
      : "અંતિમ તારીખ " + " *",
  },
  _lblFareSummary: { "en-US": "Fare Summary", "ar-AE": "ملخص الأجرة", "gu-IN": "ભાડાનો સારાંશ" },
  _lblFareSummaryWithStar: {
    "en-US": "Fare Summary *",
    "ar-AE": "ملخص الأجرة *",
    "gu-IN": "ભાડાનો સારાંશ *",
  },
  _lblTravelers: { "en-US": "Travellers", "ar-AE": "المسافرون", "gu-IN": "પ્રથમ નામ" },
  _lblFirstName: { "en-US": "First Name", "ar-AE": "الاسم الاول", "gu-IN": "પ્રથમ નામ" },
  _lblFirstNameWithStar: {
    "en-US": "First Name *",
    "ar-AE": "الاسم الاول *",
    "gu-IN": "પ્રથમ નામ *",
  },
  _lblGender: { "en-US": "Gender", "ar-AE": "الجنس", "gu-IN": "લિંગ" },
  _lblGenderWithStar: { "en-US": "Gender *", "ar-AE": "الجنس *", "gu-IN": "લિંગ *" },
  _lblGuestFirstName: { "en-US": "First Name", "ar-AE": "الاسم الاول", "gu-IN": "પ્રથમ નામ" },
  _lblGuestFirstNameWithStar: {
    "en-US": "First Name *",
    "ar-AE": "الاسم الاول *",
    "gu-IN": "પ્રથમ નામ *",
  },
  _lblGuestLastName: { "en-US": "Last Name", "ar-AE": "الاسم الاخير", "gu-IN": "અટક" },
  _lblGuestLastNameWithStar: {
    "en-US": "Last Name *",
    "ar-AE": "الاسم الاخير *",
    "gu-IN": "અટક *",
  },
  _lblHomePhone: { "en-US": "Home Phone", "ar-AE": "هاتف المنزل", "gu-IN": "ઘર નો ફોને" },
  _lblHomePhoneWithStar: {
    "en-US": "Home Phone *",
    "ar-AE": "هاتف المنزل *",
    "gu-IN": "ઘર નો ફોને *",
  },
  _lblInfant: { "en-US": "Infant", "ar-AE": "رضيع", "gu-IN": "શિશુ" },
  _lblIssuingCountry: {
    "en-US": "Issuing Country",
    "ar-AE": "بلد الأصدار",
    "gu-IN": "જારી કરનાર દેશ",
  },
  _lblIssuingCountryWithStar: {
    "en-US": "Issuing Country *",
    "ar-AE": "بلد الأصدار *",
    "gu-IN": "જારી કરનાર દેશ *",
  },
  _lblItinerary: { "en-US": "Itinerary", "ar-AE": "مسار الرحلة", "gu-IN": "પ્રવાસ" },
  _lblItineraryName: {
    "en-US": "Itinerary Name",
    "ar-AE": "اسم مسار الرحلة",
    "gu-IN": "માર્ગ-નિર્દેશિકા નામ",
  },
  _lblItineraryNameWithStar: {
    "en-US": "Itinerary Name *",
    "ar-AE": "اسم مسار الرحلة *",
    "gu-IN": "માર્ગ-નિર્દેશિકા નામ *",
  },
  _lblItineraryWithStar: { "en-US": "Itinerary *", "ar-AE": "مسار الرحلة *", "gu-IN": "પ્રવાસ *" },
  _lblLastName: { "en-US": "Last Name", "ar-AE": "الاسم الاخير", "gu-IN": "અટક" },
  _lblLastNameWithStar: { "en-US": "Last Name *", "ar-AE": "الاسم الاخير *", "gu-IN": "અટક *" },
  _lblMobileNumber: { "en-US": "Mobile Number", "ar-AE": "رقم الهاتف", "gu-IN": "મોબાઇલ નંબર" },
  _lblMobileNumberWithStar: {
    "en-US": "Mobile Number *",
    "ar-AE": "رقم الهاتف *",
    "gu-IN": "મોબાઇલ નંબર *",
  },
  _lblNationality: { "en-US": "Nationality", "ar-AE": "الجنسية", "gu-IN": "રાષ્ટ્રીયતા" },
  _lblNationalityWithStar: {
    "en-US": "Nationality *",
    "ar-AE": "الجنسية *",
    "gu-IN": "રાષ્ટ્રીયતા *",
  },
  _lblPassportNumber: {
    "en-US": "Passport Number",
    "ar-AE": "رقم وثيقة السفر",
    "gu-IN": "પાસપોર્ટ નંબર",
  },
  _lblPassportNumberWithStar: {
    "en-US": "Passport Number *",
    "ar-AE": "رقم وثيقة السفر *",
    "gu-IN": "પાસપોર્ટ નંબર *",
  },
  _lblPassword: { "en-US": "Password", "ar-AE": "كلمة المرور", "gu-IN": "પાસવર્ડ" },
  _lblPasswordNotMatch: { "en-US": "Password *", "ar-AE": "كلمة المرور *", "gu-IN": "પાસવર્ડ *" },
  _lblPasswordWithStar: { "en-US": "Password *", "ar-AE": "كلمة المرور *", "gu-IN": "પાસવર્ડ *" },
  _lblPolicyDetails: {
    "en-US": "Policy Details",
    "ar-AE": "تفاصيل السياسة",
    "gu-IN": "નીતિ વિગતો",
  },
  _lblPolicyDetailsWithStar: {
    "en-US": "Policy Details *",
    "ar-AE": "تفاصيل السياسة *",
    "gu-IN": "નીતિ વિગતો *",
  },
  _lblPrice: { "en-US": "Price", "ar-AE": "سعر", "gu-IN": "ભાવ" },
  _lblRoom: { "en-US": "Room", "ar-AE": "الغرفة", "gu-IN": "ઓરડો" },
  _lblRoomWithStar: { "en-US": "Room *", "ar-AE": "الغرفة *", "gu-IN": "રૂમ *" },
  _lblSecondaryNumber: {
    "en-US": "Secondary Number",
    "ar-AE": "الرقم الثانوي",
    "gu-IN": "માધ્યમિક નંબર",
  },
  _lblSecondaryNumberWithStar: {
    "en-US": "Secondary Number *",
    "ar-AE": "الرقم الثانوي *",
    "gu-IN": "માધ્યમિક નંબર *",
  },
  _lblSurchargeAndTax: {
    "en-US": "Surcharge & Tax",
    "ar-AE": "تكاليف اضافية وضرائب",
    "gu-IN": "સરચાર્જ અને ટેક્સ",
  },
  _lblTaxesAmount: {
    "en-US": "Surcharge & Tax",
    "ar-AE": "تكاليف اضافية وضرائب",
    "gu-IN": "સરચાર્જ અને ટેક્સ",
  },
  _lblPhoneNumber: { "en-US": "Phone Number", "ar-AE": "أرقام الهاتف", "gu-IN": "ફોન નંબર" },
  _lblTelephoneNumber: {
    "en-US": "Telephone Number",
    "ar-AE": "أرقام الهاتف",
    "gu-IN": "ટેલીફોન નંબર",
  },
  _lblTelephoneNumberWithStar: {
    "en-US": "Telephone Number *",
    "ar-AE": "أرقام الهاتف *",
    "gu-IN": "ટેલીફોન નંબર *",
  },
  _lblUserName: { "en-US": "User Name", "ar-AE": "اسم المستخدم", "gu-IN": "વપરાશકર્તા નામ" },
  _lblUserNameWithStar: {
    "en-US": "User Name *",
    "ar-AE": "اسم المستخدم *",
    "gu-IN": "વપરાશકર્તા નામ *",
  },
  _lblZipCodeOrPostalCode: {
    "en-US": "Zipcode/Postal Code",
    "ar-AE": "الرمز البريدي",
    "gu-IN": "પિનકોડ / પોસ્ટલ કોડ",
  },
  _lblZipCodeOrPostalCodeWithStar: {
    "en-US": "Zipcode/Postal Code *",
    "ar-AE": "الرمز البريدي *",
    "gu-IN": "પિનકોડ / પોસ્ટલ કોડ *",
  },
  _loadingText: { "en-US": "Loading...", "ar-AE": "تحميل ...", "gu-IN": "લોડ કરી રહ્યું છે ..." },
  _location: { "en-US": "Location", "ar-AE": "الموقع", "gu-IN": "સ્થાન" },
  _mealtypeFilter: { "en-US": "Meal Type", "ar-AE": "نوع الوجبة", "gu-IN": "ભોજનનો પ્રકાર" },
  _mobileNumber: { "en-US": "Mobile Number", "ar-AE": "رقم الهاتف", "gu-IN": "મોબાઇલ નંબર" },
  _moreDetails: { "en-US": "More Details", "ar-AE": "مزيد من التفاصيل", "gu-IN": "વધુ વિગતો" },
  _myBookings: { "en-US": "My Bookings", "ar-AE": "حجوزاتي", "gu-IN": "મારું બુકિંગ" },
  _myCoTravelers: { "en-US": "My Co-Travellers", "ar-AE": "المسافرين", "gu-IN": "મારા સહ-મુસાફરો" },
  _myCoTravellerNote: {
    "en-US":
      "For your convenience, you can save other travellers' information such as your colleagues or family. During the booking process, to save your time you can simply choose the name of the traveller from the list and all of his or her entries will be automatically filled in the booking boxes.",
    "ar-AE":
      "لراحتك ، يمكنك حفظ معلومات المسافرين الآخرين مثل زملائك أو عائلتك. أثناء عملية الحجز ، لتوفير وقتك ، يمكنك ببساطة اختيار اسم المسافر من القائمة وسيتم ملء جميع مدخلاته في صناديق الحجز تلقائيًا.",
    "gu-IN":
      "તમારી સુવિધા માટે, તમે અન્ય મુસાફરોની માહિતી જેમ કે તમારા સાથીદારો અથવા પરિવારને બચાવી શકો છો. બુકિંગ પ્રક્રિયા દરમિયાન, તમારો સમય બચાવવા માટે, તમે સૂચિમાંથી મુસાફરનું નામ ફક્ત પસંદ કરી શકો છો અને તેની બધી એન્ટ્રી આપમેળે બુકિંગ બ inક્સમાં ભરાઈ જશે.",
  },
  _myCustomers: { "en-US": "My Customers", "ar-AE": "زبائني", "gu-IN": "મારા સહ-મુસાફરો" },
  _myCustomerNote: {
    "en-US":
      "For your convenience, you can save other customers' information such as your colleagues or family. During the booking process, to save your time you can simply choose the name of the customer from the list and all of his or her entries will be automatically filled in the booking boxes.",
    "ar-AE":
      "لراحتك ، يمكنك حفظ معلومات المسافرين الآخرين مثل زملائك أو عائلتك. أثناء عملية الحجز ، لتوفير وقتك ، يمكنك ببساطة اختيار اسم المسافر من القائمة وسيتم ملء جميع مدخلاته في صناديق الحجز تلقائيًا.",
    "gu-IN":
      "તમારી સુવિધા માટે, તમે અન્ય મુસાફરોની માહિતી જેમ કે તમારા સાથીદારો અથવા પરિવારને બચાવી શકો છો. બુકિંગ પ્રક્રિયા દરમિયાન, તમારો સમય બચાવવા માટે, તમે સૂચિમાંથી મુસાફરનું નામ ફક્ત પસંદ કરી શકો છો અને તેની બધી એન્ટ્રી આપમેળે બુકિંગ બ inક્સમાં ભરાઈ જશે.",
  },
  _myProfile: { "en-US": "My Profile", "ar-AE": "ملفى الشخصى", "gu-IN": "મારી પ્રોફાઈલ" },
  _myViewReservation: {
    "en-US": "My View Reservation",
    "ar-AE": "عرض الحجز",
    "gu-IN": "મારું દૃશ્ય આરક્ષણ",
  },
  _nationality: { "en-US": "Nationality", "ar-AE": "الجنسية", "gu-IN": "રાષ્ટ્રીયતા" },
  _next: { "en-US": "Next", "ar-AE": "التالى", "gu-IN": "આગામી" },
  _nights: { "en-US": "Night(s)", "ar-AE": "ليالي", "gu-IN": "રાત્રી(ઓ)" },
  _nightsSmall: { "en-US": "night(s)", "ar-AE": "ليالي", "gu-IN": "રાત્રી(ઓ)" },
  _no: { "en-US": "No", "ar-AE": "رقم", "gu-IN": "ના" },
  _noHotelsFound: {
    "en-US": "No hotel(s) found",
    "ar-AE": "الفنادق غير متوفرة",
    "gu-IN": "કોઈ હોટલ(ઓ) મળી નથી",
  },
  _notransfersFound: {
    "en-US": "No transfer(s) found",
    "ar-AE": "لم يتم العثور على تحويلات",
    "gu-IN": "કોઈ ટ્રાન્સફર (ઓ) મળ્યાં નથી",
  },
  _novehicleFound: {
    "en-US": "No vehicle(s) found",
    "ar-AE": "لم يتم العثور على مركبة (مركبات)",
    "gu-IN": "કોઈ વાહન (ઓ) મળ્યાં નથી",
  },
  _noMatchesFound: {
    "en-US": "No Matches Found.",
    "ar-AE": "لم يتم العثور على تطابق.",
    "gu-IN": "કોઈ બરાબરી મળી નથી.",
  },
  _noMatchesFoundairairline: {
    "en-US": "No Matches Found.",
    "ar-AE": "لم يتم العثور على تطابق.",
    "gu-IN": "કોઈ બરાબરી મળી નથી.",
  },
  _noMatchesFoundactivity: {
    "en-US": "No inventory available at this location.",
    "ar-AE": "غير المتوفر في هذا الموقع.",
    "gu-IN": "આ સ્થાન પર કોઈ ઇન્વેન્ટરી ઉપલબ્ધ નથી.",
  },
  _noMatchesFoundtransfers: {
    "en-US": "No inventory available at this location.",
    "ar-AE": "غير المتوفر في هذا الموقع.",
    "gu-IN": "આ સ્થાન પર કોઈ ઇન્વેન્ટરી ઉપલબ્ધ નથી.",
  },
  _noMatchesFoundair: {
    "en-US": "No inventory available at this location.",
    "ar-AE": "غير المتوفر في هذا الموقع.",
    "gu-IN": "આ સ્થાન પર કોઈ ઇન્વેન્ટરી ઉપલબ્ધ નથી.",
  },
  _noMatchesFoundhotel: {
    "en-US": "No inventory available at this location.",
    "ar-AE": "غير المتوفر في هذا الموقع.",
    "gu-IN": "કોઈ બરાબરી મળી નથી.",
  },
  _noMatchesFoundpackage: {
    "en-US": "No inventory available at this location.",
    "ar-AE": "غير المتوفر في هذا الموقع.",
    "gu-IN": "કોઈ બરાબરી મળી નથી.",
  },
  _noPolicyFound: {
    "en-US": "No policy found.",
    "ar-AE": "لا يوجد أي شروط",
    "gu-IN": "કોઈ નીતિ મળી નથી.",
  },
  _noactivityFound: {
    "en-US": "No activity(s) found",
    "ar-AE": "لم يتم العثور على أنشطة",
    "gu-IN": "કોઈ પ્રવૃત્તિ(ઓ) મળી નથી",
  },
  _noairFound: {
    "en-US": "No flight(s) found",
    "ar-AE": "لا  وجود لاي رحلة طيران .",
    "gu-IN": "કોઈ ફ્લાઇટ(ઓ) મળી નથી",
  },
  _nohotelFound: {
    "en-US": "No hotel(s) found",
    "ar-AE": "الفنادق غير متوفرة",
    "gu-IN": "કોઈ હોટલ(ઓ) મળી નથી",
  },
  _nopackageFound: {
    "en-US": "No package(s) found",
    "ar-AE": "لم يتم العثور على حزم",
    "gu-IN": "કોઈ પેકેજ(ઓ) મળ્યું નથી",
  },
  _occupancy: { "en-US": "Occupancy", "ar-AE": "الحد الأقصى للاشخاص", "gu-IN": "વ્યવસાય" },
  _ooops: { "en-US": "Ooops!", "ar-AE": "خطأ!", "gu-IN": "અરેરે!" },
  _ooopsPageNotFound: {
    "en-US": "Oops! Page Not Found",
    "ar-AE": "وجه الفتاة! الصفحة غير موجودة",
    "gu-IN": "અરેરે! પૃષ્ઠ મળ્યું નથી",
  },
  _ooopsPageNotFoundNote: {
    "en-US":
      "Sorry, but the page you are looking for is not found. Please make sure you have typed the correct URL.",
    "ar-AE":
      "عذرا ، ولكن لم يتم العثور على الصفحة التي تبحث عنها. يرجى التأكد من كتابة عنوان URL الصحيح.",
    "gu-IN":
      "માફ કરશો, પરંતુ તમે જે પૃષ્ઠ શોધી રહ્યા છો તે મળ્યું નથી. કૃપા કરીને ખાતરી કરો કે તમે કરંટ URL ટાઇપ કર્યો છે.",
  },
  _ooopsPleaseSelectRoom: {
    "en-US": "Please select room from all room group.",
    "ar-AE": "يرجى اختيار غرفة من كل مجموعة الغرف.",
    "gu-IN": "બધા રૂમ જૂથમાંથી કૃપા કરીને રૂમ પસંદ કરો.",
  },
  _ooopsSomeThingWentWrong: {
    "en-US": "Ooops, Some thing went wrong. Kindly make a new search and try again.",
    "ar-AE": "عفوًا ، حدث خطأ ما. يرجى إجراء بحث جديد وحاول مرة أخرى.",
    "gu-IN": "અરેરે, કંઇક ખોટું થયું. કૃપા કરીને નવી શોધ કરો અને ફરીથી પ્રયાસ કરો.",
  },
  _ooopsSomeThingWentWrongAfterBooking: {
    "en-US": "Some thing went wrong. Kindly try again after some time.",
    "ar-AE": "هناك خطأ ما. يرجى المحاولة مرة أخرى بعد مرور بعض الوقت.",
    "gu-IN": "કંઇક ખોટું થયું. કૃપા કરીને નવી શોધ કરો અને ફરીથી પ્રયાસ કરો.",
  },
  _operatedBySmall: { "en-US": "Operated by", "ar-AE": "تشغلها:", "gu-IN": "દ્વારા સંચાલિત" },
  _overview: { "en-US": "Overview", "ar-AE": "الوصف", "gu-IN": "સમગ્ર ર્દષ્ટિ" },
  _overviewShowMore: { "en-US": "Show More", "ar-AE": "أظهر المزيد", "gu-IN": "વધુ બતાવો" },
  _overviewTitle: { "en-US": "Overview", "ar-AE": "الوصف", "gu-IN": "સમગ્ર ર્દષ્ટિ" },
  _packageAvailableOn: { "en-US": "Available On :", "ar-AE": "متاح في :", "gu-IN": "પર ઉપલબ્ધ:" },
  _packageDate: { "en-US": "Package Date", "ar-AE": "تاريخ الطرد", "gu-IN": "પેકેજ તારીખ" },
  _packageDetails: {
    "en-US": "Package Details",
    "ar-AE": "تفاصيل حزمة الخاص بك",
    "gu-IN": "પેકેજ વિગતો",
  },
  _packageDuration: { "en-US": "Duration :", "ar-AE": "المدة", "gu-IN": "સમયગાળો :" },
  _category: { "en-US": "Category :", "ar-AE": "الفئة :", "gu-IN": "વર્ગ:" },
  _packageLocationSearching: {
    "en-US": "Searching...",
    "ar-AE": "يبحث...",
    "gu-IN": "શોધી રહ્યું છે ...",
  },
  _noinclusions: { "en-US": "No Inclusions", "ar-AE": "لا شوائب", "gu-IN": "કોઈ સમાવેશ (ઓ) નથી" },
  _noexclusions: { "en-US": "No Exclusions", "ar-AE": "لا استثناءات", "gu-IN": "કોઈ બાકાત નથી" },
  _packageOperator: {
    "en-US": "Package Operator :",
    "ar-AE": "مشغل الحزمة:",
    "gu-IN": "પેકેજ ઓપરેટર:",
  },
  _downloadPdf: { "en-US": "Brochure :", "ar-AE": "كراسة :", "gu-IN": "બ્રોશર:" },
  _downloadPdfClickHere: {
    "en-US": "Click here to Download Brochure",
    "ar-AE": "انقر هنا لتحميل الكتيب",
    "gu-IN": "બ્રોશર ડાઉનલોડ કરવા અહીં ક્લિક કરો",
  },
  _packageSearchPlaceholder: {
    "en-US": "Enter a destination or property",
    "ar-AE": "ادخل المكان المقصود",
    "gu-IN": "સ્થાન, લેન્ડમાર્ક, શહેર અથવા હોટેલ દાખલ કરો",
  },
  _packageStartingPlace: {
    "en-US": "Starting Place :",
    "ar-AE": "بدءا مكان :",
    "gu-IN": "પ્રારંભિક સ્થાન:",
  },
  _packageType: { "en-US": "Type :", "ar-AE": "نوع :", "gu-IN": "પ્રકાર:" },
  _packageTypeFilter: { "en-US": "Package Type", "ar-AE": "نوع الحزمة", "gu-IN": "સગવડતા" },
  _passangerName: { "en-US": "Name", "ar-AE": "الاسم", "gu-IN": "નામ" },
  _passengerTypeTitle: {
    "en-US": "Passenger Type",
    "ar-AE": "نوع الراكب",
    "gu-IN": "પેસેન્જર પ્રકાર",
  },
  _pilgrimTypeTitle: {
    "en-US": "Pilgrim Type",
    "ar-AE": "نوع الحاج",
    "gu-IN": "યાત્રાળુ પ્રકાર",
  },
  _password: { "en-US": "Password", "ar-AE": "كلمة المرور", "gu-IN": "પાસવર્ડ" },
  _paymentPageAgreeMessagePart1: {
    "en-US": "I have READ and AGREED to all the ",
    "ar-AE": "قرأت ووافقت على كلا من",
    "gu-IN": "મેં તમામ નિયમો અને શરતોને વાંચી અને સંમત કરી છે (કૃપા કરીને નોંધો કે",
  },
  _paymentPageAgreeMessagePart2: {
    "en-US": "Terms of Use",
    "ar-AE": "شروط الاستخدام",
    "gu-IN": " નિયમો અને શરતો ",
  },
  _paymentPageAgreeMessagePart3: {
    "en-US": " (These are different from the booking policies of individual items listed above)",
    "ar-AE": "(هذه تختلف عن سياسات الحجز للعناصر الفردية المذكورة أعلاه)",
    "gu-IN": "ઉપર સૂચિબદ્ધ વ્યક્તિગત વસ્તુઓની બાજુમાં છે",
  },
  _paymentPageDoNotRefreshMessage: {
    "en-US":
      "Please do not refresh the page on any of the next step(s) of this transaction. Kindly do not use duplicate tab for doing this transaction.",
    "ar-AE":
      "يرجى عدم تحديث الصفحة في أي من الخطوات (الخطوات) التالية من هذه المعاملة. يرجى عدم استخدام علامة تبويب مكررة للقيام بهذه الصفقة.",
    "gu-IN":
      "કૃપા કરીને આ વ્યવહારના આગળના કોઈપણ પગલા (પૃષ્ઠ) પર પૃષ્ઠને તાજું કરશો નહીં. કૃપા કરીને આ વ્યવહાર કરવા માટે ડુપ્લિકેટ ટેબનો ઉપયોગ કરશો નહીં.",
  },
  _perNight: { "en-US": "Per Night", "ar-AE": "السعر في الليلة", "gu-IN": "રાત્રિ દીઠ" },
  _perRoomperNight: { "en-US": "Per Room/Per Night", "ar-AE": "لكل غرفة / في الليلة", "gu-IN": "દીઠ રૂમ / દીઠ નાઇટ" },
  _personsWithBraces: { "en-US": "Person(s)", "ar-AE": "الشخص (الأشخاص)", "gu-IN": "વ્યક્તિ(ઓ)" },
  _photo: { "en-US": "Photos", "ar-AE": "صور", "gu-IN": "ફોટા" },
  _pleaseAcceptTermsAndConditions: {
    "en-US": "Please accept the terms and conditions",
    "ar-AE": "يرجى قبول الشروط والأحكام.",
    "gu-IN": "કૃપા કરીને નિયમો અને શરતો સ્વીકારો",
  },
  _pleaseagreeNonRefundable: {
    "en-US": "Please accept the non-refundable conditions",
    "ar-AE": "يُرجى قبول الشروط غير القابلة للاسترداد",
    "gu-IN": "કકૃપા કરી પરત નપાત્ર શરતો સ્વીકારો",
  },
  _pleaseLoginToAccessProfile: {
    "en-US": "Please sign in to access Profile",
    "ar-AE": "يرجى تسجيل الدخول للوصول إلى الملف الشخصي",
    "gu-IN": "કૃપા કરીને પ્રોફાઇલને toક્સેસ કરવા માટે લ Loginગિન કરો",
  },
  _pleaseLoginToBook: {
    "en-US": "Sign in to complete booking",
    "ar-AE": "تسجيل الدخول لإكمال الحجز",
    "gu-IN": "કૃપા કરીને બુક માટે પ્રવેશ કરો",
  },
  _policyDetails: { "en-US": "Policy Details", "ar-AE": "تفاصيل السياسة", "gu-IN": "નીતિ વિગતો" },
  _previous: { "en-US": "Previous", "ar-AE": "سابق", "gu-IN": "અગાઉના" },
  _priceDetails: { "en-US": "Price Details", "ar-AE": "تفاصيل السعر", "gu-IN": "ભાવ વિગતો" },
  _priceRules: { "en-US": "Price Rules", "ar-AE": "قواعد السعر", "gu-IN": "ભાવ નિયમો" },
  _privacyPolicy: {
    "en-US": "Privacy Policy",
    "ar-AE": "سياسة الخصوصية",
    "gu-IN": "ગોપનીયતા નીતિ",
  },
  _rating: { "en-US": "Rating", "ar-AE": "حسب تصنيف النجوم", "gu-IN": "રેટિંગ" },
  _remove: { "en-US": "Remove", "ar-AE": "حذف", "gu-IN": "દૂર કરો" },
  _renderingTime: { "en-US": "Rendering Time", "ar-AE": "تقديم الوقت", "gu-IN": "રેન્ડરિંગ સમય" },
  _reservationDetails: {
    "en-US": "Reservation Details",
    "ar-AE": "تفاصيل الحجز",
    "gu-IN": "આરક્ષણ વિગતો",
  },
  _room: { "en-US": "Room", "ar-AE": "الغرفة", "gu-IN": "ઓરડો" },
  _roomDetails: { "en-US": "Room Details", "ar-AE": "تفاصيل الغرفة", "gu-IN": "ઓરડાની વિગત" },
  _roomImages: { "en-US": "Room Images", "ar-AE": "صور الغرفة", "gu-IN": "ઓરડાની છબીઓ" },
  _roomName: { "en-US": "Room Name", "ar-AE": "اسم الغرفة", "gu-IN": "ઓરડાનુ નામ" },
  _roomTerms: { "en-US": "Booking Policies", "ar-AE": "سياسات الحجز", "gu-IN": "ઓરડોની શરતો" },
  _rooms: { "en-US": "Room(s)", "ar-AE": "غرف(ة)", "gu-IN": "ઓરડો" },
  _error_address_require: {
    "en-US": "Address required",
    "ar-AE": "العنوان مطلوب",
    "gu-IN": "સરનામું જરૂરી છે",
  },
  _error_cancellationCharges_require: {
    "en-US": "Cancellation Charges required",
    "ar-AE": "رسوم الإلغاء مطلوبة",
    "gu-IN": "કેન્સલેશન ચાર્જ જરૂરી છે",
  },
  _error__bookingReferenceNumber_require: {
    "en-US": "Booking Reference Number required",
    "ar-AE": "الرقم المرجعي للحجز مطلوب",
    "gu-IN": "બુકિંગ સંદર્ભ નંબર જરૂરી છે",
  },
  _error_cancellationCharges_invalid: {
    "en-US": "Enter valid Cancellation Charges",
    "ar-AE": "يرجى إدخال رسوم إلغاء صالحة.",
    "gu-IN": "માન્ય રદ ચાર્જ દાખલ કરો",
  },
  _error_bookingStatusID_require: {
    "en-US": "Status required",
    "ar-AE": "الحالة المطلوبة",
    "gu-IN": "સ્થિતિ આવશ્યક છે",
  },
  _error_comment_require: {
    "en-US": "Comments required",
    "ar-AE": "التعليقات المطلوبة",
    "gu-IN": "ટિપ્પણીઓ આવશ્યક છે",
  },
  _rqAnniversarydate: {
    "en-US": "Anniversary Date required",
    "ar-AE": "تاريخ الذكرى السنوية مطلوب",
    "gu-IN": "વર્ષગાંઠની તારીખ જરૂરી છે",
  },
  _error_comments_require: {
    "en-US": "Comment is required",
    "ar-AE": "التعليق مطلوب",
    "gu-IN": "ટિપ્પણીઓ જરૂરી છે",
  },
  _error_subject_require: {
    "en-US": "Subject required",
    "ar-AE": "الموضوع (مطلوب",
    "gu-IN": "ટિપ્પણીઓ જરૂરી છે",
  },
  _error_atleastone_require: {
    "en-US": "Select at least one item.",
    "ar-AE": "حدد عنصر واحد على الأقل.",
    "gu-IN": "ટિપ્પણીઓ જરૂરી છે",
  },
  _error_birthDate_require: {
    "en-US": "Birth Date required",
    "ar-AE": "تاريخ الميلاد مطلوب",
    "gu-IN": "જન્મતારીખ જરૂરી છે",
  },
  _error_passportExpirationDate_pastdate: {
    "en-US": "Passport Expiry Date should be greater than 6 month of arrival date",
    "ar-AE": "يجب أن يكون تاريخ انتهاء جواز السفر أكبر من 6 أشهر من تاريخ الوصول",
    "gu-IN": "પાસપોર્ટ સમાપ્તિ તારીખ આગમનની તારીખના 6 મહિનાથી વધુ હોવી જોઈએ",
  },
  _error_passportExpirationDate_require: {
    "en-US": "Expiry Date required",
    "ar-AE": "تاريخ الانتهاء مطلوب",
    "gu-IN": "સમાપ્તિ તારીખ આવશ્યક છે",
  },
  _error_city_require: {
    "en-US": "City required",
    "ar-AE": "المدينة المطلوبة",
    "gu-IN": "શહેર જરૂરી છે",
  },
  _error_city_alpha_space: {
    "en-US": "Enter valid City",
    "ar-AE": "أدخل مدينة صالحة",
    "gu-IN": "માન્ય શહેર દાખલ કરો",
  },
  _error_city_length: {
    "en-US": "City must be between 2 and 50 characters long",
    "ar-AE": "يجب أن يتراوح طول المدينة بين 2 و 50 حرفًا",
    "gu-IN": "શહેર 2 થી 50 અક્ષરોની વચ્ચે હોવું જોઈએ",
  },
  _invalidCity: {
    "en-US": "Enter valid City",
    "ar-AE": "أدخل مدينة صالحة",
    "gu-IN": "માન્ય શહેર દાખલ કરો",
  },
  _rqConfirmPassword: {
    "en-US": "Confirm password required",
    "ar-AE": "تأكيد كلمة المرور المطلوبة",
    "gu-IN": "પાસવર્ડની પુષ્ટિ જરૂરી છે",
  },
  _rqDocumentNumber: {
    "en-US": "Document Number required",
    "ar-AE": "رقم الوثيقة المطلوبة",
    "gu-IN": "દસ્તાવેજ ક્રમાંક જરૂરી છે",
  },
  _rqDocumentType: {
    "en-US": "Document Type required",
    "ar-AE": "نوع الوثيقة المطلوبة",
    "gu-IN": "દસ્તાવેજ પ્રકાર જરૂરી છે",
  },
  _error_email_require: {
    "en-US": "Email required",
    "ar-AE": "البريد الإلكتروني (مطلوب",
    "gu-IN": "સંદેશો જરૂરી છે",
  },
  _error_username_require: {
    "en-US": "Username required",
    "ar-AE": "اسم المستخدم (مطلوب",
    "gu-IN": "Username જરૂરી છે",
  },
  _error_email_email: {
    "en-US": "Enter valid Email",
    "ar-AE": "الرجاء إدخال عنوان بريد إلكتروني صالح.",
    "gu-IN": "સંદેશો માન્ય નથી",
  },
  _rqExpiryDate: {
    "en-US": "Expiry Date required",
    "ar-AE": "تاريخ الانتهاء مطلوب",
    "gu-IN": "અંતિમ તારીખ જરૂરી છે",
  },
  _error_firstname_require: {
    "en-US": "First Name required",
    "ar-AE": "الاسم الأول مطلوب",
    "gu-IN": "પ્રથમ નામ જરૂરી છે",
  },
  _error_firstname_duplicate: {
    "en-US": "First name and Last name should be unique",
    "ar-AE": "يجب أن يكون الاسم الأول واسم العائلة فريدًا",
    "gu-IN": "પ્રથમ નામ અને છેલ્લું નામ અનન્ય હોવું જોઈએ",
  },
  _error_lastname_duplicate: {
    "en-US": "First name and Last name should be unique",
    "ar-AE": "يجب أن يكون الاسم الأول واسم العائلة فريدًا",
    "gu-IN": "પ્રથમ નામ અને છેલ્લું નામ અનન્ય હોવું જોઈએ",
  },
  _error_documentNumber_duplicate: {
    "en-US": "Passport Number should be unique",
    "ar-AE": "يجب أن يكون رقم جواز السفر فريدًا",
    "gu-IN": "પાસપોર્ટ નંબર અનન્ય હોવો જોઈએ",
  },
  _rqGender: { "en-US": "Gender required", "ar-AE": "الجنس مطلوب", "gu-IN": "લિંગ જરૂરી છે" },
  _rqGuestFirstName: {
    "en-US": "First Name required",
    "ar-AE": "الجنس مطلوب",
    "gu-IN": "પ્રથમ નામ જરૂરી છે",
  },
  _rqGuestLastName: {
    "en-US": "Last Name required",
    "ar-AE": "نشاط اسم مطلوب",
    "gu-IN": "અટક જરૂરી છે",
  },
  _error_issueingcountry_require: {
    "en-US": "Issuing Country required",
    "ar-AE": "بلد الإصدار المطلوب",
    "gu-IN": "જારી કરનાર દેશ જરૂરી છે",
  },
  _error_itineraryname_require: {
    "en-US": "Itinerary Name required",
    "ar-AE": "اسم مسار الرحلة مطلوب",
    "gu-IN": "પ્રવાસ નામ જરૂરી છે",
  },
  _error_itineraryname_alpha_space: {
    "en-US": "Enter valid Itinerary Name",
    "ar-AE": "أدخل اسم خط سير صالح",
    "gu-IN": "માન્ય ઇટિનરરી નામ દાખલ કરો",
  },
  _error_itineraryname_length: {
    "en-US": "Itinerary Name must be between 2 and 50 characters long",
    "ar-AE": "يجب أن يتراوح طول اسم خط السير بين 2 و 50 حرفًا",
    "gu-IN": "ઇટિનરરી નામ 2 થી 50 અક્ષરોની વચ્ચે હોવું જોઈએ",
  },
  _error_lastname_require: {
    "en-US": "Last Name required",
    "ar-AE": "نشاط اسم مطلوب",
    "gu-IN": "અટક જરૂરી છે",
  },
  _error_firstname_alpha_space: {
    "en-US": "Enter valid First Name",
    "ar-AE": "أدخل الاسم الأول الصحيح",
    "gu-IN": "માન્ય પ્રથમ નામ દાખલ કરો",
  },
  _error_lastname_alpha_space: {
    "en-US": "Enter valid Last Name",
    "ar-AE": "أدخل اسم العائلة الصحيح",
    "gu-IN": "માન્ય છેલ્લું નામ દાખલ કરો",
  },
  _error_firstname_length: {
    "en-US": "First Name must be between 2 and 50 characters long",
    "ar-AE": "يجب أن يتراوح طول الاسم الأول بين حرفين و 50 حرفًا",
    "gu-IN": "પ્રથમ નામ 2 થી 50 અક્ષરો વચ્ચેનું હોવું જોઈએ",
  },
  _invalidLastName: {
    "en-US": "Enter valid Last Name",
    "ar-AE": "أدخل اسم العائلة الصحيح",
    "gu-IN": "માન્ય છેલ્લું નામ દાખલ કરો",
  },
  _error_lastname_length: {
    "en-US": "Last Name must be between 2 and 50 characters long",
    "ar-AE": "يجب أن يتراوح طول اسم العائلة بين 2 و 50 حرفًا",
    "gu-IN": "છેલ્લું નામ 2 થી 50 અક્ષરો વચ્ચેનું હોવું જોઈએ",
  },
  _rqMinPasswordMessageWithStar: {
    "en-US": "Password * Minimum 6 Characters",
    "ar-AE": "كلمة المرور * 6 أحرف على الأقل",
    "gu-IN": "પાસવર્ડ * ન્યૂનતમ 6 અક્ષરો",
  },
  _error_phoneNumber_require: {
    "en-US": "Phone Number required",
    "ar-AE": "رقم الهاتف مطلوب",
    "gu-IN": "મોબાઇલ નંબર જરૂરી છે",
  },
  _error_phoneNumber_phonenumber: {
    "en-US": "Enter valid Phone Number",
    "ar-AE": "أدخل رقم هاتف صالح",
    "gu-IN": "માન્ય ફોન નંબર દાખલ કરો",
  },
  _error_phoneNumber_phonenumber_length: {
    "en-US": "Phone Number must be between 8 and 14 characters long",
    "ar-AE": "يجب أن يتراوح طول رقم الهاتف بين 8 و 14 حرفًا",
    "gu-IN": "ફોન નંબર 8 થી 14 અક્ષરો વચ્ચેનો હોવો જોઈએ",
  },
  _error_mobilenumber_require: {
    "en-US": "Mobile Number required",
    "ar-AE": "رقم الجوال مطلوب",
    "gu-IN": "મોબાઇલ નંબર જરૂરી છે",
  },
  _error_captcha_require: {
    "en-US": "Captcha required",
    "ar-AE": "كلمة التحقق مطلوبة",
    "gu-IN": "કેપ્ચા આવશ્યક છે",
  },
  _error_mobilenumber_phonenumber: {
    "en-US": "Enter valid Mobile Number",
    "ar-AE": "كلمة التحقق مطلوبة",
    "gu-IN": "માન્ય મોબાઇલ નંબર દાખલ કરો",
  },
  _error_mobilenumber_phonenumber_length: {
    "en-US": "Mobile Number must be between 8 and 14 characters long",
    "ar-AE": "يجب أن يتراوح طول رقم الجوال بين 8 و 14 حرفًا",
    "gu-IN": "મોબાઇલ નંબર 8 થી 14 અક્ષરો વચ્ચેનો હોવો જોઈએ",
  },
  _error_nationality_require: {
    "en-US": "Nationality required",
    "ar-AE": "الجنسية المطلوبة",
    "gu-IN": "રાષ્ટ્રીયતા જરૂરી છે",
  },
  _error_password_length: {
    "en-US": "Password must be between 6 and 20 characters long",
    "ar-AE": "يجب أن يتراوح طول كلمة المرور بين 6 و 20 حرفًا",
    "gu-IN": "પાસવર્ડ 6 અને 20 અક્ષરો વચ્ચેનો હોવો જોઈએ",
  },
  _error_password_require: {
    "en-US": "Password required",
    "ar-AE": "كلمة المرور مطلوبة",
    "gu-IN": "પાસવર્ડ જરૂરી છે",
  },
  _error_confirmpassword_require: {
    "en-US": "Confirm Password required",
    "ar-AE": "تأكيد كلمة المرور المطلوبة",
    "gu-IN": "પુષ્ટિ પાસવર્ડ જરૂરી છે",
  },
  _error_confirmpassword_not_match: {
    "en-US": "Confirm Password not matched",
    "ar-AE": "تأكيد كلمة المرور غير متطابقة",
    "gu-IN": "પુષ્ટિ કરો પાસવર્ડ મેળ ખાતો નથી",
  },
  _error_documentNumber_require: {
    "en-US": "Passport Number required",
    "ar-AE": "رقم جواز السفر مطلوب",
    "gu-IN": "પાસપોર્ટ નંબર જરૂરી છે",
  },
  _error_documentNumber_alpha_numeric: {
    "en-US": "Enter valid Passport Number",
    "ar-AE": "أدخل رقم جواز سفر صالح",
    "gu-IN": "માન્ય પાસપોર્ટ નંબર દાખલ કરો",
  },
  _error_socialSecurityNumber_alpha_numeric: {
    "en-US": "Enter valid Social Security Number",
    "ar-AE": "أدخل رقم ضمان اجتماعي صالح",
    "gu-IN": "માન્ય સામાજિક સુરક્ષા નંબર દાખલ કરો",
  },
  _error_documentType_require: {
    "en-US": "Document Type required",
    "ar-AE": "نوع الوثيقة المطلوبة",
    "gu-IN": "દસ્તાવેજ પ્રકાર જરૂરી છે",
  },
  _error_documentNumber_length: {
    "en-US": "Passport Number must be between 5 and 20 characters long",
    "ar-AE": "يجب أن يكون رقم جواز السفر بين 5 و 20 حرفاً",
    "gu-IN": "પાસપોર્ટ નંબર 5 અને 20 અક્ષરોની વચ્ચે હોવો જોઈએ",
  },
  _error_socialSecurityNumber_length: {
    "en-US": "Social Security Number must be between 2 and 20 characters long",
    "ar-AE": "يجب أن يتراوح طول رقم الضمان الاجتماعي بين حرفين و 20 حرفًا",
    "gu-IN": "સામાજિક સુરક્ષા નંબર 2 થી 20 અક્ષરોની વચ્ચે હોવો જોઈએ",
  },
  _rqPassword: {
    "en-US": "Password required",
    "ar-AE": "كلمة المرور مطلوبة",
    "gu-IN": "પાસવર્ડ જરૂરી છે",
  },
  _rqPasswordNotMatch: {
    "en-US": "Password does not match",
    "ar-AE": "كلمة السر غير متطابقة",
    "gu-IN": "પાસવર્ડ મેળ ખાતો નથી",
  },
  _rqSecondaryNumber: {
    "en-US": "Secondary Number required",
    "ar-AE": "الرقم الثانوي مطلوب",
    "gu-IN": "માધ્યમિક નંબર જરૂરી છે",
  },
  _rqUserNamePassword: {
    "en-US": "Please enter user name and password.",
    "ar-AE": "الرجاء إدخال اسم المستخدم وكلمة المرور.",
    "gu-IN": "કૃપા કરીને વપરાશકર્તા નામ અને પાસવર્ડ દાખલ કરો.",
  },
  _rqUserNameEmail: {
    "en-US": "Please enter user name and Email.",
    "ar-AE": "يرجى إدخال اسم المستخدم والبريد الإلكتروني.",
    "gu-IN": "કૃપા કરીને વપરાશકર્તા નામ અને ઇમેઇલ દાખલ કરો",
  },
  _rqMobileNumberPassword: {
    "en-US": "Please enter mobile number and password.",
    "ar-AE": "الرجاء إدخال رقم الهاتف وكلمة المرور.",
    "gu-IN": "મહેરબાની કરીને મોબાઇલ નંબર અને પાસવર્ડ દાખલ કરો.",
  },
  _error_ZipCodeOrPostalCode_require: {
    "en-US": "Zipcode/Postal Code required",
    "ar-AE": "الرمز البريدي / الرمز البريدي مطلوب",
    "gu-IN": "પિનકોડ / પોસ્ટલ કોડ જરૂરી છે",
  },
  _error_ZipCodeOrPostalCode_alpha_numeric: {
    "en-US": "Enter valid Zip code/Postal code",
    "ar-AE": "أدخل الرمز البريدي صالح / الرمز البريدي",
    "gu-IN": "માન્ય પિન કોડ / પોસ્ટલ કોડ દાખલ કરો",
  },
  _error_ZipCodeOrPostalCode_length: {
    "en-US": "Zip code/Postal code must be between 4 and 10 characters long",
    "ar-AE": "يجب أن يتراوح طول الرمز البريدي / الرمز البريدي بين 4 و 10 أحرف",
    "gu-IN": "પિન કોડ / પોસ્ટલ કોડ 4 અને 10 અક્ષરોની વચ્ચે હોવો આવશ્યક છે",
  },
  _error_pickuphotelname_require: {
    "en-US": "Pickup Hotel Name required",
    "ar-AE": "مطلوب اسم فندق بيك آب",
    "gu-IN": "દુકાન હોટેલનું નામ આવશ્યક છે",
  },
  _error_pickupfromdate_require: {
    "en-US": "Arriving Date required",
    "ar-AE": "تاريخ الوصول مطلوب",
    "gu-IN": "આગમનની તારીખ આવશ્યક છે",
  },
  _error_pickuptime_require: {
    "en-US": "Arriving Time required",
    "ar-AE": "وقت الوصول المطلوب",
    "gu-IN": "પહોંચવાનો સમય જરૂરી છે",
  },
  _error_dropoffhotelname_require: {
    "en-US": "Dropoff Hotel Name required",
    "ar-AE": "اسم الفندق",
    "gu-IN": "ડ્રropપoffફ હોટેલનું નામ આવશ્યક છે",
  },
  _error_returnpickuphotelname_require: {
    "en-US": "Return Pickup Hotel Name required",
    "ar-AE": "مطلوب اسم فندق العودة لاقط",
    "gu-IN": "રીટર્ન પિકઅપ હોટલનું નામ આવશ્યક છે",
  },
  _error_returnpickupfromdate_require: {
    "en-US": "Return Arriving Date required",
    "ar-AE": "تاريخ وصول العودة مطلوب",
    "gu-IN": "પરત આવવાની તારીખ આવશ્યક છે",
  },
  _error_returnpickuptime_require: {
    "en-US": "Return Arriving Time required",
    "ar-AE": "وقت وصول العودة مطلوب",
    "gu-IN": "પરત આવવાનો સમય જરૂરી છે",
  },
  _error_returndropoffhotelname_require: {
    "en-US": "Return Dropoff Hotel Name required",
    "ar-AE": "مطلوب اسم الفندق",
    "gu-IN": "રીટર્ન ડ્રોપઓફ હોટેલનું નામ આવશ્યક છે",
  },
  _error_otherdetailspickuphotelname_require: {
    "en-US": "Pickup Hotel Name required",
    "ar-AE": "مطلوب اسم فندق بيك آب",
    "gu-IN": "દુકાન હોટેલનું નામ આવશ્યક છે",
  },
  _error_otherdetailsdropoffhotelname_require: {
    "en-US": "Dropoff Hotel Name required",
    "ar-AE": "اسم الفندق",
    "gu-IN": "ડ્રropપoffફ હોટેલનું નામ આવશ્યક છે",
  },
  _error_otherdetailsreturnpickuphotelname_require: {
    "en-US": "Return Pickup Hotel Name required",
    "ar-AE": "مطلوب اسم فندق العودة لاقط",
    "gu-IN": "રીટર્ન પિકઅપ હોટલનું નામ આવશ્યક છે",
  },
  _error_otherdetailsreturndropoffhotelname_require: {
    "en-US": "Return Dropoff Hotel Name required",
    "ar-AE": "مطلوب اسم الفندق",
    "gu-IN": "રીટર્ન ડ્રોપઓફ હોટેલનું નામ આવશ્યક છે",
  },
  _error_otherdetailspickupairport_require: {
    "en-US": "Pickup Airport required",
    "ar-AE": "مطار بيك اب مطلوب",
    "gu-IN": "પિકઅપ એરપોર્ટ આવશ્યક છે",
  },
  _error_otherdetailsdropoffairport_require: {
    "en-US": "Dropoff Airport required",
    "ar-AE": "مطار دروبوف مطلوب",
    "gu-IN": "ડ્રોપઓફ એરપોર્ટ આવશ્યક છે",
  },
  _error_otherdetailsreturnpickupairport_require: {
    "en-US": "Return Pickup Airport required",
    "ar-AE": "مطار العودة لاقط مطلوب",
    "gu-IN": "રીટર્ન પિકઅપ એરપોર્ટ આવશ્યક છે",
  },
  _error_otherdetailsreturndropoffairport_require: {
    "en-US": "Return Dropoff Airport required",
    "ar-AE": "مطلوب مطار العودة",
    "gu-IN": "રીટર્ન ડ્રોપઓફ એરપોર્ટ આવશ્યક છે",
  },
  _error_arrivingairline_require: {
    "en-US": "Arriving Airline required",
    "ar-AE": "مطلوب شركة طيران قادمة",
    "gu-IN": "એરલાઇન આવવું જરૂરી છે",
  },
  _error_arrivingfromaddress_require: {
    "en-US": "Arriving From required",
    "ar-AE": "الوصول من المطلوب",
    "gu-IN": "જરૂરી થી પહોંચવું",
  },
  _error_arrivingflightno_require: {
    "en-US": "Arriving Flight required",
    "ar-AE": "رحلة الوصول المطلوبة",
    "gu-IN": "ફ્લાઇટ પહોંચવું જરૂરી છે",
  },
  _error_arrivingfromdate_require: {
    "en-US": "Arriving Date required",
    "ar-AE": "تاريخ الوصول مطلوب",
    "gu-IN": "આગમનની તારીખ આવશ્યક છે",
  },
  _error_arrivingtime_require: {
    "en-US": "Arriving Time required",
    "ar-AE": "وقت الوصول المطلوب",
    "gu-IN": "પહોંચવાનો સમય જરૂરી છે",
  },
  _error_departingairline_require: {
    "en-US": "Departing Airline required",
    "ar-AE": "مطلوب شركة طيران مغادرة",
    "gu-IN": "પ્રયાણ કરતી એરલાઇન આવશ્યક છે",
  },
  _error_departingfromaddress_require: {
    "en-US": "Departing From required",
    "ar-AE": "المغادرة من المطلوب",
    "gu-IN": "જરૂરી માંથી પ્રસ્થાન",
  },
  _error_departingflightno_require: {
    "en-US": "Departing Flight required",
    "ar-AE": "رحلة المغادرة مطلوبة",
    "gu-IN": "રવાના થતી ફ્લાઇટ આવશ્યક છે",
  },
  _error_departingfromdate_require: {
    "en-US": "Departing Date required",
    "ar-AE": "تاريخ المغادرة مطلوب",
    "gu-IN": "પ્રસ્થાનની તારીખ આવશ્યક છે",
  },
  _error_departingtime_require: {
    "en-US": "Departing Time required",
    "ar-AE": "وقت المغادرة المطلوب",
    "gu-IN": "પ્રસ્થાન સમય આવશ્યક છે",
  },
  _error_returnarrivingairline_require: {
    "en-US": "Return Arriving Airline required",
    "ar-AE": "مطلوب شركة طيران للعودة",
    "gu-IN": "રીટર્ન આગમન એરલાઇન આવશ્યક છે",
  },
  _error_returnarrivingfromaddress_require: {
    "en-US": "Return Arriving From required",
    "ar-AE": "العودة قادمة من المطلوب",
    "gu-IN": "પરત આવવું જરૂરી છે",
  },
  _error_returnarrivingflightno_require: {
    "en-US": "Return Arriving Flight required",
    "ar-AE": "مطلوب رحلة عودة",
    "gu-IN": "રીટર્ન આગમન ફ્લાઇટ આવશ્યક છે",
  },
  _error_returnarrivingfromdate_require: {
    "en-US": "Return Arriving Date required",
    "ar-AE": "تاريخ وصول العودة مطلوب",
    "gu-IN": "પરત આવવાની તારીખ આવશ્યક છે",
  },
  _error_returnarrivingtime_require: {
    "en-US": "Return Arriving Time required",
    "ar-AE": "وقت وصول العودة مطلوب",
    "gu-IN": "પરત આવવાનો સમય જરૂરી છે",
  },
  _error_returndepartingairline_require: {
    "en-US": "Return Departing Airline required",
    "ar-AE": "مطلوب شركة طيران عودة",
    "gu-IN": "રીટર્ન રવાના થતી એરલાઇન આવશ્યક છે",
  },
  _error_returndepartingfromaddress_require: {
    "en-US": "Return Departing From required",
    "ar-AE": "العودة المغادرة من المطلوب",
    "gu-IN": "પરત પ્રયાણથી જરૂરી",
  },
  _error_returndepartingflightno_require: {
    "en-US": "Return Departing Flight required",
    "ar-AE": "مطلوب رحلة ذهاب وعودة",
    "gu-IN": "રીટર્ન રવાના થતી ફ્લાઇટ આવશ્યક છે",
  },
  _error_returndepartingfromdate_require: {
    "en-US": "Return Departing Date required",
    "ar-AE": "تاريخ مغادرة العودة مطلوب",
    "gu-IN": "રિટર્ન પ્રસ્થાનની તારીખ આવશ્યક છે",
  },
  _error_returndepartingtime_require: {
    "en-US": "Return Departing Time required",
    "ar-AE": "وقت مغادرة العودة مطلوب",
    "gu-IN": "રિટર્ન પ્રસ્થાન સમય જરૂરી છે",
  },
  _error_additional_checkin_require: {
    "en-US": "Hotel Check Date in required",
    "ar-AE": "تاريخ تسجيل الفندق مطلوب",
    "gu-IN": "હોટેલ ચેક તારીખ જરૂરી છે",
  },
  _error_additional_addresscityzipcodehotel_require: {
    "en-US": "Hotel address,city and zip code required",
    "ar-AE": "مطلوب عنوان الفندق والمدينة والرمز البريدي",
    "gu-IN": "હોટેલ સરનામું, શહેર અને પિન કોડ આવશ્યક છે",
  },
  _error_additional_hotelname_require: {
    "en-US": "Hotel Name required",
    "ar-AE": "اسم الفندق مطلوب",
    "gu-IN": "હોટેલનું નામ આવશ્યક છે",
  },
  _error_additional_require: {
    "en-US": "Please enter mandatory Additional Information",
    "ar-AE": "يرجى إدخال معلومات إضافية إلزامية",
    "gu-IN": "કૃપા કરીને ફરજિયાત વધારાની માહિતી દાખલ કરો",
  },
  _save: { "en-US": "Save", "ar-AE": "حفظ", "gu-IN": "સાચવો" },
  _scheduleTerms: { "en-US": "Schedule Terms", "ar-AE": "شروط الجدول", "gu-IN": "સમયપત્રકની શરતો" },
  _secondaryNumber: {
    "en-US": "Secondary Number",
    "ar-AE": "الرقم الثانوي",
    "gu-IN": "માધ્યમિક નંબર",
  },
  _serviceChargesOrPaymentFees: {
    "en-US": "Service Charges / Gateway Charges",
    "ar-AE": "رسوم الخدمة / رسوم البوابة",
    "gu-IN": "સેવા ખર્ચ / ચુકવણી ફી",
  },
  _showDetails: { "en-US": "Show details", "ar-AE": "مشاهدة التفاصيل", "gu-IN": "વિગતો બતાવો" },
  _signIn: { "en-US": "Sign In", "ar-AE": "تسجيل الدخول", "gu-IN": "સાઇન ઇન" },
  _signOut: { "en-US": "Sign Out", "ar-AE": "تسجيل الخروج", "gu-IN": "સાઇન આઉટ" },
  _signUp: { "en-US": "Sign Up", "ar-AE": "سجل", "gu-IN": "સાઇન અપ કરો" },
  _signupPageAgreeMessagePart1: {
    "en-US": "I have READ and AGREED to all the and Privacy Policy",
    "ar-AE": "لقد قرأت ووافقت على جميع و سياسة الخصوصية",
    "gu-IN": "મેં બધી ગોપનીયતા નીતિને વાંચી અને સંમત કરી છે",
  },
  _signupPageAgreeMessagePart2: {
    "en-US": "Terms and Conditions",
    "ar-AE": "الأحكام والشروط",
    "gu-IN": "નિયમો અને શરત",
  },
  _signupPageAgreeMessagePart3: { "en-US": "and", "ar-AE": "و", "gu-IN": "અને" },
  _signupPageAgreeMessagePart4: {
    "en-US": "Privacy Policy",
    "ar-AE": "سياسة الخصوصية",
    "gu-IN": "ગોપનીયતા નીતિ",
  },
  _signupPageAgreeMessagePart5: {
    "en-US": "are besides the individual items listed above)",
    "ar-AE": "إلى جانب العناصر الفردية المذكورة أعلاه)",
    "gu-IN": "ઉપર સૂચિબદ્ધ વ્યક્તિગત વસ્તુઓ ઉપરાંત)",
  },
  _sortBy: { "en-US": "Sort By", "ar-AE": "ترتيب", "gu-IN": "સૉર્ટ કરો" },
  _sortname: { "en-US": "Name", "ar-AE": "الاسم", "gu-IN": "નામ" },
  _sortpromotions: { "en-US": "Promotions", "ar-AE": "لا يوجد عروض", "gu-IN": "પ્રમોશન" },
  _sortrate: { "en-US": "Rate", "ar-AE": "سعر", "gu-IN": "કિંમત" },
  _sortcategory: { "en-US": "Category", "ar-AE": "الفئة", "gu-IN": "કેટેગરી" },
  _sortstarrating: { "en-US": "Star rating", "ar-AE": "تقييم", "gu-IN": "સ્ટાર રેટિંગ" },
  _sortduration: { "en-US": "Duration", "ar-AE": "المدة", "gu-IN": "સમયગાળો" },
  _sortairline: { "en-US": "Airline", "ar-AE": "الخطوط الجوية", "gu-IN": "એરલાઇન" },
  _sortdeparture: { "en-US": "Departure", "ar-AE": "مغادرة", "gu-IN": "પ્રસ્થાન" },
  _sortarrival: { "en-US": "Arrival", "ar-AE": "وصول", "gu-IN": "આગમન" },
  _status: { "en-US": "Status", "ar-AE": "الحالة", "gu-IN": "સ્થિતિ" },
  _telephoneNumber: { "en-US": "Telephone Number", "ar-AE": "رقم هاتف", "gu-IN": "ટેલીફોન નંબર" },
  _termsAndConditions: {
    "en-US": "Terms & Conditions",
    "ar-AE": "الأحكام والشروط",
    "gu-IN": "નિયમો અને શરત",
  },
  _thingsYouWillLove: {
    "en-US": "Things you'll love",
    "ar-AE": "أشياء ستحبها",
    "gu-IN": "તમને ગમતી વસ્તુઓ",
  },
  _titleAgentLogin: {
    "en-US": "Agent Login",
    "ar-AE": "تسجيل الدخول الوكيل",
    "gu-IN": "એજન્ટ પ્રવેશ કરો",
  },
  _titleAvailability: { "en-US": "Availability", "ar-AE": "اختيار الغرف", "gu-IN": "ઉપલભ્યતા" },
  _titleRoomsAndRates: { "en-US": "Rooms & Rates", "ar-AE": "الغرف والأسعار", "gu-IN": "ઉપલભ્યતા" },
  _titleScheduleAndRates: {
    "en-US": "Schedule & Rates",
    "ar-AE": "الجدول والأسعار",
    "gu-IN": "ઉપલભ્યતા",
  },
  _titleBookingFailed: {
    "en-US": "Your booking has been Failed",
    "ar-AE": "فشل الحجز الخاص بك",
    "gu-IN": "તમારું બુકિંગ નિષ્ફળ થયું છે",
  },
  _titleBookingStatus: {
    "en-US": "Booking Status",
    "ar-AE": "وضع الحجز",
    "gu-IN": "બુકિંગ સ્થિતિ",
  },
  _titleBookingSuccessfully: {
    "en-US": "Your booking has been successfully completed",
    "ar-AE": "اكتمل حجزك بنجاح",
    "gu-IN": "તમારું બુકિંગ સફળતાપૂર્વક પૂર્ણ થઈ ગયું છે",
  },
  _titleBookings: { "en-US": "Bookings", "ar-AE": "الحجوزات", "gu-IN": "બુકિંગ" },
  _titleContactDetails: {
    "en-US": "Contact Details",
    "ar-AE": "تفاصيل الاتصال",
    "gu-IN": "સંપર્ક વિગતો",
  },
  _titleContactInformation: {
    "en-US": "Contact Information",
    "ar-AE": "معلومات التواصل",
    "gu-IN": "સંપર્ક માહિતી",
  },
  _titleLoginInformation: {
    "en-US": "Login Information",
    "ar-AE": "معلومات تسجيل الدخول",
    "gu-IN": "પ્રવેશ માહિતી",
  },
  _titleDocumentInformation: {
    "en-US": "Document Information",
    "ar-AE": "معلومات المستند",
    "gu-IN": "દસ્તાવેજ માહિતી",
  },
  _showChangePassword: {
    "en-US": "Change Password",
    "ar-AE": "تغيير كلمة المرور",
    "gu-IN": "પાસવર્ડ બદલો",
  },
  _hideChangePassword: {
    "en-US": "Hide Change Password",
    "ar-AE": "إخفاء تغيير كلمة المرور",
    "gu-IN": "બદલો પાસવર્ડ છુપાવો",
  },
  _titleCreateMyAccount: {
    "en-US": "Create my account",
    "ar-AE": "إنشاء حساب جديد",
    "gu-IN": "મારું એકાઉન્ટ બનાવો",
  },
  _titleDocumentDetails: {
    "en-US": "Document Details",
    "ar-AE": "معلومات الوثائق الخاصة بي",
    "gu-IN": "દસ્તાવેજ વિગતો",
  },
  _titleExclusions: { "en-US": "Exclusions", "ar-AE": "الاستثناءات", "gu-IN": "પર્યટન" },
  _titleFacilities: { "en-US": "Facilities", "ar-AE": "التسهيلات", "gu-IN": "સુવિધાઓ" },
  _titleHotels: { "en-US": "Hotels", "ar-AE": "فندق", "gu-IN": "હોટેલ" },
  _titleInclusions: { "en-US": "Inclusions", "ar-AE": "يتضمن", "gu-IN": "સમાવેશ" },
  _titleItineraryDetails: {
    "en-US": "Itinerary Details",
    "ar-AE": "تفاصيل خط سير",
    "gu-IN": "પ્રવાસ વિગતો",
  },
  _titleLogin: { "en-US": "Sign in", "ar-AE": "تسجيل الدخول", "gu-IN": "પ્રવેશ કરો" },
  _titleMyBookings: { "en-US": "My Bookings", "ar-AE": "حجوزاتي", "gu-IN": "મારું બુકિંગ" },
  _titlePackageDetails: {
    "en-US": "Package Details",
    "ar-AE": "تفاصيل حزمة الخاص بك",
    "gu-IN": "પેકેજ વિગતો",
  },
  _titletransfersDetails: {
    "en-US": "Transfers Details",
    "ar-AE": "تفاصيل التحويلات",
    "gu-IN": "સ્થાનાંતરણ વિગતો",
  },
  _titleActivityDetails: {
    "en-US": "Activity Details",
    "ar-AE": "تفاصيل النشاط",
    "gu-IN": "પેકેજ વિગતો",
  },
  _titlePaymentMode: {
    "en-US": "How would you like to pay?",
    "ar-AE": "كيف تريد الدفع؟",
    "gu-IN": "ચુકવણી પદ્ધતિ",
  },
  _titlePersonalInformation: {
    "en-US": "Personal Information",
    "ar-AE": "مفلومات شخصية",
    "gu-IN": "વ્યક્તિગત માહિતી",
  },
  _titlePolicies: { "en-US": "Policies", "ar-AE": "سياسات", "gu-IN": "નીતિઓ" },
  _titleRoomFacilities: {
    "en-US": "Room Facilities",
    "ar-AE": "مرافق الغرفة",
    "gu-IN": "રૂમ સુવિધાઓ",
  },
  _titleSelectedRooms: {
    "en-US": "Selected Room(s)",
    "ar-AE": "الغرف المختارة",
    "gu-IN": "પસંદ કરેલ ઓરડા(ઓ)",
  },
  _titleTravellersDetails: {
    "en-US": "Guest Details",
    "ar-AE": "تفاصيل الضيف",
    "gu-IN": "પ્રવાસીઓ વિગતો",
  },
  _titleactivity: { "en-US": "Activity(s)", "ar-AE": "الانشطة", "gu-IN": "પ્રવૃત્તિ(ઓ)" },
  _titletransfers: { "en-US": "Transfer(s)", "ar-AE": "تحويلات", "gu-IN": "સ્થાનાંતરણો" },
  _titleair: { "en-US": "Flight(s)", "ar-AE": "رحلات طيران", "gu-IN": "ફ્લાઇટ(ઓ)" },
  _titlehotel: { "en-US": "Hotel(s)", "ar-AE": "فندق", "gu-IN": "હોટેલ(ઓ)" },
  _titlepackage: { "en-US": "Package", "ar-AE": "صفقة", "gu-IN": "પેકેજ" },
  _titlevehicle: { "en-US": "Vehicle(s)", "ar-AE": "مركبات)", "gu-IN": "વાહનો" },
  _toSmall: { "en-US": "to", "ar-AE": "إلى", "gu-IN": "તરફ" },
  _total: { "en-US": "Total", "ar-AE": "كلي", "gu-IN": "કુલ" },
  _totalAmount: { "en-US": "Total Price", "ar-AE": "السعر الاجمالى", "gu-IN": "કુલ રકમ" },
  _TotalAmount: { "en-US": "Total Price", "ar-AE": "السعر الاجمالى", "gu-IN": "કુલ રકમ" },
  _lblTotalAmount: { "en-US": "Total Price", "ar-AE": "السعر الاجمالى", "gu-IN": "કુલ રકમ" },
  _totalPayableAmount: {
    "en-US": "Total Payable Amount ",
    "ar-AE": "إجمالي المبلغ المستحق",
    "gu-IN": "કુલ રકમ",
  },
  _totalPrice: { "en-US": "Total Price", "ar-AE": "السعر الاجمالى", "gu-IN": "કુલ કિંમત" },
  _totalPriceperRoom: { "en-US": "Total Price/Per Room", "ar-AE": "السعر الإجمالي / لكل غرفة", "gu-IN": "કુલ ભાવ / દીઠ રૂમ" },
  _transactionToken: {
    "en-US": "Transaction Token",
    "ar-AE": "رمز العملية",
    "gu-IN": "ટ્રાન્ઝેક્શન ટોકન",
  },
  _travelersDetails: {
    "en-US": "Guest Details",
    "ar-AE": "تفاصيل المعتمد",
    "gu-IN": "પ્રવાસીઓની વિગતો",
  },
  _mutamerDetails: {
    "en-US": "Mutamer Details",
    "ar-AE": "تفاصيل الضيف",
    "gu-IN": "પ્રવાસીઓની વિગતો",
  },
  _userName: { "en-US": "User Name", "ar-AE": "اسم المستخدم", "gu-IN": "વપરાશકર્તા નામ" },
  _validationEmptyMessage: {
    "en-US": " is not allowed to be empty",
    "ar-AE": "لا يجوز أن تكون فارغة",
    "gu-IN": "ખાલી રહેવાની મંજૂરી નથી",
  },
  _SorryNoItemHere: {
    "en-US": "Sorry No Item Here!",
    "ar-AE": "عذرا لا يوجد عنصر هنا!",
    "gu-IN": "માફ કરશો નહીં અહીં કોઈ વસ્તુ નથી!",
  },
  _viewAddress: { "en-US": "Address", "ar-AE": "العنوان", "gu-IN": "સરનામું" },
  _viewactivityDate: {
    "en-US": "Activity Date",
    "ar-AE": "تاريخ النشاط",
    "gu-IN": "પ્રવૃત્તિ તારીખ",
  },
  _viewtransfersDate: {
    "en-US": "Transfer Date",
    "ar-AE": "تاريخ التحويل",
    "gu-IN": "પરિવહન તારીખ",
  },
  _viewactivityDetailsTitile: {
    "en-US": "Activity Details",
    "ar-AE": "تفاصيل النشاط",
    "gu-IN": "પ્રવૃત્તિ વિગતો",
  },
  _viewtransfersDetailsTitile: {
    "en-US": "Transfer Details",
    "ar-AE": "تفاصيل التحويل",
    "gu-IN": "સ્થાનાંતરણ વિગતો",
  },
  _viewhotelDetailsTitile: {
    "en-US": "Hotel Details",
    "ar-AE": "تفاصيل الفندق",
    "gu-IN": "હોટેલ વિગતો",
  },
  _viewhotelDetails: { "en-US": "Hotel Details", "ar-AE": "تفاصيل الفندق", "gu-IN": "હોટેલ વિગતો" },
  _viewactivity: { "en-US": "Activity", "ar-AE": "نشاط", "gu-IN": "પ્રવૃત્તિ" },
  _viewhotel: { "en-US": "Hotel", "ar-AE": "فندق", "gu-IN": "હોટેલ" },
  _viewactivityName: {
    "en-US": "Activity Name",
    "ar-AE": "اسم النشاط",
    "gu-IN": "પ્રવૃત્તિનું નામ",
  },
  _viewtransfersName: {
    "en-US": "Transfer Name",
    "ar-AE": "اسم التحويل",
    "gu-IN": "નામ સ્થાનાંતરિત કરો",
  },
  _viewhotelName: { "en-US": "Hotel Name", "ar-AE": "اسم الفندق", "gu-IN": "હોટેલનું નામ" },
  _viewAdult: { "en-US": "Adult", "ar-AE": "بالغ", "gu-IN": "પુખ્તવયી" },
  _viewAdults: { "en-US": "Adults", "ar-AE": "البالغين", "gu-IN": "પુખ્ત વયના લોકો" },
  _viewAirDepart: { "en-US": "Depart", "ar-AE": "تغادر", "gu-IN": "પ્રસ્થાન" },
  _viewAirArrive: { "en-US": "Arrive", "ar-AE": "الوصول", "gu-IN": "આવવું" },
  _lblBaseRate: { "en-US": "Base Price", "ar-AE": "السعر الأساسي", "gu-IN": "આધાર દર" },
  _lblBaseFare: { "en-US": "Base Price", "ar-AE": "السعر الأساسي", "gu-IN": "આધાર દર" },
  _viewBaseRate: { "en-US": "Base Price", "ar-AE": "السعر الأساسي", "gu-IN": "આધાર દર" },
  _viewBASEPRICE: { "en-US": "Base Price", "ar-AE": "السعر الأساسي", "gu-IN": "આધાર ભાવ" },
  _viewBirthday: { "en-US": "Birth Date", "ar-AE": "تاريخ الميلاد", "gu-IN": "જન્મદિવસ" },
  _viewBirthDate: { "en-US": "Birth Date", "ar-AE": "تاريخ الميلاد", "gu-IN": "જન્મતારીખ" },
  _viewBoardTypes: { "en-US": "Board types", "ar-AE": "نوع الغرفة", "gu-IN": "બોર્ડ પ્રકારો" },
  _viewBookingDate: { "en-US": "Booking Date", "ar-AE": "تاريخ الحجز", "gu-IN": "બુકિંગ તારીખ" },
  _viewBookingFor: { "en-US": "Booking for", "ar-AE": "حجز ل", "gu-IN": "માટે બુકિંગ" },
  _viewTripType: { "en-US": "Trip Type", "ar-AE": "نوع الرحلة", "gu-IN": "ટ્રિપનો પ્રકાર" },
  _PickupHotel: { "en-US": "Pickup Hotel", "ar-AE": "فندق بيك اب", "gu-IN": "પિકઅપ હોટલ" },
  _ReturnPickupHotel: {
    "en-US": "Return Pickup Hotel",
    "ar-AE": "العودة فندق بيك اب",
    "gu-IN": "રીટર્ન પીકઅપ હોટલ",
  },
  _DropoffHotel: { "en-US": "Dropoff Hotel", "ar-AE": "فندق دروبوف", "gu-IN": "ડ્રોપઓફ હોટેલ" },
  _ReturnDropoffHotel: {
    "en-US": "Return Dropoff Hotel",
    "ar-AE": "فندق العودة",
    "gu-IN": "પરત ડ્રોપઓફ હોટેલ",
  },
  _PickupAirport: { "en-US": "Pickup Airport", "ar-AE": "مطار بيك اب", "gu-IN": "પિકઅપ એરપોર્ટ" },
  _PickupDate: { "en-US": "Pickup Date", "ar-AE": "اختر تاريخا", "gu-IN": "દુકાન તારીખ" },
  _PickupTime: { "en-US": "Pickup Time", "ar-AE": "اختار المعاد", "gu-IN": "દુકાન સમય" },
  _DropOffDate: { "en-US": "Drop Off Date", "ar-AE": "تاريخ التسليم", "gu-IN": "તારીખ છોડો" },
  _ReturnPickupAirport: {
    "en-US": "Return Pickup Airport",
    "ar-AE": "العودة مطار العودة",
    "gu-IN": "રીટર્ન પીકઅપ એરપોર્ટ",
  },
  _ReturnPickupDate: {
    "en-US": "Return Pickup Date",
    "ar-AE": "تاريخ الاستلام",
    "gu-IN": "રીટર્ન પિકઅપ તારીખ",
  },
  _ReturnPickupTime: {
    "en-US": "Return Pickup Time",
    "ar-AE": "وقت الاسترجاع",
    "gu-IN": "રીટર્ન પિકઅપ ટાઇમ",
  },
  _DropoffAirport: { "en-US": "Dropoff Airport", "ar-AE": "مطار دروب", "gu-IN": "ડ્રોપઓફ એરપોર્ટ" },
  _DropoffDate: { "en-US": "Dropoff Date", "ar-AE": "تاريخ الانسحاب", "gu-IN": "ડ્રોપઓફ તારીખ" },
  _DropoffTime: { "en-US": "Dropoff Time", "ar-AE": "لا تحتاج الي وقت", "gu-IN": "ડ્રોપઓફ સમય" },
  _ReturnDropoffAirport: {
    "en-US": "Return Dropoff Airport",
    "ar-AE": "مطار العودة",
    "gu-IN": "રીટર્ન ડ્રોપઓફ એરપોર્ટ",
  },
  _ReturnDropoffDate: {
    "en-US": "Return Dropoff Date",
    "ar-AE": "تاريخ الإرجاع",
    "gu-IN": "પરત છોડવાની તારીખ",
  },
  _ReturnDropoffTime: {
    "en-US": "Return Dropoff Time",
    "ar-AE": "وقت الإرجاع",
    "gu-IN": "વળતર છોડવાનો સમય",
  },
  _lblUploadSocument: {
    "en-US": "Upload File *",
    "ar-AE": "تحميل الملفات *",
    "gu-IN": "ફાઈલ અપલોડ કરો *",
  },
  _error_uploadDocument_notValidExtensions: {
    "en-US": "File type allowed (.jpg,.png,.jpeg,.zip,.pdf,.doc,.docx)",
    "ar-AE": "نوع الملف مسموح به (.jpg,.png,.jpeg,.zip,.pdf,.doc,.docx)",
    "gu-IN":
      "કૃપા કરી માન્ય એક્સ્ટેંશન (.jpg, .png, .jpeg, .zip, .pdf, .doc, .docx) સાથે ફાઇલ અપલોડ કરો.",
  },
  _error_uploadDocument_notValidSize: {
    "en-US": "File size must be less than 512 KB.  ",
    "ar-AE": "يجب أن يكون حجم الملف أقل من 512 كيلوبايت.",
    "gu-IN": "કૃપા કરી માન્ય કદ સાથે ફાઇલ અપલોડ કરો (512 કેબી કરતા ઓછી)",
  },
  _error_uploadDocument_require: {
    "en-US": "Upload File required",
    "ar-AE": "مطلوب رفع ملف",
    "gu-IN": "ફાઇલ અપલોડ કરવી જરૂરી છે",
  },
  _viewBookingReferenceNumber: {
    "en-US": "Booking Reference Number",
    "ar-AE": "رقم مرجع الحجز",
    "gu-IN": "બુકિંગ સંદર્ભ નંબર",
  },
  _viewBtnDelete: { "en-US": "Delete", "ar-AE": "الغاء", "gu-IN": "કાઢી નાંખવું" },
  _viewBtnEdit: { "en-US": "Edit", "ar-AE": "تحرير", "gu-IN": "સંપાદિત કરો" },
  _viewCheckIn: { "en-US": "Check-in", "ar-AE": "تاريخ الدخول", "gu-IN": "ચેક-ઇન" },
  _viewCheckOut: { "en-US": "Check-out", "ar-AE": "تاريخ الخروج", "gu-IN": "ચેક-આઉટ" },
  _viewCabinClass: { "en-US": "Class", "ar-AE": "درجة", "gu-IN": "વર્ગ" },
  _viewChild: { "en-US": "Child", "ar-AE": "الطفل", "gu-IN": "બાળક" },
  _viewChildren: { "en-US": "Children", "ar-AE": "الأطفال", "gu-IN": "બાળકો" },
  _viewCity: { "en-US": "City", "ar-AE": "المدينة", "gu-IN": "શહેર" },
  _viewCountry: { "en-US": "Country", "ar-AE": "البلد", "gu-IN": "શહેર" },
  _viewContact: { "en-US": "Contact", "ar-AE": "اتصال", "gu-IN": "સંપર્ક કરો" },
  _viewContactPhone: { "en-US": "Contact Phone", "ar-AE": "رقم التواصل", "gu-IN": "ફોન સંપર્ક" },
  _viewDetails: { "en-US": "View Details", "ar-AE": "عرض التفاصيل", "gu-IN": "વિગતો જુઓ" },
  _viewDocument: { "en-US": "Document", "ar-AE": "المستند", "gu-IN": "દસ્તાવેજ" },
  _viewDuration: { "en-US": "Duration", "ar-AE": "المدة", "gu-IN": "સમયગાળો" },
  _viewEmail: { "en-US": "Email", "ar-AE": "الايميل", "gu-IN": "સંદેશો" },
  _viewETicket: { "en-US": "E-Ticket", "ar-AE": "تذكرة الكترونية", "gu-IN": "ઇ ટિકિટ" },
  _viewFareSummary: { "en-US": "Price Details", "ar-AE": "تفاصيل السعر", "gu-IN": "ભાડાનો સારાંશ" },
  _viewAgentDetails: { "en-US": "Agent Details", "ar-AE": "تفاصيل العميل", "gu-IN": "એજન્ટ વિગતો" },
  _viewFlight: { "en-US": "Flight", "ar-AE": "طيران", "gu-IN": "ફ્લાઇટ" },
  _viewFees: { "en-US": "Fees", "ar-AE": "الرسوم", "gu-IN": "Fees" },
  _viewFirstName: { "en-US": "First name", "ar-AE": "الاسم الاول", "gu-IN": "પ્રથમ નામ" },
  _viewGender: { "en-US": "Gender", "ar-AE": "الجنس", "gu-IN": "લિંગ" },
  _viewGuests: { "en-US": "Guest(s)", "ar-AE": "الضيوف", "gu-IN": "અતિથિ(ઓ)" },
  _viewTravellers: { "en-US": "Traveller(s)", "ar-AE": "المسافر (ق)", "gu-IN": "પ્રવાસી (ઓ)" },
  _lblBaggage: { "en-US": "Baggage", "ar-AE": "أمتعة", "gu-IN": "ar_Baggage" },
  _viewGuestName: { "en-US": "Guest Name", "ar-AE": "الاسم", "gu-IN": "અતિથિ નામ" },
  _viewInfant: { "en-US": "Infant", "ar-AE": "رضيع", "gu-IN": "શિશુ" },
  _viewItineraryName: {
    "en-US": "Itinerary Name",
    "ar-AE": "اسم مسار الرحلة",
    "gu-IN": "પ્રવાસ નામ",
  },
  _viewItineraryNumber: {
    "en-US": "Itinerary Number",
    "ar-AE": "رقم  خط سير الرحلة",
    "gu-IN": "પ્રવાસ નંબર",
  },
  _viewMeetingPlace: { "en-US": "Meeting Place", "ar-AE": "مكان الالتقاء", "gu-IN": "સભા સ્થળ" },
  _viewName: { "en-US": "Name", "ar-AE": "الاسم", "gu-IN": "નામ" },
  _viewNights: { "en-US": "Night(s)", "ar-AE": "ليالي", "gu-IN": "રાત્રિ(ઓ)" },
  _viewNightsSmall: { "en-US": "night(s)", "ar-AE": "ليالي", "gu-IN": "રાત્રિ(ઓ)" },
  _viewNoCoTravellerFound: {
    "en-US": "No Co-Travellers Found",
    "ar-AE": "لم يتم العثور على مسافرين مشاركين",
    "gu-IN": "કોઈ સહ-મુસાફરો મળ્યાં નથી",
  },
  _viewNoCustomerFound: {
    "en-US": "No Customer Found",
    "ar-AE": "لم يتم العثور على زبون",
    "gu-IN": "કોઈ સહ-મુસાફરો મળ્યાં નથી",
  },
  _viewOperatedBy: { "en-US": "Operated by", "ar-AE": "تشغلها", "gu-IN": "દ્વારા સંચાલિત" },
  _viewOperatorName: { "en-US": "Operator Name", "ar-AE": "اسم المشغل", "gu-IN": "પ્રચાલક નામ" },
  _viewOther: { "en-US": "Portal Policy Fees", "ar-AE": "رسوم سياسة البوابة", "gu-IN": "અન્ય" },
  _viewpackage: { "en-US": "Package", "ar-AE": "صفقة", "gu-IN": "પેકેજ" },
  _viewpackageDate: { "en-US": "Package Date", "ar-AE": "تاريخ الطرد", "gu-IN": "પેકેજ તારીખ" },
  _viewpackageDetailsTitile: {
    "en-US": "Package Details",
    "ar-AE": "تفاصيل حزمة الخاص بك",
    "gu-IN": "પેકેજ વિગતો",
  },
  _viewpackageName: { "en-US": "Package Name", "ar-AE": "اسم الحزمة", "gu-IN": "પેકેજ નામ" },
  _viewPaymentGateway: {
    "en-US": "Payment Gateway",
    "ar-AE": "بوابة الدفع",
    "gu-IN": "ચુકવણી પ્રવેશદ્વાર",
  },
  _viewPhone: { "en-US": "Phone", "ar-AE": "الهاتف", "gu-IN": "ફોન" },
  _viewPhoneHome: { "en-US": "Home Phone", "ar-AE": "هاتف المنزل", "gu-IN": "ઘર નો ફોને" },
  _viewPolicyDetails: {
    "en-US": "Policy Details",
    "ar-AE": "تفاصيل السياسة",
    "gu-IN": "નીતિ વિગતો",
  },
  _viewRating: { "en-US": "Rating", "ar-AE": "حسب تصنيف النجوم", "gu-IN": "રેટિંગ" },
  _viewReservationDetails: {
    "en-US": "Reservation Details",
    "ar-AE": "تفاصيل الحجز",
    "gu-IN": "આરક્ષણ વિગતો",
  },
  _viewRoom: { "en-US": "Room", "ar-AE": "الغرفة", "gu-IN": "ઓરડો" },
  _viewRoomDetails: { "en-US": "Room Details", "ar-AE": "تفاصيل الغرفة", "gu-IN": "ઓરડાની વિગત" },
  _viewStatus: { "en-US": "Status", "ar-AE": "الحالة", "gu-IN": "સ્થિતિ" },
  _viewServiceFee: { "en-US": "Service Fee", "ar-AE": "رسوم الخدمة", "gu-IN": "સેવા ફી" },
  _viewTaxesAmount: { "en-US": "Service Fees", "ar-AE": "رسوم الخدمة", "gu-IN": "કરની રકમ" },
  _viewPortalPaymentGatewayCharges: {
    "en-US": "Payment Gateway Charges",
    "ar-AE": "رسوم بوابة الدفع",
    "gu-IN": "ચુકવણી ગેટવે ચાર્જ",
  },
  _lblOther: {
    "en-US": "Service Fees",
    "ar-AE": "رسوم الخدمة",
    "gu-IN": "સેવા ફી",
  },
  _lblFees: {
    "en-US": "Fees",
    "ar-AE": "الرسوم",
    "gu-IN": "ફી",
  },
  _lblPortalPromotion: {
    "en-US": "Discount",
    "ar-AE": "تخفيض",
    "gu-IN": "ડિસ્કાઉન્ટ",
  },
  _lblDiscount: {
    "en-US": "Discount",
    "ar-AE": "تخفيض",
    "gu-IN": "ડિસ્કાઉન્ટ",
  },

  _viewTotal: { "en-US": "Total Price", "ar-AE": "السعر الاجمالى", "gu-IN": "કુલ" },
  _viewTotalAmount: { "en-US": "Total Price", "ar-AE": "السعر الاجمالى", "gu-IN": "કુલ રકમ" },
  _viewTOTALAMOUNT: { "en-US": "Total Price", "ar-AE": "السعر الاجمالى", "gu-IN": "કુલ રકમ" },
  _viewPREPAYAMOUNT: { "en-US": "Prepay Amount", "ar-AE": "الدفع المسبق", "gu-IN": "પ્રીપે રકમ" },
  _viewTravelersDetails: {
    "en-US": "Guest Details",
    "ar-AE": "تفاصيل الضيف",
    "gu-IN": "પ્રવાસીઓ વિગતો",
  },
  _viewTravelersDetailsAir: {
    "en-US": "Traveller Details",
    "ar-AE": "تفاصيل المسافر",
    "gu-IN": "પ્રવાસીઓ વિગતો",
  },
  _viewTravelersDetailsAirPilgrim: {
    "en-US": "Pilgrim Details",
    "ar-AE": "تفاصيل الحاج",
    "gu-IN": "પ્રવાસીઓ વિગતો",
  },
  _viewTravelerType: {
    "en-US": "Traveller Type",
    "ar-AE": "نوع المسافر",
    "gu-IN": "મુસાફરોનો પ્રકાર",
  },
  _viewTravelTime: { "en-US": "Travel Time", "ar-AE": "وقت السفر", "gu-IN": "પ્રવાસ નો સમય" },
  _viewTripDirection: {
    "en-US": "Trip Direction",
    "ar-AE": "اتجاه الرحلة",
    "gu-IN": "પ્રવાસ દિશા",
  },
  _viewType: { "en-US": "Type", "ar-AE": "نوع", "gu-IN": "પ્રકાર" },
  _widgetAbove18Years: {
    "en-US": "Above 18 years",
    "ar-AE": "فوق 18 سنة",
    "gu-IN": "18 વર્ષથી ઉપર",
  },
  _widgetAddAnotherRoom: {
    "en-US": "Add another room",
    "ar-AE": "أضف غرفة أخرى",
    "gu-IN": "બીજો રૂમ ઉમેરો",
  },
  _widgetAdults: { "en-US": "Adults", "ar-AE": "البالغين", "gu-IN": "પુખ્ત વયના લોકો" },
  _widgetAdultsWithBraces: { "en-US": "Adult(s)", "ar-AE": "البالغين", "gu-IN": "પુખ્ત વયના લોકો" },
  _widgetAgeOfChild: { "en-US": "Age of child", "ar-AE": "عمر الطفل", "gu-IN": "બાળકની ઉંમર" },
  _widgetAgeofGuest: { "en-US": "Age of Guest(s)", "ar-AE": "سن الضيوف", "gu-IN": "મહેમાનની ઉંમર" },
  _widgetAgeofGuestNote: {
    "en-US": "Adult age should be more than 15.",
    "ar-AE": "يجب أن يكون عمر الشخص البالغ أكثر من 15 عامًا.",
    "gu-IN": "પુખ્ત વય 15 કરતા વધુ હોવી જોઈએ.",
  },
  _widgetBelow18Years: {
    "en-US": "Below 18 years",
    "ar-AE": "أقل من 18 سنة",
    "gu-IN": "18 વર્ષથી નીચે",
  },
  _widgetCheckIn: { "en-US": "Check-In", "ar-AE": "تاريخ الدخول", "gu-IN": "ચેક-ઇન" },
  _widgetCheckOut: { "en-US": "Check-Out", "ar-AE": "تاريخ الخروج", "gu-IN": "ચેક-આઉટ" },
  _widgetChildren: { "en-US": "Children", "ar-AE": "الأطفال", "gu-IN": "બાળકો" },
  _widgetChildrenWithBraces: { "en-US": "Child(ren)", "ar-AE": "الأطفال", "gu-IN": "બાળકો" },
  _widgetInfants: { "en-US": "Infant(s)", "ar-AE": "الرضع (ق)", "gu-IN": "શિશુ" },
  _widgetMinMxTravellerNote: {
    "en-US": "Maximum 9",
    "ar-AE": "9 كحد أقصى",
    "gu-IN": "Maximum 9",
  },
  _widgetPersons: { "en-US": "Person(s)", "ar-AE": "الشخص (الأشخاص)", "gu-IN": "વ્યક્તિ(ઓ)" },
  _widgetRoom: { "en-US": "Room", "ar-AE": "الغرفة", "gu-IN": "ઓરડો" },
  _widgetRoomsWithBraces: { "en-US": "Room(s)", "ar-AE": "غرف(ة)", "gu-IN": "ઓરડો" },
  _widgetSearch: { "en-US": "Search", "ar-AE": "بحث", "gu-IN": "શોધો" },
  _widgetSearchactivity: {
    "en-US": "Search Activity",
    "ar-AE": "نشاط البحث",
    "gu-IN": "પ્રવૃત્તિ શોધો",
  },
  _widgetSearchair: {
    "en-US": "Search Flights",
    "ar-AE": "ابحث عن رحلات جوية",
    "gu-IN": "ફ્લાઇટ્સ શોધો",
  },
  _widgetSearchbus: { "en-US": "Search Bus(s)", "ar-AE": "البحث حافلة", "gu-IN": "બસ શોધો" },
  _widgetSearchhotel: { "en-US": "Search Hotel", "ar-AE": "البحث عن فنادق", "gu-IN": "હોટેલ શોધો" },
  _widgetSearchpackage: { "en-US": "Search Package", "ar-AE": "حزمة البحث", "gu-IN": "પેકેજ શોધો" },
  _widgetSearchtransfers: {
    "en-US": "Search Transfer(s)",
    "ar-AE": "نقل البحث",
    "gu-IN": "શોધ સ્થાનાંતરણો",
  },
  _widgetSearchvehicle: {
    "en-US": "Search Vehicle",
    "ar-AE": "ابحث عن مركبة",
    "gu-IN": "વાહન શોધો",
  },
  _widgetSearchgroundservice: {
    "en-US": "Search Ground Service",
    "ar-AE": "خدمة البحث الأرضي",
    "gu-IN": "શોધ ગ્રાઉન્ડ સર્વિસ",
  },
  _widgetgroundserviceCategory: {
    "en-US": "Category",
    "ar-AE": "فئة",
    "gu-IN": "કેટેગરી",
  },
  _widgetTabactivity: { "en-US": "Activities", "ar-AE": "الانشطة", "gu-IN": "પ્રવૃત્તિઓ" },
  _widgetTabbus: { "en-US": "Buses", "ar-AE": "حافلات", "gu-IN": "બસો" },
  _widgetTabvehicle: { "en-US": "Vehicle", "ar-AE": "مركبة", "gu-IN": "વાહન" },
  _widgetTabFlights: { "en-US": "Flights", "ar-AE": "رحلات طيران", "gu-IN": "ફ્લાઇટ્સ" },
  _widgetTabhotel: { "en-US": "Hotels", "ar-AE": "فندق", "gu-IN": "હોટલો" },
  _widgetTabpackage: { "en-US": "Packages", "ar-AE": "الحزم", "gu-IN": "પેકેજો" },
  _widgetTabtransfers: { "en-US": "Transfer", "ar-AE": "نقل", "gu-IN": "સ્થાનાંતરણ" },
  _widgetTabair: { "en-US": "Flights", "ar-AE": "رحلات طيران", "gu-IN": "ફ્લાઇટ્સ" },
  _widgetTabcustom: { "en-US": "Custom", "ar-AE": "رحلات طيران", "gu-IN": "ફ્લાઇટ્સ" },
  _widgetTabumrahpackage: { "en-US": "Umrah Package", "ar-AE": "باقة العمرة", "gu-IN": "ઉમરાહ પેકેજ" },
  _widgetTravellers: { "en-US": "Travellers", "ar-AE": "المسافرون", "gu-IN": "મુસાફરો" },
  _widgetTransfersTravellers: { "en-US": "NO OF GUESTS", "ar-AE": "لا للضيوف" },
  _widgetactivityCheckIn: { "en-US": "From Date", "ar-AE": "من تاريخ", "gu-IN": "તારીખથી" },
  _widgetactivityCheckOut: { "en-US": "To Date", "ar-AE": "الى تاريخ", "gu-IN": "આજ સુધી" },
  _widgetactivityFromLocationTitle: {
    "en-US": "Region / City",
    "ar-AE": "المنطقة / المدينة",
    "gu-IN": "પ્રદેશ / શહેર",
  },
  _widgetactivityTitle: {
    "en-US": "Search For Activity",
    "ar-AE": "ابحث عن النشاط",
    "gu-IN": "પ્રવૃત્તિ માટે શોધ",
  },
  _widgetairCheckIn: { "en-US": "Depart", "ar-AE": "تغادر", "gu-IN": "પ્રસ્થાન" },
  _widgetairCheckOut: { "en-US": "Return", "ar-AE": "إرجاع", "gu-IN": "પાછું આવવું" },
  _widgetairFromLocationTitle: { "en-US": "From", "ar-AE": "من", "gu-IN": "પ્રસ્થાન" },
  _widgetairTitle: {
    "en-US": "Search For Flights",
    "ar-AE": "البحث عن الرحلات الجوية",
    "gu-IN": "ફ્લાઇટ્સ માટે શોધ કરો",
  },
  _widgetairToLocationTitle: { "en-US": "To", "ar-AE": "إلى", "gu-IN": "લક્ષ્યસ્થાન" },
  _widgetbusCheckIn: { "en-US": "Check-In", "ar-AE": "تاريخ الدخول", "gu-IN": "ચેક-ઇન" },
  _widgetbusCheckOut: { "en-US": "Check-Out", "ar-AE": "تاريخ الخروج", "gu-IN": "ચેક-આઉટ" },
  _widgetbusFromLocationTitle: { "en-US": "From", "ar-AE": "من", "gu-IN": "પ્રસ્થાન" },
  _widgetbusTitle: {
    "en-US": "Search For Bus(s)",
    "ar-AE": "ابحث عن حافلة",
    "gu-IN": "બસ(ઓ) માટે શોધ",
  },
  _widgethotelCheckIn: { "en-US": "Check-In", "ar-AE": "تاريخ الدخول", "gu-IN": "ચેક-ઇન" },
  _widgethotelCheckOut: { "en-US": "Check-Out", "ar-AE": "تاريخ الخروج", "gu-IN": "ચેક-આઉટ" },
  _widgethotelFromLocationTitle: {
    "en-US": "Enter a destination or property",
    "ar-AE": "أدخل وجهة أو خاصية",
    "gu-IN": "કોઈ સ્થળ અથવા સંપત્તિ દાખલ કરો",
  },
  _widgethotelLocationTitle: {
    "en-US": "Enter a destination or property",
    "ar-AE": "أدخل وجهة أو خاصية",
    "gu-IN": "કોઈ સ્થળ અથવા સંપત્તિ દાખલ કરો",
  },
  _widgethotelTitle: {
    "en-US": "Search For Hotel",
    "ar-AE": "ابحث عن فندق",
    "gu-IN": "હોટેલ માટે શોધ",
  },
  _widgetgroundserviceTitle: {
    "en-US": "Search For Ground Service",
    "ar-AE": "ابحث عن خدمة أرضية",
    "gu-IN": "ગ્રાઉન્ડ સર્વિસ માટે શોધ કરો",
  },
  _widgetpackageCheckIn: { "en-US": "From Date", "ar-AE": "من تاريخ", "gu-IN": "તારીખથી" },
  _widgetpackageCheckOut: { "en-US": "To Date", "ar-AE": "الى تاريخ", "gu-IN": "આજ સુધી" },
  _widgetpackageFromLocationTitle: {
    "en-US": "Enter a destination or property",
    "ar-AE": "أدخل وجهة أو خاصية",
    "gu-IN": "કોઈ સ્થળ અથવા સંપત્તિ દાખલ કરો",
  },
  _widgetpackageTitle: {
    "en-US": "Search For Package",
    "ar-AE": "ابحث عن الحزمة",
    "gu-IN": "પેકેજ માટે શોધો",
  },
  _widgettransfersCheckIn: { "en-US": "Tranfers Date", "ar-AE": "تاريخ التحويل", "gu-IN": "" },
  _widgettransfersCheckOut: {
    "en-US": "Transfers Return Date",
    "ar-AE": "تاريخ عودة التحويلات",
    "gu-IN": "ચેક-આઉટ",
  },
  _widgettransfersPickupLocationTitle: {
    "en-US": "Pickup Location",
    "ar-AE": "اختر موقعا",
    "gu-IN": "પિકઅપ સ્થાન",
  },
  _widgettransfersDropoffLocationTitle: {
    "en-US": "Dropoff Location",
    "ar-AE": "موقع الانسحاب",
    "gu-IN": "સ્થાન છોડો",
  },
  _widgettransfersTypeTitle: { "en-US": "Type", "ar-AE": "نوع", "gu-IN": "પ્રકાર" },
  _widgettransfersAirport: { "en-US": "Airport", "ar-AE": "مطار", "gu-IN": "એરપોર્ટ" },
  _widgettransfersAccommodation: { "en-US": "Accommodation", "ar-AE": "الإقامة", "gu-IN": "આવાસ" },
  _widgettransfersStartTimeTitle: {
    "en-US": "Transfers Start Time",
    "ar-AE": "وقت بدء التحويلات",
    "gu-IN": "ટ્રાન્સફર પ્રારંભ સમય",
  },
  _widgettransfersReturnStartTimeTitle: {
    "en-US": "Transfers Return Time",
    "ar-AE": "وقت إرجاع التحويلات",
    "gu-IN": "પરિવહન રીટર્ન સમય",
  },
  _widgettransfersFromDate: {
    "en-US": "Transfers From Date",
    "ar-AE": "التحويلات من التاريخ",
    "gu-IN": "તારીખથી પરિવહન",
  },
  _widgettransfersTitle: {
    "en-US": "Search For Transfers",
    "ar-AE": "ابحث عن التحويلات",
    "gu-IN": "પરિવહન માટે શોધ",
  },
  _widgetvehicleCheckIn: { "en-US": "Check-In", "ar-AE": "تاريخ الدخول", "gu-IN": "ચેક-ઇન" },
  _widgetvehicleCheckOut: { "en-US": "Check-Out", "ar-AE": "تاريخ الخروج", "gu-IN": "ચેક-આઉટ" },
  _widgetvehicleFromLocationTitle: { "en-US": "Pick Up", "ar-AE": "امسك", "gu-IN": "ચૂંટો" },
  _widgetvehicleToLocationTitle: { "en-US": "Drop Off", "ar-AE": "إنزال", "gu-IN": "છોડો" },
  _widgetvehicledriverage: {
    "en-US": "Driver Age",
    "ar-AE": "عمر السائق",
    "gu-IN": "ડ્રાઈવર ઉંમર",
  },
  _widgetvehicleTitle: {
    "en-US": "Search For Vehicle",
    "ar-AE": "ابحث عن مركبة",
    "gu-IN": "વાહન માટે શોધ કરો",
  },
  _vehicleLocationSearching: {
    "en-US": "Searching...",
    "ar-AE": "يبحث",
    "gu-IN": "શોધી રહ્યું છે ...",
  },
  _widgetvehicleStartTimeTitle: {
    "en-US": "Pick Up Time",
    "ar-AE": "اختار المعاد",
    "gu-IN": "સમય ચૂંટો",
  },
  _widgetvehicleReturnStartTimeTitle: {
    "en-US": "Drop Off Time",
    "ar-AE": "لا تحتاج الي وقت",
    "gu-IN": "પરિવહન રીટર્ન સમયછોડો સમય",
  },
  _yes: { "en-US": "Yes", "ar-AE": "نعم", "gu-IN": "હા" },
  _zipCodeOrPostalCode: {
    "en-US": "Zipcode/Postal Code",
    "ar-AE": "الرمز البريدي",
    "gu-IN": "પિનકોડ / પોસ્ટલ કોડ",
  },
  _listView: { "en-US": "List View", "ar-AE": "عرض القائمة", "gu-IN": "સૂચિ દૃશ્ય" },
  _gridView: { "en-US": "Grid View", "ar-AE": "شبكة عرض", "gu-IN": "જાળી દૃશ્ય" },
  _mapView: { "en-US": "Map View", "ar-AE": "عرض الخريطة", "gu-IN": "નકશો દૃશ્ય" },
  _viewOnMap: { "en-US": "View on map", "ar-AE": "عرض على الخريطة", "gu-IN": "નકશા પર જુઓ" },
  _backToSearchResult: {
    "en-US": "Back to results",
    "ar-AE": "العودة إلى النتائج",
    "gu-IN": "શોધ પરિણામ પર પાછા જાઓ",
  },
  _ChangeDatesandOccupancy: {
    "en-US": "Change Dates & Occupancy",
    "ar-AE": "تغيير التواريخ والإشغال",
    "gu-IN": "તારીખ અને વ્યવસાય બદલો",
  },
  _amenities: { "en-US": "Amenities", "ar-AE": "وسائل الراحة", "gu-IN": "સુવિધાઓ" },
  _detailhotelNotAvailable: {
    "en-US": "Selected hotel no longer available.",
    "ar-AE": "الفندق المختار لم يعد متاحا.",
    "gu-IN": "પસંદ કરેલી હોટેલ ઉપલબ્ધ નથી.",
  },
  _detailactivityNotAvailable: {
    "en-US": "Selected activity no longer available.",
    "ar-AE": "لم يعد النشاط المحدد متاحًا.",
    "gu-IN": "પસંદ કરેલી પ્રવૃત્તિ ઉપલબ્ધ નથી.",
  },
  _detailtransfersNotAvailable: {
    "en-US": "Selected transfer no longer available.",
    "ar-AE": "لم يعد النقل المحدد متاحًا.",
    "gu-IN": "પસંદ કરેલું ટ્રાન્સફર હવે ઉપલબ્ધ નથી.",
  },
  _detailpackageNotAvailable: {
    "en-US": "Selected package no longer available.",
    "ar-AE": "الحزمة المختارة لم تعد متاحة.",
    "gu-IN": "પસંદ કરેલું પેકેજ ઉપલબ્ધ નથી..",
  },
  _haveSpecialRequest: {
    "en-US": "Have Special Request(s)?",
    "ar-AE": "هل لديك طلب (طلبات) خاص؟",
    "gu-IN": "વિશેષ વિનંતી",
  },
  _PriceBreakupLabel: {
    "en-US": "Price Breakup",
    "ar-AE": "تقسيم الاسعار",
    "gu-IN": "ભાવ બ્રેકઅપ",
  },
  _FareBreakupLabel: {
    "en-US": "Price Breakup Details",
    "ar-AE": "تفاصيل تفاصيل السعر",
    "gu-IN": "ભાવ બ્રેકઅપ",
  },
  _filterairfaretyperefundable: {
    "en-US": "Refundable",
    "ar-AE": "مستردة",
    "gu-IN": "રિફંડ પાત્ર",
  },
  _filterairfaretypenonrefundable: {
    "en-US": "Non-Refundable",
    "ar-AE": "غير قابل للاسترجاع",
    "gu-IN": "બિન-પરતપાત્ર",
  },
  _filterairstopsdirect: { "en-US": "Direct", "ar-AE": "مباشرة", "gu-IN": "સીધું" },
  _filterairstops1stop: { "en-US": "1 Stop", "ar-AE": "ترنسيت 1", "gu-IN": "1 સ્ટોપ" },
  _filterairstops1plusstop: { "en-US": "1+ Stop", "ar-AE": "ت+رنسيت 1", "gu-IN": "1+ સ્ટોપ" },
  _refundable: { "en-US": "Refundable", "ar-AE": "مستردة", "gu-IN": "રિફંડ પાત્ર" },
  _bookAndPayLaterMessage: {
    "en-US":
      "Please make the payment within 24 hours to get the booking confirmed and avoid automatic cancellation.",
    "ar-AE": "يُرجى إجراء الدفع في غضون 24 ساعة لتأكيد الحجز وتجنب الإلغاء التلقائي.",
    "gu-IN": "બુકિંગ પછી 23 કલાકની અંદર ચૂકવણી કરો, સિસ્ટમ પછીના સમય પછી આપમેળે બુકિંગ રદ થશે.",
  },
  _companyInfoTitle: {
    "en-US": "You can contact us, using below details, in case you require assistance",
    "ar-AE": "يمكنك الاتصال بنا ، باستخدام التفاصيل أدناه ، في حال كنت بحاجة إلى المساعدة",
    "gu-IN": "જો તમને સહાયની જરૂર હોય તો તમે નીચેની વિગતોનો ઉપયોગ કરીને અમારો સંપર્ક કરી શકો છો",
  },
  _call: { "en-US": "Call", "ar-AE": "أتصال", "gu-IN": "બોલાવો" },
  _unrate: { "en-US": "Unrate", "ar-AE": "لم يقيم بعد", "gu-IN": "અનરેટ કરો" },
  _voucher: { "en-US": "Voucher", "ar-AE": "قسيمة", "gu-IN": "વાઉચર" },
  _invoice: { "en-US": "Invoice", "ar-AE": "فاتورة", "gu-IN": "ભરતિયું" },
  _sendEmail: { "en-US": "Send Email", "ar-AE": "أرسل بريد ألكتروني", "gu-IN": "ઈ - મેલ મોકલો" },
  _emailTo: { "en-US": "E-mail To", "ar-AE": "أرسل إلى", "gu-IN": "ઇ-મેલ" },
  _emailCC: { "en-US": "E-mail CC", "ar-AE": "البريد الإلكتروني CC", "gu-IN": "ઇ-મેલ સીસી" },
  _sendEmailTemplateMessage: {
    "en-US": "Separate multiple addresses with commas",
    "ar-AE": "عناوين متعددة منفصلة مع فواصل",
    "gu-IN": "બહુવિધ સરનામાં અલ્પવિરામથી અલગ કરો",
  },
  _error_emailTo_require: {
    "en-US": "E-mail To required",
    "ar-AE": "البريد الإلكتروني المطلوب",
    "gu-IN": "ઇ મેલ જરૂરી છે",
  },
  _error_emailTo_email: {
    "en-US": "Enter valid E-mail To ",
    "ar-AE": "أدخل بريدًا إلكترونيًا صالحًا إلى",
    "gu-IN": "માન્ય ઇ-મેલ દાખલ કરો",
  },
  _error_emailCC_email: {
    "en-US": "Enter valid E-mail CC",
    "ar-AE": "أدخل CC بريد إلكتروني صالح",
    "gu-IN": "માન્ય ઇ-મેલ સીસી દાખલ કરો",
  },
  _emailSentSucccessfully: {
    "en-US": "Email sent succcessfully.",
    "ar-AE": "تم إرسال البريد الإلكتروني بنجاح.",
    "gu-IN": "ઇમેઇલ સફળતાપૂર્વક મોકલાયો.",
  },
  _noBookingFound: {
    "en-US": "No record(s) found matching your criteria",
    "ar-AE": "لم يتم العثور على سجل (سجلات) مطابق لمعاييرك",
    "gu-IN": "તમારા માપદંડ સાથે મેળ ખાતા કોઈ રેકોર્ડ (ઓ) મળ્યાં નથી",
  },
  _cultureList_ar: {
    "en-US": "عربى",
    "ar-AE": "عربى",
    "gu-IN": "عربى",
    "es-ES": "عربى",
    "it-IT": "عربى",
  },
  _cultureList_ar_AE: {
    "en-US": "عربى",
    "ar-AE": "عربى",
    "gu-IN": "عربى",
    "es-ES": "عربى",
    "it-IT": "عربى",
  },
  "_cultureList_ar-AE": {
    "en-US": "عربى",
    "ar-AE": "عربى",
    "gu-IN": "عربى",
    "es-ES": "عربى",
    "it-IT": "عربى",
  },
  _cultureList_en: {
    "en-US": "English",
    "ar-AE": "English",
    "gu-IN": "English",
    "es-ES": "English",
    "it-IT": "English",
  },
  _cultureList_en_US: {
    "en-US": "English",
    "ar-AE": "English",
    "gu-IN": "English",
    "es-ES": "English",
    "it-IT": "English",
  },
  "_cultureList_en-US": {
    "en-US": "English",
    "ar-AE": "English",
    "gu-IN": "English",
    "es-ES": "English",
    "it-IT": "English",
  },
  _cultureList_es: {
    "en-US": "Spanish",
    "ar-AE": "Spanish",
    "gu-IN": "Spanish",
    "es-ES": "Spanish",
    "it-IT": "Spanish",
  },
  _cultureList_es_ES: {
    "en-US": "Spanish",
    "ar-AE": "Spanish",
    "gu-IN": "Spanish",
    "es-ES": "Spanish",
    "it-IT": "Spanish",
  },
  _cultureList_it: {
    "en-US": "Italian",
    "ar-AE": "Italian",
    "gu-IN": "Italian",
    "es-ES": "Italian",
    "it-IT": "Italian",
  },
  _cultureList_it_IT: {
    "en-US": "Italian",
    "ar-AE": "Italian",
    "gu-IN": "Italian",
    "es-ES": "Italian",
    "it-IT": "Italian",
  },
  _hotel: { "en-US": "Hotel", "ar-AE": "فندق", "gu-IN": "હોટેલ" },
  _air: { "en-US": "Flight", "ar-AE": "طيران", "gu-IN": "ફ્લાઇટ" },
  _activity: { "en-US": "Activity", "ar-AE": "نشاط", "gu-IN": "પ્રવૃત્તિ" },
  _custom: { "en-US": "Custom", "ar-AE": "العادة", "gu-IN": "કસ્ટમ" },
  _package: { "en-US": "Package", "ar-AE": "صفقة", "gu-IN": "પેકેજ" },
  _transfers: { "en-US": "Transfers", "ar-AE": "النقل", "gu-IN": "પરિવહન" },
  _vehicle: { "en-US": "Vehicle", "ar-AE": "مركبات", "gu-IN": "વાહન" },
  _bus: { "en-US": "Bus", "ar-AE": "حافلة", "gu-IN": "બસ" },
  _transportation: { "en-US": "Transportation", "ar-AE": "النقل", "gu-IN": "પરિવહન" },
  _groundservice: { "en-US": "Ground Service", "ar-AE": "خدمات ارضية", "gu-IN": "ગ્રાઉન્ડ સર્વિસ" },
  _all: { "en-US": "All", "ar-AE": "الكل", "gu-IN": "બધા" },
  _showAdditionalOptions: {
    "en-US": "Show Additional Options",
    "ar-AE": "أظهار خيارات إضافية",
    "gu-IN": "અતિરિક્ત વિકલ્પો બતાવો",
  },
  _hideAdditionalOptions: {
    "en-US": "Hide Additional Options",
    "ar-AE": "إخفاء خيارات إضافية",
    "gu-IN": "વધારાના વિકલ્પો છુપાવો",
  },
  _ACPercentage: { "en-US": "AC %", "ar-AE": "نسبة الربح", "gu-IN": "AC %" },
  _success: { "en-US": "Success", "ar-AE": "نجاح", "gu-IN": "સફળતા" },
  _bookToConfirmSuccessMessage: {
    "en-US": "Your Booking has been successfully confirmed.",
    "ar-AE": "تم تأكيد الحجز الخاص بك بنجاح.",
    "gu-IN": "તમારા બુકિંગની સફળતાપૂર્વક પુષ્ટિ થઈ ગઈ છે.",
  },
  _error_comment_valid: {
    "en-US": "Enver valid Comment",
    "ar-AE": "أدخل تعليق صالح",
    "gu-IN": "Enver valid Comment",
  },
  _confirmbooking: { "en-US": "Complete Booking", "ar-AE": "إتمام الحجز", "gu-IN": "પૂર્ણ બુકિંગ" },
  _comment: { "en-US": "Comment", "ar-AE": "تعليق", "gu-IN": "ટિપ્પણી" },
  _commentType: { "en-US": "Comment Type", "ar-AE": "نوع التعليق", "gu-IN": "ટિપ્પણી પ્રકાર" },
  _commentTypeCash: { "en-US": "Cash", "ar-AE": "نقدا", "gu-IN": "રોકડ" },
  _commentTypeBankTransfers: {
    "en-US": "Bank Transfers",
    "ar-AE": "تحويل مصرفي",
    "gu-IN": "બેંક ટ્રાન્સફર",
  },
  _commentTypeCheque: { "en-US": "Cheque", "ar-AE": "التحقق من", "gu-IN": "તપાસો" },
  _commentTypeOther: { "en-US": "Other", "ar-AE": "آخر", "gu-IN": "અન્ય" },
  _deadlineDate: { "en-US": "Deadline Date", "ar-AE": "الموعد النهائي", "gu-IN": "અંતિમ તારીખ" },
  _providerName: { "en-US": "Provider Name", "ar-AE": "اسم المزود", "gu-IN": "પ્રદાતા નામ" },
  _error_260030_hotel: {
    "en-US": "Selected rooms sold out. Kindly make a new search and try again.",
    "ar-AE": "بيعت غرف مختارة. يرجى إجراء بحث جديد وحاول مرة أخرى.",
    "gu-IN": "પસંદ કરેલા ઓરડાઓ વેચાયા. કૃપા કરીને નવી શોધ કરો અને ફરીથી પ્રયાસ કરો.",
  },
  _hotelPriceChangeMessage1: {
    "en-US": "We're Sorry, The price for your hotel has changed.",
    "ar-AE": "عذرًا ، لقد تغير سعر فندقك.",
    "gu-IN": "અમને માફ કરશો, તમારી હોટલની કિંમત બદલાઈ ગઈ છે.",
  },
  _hotelPriceChangeMessage2: {
    "en-US": "The price of this itinerary has changed since you selected your hotel.",
    "ar-AE": "لم يتغير سعر هذه الرحلة منذ قمت بتحديدها فندقك.",
    "gu-IN": "તમે તમારી હોટેલ પસંદ કરી ત્યારથી આ પ્રવાસના ભાવમાં ફેરફાર થયો છે.",
  },
  _hotelPriceChangeMessage3: {
    "en-US":
      "This happens because someone else has booked this hotel since you made your selection.",
    "ar-AE": "وهذا يحدث لأن شخصا آخر قد حجز هذا الفندق منذ أجريتها اختيارك.",
    "gu-IN": "આવું થાય છે કારણ કે તમે તમારી પસંદગી કરી ત્યારથી કોઈ બીજાએ આ હોટેલ બુક કરાવી છે.",
  },
  _transfersPriceChangeMessage1: {
    "en-US": "We're Sorry, The price for your transfer has changed.",
    "ar-AE": "عذرًا ، لقد تغير سعر تحويلك",
    "gu-IN": "માફ કરશો, તમારા ટ્રાન્સફર માટેની કિંમત બદલાઈ ગઈ છે.",
  },
  _transfersPriceChangeMessage2: {
    "en-US": "The price of this itinerary has changed since you selected your transfer.",
    "ar-AE": "تغير سعر خط سير الرحلة هذا منذ اختيارك للتحويل.",
    "gu-IN": "તમે તમારા સ્થાનાંતરણને પસંદ કર્યું ત્યારથી આ પ્રવાસના ભાવમાં ફેરફાર થયો છે.",
  },
  _transfersPriceChangeMessage3: {
    "en-US":
      "This happens because someone else has booked this transfer since you made your selection.",
    "ar-AE": "يحدث هذا لأن شخصًا آخر حجز هذا النقل منذ اختيارك.",
    "gu-IN":
      "આવું થાય છે કારણ કે તમે તમારી પસંદગી કરી ત્યારથી કોઈ બીજાએ આ ટ્રાન્સફર બુક કરાવ્યું છે.",
  },
  _activityPriceChangeMessage1: {
    "en-US": "We're Sorry, The price for your activity has changed.",
    "ar-AE": "عذرًا ، لقد تغير سعر نشاطك.",
    "gu-IN": "માફ કરશો, તમારી પ્રવૃત્તિ માટેનો ભાવ બદલાઈ ગયો છે.",
  },
  _activityPriceChangeMessage2: {
    "en-US": "The price of this itinerary has changed since you selected your activity.",
    "ar-AE": "تغير سعر مسار الرحلة هذا منذ تحديد نشاطك.",
    "gu-IN": "તમે તમારી પ્રવૃત્તિ પસંદ કરી ત્યારથી આ પ્રવાસના ભાવમાં ફેરફાર થયો છે.",
  },
  _activityPriceChangeMessage3: {
    "en-US":
      "This happens because someone else has booked this activity since you made your selection.",
    "ar-AE": "يحدث هذا لأن شخصًا آخر حجز هذا النشاط منذ اختيارك.",
    "gu-IN": "આવું થાય છે કારણ કે તમે તમારી પસંદગી કરી ત્યારથી કોઈ બીજાએ આ પ્રવૃત્તિ બુક કરાવી છે.",
  },
  _packagePriceChangeMessage1: {
    "en-US": "We're Sorry, The price for your package has changed.",
    "ar-AE": "عذرًا ، لقد تغير سعر الطرد الخاص بك.",
    "gu-IN": "માફ કરશો, તમારા પેકેજની કિંમત બદલાઈ ગઈ છે.",
  },
  _packagePriceChangeMessage2: {
    "en-US": "The price of this itinerary has changed since you selected your package.",
    "ar-AE": "لقد تغير سعر خط سير الرحلة هذا منذ قيامك باختيار باقتك.",
    "gu-IN": "તમે તમારા પેકેજને પસંદ કર્યું છે ત્યારથી આ પ્રવાસના ભાવમાં ફેરફાર થયો છે.",
  },
  _packagePriceChangeMessage3: {
    "en-US":
      "This happens because someone else has booked this package since you made your selection.",
    "ar-AE": "يحدث هذا لأن شخصًا آخر حجز هذه الحزمة منذ اختيارك.",
    "gu-IN": "આવું થાય છે કારણ કે તમે તમારી પસંદગી કરી ત્યારથી કોઈ બીજાએ આ પેકેજ બુક કરાવ્યું છે.",
  },
  _vehiclePriceChangeMessage1: {
    "en-US": "We're Sorry, The price for your vehicle has changed.",
    "ar-AE": "نحن آسفون ، لقد تغير سعر سيارتك.",
    "gu-IN": "માફ કરશો, તમારા વાહનની કિંમત બદલાઈ ગઈ છે.",
  },
  _vehiclePriceChangeMessage2: {
    "en-US": "The price of this itinerary has changed since you selected your vehicle.",
    "ar-AE": "لقد تغير سعر خط سير الرحلة هذا منذ أن اخترت سيارتك",
    "gu-IN": "તમે તમારું વાહન પસંદ કર્યું ત્યારથી આ પ્રવાસના ભાવમાં ફેરફાર થયો છે.",
  },
  _vehiclePriceChangeMessage3: {
    "en-US":
      "This happens because someone else has booked this vehicle since you made your selection.",
    "ar-AE": "يحدث هذا لأن شخصًا آخر قد حجز هذه السيارة منذ اختيارك.",
    "gu-IN": "આવું થાય છે કારણ કે તમે તમારી પસંદગી કરી ત્યારથી કોઈ બીજાએ આ વાહન બુક કરાવ્યું છે.",
  },
  _oldPrice: { "en-US": "Old Price", "ar-AE": "السعر القديم", "gu-IN": "જૂની કિંમત" },
  _newPrice: { "en-US": "New Price", "ar-AE": "السعر الجديد", "gu-IN": "નવી કિંમત" },
  _hotelSoldOutMessage1: {
    "en-US": "We're Sorry, selected room for this Hotel is no longer available.",
    "ar-AE": "نحن آسفون، وغرفة المختارة لهذا الفندق  لم تعد متوفرة.",
    "gu-IN": "માફ કરશો, આ હોટેલ માટે પસંદ કરેલો ઓરડો હવે ઉપલબ્ધ નથી.",
  },
  _airSoldOutMessage1: {
    "en-US": "We're Sorry, selected flight is no longer available.",
    "ar-AE": "نحن آسفون، المقاعد المختارة  لم تعد متوفرة.",
    "gu-IN": "માફ કરશો, પસંદ કરેલી ફ્લાઇટ હવે ઉપલબ્ધ નથી.",
  },
  _transportationSoldOutMessage1: {
    "en-US": "We're Sorry, selected transportation is no longer available.",
    "ar-AE": "عذرًا ، النقل المحدد لم يعد متاحًا.",
    "gu-IN": "માફ કરશો, પસંદ કરેલું પરિવહન હવે ઉપલબ્ધ નથી.",
  },
  _groundserviceSoldOutMessage1: {
    "en-US": "We're Sorry, selected ground service is no longer available.",
    "ar-AE": "عذرًا ، الخدمة الأرضية المحددة لم تعد متوفرة.",
    "gu-IN": "માફ કરશો, પસંદ કરેલી ગ્રાઉન્ડ સર્વિસ હવે ઉપલબ્ધ નથી.",
  },
  _transfersSoldOutMessage1: {
    "en-US": "We're Sorry, selected transfer is no longer available.",
    "ar-AE": "عذرًا ، لم يعد النقل المحدد متاحًا.",
    "gu-IN": "માફ કરશો, પસંદ કરેલું ટ્રાન્સફર હવે ઉપલબ્ધ નથી.",
  },
  _vehicleSoldOutMessage1: {
    "en-US": "We're Sorry, selected vehicle is no longer available.",
    "ar-AE": "نحن آسفون ، السيارة المختارة لم تعد متوفرة.",
    "gu-IN": "માફ કરશો, પસંદ કરેલું વાહન હવે ઉપલબ્ધ નથી.",
  },
  _soldOutMessage: {
    "en-US": "Itinerary : ",
    "ar-AE": "مسار الرحلة : ",
    "gu-IN": "પ્રવાસ માર્ગ:",
  },
  _currentBalance: {
    "en-US": "Current balance",
    "ar-AE": "الرصيد الحالي",
    "gu-IN": "વર્તમાન રકમ",
  },
  _titleModifyCancleReservation: {
    "en-US": "Modify/Cancel Reservation",
    "ar-AE": "تعديل / الغاء الحجز",
    "gu-IN": "ફેરફાર કરો / કેનલ રિઝર્વેશન",
  },
  _modifyCancelSuccessLabel: {
    "en-US": "Change request has been successfully sent.",
    "ar-AE": "تم ارسال طلب التغيير بنجاح.",
    "gu-IN": "વિનંતી વિનંતી સફળતાપૂર્વક મોકલી છે.",
  },
  _modifyRequestLine1Label: {
    "en-US": "Thank you for your request. We will respond to it via email as soon as possible.",
    "ar-AE":
      "شكرا لك على طلبك. سوف يتلقى طلبك  اهتماما فوريا وسوف نرد عبر البريد الإلكتروني في أقرب وقت ممكن.",
    "gu-IN": "તમારી વિનંતી બદલ આભાર. અમે શક્ય તેટલી વહેલી તકે ઇમેઇલ દ્વારા તેનો પ્રતિસાદ આપીશું.",
  },
  _modifyRequestLine2Label: {
    "en-US": "You can try Three Ways to Find Answers",
    "ar-AE": "ثلاث طرق للعثور على إجابة",
    "gu-IN": "જવાબો શોધવા માટેની તમે ત્રણ રીતો અજમાવી શકો છો",
  },
  _modifyRequestLine3Label: {
    "en-US": "Search by My Bookings to find answers to your questions.",
    "ar-AE": "ابحث عن حجوزاتي للعثور على إجابات لأسئلتك.",
    "gu-IN": "તમારા પ્રશ્નોના જવાબો શોધવા માટે માય બુકિંગ દ્વારા શોધો.",
  },
  _modifyRequestLine4Label: {
    "en-US": "Call Customer Support at ##ProviderPhone## ",
    "ar-AE": "استدعاء دعم العملاء في ##ProviderPhone##",
    "gu-IN": "## પ્રદાતાફોન ## પર ગ્રાહક સપોર્ટ પર ક Callલ કરો",
  },
  _modifyRequestLine7Label: {
    "en-US": "We are available 24 hours a day, 7 days a week.",
    "ar-AE": "نحن جاهزون 24 ساعة في اليوم، 7 أيام في الأسبوع.",
    "gu-IN": "અમે દિવસના 24 કલાક, અઠવાડિયાના 7 દિવસ ઉપલબ્ધ છીએ.",
  },
  _modifyRequestLine5Label: {
    "en-US": " Send us an email at ##AgentEmail## with any non-urgent questions.",
    "ar-AE": "مراسلتنا على البريد الإلكتروني في ##AgentEmail##مع أي أسئلة غير العاجلة.",
    "gu-IN": " કોઈપણ બિન-તાત્કાલિક પ્રશ્નો સાથે અમને ## એજન્ટ ઇમેઇલ ## પર ઇમેઇલ મોકલો.",
  },
  _modifyRequestLine6Label: {
    "en-US": "We respond to inquires within 24 hours.",
    "ar-AE": "نرد على الاستفسارات مع 24 ساعة.",
    "gu-IN": "અમે 24 કલાકની અંદર પૂછપરછનો જવાબ આપીશું",
  },
  _checkIn: { "en-US": "Check-In", "ar-AE": "تاريخ الدخول", "gu-IN": "ચેક-ઇન" },
  _checkOut: { "en-US": "Check-Out", "ar-AE": "تاريخ الخروج", "gu-IN": "ચેક-આઉટ" },
  _removeItinerary: {
    "en-US": "Remove Itinerary",
    "ar-AE": "إزالة خط سير الرحلة",
    "gu-IN": "ઇટિનરરીને દૂર કરો",
  },
  _removeItinerary1: {
    "en-US": "Itinerary removed successfully!",
    "ar-AE": "تمت إزالة خط سير الرحلة بنجاح!",
    "gu-IN": "માર્ગ સફળતાપૂર્વક દૂર થયો!",
  },
  _removeItineraryMessage: {
    "en-US": "Click here to remove this itineary from cart.",
    "ar-AE": "انقر هنا لإزالة هذا itineary من سلة التسوق.",
    "gu-IN": "કાર્ટમાંથી આ ઇટિનરીને દૂર કરવા અહીં ક્લિક કરો.",
  },
  _error_payment_soldout_itinerary_message: {
    "en-US": "Remove unavailable itinerary first to book.",
    "ar-AE": "أزل خط الرحلة غير المتاح أولاً للحجز.",
    "gu-IN": "બુક કરવા માટે પહેલા અનુપલબ્ધ ઇટિનરરીને દૂર કરો.",
  },
  _fromDate: { "en-US": "From Date", "ar-AE": "من تاريخ", "gu-IN": "તારીખથી" },
  _toDate: { "en-US": "To Date", "ar-AE": "الى تاريخ", "gu-IN": "આજ સુધી" },
  _inclusions: { "en-US": "Inclusions", "ar-AE": "يتضمن", "gu-IN": "સમાવેશ" },
  _grandTotal: { "en-US": "Grand Total", "ar-AE": "السعر الاجمالى", "gu-IN": "ગ્રાન્ડ કુલ" },
  _widgetTabtransportation: { "en-US": "Transportation", "ar-AE": "النقل", "gu-IN": "સ્થાનાંતરણ" },
  _widgetTabgroundservice: { "en-US": "Ground Service", "ar-AE": "خدمات ارضية", "gu-IN": "ગ્રાઉન્ડ સર્વિસ" },
  _widgettransportationTitle: {
    "en-US": "Search For Transportation",
    "ar-AE": "ابحث عن النقل",
    "gu-IN": "પરિવહન માટે શોધ",
  },
  _widgetSearchtransportation: {
    "en-US": "Search Transportation",
    "ar-AE": "البحث عن وسائل النقل",
    "gu-IN": "શોધ પરિવહન",
  },
  _widgettransportationRoute: { "en-US": "Route", "ar-AE": "طريق", "gu-IN": "રસ્તો" },
  _widgettransportationCheckIn: {
    "en-US": "Travel Date",
    "ar-AE": "موعد السفر",
    "gu-IN": "મુસાફરીની તારીખ",
  },
  _widgetgroundserviceCheckIn: {
    "en-US": "Date",
    "ar-AE": "تاريخ",
    "gu-IN": "તારીખ",
  },
  _widgetVehivle: { "en-US": "Vehicle", "ar-AE": "مركبات", "gu-IN": "વાહન" },
  _widgetNoofGuestPerVehicleNote: {
    "en-US": "Maximum 10 Vehicle allowed.",
    "ar-AE": "يسمح بحد أقصى 10 مركبة.",
    "gu-IN": "મહત્તમ 10 વાહનની મંજૂરી.",
  },
  _widgetNoofGuestPerVehicleNote1: {
    "en-US": "Maximum 60 travellers allowed per vehicle.",
    "ar-AE": "يُسمح بحد أقصى 60 مسافرًا لكل مركبة.",
    "gu-IN": "વાહન દીઠ મહત્તમ 60 મુસાફરોને મંજૂરી છે.",
  },
  _guestSInVehicle: {
    "en-US": "Traveller(s) in vehicle",
    "ar-AE": "المسافر في المركبة",
    "gu-IN": "વાહન મુસાફરીઓ",
  },
  _vehicles: { "en-US": "Vehicle(s)", "ar-AE": "مركبات", "gu-IN": "વાહનો" },
  _titletransportation: { "en-US": "Transportation", "ar-AE": "النقل", "gu-IN": "પરિવહન" },
  _titlegroundservice: { "en-US": "Ground Service", "ar-AE": "الخدمة (الخدمات) الأرضية", "gu-IN": "ગ્રાઉન્ડ સર્વિસ" },
  _filtertransportationName: {
    "en-US": "Transportation Name",
    "ar-AE": "اسم النقل",
    "gu-IN": "પરિવહન નામ",
  },
  _filtertransportationvehiclecategory: {
    "en-US": "Vehicle Category",
    "ar-AE": "فئة المركبة",
    "gu-IN": "વાહન વર્ગ",
  },
  _notransportationFound: {
    "en-US": "No Transportation Found",
    "ar-AE": "لم يتم العثور على وسائل النقل",
    "gu-IN": "કોઈ પરિવહન મળ્યું નથી",
  },
  _nogroundserviceFound: {
    "en-US": "No Ground Service Found",
    "ar-AE": "لم يتم العثور على خدمة أرضية",
    "gu-IN": "કોઈ ગ્રાઉન્ડ સર્વિસ મળી નથી",
  },
  _transportationDate: {
    "en-US": "Transportation Date",
    "ar-AE": "تاريخ النقل",
    "gu-IN": "પરિવહન તારીખ",
  },
  _groundserviceDate: {
    "en-US": "Ground Service Date",
    "ar-AE": "تاريخ الخدمة الأرضية",
    "gu-IN": "ગ્રાઉન્ડ સર્વિસ ડેટ",
  },
  _error_passportNumber_require: {
    "en-US": "Passport Number Required",
    "ar-AE": "رقم جواز السفر مطلوب",
    "gu-IN": "પાસપોર્ટ નંબર 2 થી 50 અક્ષરો વચ્ચેનો હોવો જોઈએ",
  },
  _error_passportNumber_length: {
    "en-US": "Passport Number must be between 2 and 50 characters long",
    "ar-AE": "يجب أن يتراوح طول رقم جواز السفر بين 2 و 50 حرفًا",
    "gu-IN": "પાસપોર્ટ નંબર 2 થી 50 અક્ષરો વચ્ચેનો હોવો જોઈએ",
  },
  _error_passportNumber_invalid: {
    "en-US": "Enter valid Passport Number",
    "ar-AE": "أدخل رقم جواز سفر صالح",
    "gu-IN": "માન્ય પાસપોર્ટ નંબર દાખલ કરો",
  },
  _transportationPriceChangeMessage1: {
    "en-US": "We're Sorry, The price for your transportation has changed.",
    "ar-AE": "نأسف ، لقد تغير سعر النقل الخاص بك.",
    "gu-IN": "માફ કરશો, તમારા પરિવહન માટેનો ભાવ બદલાઈ ગયો છે.",
  },
  _transportationPriceChangeMessage2: {
    "en-US": "The price of this itinerary has changed since you selected your transportation.",
    "ar-AE": "لقد تغير سعر خط سير الرحلة هذا منذ تحديد النقل الخاص بك.",
    "gu-IN": "તમે તમારા પરિવહનને પસંદ કર્યું હોવાથી આ પ્રવાસના ભાવમાં ફેરફાર થયો છે.",
  },
  _transportationPriceChangeMessage3: {
    "en-US":
      "This happens because someone else has booked this transportation since you made your selection.",
    "ar-AE": "؛ يحدث هذا لأن شخصًا آخر حجز هذا النقل منذ أن قمت بتحديدك.",
    "gu-IN": "આવું થાય છે કારણ કે તમે તમારી પસંદગી કરી ત્યારથી કોઈ બીજાએ આ પરિવહન બુક કર્યુ છે.",
  },
  _groundservicePriceChangeMessage1: {
    "en-US": "We're Sorry, The price for your ground service has changed.",
    "ar-AE": "عذرًا ، لقد تغير سعر الخدمة الأرضية الخاصة بك.",
    "gu-IN": "માફ કરશો, તમારી ગ્રાઉન્ડ સર્વિસની કિંમત બદલાઈ ગઈ છે.",
  },
  _groundservicePriceChangeMessage2: {
    "en-US": "The price of this itinerary has changed since you selected your ground service.",
    "ar-AE": "لقد تغير سعر خط سير الرحلة هذا منذ أن حددت الخدمة الأرضية.",
    "gu-IN": "તમે તમારી ગ્રાઉન્ડ સર્વિસ પસંદ કરી ત્યારથી આ પ્રવાસના ભાવમાં ફેરફાર થયો છે.",
  },
  _groundservicePriceChangeMessage3: {
    "en-US":
      "This happens because someone else has booked this ground service since you made your selection.",
    "ar-AE": "يحدث هذا لأن شخصًا آخر قد حجز هذه الخدمة الأرضية منذ اختيارك.",
    "gu-IN": "આવું થાય છે કારણ કે તમે તમારી પસંદગી કરી ત્યારથી કોઈએ આ ગ્રાઉન્ડ સર્વિસ બુક કરાવી છે.",
  },
  _airPriceChangeMessage1: {
    "en-US": "We're Sorry, The price for your flight has changed.",
    "ar-AE": "معذرة، لقد تغير السعر لرحلتك.",
    "gu-IN": "માફ કરશો, તમારી ફ્લાઇટની કિંમત બદલાઈ ગઈ છે.",
  },
  _airPriceChangeMessage2: {
    "en-US": "The price of this itinerary has changed since you selected your flight.",
    "ar-AE": "لم يتغير سعر هذه الرحلة منذ قمت بتحديدها رحلتك.",
    "gu-IN": "તમે તમારી ફ્લાઇટ પસંદ કરી ત્યારથી આ પ્રવાસના ભાવમાં ફેરફાર થયો છે.",
  },
  _airPriceChangeMessage3: {
    "en-US":
      "This happens because someone else has booked this flight since you made your selection.",
    "ar-AE": "وهذا يحدث لأن شخصا آخر قد حجز هذه الرحلة منذ أجريتها اختيارك.",
    "gu-IN": "આવું થાય છે કારણ કે તમે તમારી પસંદગી કરી ત્યારથી કોઈ બીજાએ આ ફ્લાઇટ બુક કરાવી છે.",
  },
  _widgettransportationNoOfVehicle: {
    "en-US": "No. of Vehicle",
    "ar-AE": "عدد الركاب",
    "gu-IN": "વાહનની સંખ્યા",
  },
  _widgettransportationNoOfPerson: {
    "en-US": "No. of Person",
    "ar-AE": "عدد للضيوف",
    "gu-IN": "વ્યક્તિની સંખ્યા",
  },
  _widgetgroundserviceNoOfPerson: {
    "en-US": "No. of Person",
    "ar-AE": "عدد للضيوف",
    "gu-IN": "વ્યક્તિની સંખ્યા",
  },
  _widgetairAirLine: { "en-US": "Airline", "ar-AE": "الخطوط الجوية", "gu-IN": "એરલાઇન" },
  _widgetairCabinClass: {
    "en-US": "Class of service",
    "ar-AE": "فئة الخدمة",
    "gu-IN": "સેવાનો વર્ગ",
  },
  _listtransportationMessage: {
    "en-US":
      "Dear valued customer, The result of the required search is coming from MOHU Maqam (GDS) Database. Your result maybe get affected by the availability of services in MOHU Maqam (GDS)",
    "ar-AE":
      "عزيزنا العميل.يرجى العلم بإن نتائج البحث للخدمات المختارة يتم من خلال الاستعلام في بوابة الحجز المركزي (مقام-GDS) ، لذا فإن نتائج البحث ربما تتأثر بالتوافرية لدى بوابة مقام .",
    "gu-IN":
      "પ્રિય મૂલ્યવાન ગ્રાહક, આવશ્યક શોધનું પરિણામ મોહૂ મકમ (જીડીએસ) ડેટાબેસમાંથી આવી રહ્યું છે. તમારું પરિણામ કદાચ એમએચએચયુ મકમ (જીડીએસ) માં સેવાઓની ઉપલબ્ધતાથી પ્રભાવિત થઈ શકે છે.",
  },
  _filtertransportationvehicletype: {
    "en-US": "Vehicle Type",
    "ar-AE": "نوع السيارة",
    "gu-IN": "વાહનનો પ્રકાર",
  },
  _ddlSelect: { "en-US": "--Select--", "ar-AE": "--تحديد--", "gu-IN": "--પસંદ કરો--" },
  _airSearchFilterEconomy: { "en-US": "Economy", "ar-AE": "الاقتصاد", "gu-IN": "અર્થતંત્ર" },
  _airSearchFilterEconomyPremiumEconomy: {
    "en-US": "Premium Economy",
    "ar-AE": "اقتصاد مميز",
    "gu-IN": "પ્રીમિયમ ઇકોનોમી",
  },
  _airSearchFilterBusiness: { "en-US": "Business", "ar-AE": "عمل", "gu-IN": "બિઝનેસ" },
  _airSearchFilterFirstClass: {
    "en-US": "First Class",
    "ar-AE": "الصف الأول",
    "gu-IN": "પ્રથમ વર્ગ",
  },
  _airSearchFilterDirectFlightOnly: {
    "en-US": "Direct Flights Only",
    "ar-AE": "رحلة طيران مباشرة فقط",
    "gu-IN": "ફક્ત સીધી ફ્લાઇટ્સ",
  },
  _airSearchFilterRefundableFlightsOnly: {
    "en-US": "Refundable Flights Only",
    "ar-AE": "رحلات لرد فقط",
    "gu-IN": "રિફંડપાત્ર ફ્લાઇટ્સ ફક્ત",
  },
  _widgetAdvanceOptions: {
    "en-US": "Advance Options",
    "ar-AE": "خيارات متقدمة",
    "gu-IN": "એડવાન્સ વિકલ્પો",
  },
  _airairlineSearchPlaceholder: {
    "en-US": 'e.g. "Etihad"',
    "ar-AE": 'e.g. "Etihad"',
    "gu-IN": 'e.g. "Etihad"',
  },
  _widgetMinMxTravellerNote1: {
    "en-US": "Number of infants can not be greater than Adult.",
    "ar-AE": "لا يمكن أن يكون عدد الرضع أكبر من البالغين.",
    "gu-IN": "શિશુઓની સંખ્યા પુખ્ત કરતા વધારે ન હોઇ શકે.",
  },
  _widgettransportationCompany: {
    "en-US": "Transportation Company",
    "ar-AE": "شركة النقل",
    "gu-IN": "ટ્રાન્સપોર્ટેશન કંપની",
  },
  _widgetgroundserviceCompany: {
    "en-US": "Umrah Operator Company",
    "ar-AE": "شركة مشغل العمرة",
    "gu-IN": "ઉમરાહ ઓપરેટર કંપની",
  },
  _transportationSearchPlaceholder: {
    "en-US": "Choose Transportation Company",
    "ar-AE": "اختر شركة النقل",
    "gu-IN": "ટ્રાન્સપોર્ટેશન કંપની પસંદ કરો",
  },
  _groundserviceSearchPlaceholder: {
    "en-US": "Choose Umrah Operator Company",
    "ar-AE": "اختر شركة عمرة",
    "gu-IN": "ઉમરાહ ratorપરેટર કંપની પસંદ કરો",
  },
  _noMatchesFoundtransportation: {
    "en-US": "Transportation Company not found.",
    "ar-AE": "لم يتم العثور على شركة النقل.",
    "gu-IN": "ગ્રાહક પ્રોફાઇલ સફળતાપૂર્વક અપડેટ થઈ",
  },
  _noMatchesFoundgroundservice: {
    "en-US": "Umrah Operator Company not found.",
    "ar-AE": "لم يتم العثور على شركة مشغل العمرة.",
    "gu-IN": "ઉમરાહ ratorપરેટર કંપની મળી નથી.",
  },

  _myProfileUpdatedSuccessfully: {
    "en-US": "Customer profile updated successfully.",
    "ar-AE": "تم تحديث ملف تعريف العميل بنجاح.",
    "gu-IN": "ગ્રાહક પ્રોફાઇલ સફળતાપૂર્વક અપડેટ થઈ",
  },
  _coTravellerUpdatedSuccessfully: {
    "en-US": "Co-Traveller updated successfully",
    "ar-AE": "تم تحديث برنامج Co-Traveler بنجاح",
    "gu-IN": "સહ-પ્રવાસી સફળતાપૂર્વક અપડેટ થયું",
  },
  _coTravellerAddedSuccessfully: {
    "en-US": "Co-Traveller added successfully",
    "ar-AE": "تمت إضافة Co-Traveler بنجاح",
    "gu-IN": "સહ-પ્રવાસી સફળતાપૂર્વક ઉમેર્યા",
  },
  _customerUpdatedSuccessfully: {
    "en-US": "Customer updated successfully",
    "ar-AE": "تم تحديث العميل بنجاح",
    "gu-IN": "ગ્રાહક સફળતાપૂર્વક અપડેટ થયું",
  },
  _customerAddedSuccessfully: {
    "en-US": "Customer added successfully",
    "ar-AE": "تمت إضافة العميل بنجاح",
    "gu-IN": "ગ્રાહક સફળતાપૂર્વક ઉમેર્યું",
  },
  _ok: {
    "en-US": "Ok",
    "ar-AE": "حسنا",
    "gu-IN": "બરાબર",
  },
  _viewtransportationDate: {
    "en-US": "Transportation Date",
    "ar-AE": "تاريخ النقل",
    "gu-IN": "પરિવહન તારીખ",
  },
  _viewgroundserviceDate: {
    "en-US": "Ground Service Date",
    "ar-AE": "تاريخ الخدمة الأرضية",
    "gu-IN": "ગ્રાઉન્ડ સર્વિસ ડેટ",
  },
  _lblMilesOrFrequentFlyerCardHolder: {
    "en-US": "Miles / Frequent Flyer Card Holder",
    "ar-AE": "الأميال / المتكرر رقم بطاقة الطيارة",
    "gu-IN": "માઇલ્સ / વારંવાર ફ્લાયર કાર્ડ ધારક",
  },
  _lblMilesCard: {
    "en-US": "Miles Card *",
    "ar-AE": "بطاقة مايلز *",
    "gu-IN": "માઇલ્સ કાર્ડ *",
  },
  _lblMilesCardNumber: {
    "en-US": "Miles Card Number *",
    "ar-AE": "رقم بطاقة الأميال *",
    "gu-IN": "માઇલ કાર્ડ નંબર *",
  },
  _error_milescard_require: {
    "en-US": "Miles card required",
    "ar-AE": "رقم بطاقة الأميال",
    "gu-IN": "માઇલ્સ કાર્ડ આવશ્યક છે",
  },
  _error_milescardnumber_require: {
    "en-US": "Miles card number required",
    "ar-AE": "رقم بطاقة الأميال مطلوب",
    "gu-IN": "માઇલ કાર્ડ નંબર આવશ્યક છે",
  },
  _error_milescard_length: {
    "en-US": "Miles card must be between 2 and 20 characters long",
    "ar-AE": "يجب أن يتراوح طول بطاقة الأميال بين 2 و 20 حرفاً",
    "gu-IN": "માઇલ્સ કાર્ડ નંબર 2 અને 20 અક્ષરો વચ્ચેનો હોવો આવશ્યક છે",
  },
  _error_milescardnumber_length: {
    "en-US": "Miles card number must be between 2 and 20 characters long",
    "ar-AE": "يجب أن يتراوح طول رقم بطاقة مايلز بين 2 و 20 حرفاً",
    "gu-IN": "માઇલ્સ કાર્ડ નંબર 2 અને 20 અક્ષરો વચ્ચેનો હોવો આવશ્યક છે",
  },
  _widgettransportationMaxVehicle: {
    "en-US": "Max. 10",
    "ar-AE": "ماكس. 10",
    "gu-IN": "મહત્તમ. 10",
  },
  _widgettransportationMaxPerson: {
    "en-US": "Max. 60",
    "ar-AE": "ماكس. 60",
    "gu-IN": "મહત્તમ. 60",
  },
  _vehicleCategory: { "en-US": "Category", "ar-AE": "فئة", "gu-IN": "કેટેગરી" },
  _vehicleModel: { "en-US": "Model", "ar-AE": "الموديل", "gu-IN": "મોડેલ" },
  _vehicleQuantity: {
    "en-US": "Vehicle Quantity",
    "ar-AE": "كمية السيارة",
    "gu-IN": "વાહનની માત્રા",
  },
  _VehicleNoOfPersion: {
    "en-US": "No of Person",
    "ar-AE": "عدد الأشخاص",
    "gu-IN": "વ્યક્તિની સંખ્યા",
  },
  _vehicleName: { "en-US": "Vehicle Name", "ar-AE": "اسم السيارة", "gu-IN": "વાહનનું નામ" },
  _filterplacemap: { "en-US": "Location", "ar-AE": "الموقع", "gu-IN": "સ્થાન" },
  _filterMapDistance: {
    "en-US": "Distance (in kilometer)",
    "ar-AE": "المسافة (بالكيلومتر)",
    "gu-IN": "અંતર (કિલોમીટરમાં)",
  },
  _distance: { "en-US": "Distance", "ar-AE": "مسافة", "gu-IN": "અંતર" },
  _searchPlaces: {
    "en-US": "Search Places ...",
    "ar-AE": "البحث في الأماكن ...",
    "gu-IN": "સ્થાનો શોધો ...",
  },
  _bookingStatusTicketonProcess: {
    "en-US": "Ticket On Process",
    "ar-AE": "فشل تذكرة إلكترونية",
    "gu-IN": "પ્રક્રિયા પર ટિકિટ",
  },
  _bookingStatusBookingFailure: {
    "en-US": "Booking Failure",
    "ar-AE": "ملغي آلياً",
    "gu-IN": "બુકિંગ નિષ્ફળતા",
  },
  _bookingStatusAutoCancel: {
    "en-US": "Auto Cancel",
    "ar-AE": "ملغي آلياً",
    "gu-IN": "ઓટો રદ કરો",
  },
  _bookingStatusAutoCancelFailure: {
    "en-US": "Auto Cancel Failure",
    "ar-AE": "فشل في  الإلغاء",
    "gu-IN": "સ્વત રદ નિષ્ફળતા",
  },
  _bookingStatuseTicketSuspended: {
    "en-US": "eTicket Suspended",
    "ar-AE": "تذكرة إلكترونية ملغية",
    "gu-IN": "eTicket Suspended",
  },
  _bookingStatusSystemVoid: {
    "en-US": "System Void",
    "ar-AE": "تجاهل من قبل النظام",
    "gu-IN": "સિસ્ટમ રદબાતલ",
  },
  _filtertransportationvehiclemodel: {
    "en-US": "Vehicle Model",
    "ar-AE": "نموذج المركبة",
    "gu-IN": "વાહન મોડેલ",
  },
  _widgetNoofRooms: {
    "en-US": "Number of Room(s)",
    "ar-AE": "عدد الغرف",
    "gu-IN": "ઓરડાઓની સંખ્યા",
  },
  _widgetAdultsPerRoom: {
    "en-US": "Adult(s) Per Room",
    "ar-AE": "أشخاص في الغرفة",
    "gu-IN": "પુખ્ત રૂમ દીઠ",
  },
  _widgetChildrenPerRoom: {
    "en-US": "Child(ren) Per Room",
    "ar-AE": "طفل (أطفال) في الغرفة",
    "gu-IN": "બાળકો રૂમ દીઠ",
  },
  _widgetAddAnotherRoomsGroup: {
    "en-US": "Add another room(s) group",
    "ar-AE": "أضف مجموعة (غرف) أخرى",
    "gu-IN": "બીજો ઓરડો (ઓ) જૂથ ઉમેરો",
  },
  _widgetRoomGroup: {
    "en-US": "Room group",
    "ar-AE": "مجموعة الغرفة",
    "gu-IN": "રૂમ જૂથ",
  },
  _widgetRoomGroupMessage: {
    "en-US": "You can search maximum 120 rooms.",
    "ar-AE": "يمكنك البحث في 120 غرفة كحد أقصى.",
    "gu-IN": "તમે મહત્તમ 120 ઓરડાઓ શોધી શકો છો.",
  },
  _widgethotelSpecialCode: {
    "en-US": "Special Code",
    "ar-AE": "كود خاص",
    "gu-IN": "વિશેષ કોડ",
  },
  _widgethotelsubPCCCode: {
    "en-US": "Sub PCC Code",
    "ar-AE": "كود PCC الفرعي",
    "gu-IN": "સબ પીસીસી કોડ",
  },
  _activitySoldOutMessage1: {
    "en-US": "We're Sorry, selected schedule for this activity is no longer available.",
    "ar-AE": "عذرًا ، الجدول الزمني المحدد لهذا النشاط لم يعد متاحًا.",
    "gu-IN": "માફ કરશો, આ પ્રવૃત્તિ માટે પસંદ કરેલું શેડ્યૂલ હવે ઉપલબ્ધ નથી.",
  },
  _packageSoldOutMessage1: {
    "en-US": "We're Sorry, selected schedule for this package is no longer available.",
    "ar-AE": "عذرًا ، الجدول المحدد لهذه الحزمة لم يعد متاحًا.",
    "gu-IN": "માફ કરશો, આ પેકેજ માટે પસંદ કરેલું શેડ્યૂલ હવે ઉપલબ્ધ નથી.",
  },
  _cancelBooking: {
    "en-US": "Cancel Booking",
    "ar-AE": "إلغاء الحجز",
    "gu-IN": "બુકિંગ રદ કરો",
  },
  _modifyBooking: {
    "en-US": "Modify Booking",
    "ar-AE": "تعديل الحجز",
    "gu-IN": "બુકિંગમાં ફેરફાર કરો",
  },
  _reservation: {
    "en-US": "Reservation",
    "ar-AE": "حجز",
    "gu-IN": "આરક્ષણ",
  },
  _confirm: {
    "en-US": "Confirm",
    "ar-AE": "أكد",
    "gu-IN": "પુષ્ટિ કરો",
  },
  _name: {
    "en-US": "Name",
    "ar-AE": "الاسم",
    "gu-IN": "નામ",
  },
  _trip: {
    "en-US": "Trip",
    "ar-AE": "رحلة قصيرة",
    "gu-IN": "સફર",
  },
  _date: {
    "en-US": "Date",
    "ar-AE": "تاريخ",
    "gu-IN": "તારીખ",
  },
  _airline: { "en-US": "Airline", "ar-AE": "الخطوط الجوية", "gu-IN": "એરલાઇન" },
  _passenger: { "en-US": "Passenger", "ar-AE": "راكب", "gu-IN": "પેસેન્જર" },
  _roomType: { "en-US": "Room Type", "ar-AE": "نوع الغرفة", "gu-IN": "ઓરડા નો પ્રકાર" },
  _departureDate: { "en-US": "Departure Date", "ar-AE": "تاريخ المغادرة", "gu-IN": "જવા ની તારીખ" },
  _returnDate: { "en-US": "Return Date", "ar-AE": "تاريخ العودة", "gu-IN": "પરત ફરવાની તારીખ" },
  _noOfPassengers: {
    "en-US": "No of Passenger(s)",
    "ar-AE": "عدد الركاب",
    "gu-IN": "મુસાફરોની સંખ્યા",
  },
  _operator: { "en-US": "Operator", "ar-AE": "الشركة", "gu-IN": "ઑપરેટર" },
  _cartUnavailableMessage: {
    "en-US": "Remove all unavailable item(s) to proceed payment page.",
    "ar-AE": "قم بإزالة كافة العناصر غير المتوفرة لمتابعة صفحة الدفع.",
    "gu-IN": "ચુકવણી પૃષ્ઠને આગળ વધારવા માટે બધી અનુપલબ્ધ આઇટમ (ઓ) ને દૂર કરો.",
  },
  _paymentnonBNPLMessage: {
    "en-US":
      "I agree and understand that this is a non-refundable booking. Cancellation charges may apply at the time of cancellation.",
    "ar-AE": "أوافق وأفهم أن هذا حجز غير قابل للاسترداد. قد يتم تطبيق رسوم الإلغاء في وقت الإلغاء.",
    "gu-IN":
      "હું સંમત છું અને સમજી શકું છું કે આ એક પરત ન ભરવા યોગ્ય બુકિંગ છે. રદ કરવાના સમયે રદ કરવાના ચાર્જ લાગુ થઈ શકે છે.",
  },
  _addComment: { "en-US": "Add Comment", "ar-AE": "إضافة تعليق", "gu-IN": "ટિપ્પણી ઉમેરો" },
  _noFarebreakupFound: {
    "en-US": "No fare breakup found.",
    "ar-AE": "لم يتم العثور على تفريق أجرة.",
    "gu-IN": "ભાડાનું બ્રેકઅપ મળ્યું નથી.",
  },
  _noTransactionFound: {
    "en-US": "No transaction found.",
    "ar-AE": "لم يتم العثور على معاملة.",
    "gu-IN": "કોઈ વ્યવહાર મળ્યો નથી.",
  },
  _widgetewalletstatementCheckIn: {
    "en-US": "From Date",
    "ar-AE": "من التاريخ",
    "gu-IN": "તારીખથી",
  },
  _widgetewalletstatementCheckOut: {
    "en-US": "To Date",
    "ar-AE": "حتى تاريخه",
    "gu-IN": "આજ સુધી",
  },
  _myBalance: { "en-US": "My Balance", "ar-AE": "رصيدي", "gu-IN": "My Balance" },
  _EWalletStatement: {
    "en-US": "E-Wallet Statement",
    "ar-AE": "بيان المحفظة الإلكترونية",
    "gu-IN": "ઇ-વૉલેટ પત્રક",
  },
  _failedBookings: {
    "en-US": "Failed Bookings",
    "ar-AE": "الحجوزات الفاشلة",
    "gu-IN": "નિષ્ફળ બુકિંગ",
  },
  _offlineBookings: {
    "en-US": "Offline Bookings",
    "ar-AE": "حجوزات بلا إنترنت",
    "gu-IN": "બંધ બુકિંગ",
  },
  _issueDocuments: {
    "en-US": "Issue Documents",
    "ar-AE": "وثائق الإصدار",
    "gu-IN": "દસ્તાવેજો જારી કરો",
  },
  _manageCustomers: {
    "en-US": "Manage Customers",
    "ar-AE": "إدارة العملاء",
    "gu-IN": "ગ્રાહકો મેનેજ કરો",
  },
  _error_transportation_Detail_ValidationMessage1: {
    "en-US": "Please select vehicle as per your pax capacity.",
    "ar-AE": "يرجى تحديد السيارة حسب سعة باكس الخاصة بك.",
    "gu-IN": "કૃપા કરી તમારી મુસાફરોની ક્ષમતા પ્રમાણે વાહન પસંદ કરો.",
  },
  _grid_paging_totalRecord: {
    "en-US": "Total Record",
    "ar-AE": "إجمالي السجل",
    "gu-IN": "કુલ રેકોર્ડ",
  },
  _grid_paging_recordsPerPage: {
    "en-US": "Records per Page",
    "ar-AE": "تسجيلات لكل صفحة",
    "gu-IN": "પૃષ્ઠ દીઠ રેકોર્ડ્સ",
  },
  _grid_paging_gotoPage: {
    "en-US": "Go to Page",
    "ar-AE": "انتقل إلى صفحة",
    "gu-IN": "પૃષ્ઠ પર જાઓ",
  },
  _grid_paging_page: { "en-US": "Page", "ar-AE": "الصفحة", "gu-IN": "પાનું" },
  _grid_paging_of: { "en-US": "of", "ar-AE": "من", "gu-IN": "ની" },
  _grid_paging_previous: { "en-US": "Previous", "ar-AE": "السابق", "gu-IN": "ગત" },
  _grid_paging_next: { "en-US": "Next", "ar-AE": "التالى", "gu-IN": "આગળ" },
  _view: { "en-US": "View", "ar-AE": "رأي", "gu-IN": "જુઓ" },
  _modify: { "en-US": "Modify", "ar-AE": "تعديل", "gu-IN": "સુધારો" },
  _business: { "en-US": "Business", "ar-AE": "اعمال", "gu-IN": "બિઝનેસ" },
  _bookingRefNo: {
    "en-US": "Booking Ref. No.",
    "ar-AE": "رقم مرجع الحجز",
    "gu-IN": "બુકિંગ સંદર્ભ નંબર",
  },
  _itineraryRefNo: {
    "en-US": "Itinerary Ref. No.",
    "ar-AE": "الرقم المرجعي لمسار الرحلة",
    "gu-IN": "ઇટિનરરી સંદર્ભ નંબર",
  },
  _emailAddress: {
    "en-US": "E-mail Address",
    "ar-AE": "عنوان بريد الكتروني",
    "gu-IN": "ઈ - મેઈલ સરનામું",
  },
  _customerName: { "en-US": "Customer Name", "ar-AE": "اسم الزبون", "gu-IN": "ગ્રાહક નું નામ" },
  _customerNamewithstar: {
    "en-US": "Customer Name *",
    "ar-AE": "اسم الزبون *",
    "gu-IN": "ગ્રાહક નું નામ *",
  },
  _supplierName: { "en-US": "Supplier Name", "ar-AE": "اسم المورد", "gu-IN": "પુરવઠોકર્તા નામ" },
  _transactionType: {
    "en-US": "Transaction Type",
    "ar-AE": "نوع المعاملة",
    "gu-IN": "વ્યવહારનો પ્રકાર",
  },
  _transactionStatus: {
    "en-US": "Transaction Status",
    "ar-AE": "حالة عملية",
    "gu-IN": "ટ્રાન્ઝેક્શનની સ્થિતિ",
  },
  _contactNumber: { "en-US": "Contact Number", "ar-AE": "رقم الاتصال", "gu-IN": "સંપર્ક નંબર" },
  _transactionNo: { "en-US": "Transaction No.", "ar-AE": "رقم التحويلة", "gu-IN": "વ્યવહાર નંબર" },
  _agent: { "en-US": "Agent", "ar-AE": "وكيل", "gu-IN": "એજન્ટ" },
  _customer: { "en-US": "Customer", "ar-AE": "الزبون", "gu-IN": "ગ્રાહક" },
  _gateway: { "en-US": "Gateway", "ar-AE": "بوابة", "gu-IN": "ગેટવે" },
  _supplier: { "en-US": "Supplier", "ar-AE": "المورد", "gu-IN": "સપ્લાયર" },
  _netBookingAmount: {
    "en-US": "Net Booking Amount",
    "ar-AE": "مبلغ الحجز الصافي",
    "gu-IN": "નેટ બુકિંગ રકમ",
  },
  _actions: { "en-US": "Actions", "ar-AE": "أجراءات", "gu-IN": "ક્રિયાઓ" },
  _GDSErrorInformation: {
    "en-US": "GDS Error Information",
    "ar-AE": "معلومات خطأ GDS",
    "gu-IN": "જીડીએસ ભૂલ માહિતી",
  },
  _GDSReferenceCode: {
    "en-US": "GDS Reference Code",
    "ar-AE": "كود GDS المرجعي",
    "gu-IN": "જીડીએસ સંદર્ભ કોડ",
  },
  _GDSeWalletError: {
    "en-US": "GDS eWallet Error",
    "ar-AE": "خطأ في محفظة GDS الإلكترونية",
    "gu-IN": "જીડીએસ ઇવalલેટ ભૂલ",
  },
  _cancellationCharges: {
    "en-US": "Cancellation Charges",
    "ar-AE": "رسوم الإلغاء",
    "gu-IN": "રદ ચાર્જ",
  },
  _itineraryDetails: {
    "en-US": "Itinerary Details",
    "ar-AE": "تفاصيل خط سير الرحلة",
    "gu-IN": "પ્રવાસની વિગતો",
  },
  _confirmBooking: {
    "en-US": "Confirm Booking",
    "ar-AE": "تأكيد الحجز",
    "gu-IN": "બુકિંગની પુષ્ટિ કરો",
  },
  _eWalletBalance: {
    "en-US": "E-Wallet Balance",
    "ar-AE": "رصيد المحفظة الإلكترونية",
    "gu-IN": "ઇ-વletલેટ બેલેન્સ",
  },
  _transactionDate: {
    "en-US": "Transaction Date",
    "ar-AE": "تاريخ الصفقة",
    "gu-IN": "વ્યવહાર તારીખ",
  },
  _transactionAmount: {
    "en-US": "Transaction Amount",
    "ar-AE": "قيمة التحويل",
    "gu-IN": "ટ્રાન્ઝેક્શનની રકમ",
  },
  _forgotPassword: {
    "en-US": "Forgot Password",
    "ar-AE": "هل نسيت كلمة المرور",
    "gu-IN": "પાસવર્ડ ભૂલી ગયા છો",
  },
  _noEWalletTransactionFound: {
    "en-US":
      "No record(s) found. You may try changing your search criteria to see available options.",
    "ar-AE": "لا توجد سجلات. يمكنك محاولة تغيير معايير البحث الخاصة بك لرؤية الخيارات المتاحة.",
    "gu-IN":
      "કોઈ રેકોર્ડ (ઓ) મળ્યો નથી. ઉપલબ્ધ વિકલ્પો જોવા માટે તમે તમારા શોધ માપદંડને બદલવાનો પ્રયાસ કરી શકો છો.",
  },
  _ChangeSearchCheckAvailMsg: {
    "en-US": "Please change the search criteria to check availability.",
    "ar-AE": "يرجى تغيير معايير البحث للتحقق من توافر.",
    "gu-IN": "ઉપલબ્ધતા ચકાસવા માટે કૃપા કરીને શોધ માપદંડ બદલો.",
  },
  _widgetAddAnotherRoomGroup: {
    "en-US": "Add another room group",
    "ar-AE": "أضف مجموعة غرف أخرى",
    "gu-IN": "બીજો ઓરડો જૂથ ઉમેરો",
  },
  _transportationDetails: {
    "en-US": "Transportation Details",
    "ar-AE": "تفاصيل النقل",
    "gu-IN": "પરિવહન વિગતો",
  },
  _groundserviceDetails: {
    "en-US": "Ground Service Details",
    "ar-AE": "تفاصيل الخدمة الأرضية",
    "gu-IN": "ગ્રાઉન્ડ સર્વિસ વિગતો",
  },
  _umrahLocationSA26: {
    "en-US": "Makkah, Saudi Arabia - Location",
    "ar-AE": "مكة",
    "gu-IN": "મક્કાહ, સાઉદી અરેબિયા - સ્થાન",
  },
  _umrahLocationSA25: {
    "en-US": "Madinah, Saudi Arabia - Location",
    "ar-AE": "المدينة المنورة",
    "gu-IN": "મદીનાહ, સાઉદી અરેબિયા - સ્થાન",
  },
  _goToHomePage: {
    "en-US": "Go to Home page",
    "ar-AE": "انتقل إلى الصفحة الرئيسية",
    "gu-IN": "હોમ પેજ પર જાઓ",
  },
  _warningTitle: { "en-US": "Warning", "ar-AE": "تحذير", "gu-IN": "Warning" },
  _eTicketNotGenerated: {
    "en-US": "E-ticket not generated",
    "ar-AE": "لم يتم إنشاء التذكرة الإلكترونية",
    "gu-IN": "E-ticket not generated",
  },
  _error_address_length: {
    "en-US": "Address must be between 2 and 50 characters long",
    "ar-AE": "يجب أن يتراوح طول المدينة بين 2 و 50 حرفًا",
    "gu-IN": "સરનામું 2 થી 50 અક્ષરોની વચ્ચે હોવું જોઈએ",
  },
  _pax: { "en-US": "Pax", "ar-AE": "السلام" },
  _balance: { "en-US": "Balance", "ar-AE": "توازن" },
  _payableAmount: {
    "en-US": "Payable Amount ",
    "ar-AE": "إجمالي المبلغ المستحق",
    "gu-IN": "કુલ રકમ",
  },
  _comments: { "en-US": "Comments", "ar-AE": "تعليقات" },
  _areYouSureYouWantToConfirmOrCancle: {
    "en-US": "Are you sure you want to",
    "ar-AE": "هل أنت متأكد من أنك تريد",
  },
  _thisBooking: { "en-US": "this booking ?", "ar-AE": "هذا الحجز؟" },
  _viewComments: { "en-US": "View Comments", "ar-AE": "تعليقات عرض" },
  _processRequest: { "en-US": "Process Request", "ar-AE": "طلب العملية" },
  _bookingDetails: { "en-US": "Booking Details", "ar-AE": "تفاصيل الحجز" },
  _vendor: { "en-US": "Vendor", "ar-AE": "بائع" },
  _customerDetails: { "en-US": "Customer Details", "ar-AE": "تفاصيل العميل" },
  _select: { "en-US": "Select", "ar-AE": "تحديد" },
  _paidAmount: { "en-US": "Paid Amount", "ar-AE": "المبلغ المدفوع" },
  _notePaidAmountIncludesFeesByAgent: {
    "en-US": "Note: Paid Amount includes fees by Agent.",
    "ar-AE": "ملحوظة: المبلغ المدفوع يشمل الرسوم حسب الوكيل.",
  },
  _submit: { "en-US": "Submit", "ar-AE": "إرسال" },
  _requestComments: { "en-US": "Request Comments", "ar-AE": "طلب التعليقات" },
  _requestDate: { "en-US": "Request Date", "ar-AE": "تاريخ الطلب" },
  _sendRequest: { "en-US": "Send Request", "ar-AE": "ارسل طلب" },
  _refundAmount: { "en-US": "Refund Amount", "ar-AE": "المبلغ المسترد" },
  _bookingForGuestCustomer: {
    "en-US": "Booking For - Guest Customer",
    "ar-AE": "الحجز لـ - ضيف ضيف",
  },
  _milesOrFrequentFlyerCard: {
    "en-US": "Miles/Frequent Flyer Card",
    "ar-AE": "أميال / بطاقة المسافر الدائم",
  },
  _milesOrFrequentFlyerCardNumber: {
    "en-US": "Miles/Frequent Flyer Card Number",
    "ar-AE": "أميال / بطاقة المسافر الدائم",
  },
  _preferencesAndRequests: { "en-US": "Preferences & Requests", "ar-AE": "التفضيلات والطلبات" },
  _mealPreferences: { "en-US": "Meal Preferences", "ar-AE": "تفضيلات الوجبات" },
  _seatAssignment: { "en-US": "Seat Assignment", "ar-AE": "تعيين المقعد" },
  _baggageFeeDeparture: { "en-US": "Baggage Fee : Departure", "ar-AE": "رسوم الأمتعة: المغادرة" },
  _baggageFeeArrival: { "en-US": "Baggage Fee : Arrival", "ar-AE": "رسوم الأمتعة: الوصول" },
  _baggageFee: { "en-US": "Baggage Fee", "ar-AE": "رسوم الأمتل" },
  _additionalBaggageChargesWillBeAddedToYourTotalAmount: {
    "en-US": "Additional Baggage Charges will be added to your Total Amount",
    "ar-AE": "سيتم إضافة رسوم الأمتعة الإضافية إلى المبلغ الإجمالي الخاص بك",
  },
  _pleaseContactTheAirlineDirectlyToConfirmPreferencesAndRequests: {
    "en-US": "Please contact the airline directly to confirm Preference(s) & Request(s)",
    "ar-AE": "يرجى الاتصال بشركة الطيران مباشرة لتأكيد التفضيل (الطلبات) والطلبات (الطلبات)",
  },
  _saveAsCoTraveller: { "en-US": "Save as Co-Traveller", "ar-AE": "حفظ كمسافر مشارك" },
  _infantMessageInTraveller: {
    "en-US":
      "The infant should be less than 2 years and child should be less than 12 years throughout the duration of the plan.",
    "ar-AE": "يجب أن يكون الرضيع أقل من 2 سنة وأن يكون الطفل أقل من 12 سنة طوال مدة الخطة.",
  },
  _pickupDetails: { "en-US": "Pickup Details", "ar-AE": "تفاصيل الالتقاط" },
  _dropoffDetails: { "en-US": "Dropoff Details", "ar-AE": "تفاصيل الانسحاب" },
  _dashboard: { "en-US": "Dashboard", "ar-AE": "لوحة القيادة" },
  _reviews: { "en-US": "reviews", "ar-AE": "المراجعات" },
  _addToQuotation: { "en-US": "Add to Quotation", "ar-AE": "أضف إلى الاقتباس" },
  _addToWishList: { "en-US": "Add to Wish List", "ar-AE": "أضف إلى الاقتباس" },
  _removeFromWishList: { "en-US": "Remove from Wish List", "ar-AE": " إلى الاقتباس" },
  _showInMap: { "en-US": "Show In Map", "ar-AE": "إظهار في الخريطة" },
  _iHaveAnOfferCode: { "en-US": "I have an offer code", "ar-AE": "لدي كود عرض" },
  _apply: { "en-US": "Apply", "ar-AE": "تطبيق" },
  _promoCodeAppliedSuccessfullyMessage1: {
    "en-US": "Promo Code Applied Successfully, You get",
    "ar-AE": "تم تطبيق الرمز الترويجي بنجاح ، تحصل عليه",
  },
  _promoCodeAppliedSuccessfullyMessage2: { "en-US": "as discount.", "ar-AE": "كخصم." },
  _invalidPromoCodeMessage: {
    "en-US": "Invalid promo code, Please try again.",
    "ar-AE": "رمز ترويجي غير صالح ، يرجى المحاولة مرة أخرى.",
  },
  _quotationTitle: { "en-US": "Quotation Title", "ar-AE": "عنوان الاقتباس" },
  _quotationTitleWithStar: { "en-US": "Quotation Title *", "ar-AE": "عنوان الاقتباس *" },
  _create: { "en-US": "Create", "ar-AE": "خلق" },
  _update: { "en-US": "Update", "ar-AE": "تحديث" },
  _quotationDetails: { "en-US": "Quotation Details", "ar-AE": "تفاصيل الاقتباس" },
  _1xRoom: { "en-US": "1 x Room", "ar-AE": "1 × غرفة" },
  _quotationFor: { "en-US": "Quotation for", "ar-AE": "اقتباس ل" },
  _selectRooms: { "en-US": "Select Rooms", "ar-AE": "حدد الغرف" },
  _found: { "en-US": "Found", "ar-AE": "وجدت" },
  _filtersAndSort: { "en-US": "Filters and Sort", "ar-AE": "مرشحات وفرز" },
  _emailWishList: { "en-US": "Email Wish List", "ar-AE": "قائمة الرغبات بالبريد الإلكتروني" },
  _emailWishListMessage: {
    "en-US": "Email has been sent successfully.",
    "ar-AE": "تم ارسال البريد الالكتروني بنجاح.",
  },
  _noItemsAdded: { "en-US": "No Items Added", "ar-AE": "لم تتم إضافة عناصر" },
  _subject: { "en-US": "Subject", "ar-AE": "موضوع" },
  _wishList: { "en-US": "Wish List", "ar-AE": "قائمة الرغبات" },
  _clear: { "en-US": "Clear", "ar-AE": "مرشحات واضحة" },
  _addItemsToQuotation: { "en-US": "Add Items to Quotation", "ar-AE": "إضافة عناصر إلى الاقتباس" },
  _addItemsToItinerary: { "en-US": "Add Items to Itinerary", "ar-AE": "إضافة عناصر إلى الاقتباس" },
  _addOffline: { "en-US": "Add Manually", "ar-AE": "إضافة دون اتصال" },
  _importFromBRN: { "en-US": "Import Booking", "ar-AE": "استيراد من BRN" },
  _portal: { "en-US": "Portal", "ar-AE": "بوابة" },
  _agentName: { "en-US": "Agent Name", "ar-AE": "اسم العميل" },
  _branchName: { "en-US": "Branch Name", "ar-AE": "اسم الفرع" },
  _notes: { "en-US": "Notes", "ar-AE": "ملاحظات" },
  _ticket: { "en-US": "Ticket", "ar-AE": "تذكرة الكترونية" },
  _allFlightTimesAreLocalToEachCity: {
    "en-US": "All flight times are local to each city",
    "ar-AE": "جميع أوقات الرحلات المحلية لكل مدينة",
  },
  _seatAssignmentAtAirportCheckInDeskOnly: {
    "en-US": "Seat assignment at airport check-in desk only",
    "ar-AE": "تخصيص المقعد في مكتب تسجيل الوصول في المطار فقط",
  },
  _specialRequest: { "en-US": "Special Request", "ar-AE": "طلب خاص" },
  _seat: { "en-US": "Seat", "ar-AE": "مقعد" },
  _meal: { "en-US": "Meal", "ar-AE": "وجبة" },
  _baggageDeparture: { "en-US": "Baggage Departure", "ar-AE": "مغادرة الأمتعة" },
  _baggageArrival: { "en-US": "Baggage Arrival", "ar-AE": "وصول الأمتعة" },
  _airlineBaggageAndOnlineCheckIn: {
    "en-US": "Airline Baggage & Online Check In",
    "ar-AE": "الأمتعة على متن الخطوط الجوية وتسجيل الوصول عبر الإنترنت",
  },
  _baggageFeeSecondaryMessage: {
    "en-US":
      "Your baggage allowance will be printed on your eticket receipt or email confirmation. To see the latest information, click on the link below to visit the individual airline's page.",
    "ar-AE":
      "ستتم طباعة بدل أمتعتك على إيصال الإيتيكيت الخاص بك أو تأكيد البريد الإلكتروني. للاطلاع على أحدث المعلومات ، انقر على الرابط أدناه لزيارة صفحة شركة الطيران الفردية.",
  },
  _baggageAllowances: { "en-US": "Baggage Allowances", "ar-AE": "بدل الأمتعة" },
  _onlineCheckIn: { "en-US": "Online Check In", "ar-AE": "قم بالتسجيل عبر الانترنت" },
  _frequentFlyer: { "en-US": "Frequent Flyer", "ar-AE": "المسافر الدائم" },
  _ATOLCertificateMessage: {
    "en-US":
      "Many of the flights and flight-inclusive holidays on this website are financially protected by the ATOL scheme. But ATOL protection does not apply to all holiday and travel services listed on this your booking. If you do not receive an ATOL Certificate then the booking will not be ATOL protected. If you do receive an ATOL Certificate but all the parts of your trip are not listed on it, those parts will not be ATOL protected. Please see our booking conditions for information, or for more information about financial protection and the ATOL Certificate go to",
    "ar-AE":
      "العديد من الرحلات الجوية والعطلات الشاملة للرحلات على هذا الموقع محمية ماليًا من خلال مخطط ATOL. لكن حماية ATOL لا تنطبق على جميع خدمات العطلات والسفر المدرجة في حجزك هذا. إذا لم تحصل على شهادة ATOL ، فلن يكون الحجز محميًا من ATOL. إذا حصلت على شهادة ATOL ولكن لم يتم إدراج جميع أجزاء رحلتك عليها ، فلن تكون هذه الأجزاء محمية من ATOL. يرجى الاطلاع على شروط الحجز الخاصة بنا للحصول على معلومات ، أو لمزيد من المعلومات حول الحماية المالية وشهادة ATOL",
  },
  _balanceTransactionDetails: {
    "en-US": "Balance Transaction Details",
    "ar-AE": "تفاصيل معاملة الرصيد",
  },
  _paymentDate: { "en-US": "Payment Date", "ar-AE": "موعد الدفع" },
  _openingBalance: { "en-US": "Opening Balance", "ar-AE": "الرصيد الافتتاحي" },
  _creditBalance: { "en-US": "Credit Balance", "ar-AE": "الرصيد الدائن" },
  _debitBalance: { "en-US": "Debit Balance", "ar-AE": "رصيد مدين" },
  _closingBalance: { "en-US": "Closing Balance", "ar-AE": "الرصيد الختامي" },
  _receiptNumber: { "en-US": "Receipt Number", "ar-AE": "عدد إيصال" },
  _reason: { "en-US": "Reason", "ar-AE": "السبب" },
  _manageBookings: { "en-US": "Manage Bookings", "ar-AE": "إدارة الحجوزات" },
  _makeBookings: { "en-US": "Make Bookings", "ar-AE": "قم بإجراء الحجوزات" },
  _myWallet: { "en-US": "My Wallet", "ar-AE": "محفظتي" },
  _makeQuotation: { "en-US": "Make Quotation", "ar-AE": "جعل الاقتباس" },
  _manageQuotation: { "en-US": "Manage Quotation", "ar-AE": "إدارة عرض الأسعار" },
  _manageItinerary: { "en-US": "Manage Itinerary", "ar-AE": "إدارة عرض الأسعار" },
  _manageAgents: { "en-US": "Manage Agents", "ar-AE": "إدارة الوكلاء" },
  _B2BB2CProfileManagement: {
    "en-US": "B2B/B2C Profile Management",
    "ar-AE": "إدارة الملف الشخصي B2B / B2C",
  },
  _reports: { "en-US": "Reports", "ar-AE": "التقارير" },
  _manageContent: { "en-US": "Manage Content", "ar-AE": "إدارة المحتوى" },
  _marketingCenter: { "en-US": "Marketing Center", "ar-AE": "مركز التسويق" },
  _manageSuppliers: { "en-US": "Manage Suppliers", "ar-AE": "إدارة الموردين" },
  _manageEmployees: { "en-US": "Manage Employees", "ar-AE": "إدارة الموظفين" },
  _emailTemplates: { "en-US": "Email Templates", "ar-AE": "قوالب البريد الإلكتروني" },
  _manageRevenue: { "en-US": "Manage Revenue", "ar-AE": "إدارة الإيرادات" },
  _affiliatePortals: { "en-US": "Affiliate Portals", "ar-AE": "البوابات التابعة" },
  _customerClasses: { "en-US": "Customer Classes", "ar-AE": "فئات العملاء" },
  _portalPolicies: { "en-US": "Portal Policies", "ar-AE": "سياسات البوابة" },
  _manageWallets: { "en-US": "Manage Wallets", "ar-AE": "إدارة المحافظ" },
  _settings: { "en-US": "Settings", "ar-AE": "الإعدادات" },
  _corporateBooking: { "en-US": "Corporate Booking", "ar-AE": "حجز الشركات" },
  _deadlineDateHasPassedForBooking: {
    "en-US": "Deadline date has passed for booking(s)",
    "ar-AE": "انقضى الموعد النهائي للحجز (الحجوزات)",
  },
  _todayIsTheDeadlineDateForBooking: {
    "en-US": "Today is the deadline date for booking(s)",
    "ar-AE": "اليوم هو الموعد النهائي للحجز (الحجوزات)",
  },
  _thisBookingDeadlineDateIsUpcoming: {
    "en-US": "This booking(s) deadline date is upcoming.",
    "ar-AE": "الموعد النهائي لهذا الحجز (الحجز) قادم.",
  },
  _eventDate: { "en-US": "Event Date", "ar-AE": "تاريخ الحدث" },
  _createQuotation: { "en-US": "Create Quotation", "ar-AE": "إنشاء عرض أسعار" },
  _manageQuotations: { "en-US": "Manage Quotations", "ar-AE": "إدارة عروض الأسعار" },
  _manageItineraries: { "en-US": "Manage Itineraries", "ar-AE": "إدارة عروض الأسعار" },
  _saveQuotation: { "en-US": "Save Quotation", "ar-AE": "حفظ الاقتباس" },
  _emailQuotation: { "en-US": "Email Quotation", "ar-AE": "اقتباس البريد الإلكتروني" },
  _quotationEmail: { "en-US": "Quotation Email", "ar-AE": "اقتباس البريد الإلكتروني" },
  _sendQuotation: { "en-US": "Send Quotation", "ar-AE": "اقتباس البريد الإلكتروني" },
  _bookQuotation: { "en-US": "Book Quotation", "ar-AE": "اقتباس كتاب" },
  _authorisingUser: { "en-US": "Authorising User", "ar-AE": "تفويض المستخدم" },
  _rqMobileNumber: {
    "en-US": "Please enter mobile number.",
    "ar-AE": "يرجى إدخال رقم الهاتف المحمول.",
  },
  _rqEmail: { "en-US": "Please enter email.", "ar-AE": "يرجى إدخال البريد الإلكتروني." },
  _Userdoesnotexist: { "en-US": "User does not exist", "ar-AE": "المستخدم غير موجود" },
  _nonstop: { "en-US": "non stop", "ar-AE": "بدون توقف", "gu-IN": "કોઈપણ વિરામ વગર" },
  _1stop: { "en-US": "1 stop", "ar-AE": "1 توقف" },
  _2stop: { "en-US": "2 stop", "ar-AE": "2 توقف" },
  _3stop: { "en-US": "3 stop", "ar-AE": "3 توقف" },
  _1stops: { "en-US": "1 stops", "ar-AE": "1 توقف" },
  _2stops: { "en-US": "2 stops", "ar-AE": "2 توقف" },
  _3stops: { "en-US": "3 stops", "ar-AE": "3 توقف" },
  _enterOfferCode: { "en-US": "Enter offer code", "ar-AE": "أدخل رمز العرض" },
  _pleaseEnterPromoCode: {
    "en-US": "Please Enter Promo Code",
    "ar-AE": "يرجى إدخال الرمز الترويجي",
  },
  _yourCommentHasBeenAddedSuccessfully: {
    "en-US": "Your comment has been added successfully.",
    "ar-AE": "لقد تم اضافة تعليقك بنجاح.",
  },
  _supplierAmount: { "en-US": "Supplier Amount", "ar-AE": "مبلغ المورد" },
  _quotationSentSuccessfully: {
    "en-US": "Quotation Sent Successfully !!",
    "ar-AE": "تم إرسال عرض الأسعار بنجاح !!",
  },
  _saveAndSendQuotation: { "en-US": "Save and Send Quotation", "ar-AE": "حفظ وإرسال عرض الأسعار" },
  _continueAsGuest: {
    "en-US": "Continue as guest",
    "ar-AE": "تواصل كضيف",
  },
  _or: {
    "en-US": "OR",
    "ar-AE": "أو",
  },
  _commentBy: { "en-US": "Comment By", "ar-AE": "تعليق" },
  _autoCancel_TotalBookingCountForAdminMsg: {
    "en-US":
      "Kindly cross verify your on hold (Booked and Book now pay later) bookings to avoid any cancellation penalties applicable by supplier.",
    "ar-AE":
      "يرجى التحقق من حجوزاتك المعلقة (محجوزة ودفع الآن في وقت لاحق) حجوزات لتجنب أي عقوبات الإلغاء المطبقة من قبل المورد.",
  },
  _autoCancel_PastDateBookingCountMsg: {
    "en-US": "Bookings passed auto cancellation due date: ##PastDateBookingCount## booking(s).",
    "ar-AE": "اجتازت الحجوزات تاريخ الإلغاء التلقائي: ##PastDateBookingCount## حجز (حجوزات).",
  },
  _autoCancel_TodaysDateBookingCountMsg: {
    "en-US": "Auto Cancellation Due today: ##TodaysDateBookingCount## booking(s).",
    "ar-AE": "إلغاء تلقائي مستحق اليوم: ##TodaysDateBookingCount## حجز.",
  },
  _autoCancel_UpcomingDateBookingCountMsg: {
    "en-US": "Auto Cancellation due in future: ##UpcomingDateBookingCount## booking(s).",
    "ar-AE": "إلغاء تلقائي مستحق في المستقبل: ##UpcomingDateBookingCount## حجز (حجوزات).",
  },
  _autoCancel_NoRecordMessage: {
    "en-US": "No booking(s) due for auto cancellation.",
    "ar-AE": "لا يوجد حجز (حجوزات) بسبب الإلغاء التلقائي.",
  },
  _utcDate: { "en-US": "UTC Date", "ar-AE": "تاريخ UTC" },
  _milesOrFrequentFlyerCardNummber: {
    "en-US": "Miles/Frequent Flyer Card Number",
    "ar-AE": "أميال / رقم بطاقة المسافر الدائم",
  },
  _close: { "en-US": "Close", "ar-AE": "قريب" },
  _uploadedFile: { "en-US": "Uploaded File", "ar-AE": "تم تحميل الملف" },
  _fareBreakup_Discount: { "en-US": "Discount", "ar-AE": "خصم" },
  _forgotPasswordMessageEmail: {
    "en-US": "Sent email. Please check your email.",
    "ar-AE": "البريد الالكتروني المرسل. من فضلك تفقد بريدك الالكتروني.",
  },
  _forgotPasswordMessageSMS: {
    "en-US": "Sent email. Please check your email.",
    "ar-AE": "البريد الالكتروني المرسل. من فضلك تفقد بريدك الالكتروني.",
  },
  _260029: { "en-US": "User already exists", "ar-AE": "المستخدم موجود اصلا" },
  _260031: { "en-US": "Phone number already taken", "ar-AE": "تم أخذ رقم الهاتف بالفعل" },
  _4: { "en-US": "User does not exist", "ar-AE": "المستخدم غير موجود" },
  _bookingTypeAll: { "en-US": "All", "ar-AE": "الكل" },
  _bookingTypeUpcoming: { "en-US": "Upcoming", "ar-AE": "القادمة" },
  _bookingTypeCompleted: { "en-US": "Completed", "ar-AE": "منجز" },
  _bookingTypeCancelled: { "en-US": "Cancelled", "ar-AE": "ألغيت" },
  _bookingTypeOther: { "en-US": "Other", "ar-AE": "آخر" },
  _transactionTypeAll: { "en-US": "All", "ar-AE": "الكل" },
  _transactionTypeRechargeCash: { "en-US": "Recharge - Cash", "ar-AE": "إعادة الشحن - نقدا" },
  _transactionTypeRechargeCheque: { "en-US": "Recharge - Cheque", "ar-AE": "إعادة الشحن - الشيك" },
  _transactionTypeRechargeForBooking: {
    "en-US": "Recharge - For Booking",
    "ar-AE": "إعادة الشحن - للحجز",
  },
  _transactionTypeReservation: { "en-US": "Reservation", "ar-AE": "حجز" },
  _transactionTypeRefund: { "en-US": "Refund", "ar-AE": "إعادة مال" },
  _transactionTypeRechargeOnline: {
    "en-US": "Recharge - Online",
    "ar-AE": "إعادة الشحن - عبر الإنترنت",
  },
  _failedBookingTransactionStatusAll: { "en-US": "All", "ar-AE": "الكل" },
  _failedBookingTransactionStatusDirtyAttempt: { "en-US": "Dirty Attempt", "ar-AE": "محاولة قذرة" },
  _failedBookingTransactionStatusPaymentReceived: {
    "en-US": "Payment Received",
    "ar-AE": "تم استلام الدفعة",
  },
  _failedBookingTransactionStatusPaymentFailure: {
    "en-US": "Payment Failure",
    "ar-AE": "فشل الدفع",
  },
  _failedBookingTransactionStatusReservationServiceSuccess: {
    "en-US": "Reservation Service Success",
    "ar-AE": "نجاح خدمة الحجز",
  },
  _failedBookingTransactionStatusReservationFailure: {
    "en-US": "Reservation Failure",
    "ar-AE": "فشل الحجز",
  },
  _failedBookingTransactionStatusTransactionLoggedSuccess: {
    "en-US": "Transaction Logged Success",
    "ar-AE": "تم تسجيل المعاملة بنجاح",
  },
  _failedBookingTransactionStatusTransactionLoggedFailure: {
    "en-US": "Transaction Logged Failure",
    "ar-AE": "فشل تسجيل المعاملة",
  },
  _failedBookingTransactionStatusTransactionStart: {
    "en-US": "Transaction Start",
    "ar-AE": "بدء المعاملة",
  },
  _failedBookingTransactionStatusRefundFailure: {
    "en-US": "Refund Failure",
    "ar-AE": "فشل رد الأموال",
  },
  _supplierBaseRate: { "en-US": "Supplier Base Rate", "ar-AE": "السعر الأساسي للمورد" },
  _schedule: { "en-US": "Schedule", "ar-AE": "جدول" },
  _lblAddonsAmount: { "en-US": "Add-on(s)", "ar-AE": "الإضافة (الإضافات)" },
  _transportationCompany: {
    "en-US": "Transportation Company",
    "ar-AE": "شركة النقل",
    "gu-IN": "ટ્રાન્સપોર્ટેશન કંપની",
  },
  PT1Desc: {
    "en-US": "Meet and greet",
    "ar-AE": "التقاء و تحية",
    "gu-IN": "મળો અને અભિવાદન કરો",
  },
  PT2Desc: {
    "en-US": "Shuttle bus to car",
    "ar-AE": "حافلة مكوكية للسيارة",
    "gu-IN": "કારમાં શટલ બસ",
  },
  PT3Desc: {
    "en-US": "Car with driver",
    "ar-AE": "سيارة مع سائق",
    "gu-IN": "ડ્રાઇવર સાથે કાર",
  },
  PT4Desc: {
    "en-US": "Terminal rental counter and shuttle bus to car",
    "ar-AE": "عداد تأجير المحطة وحافلات النقل المكوكية إلى السيارة",
    "gu-IN": "કાર માટે ટર્મિનલ ભાડા કાઉન્ટર અને શટલ બસ",
  },
  PT5Desc: {
    "en-US": "Terminal rental counter and pickup",
    "ar-AE": "كاونتر تأجير المحطة والبيك اب",
    "gu-IN": "ટર્મિનલ ભાડા કાઉન્ટર અને દુકાન",
  },
  FB1: {
    "en-US": "Free Additional Driver",
    "ar-AE": "سائق إضافي مجاني",
    "gu-IN": "મફત અતિરિક્ત ડ્રાઈવર",
  },
  FB2: {
    "en-US": "Free Baby Seat",
    "ar-AE": "مقعد مجاني للأطفال",
    "gu-IN": "મફત બેબી સીટ",
  },
  FB3: {
    "en-US": "Free GPS",
    "ar-AE": "GPS مجاني",
    "gu-IN": "મફત જીપીએસ",
  },
  FB4: {
    "en-US": "Free Upgrade",
    "ar-AE": "ترقية مجانية",
    "gu-IN": "મફત અપગ્રેડ",
  },
  FreeCancellationLabel: {
    "en-US": "Free Cancellation",
    "ar-AE": "إلغاء مجاني",
    "gu-IN": "નિ: શુલ્ક રદ",
  },
  FreeCancellation: {
    "en-US":
      "Secure your booking now and if you change your mind you have ##CancellationDay## days to cancel for FREE",
    "ar-AE": "أيام للإلغاء مجانًا##CancellationDay## قم بتأمين حجزك الآن وإذا غيرت رأيك فعليك بذلك",
    "gu-IN":
      "તમારી બુકિંગને હમણાં સુરક્ષિત કરો અને જો તમે તમારો વિચાર બદલો છો ##CancellationDay## મફત માટે રદ કરવા માટે દિવસ",
  },
  FreeAmendmentLabel: {
    "en-US": "Free Amendment",
    "ar-AE": "تعديل مجاني",
    "gu-IN": "મફત સુધારો",
  },
  FreeAmendment: {
    "en-US": "We don't charge any fees for changing your reservation.",
    "ar-AE": "نحن لا نفرض أي رسوم لتغيير الحجز الخاص بك.",
    "gu-IN": "અમે તમારા અનામતને બદલવા માટે કોઈ ફી લેતા નથી.",
  },
  FreeMileageLabel: {
    "en-US": "Unlimited mileage",
    "ar-AE": "غير محدود من الأميال",
    "gu-IN": "અમર્યાદિત માઇલેજ",
  },
  MileageLabel: {
    "en-US": "This rental includes FREE unlimited mileage.",
    "ar-AE": "يشمل هذا الإيجار أميالاً مجانية غير محدودة.",
    "gu-IN": "આ ભાડામાં મફત અમર્યાદિત માઇલેજ શામેલ છે.",
  },
  DieselVehLabel: {
    "en-US": "Diesel Vehicle",
    "ar-AE": "سيارة ديزل",
    "gu-IN": "ડીઝલ વાહન",
  },
  FP1: {
    "en-US": "Fuel : Pickup Full – Return Full",
    "ar-AE": "الوقود: بيك اب ممتلئ - رجوع ممتلئ",
    "gu-IN": "બળતણ: દુકાન પૂર્ણ - સંપૂર્ણ પાછા ફરો",
  },
  FP1Desc: {
    "en-US":
      "Your vehicle will be supplied with a full tank of fuel and must be returned full. Missing fuel will be charged on your return. (Suppliers fuel policies change from time to time, please confirm at rental counter) Fuel charges are payable at the rental desk",
    "ar-AE":
      "سيتم تزويد سيارتك بخزان وقود ممتلئ ويجب إعادتها ممتلئة. سيتم احتساب الوقود المفقود عند عودتك. (تتغير سياسات وقود الموردين من وقت لآخر ، يرجى التأكيد عند مكتب التأجير) يتم دفع رسوم الوقود في مكتب التأجير",
    "gu-IN":
      "તમારું વાહન ઇંધણની સંપૂર્ણ ટાંકી સાથે પૂરું પાડવામાં આવશે અને સંપૂર્ણ પરત આપવું આવશ્યક છે. ગુમ થયેલ બળતણ તમારા વળતર પર લેવામાં આવશે. (સપ્લાયર્સ બળતણ નીતિઓ સમયે સમયે બદલાય છે, કૃપા કરીને ભાડાની કાઉન્ટર પર પુષ્ટિ કરો) ભાડા ડેસ્ક પર બળતણ શુલ્ક ચૂકવવાપાત્ર છે",
  },
  FP2: {
    "en-US": "Fuel : Pickup Same – Return Same",
    "ar-AE": "الوقود: بيك آب نفس - إرجاع نفس الشيء",
    "gu-IN": "બળતણ: દુકાન સમાન - પાછા આવો",
  },
  FP2Desc: {
    "en-US": "Your vehicle will be supplied with a full tank of fuel and must be returned full.",
    "ar-AE": "سيتم تزويد سيارتك بخزان وقود ممتلئ ويجب إعادتها ممتلئة.",
    "gu-IN":
      "તમારું વાહન ઇંધણની સંપૂર્ણ ટાંકી સાથે પૂરું પાડવામાં આવશે અને સંપૂર્ણ પરત આપવું આવશ્યક છે.",
  },
  FP3: {
    "en-US": "Fuel : Pickup Empty – Return Empty",
    "ar-AE": "الوقود: بيك أب فارغ - إرجاع فارغ",
    "gu-IN": "બળતણ: દુકાન ખાલી - ખાલી",
  },
  FP3Desc: {
    "en-US":
      "Your vehicle will be supplied with a empty tank of fuel and can be returned empty. (Suppliers fuel policies change from time to time, please confirm at rental counter) Fuel charges are payable at the rental desk unless otherwise stated in the section of r",
    "ar-AE":
      "سيتم تزويد سيارتك بخزان وقود فارغ ويمكن إعادته فارغة. (تتغير سياسات وقود الموردين من وقت لآخر ، يرجى التأكيد عند مكتب التأجير) تُدفع رسوم الوقود في مكتب التأجير ما لم يُنص على خلاف ذلك في قسم r",
    "gu-IN":
      "તમારા વાહનને બળતણની ખાલી ટાંકી આપવામાં આવશે અને ખાલી પરત કરી શકાય છે. (સપ્લાયર્સ ઇંધણ નીતિઓ સમયે સમયે બદલાય છે, કૃપા કરીને ભાડાની કાઉન્ટર પર પુષ્ટિ કરો) સિવાય કે આર ના વિભાગમાં જણાવ્યું ન હોય ત્યાં સુધી બળતણ ચાર્જ ભાડા ડેસ્ક પર ચૂકવવાપાત્ર છે.",
  },
  FP4: {
    "en-US": "Fuel : Pickup Full – Return Empty",
    "ar-AE": "الوقود: بيك اب ممتلئ - إرجاع فارغ",
    "gu-IN": "બળતણ: દુકાન પૂર્ણ - ખાલી",
  },
  FP4Desc: {
    "en-US":
      "Your vehicle will be supplied with a full tank of fuel and can be returned empty. Pick up fuel will be charged at rental desk.  (Suppliers fuel policies change from time to time, please confirm at rental counter) Fuel charges are payable at the rental des",
    "ar-AE":
      "سيتم تزويد سيارتك بخزان وقود ممتلئ ويمكن إعادتها فارغة. سيتم تحصيل رسوم الوقود من مكتب التأجير. (تتغير سياسات وقود الموردين من وقت لآخر ، يرجى التأكيد عند مكتب التأجير) يتم دفع رسوم الوقود في مكتب الإيجار",
    "gu-IN":
      "તમારા વાહનને બળતણની સંપૂર્ણ ટાંકી આપવામાં આવશે અને ખાલી પરત કરી શકાય છે. અપ ઇંધણ ભાડા ડેસ્ક પર લેવામાં આવશે. (સપ્લાયર્સ ઇંધણ નીતિઓ સમય-સમય પર બદલાય છે, કૃપા કરીને ભાડાની કાઉન્ટર પર પુષ્ટિ કરો) ફ્યુઅલ ચાર્જ ભાડા ડેસ પર ચૂકવવાપાત્ર છે",
  },
  FP5: {
    "en-US": "Fuel : Pickup Half – Return Empty",
    "ar-AE": "الوقود: نصف بيك أب - إرجاع فارغ",
    "gu-IN": "બળતણ: પીકઅપ હાફ - ખાલી ખાલી",
  },
  FP5Desc: {
    "en-US":
      "Your vehicle will be supplied with a half full tank of fuel and can be returned empty. Pick up fuel will be charged at rental desk. (Suppliers fuel policies change from time to time, please confirm at rental counter) Fuel charges are payable at the rental",
    "ar-AE":
      "سيتم تزويد سيارتك بنصف خزان وقود ممتلئ ويمكن إعادتها فارغة. سيتم تحصيل رسوم الوقود من مكتب التأجير. (تتغير سياسات وقود الموردين من وقت لآخر ، يرجى التأكيد عند مكتب التأجير) يتم دفع رسوم الوقود في الإيجار",
    "gu-IN":
      "તમારા વાહનને બળતણની અડધી પૂર્ણ ટાંકી આપવામાં આવશે અને ખાલી પરત કરી શકાય છે. અપ ઇંધણ ભાડા ડેસ્ક પર લેવામાં આવશે. (સપ્લાયર્સ ઇંધણ નીતિઓ સમયે સમયે બદલાય છે, કૃપા કરીને ભાડાની કાઉન્ટર પર પુષ્ટિ કરો) ઇંધણ ચાર્જ ભાડા પર ચૂકવવાપાત્ર છે",
  },
  FP6: {
    "en-US": "Fuel : Pickup Quarter Full - Return Empty",
    "ar-AE": "الوقود: ربع بيك اب ممتلئ - إرجاع فارغ",
    "gu-IN": "બળતણ: દુકાન ક્વાર્ટર પૂર્ણ - ખાલી રીટર્ન",
  },
  FP6Desc: {
    "en-US":
      "Your vehicle will be supplied with a quarter full tank of fuel and can be returned empty. Pick up fuel will be charged at rental desk. (Suppliers fuel policies change from time to time, please confirm at rental counter) Fuel charges are payable at the ren",
    "ar-AE":
      "سيتم تزويد سيارتك بربع مليء بخزان الوقود ويمكن إعادتها فارغة. سيتم تحصيل رسوم الوقود من مكتب التأجير. (تتغير سياسات وقود الموردين من وقت لآخر ، يرجى التأكيد عند مكتب الإيجار) رسوم الوقود واجبة الدفع في رين",
    "gu-IN":
      "તમારું વાહન ઇંધણની ક્વાર્ટરની સંપૂર્ણ ટાંકી સાથે પૂરા પાડવામાં આવશે અને ખાલી પરત કરી શકાય છે. અપ ઇંધણ ભાડા ડેસ્ક પર લેવામાં આવશે. (સપ્લાયર્સ ઇંધણ નીતિઓ સમય-સમય પર બદલાય છે, કૃપા કરીને ભાડાની કાઉન્ટર પર પુષ્ટિ કરો) ફ્યુઅલ ચાર્જ ભાડે ચૂકવવાપાત્ર છે",
  },
  FP7: {
    "en-US": "Fuel : Pickup Half – Return Half",
    "ar-AE": "الوقود: نصف بيك أب - نصف عودة",
    "gu-IN": "બળતણ: પીકઅપ હાફ - રીટર્ન હાફ",
  },
  FP7Desc: {
    "en-US":
      "Your vehicle will be supplied with a half full tank of fuel and must be returned half full. Missing fuel will be charged on your return. (Suppliers fuel policies change from time to time, please confirm at rental counter) Fuel charges are payable at the r",
    "ar-AE":
      "سيتم تزويد سيارتك بنصف خزان وقود ممتلئ ويجب إعادتها نصف ممتلئة. سيتم احتساب الوقود المفقود عند عودتك. (تتغير سياسات وقود الموردين من وقت لآخر ، يرجى التأكيد عند مكتب الإيجار) يتم دفع رسوم الوقود في r",
    "gu-IN":
      "તમારું વાહન ઇંધણની અડધી પૂર્ણ ટાંકી સાથે પૂરા પાડવામાં આવશે અને અડધો સંપૂર્ણ પરત આપવો આવશ્યક છે. ગુમ થયેલ બળતણ તમારા વળતર પર લેવામાં આવશે. (સપ્લાયર્સ ઇંધણ નીતિઓ સમયે સમયે બદલાય છે, કૃપા કરીને ભાડાની કાઉન્ટર પર પુષ્ટિ કરો) ઇંધણ ચાર્જ આર પર ચૂકવવાપાત્ર છે",
  },
  FP8: {
    "en-US": "Fuel : Pickup Quarter Full - Return Quarter Full",
    "ar-AE": "الوقود: ربع بيك اب ممتلئ - ربع عودة ممتلئ",
    "gu-IN": "બળતણ: પીકઅપ ક્વાર્ટર પૂર્ણ - રીટર્ન ક્વાર્ટર પૂર્ણ",
  },
  FP8Desc: {
    "en-US":
      "Your vehicle will be supplied with a quarter full tank of fuel and must be returned quarter full. Missing fuel will be charged on your return. (Suppliers fuel policies change from time to time, please confirm at rental counter) Fuel charges are payable at Vehicle",
    "ar-AE":
      "سيتم تزويد سيارتك بربع مليء بخزان الوقود ويجب إعادتها ربع ممتلئة. سيتم احتساب الوقود المفقود عند عودتك. (تتغير سياسات وقود الموردين من وقت لآخر ، يرجى التأكيد عند مكتب الإيجار) يتم دفع رسوم الوقود في السيارة",
    "gu-IN":
      "તમારું વાહન બળતણની ક્વાર્ટરની સંપૂર્ણ ટાંકી સાથે પૂરા પાડવામાં આવશે અને ક્વાર્ટર પૂર્ણ ભરેલું હોવું આવશ્યક છે. ગુમ થયેલ બળતણ તમારા વળતર પર લેવામાં આવશે. (સપ્લાયર્સ ઇંધણ નીતિઓ સમયે સમયે બદલાય છે, કૃપા કરીને ભાડાની કાઉન્ટર પર પુષ્ટિ કરો) વાહન પર બળતણ શુલ્ક ચૂકવવાપાત્ર છે",
  },
  RentalConditionslabel: {
    "en-US": "Rental Conditions",
    "ar-AE": "شروط الإيجار",
    "gu-IN": "ભાડાની શરતો",
  },
  SelectCarlabel: {
    "en-US": "Select Car",
    "ar-AE": "حدد السيارة",
    "gu-IN": "કાર પસંદ કરો",
  },
  ExtrasvehicleMsg: {
    "en-US": "Extras are paid on car pickup",
    "ar-AE": "يتم دفع الإضافات عند استلام السيارة",
    "gu-IN": "કાર પસંદ કરોકાર પીકઅપ પર વધારાની રકમ ચૂકવવામાં આવે છે",
  },
  NoAdditionalServiceFoundMsg: {
    "en-US": "No additional services found",
    "ar-AE": "لم يتم العثور على خدمات إضافية",
    "gu-IN": "કોઈ વધારાની સેવાઓ મળી નથી",
  },
  NoExtrasvehicleMsg: {
    "en-US": "There are no add ons available for this rental if you are in need of additional equipment, please ask upon arrival or choose another option, otherwise please click Book Now to continue.",
    "ar-AE": "لا توجد إضافات متاحة لهذا الإيجار إذا كنت بحاجة إلى معدات إضافية ، يرجى السؤال عند الوصول أو تحديد خيار آخر ، وإلا يرجى النقر فوق احجز الآن للمتابعة.",
    "gu-IN": "જો તમને વધારાના ઉપકરણોની જરૂર હોય તો આ ભાડા માટે કોઈ એડ needન્સ ઉપલબ્ધ નથી, કૃપા કરીને આગમન પર પૂછો અથવા બીજો વિકલ્પ પસંદ કરો, નહીં તો ચાલુ રાખવા માટે બુક નાઉ ક્લિક કરો.",
  },
  Extras: {
    "en-US": "Extras",
    "ar-AE": "إضافات",
    "gu-IN": "એક્સ્ટ્રાઝ",
  },
  ItemDescriptionLabel: {
    "en-US": "Item Description",
    "ar-AE": "وصف السلعة",
    "gu-IN": "આઇટમ વર્ણન",
  },
  Quantity: {
    "en-US": "Quantity",
    "ar-AE": "كمية",
    "gu-IN": "જથ્થો",
  },
  Addlabel: {
    "en-US": "Add",
    "ar-AE": "أضف",
    "gu-IN": "ઉમેરો",
  },
  _noMatchesFoundvehicle: {
    "en-US": "No inventory available at this location.",
    "ar-AE": "غير المتوفر في هذا الموقع.",
    "gu-IN": "આ સ્થાન પર કોઈ ઇન્વેન્ટરી ઉપલબ્ધ નથી.",
  },
  PriceIncludesLabel: {
    "en-US": "Price Includes:",
    "ar-AE": "ويشمل السعر:",
    "gu-IN": "ભાવ શામેલ છે:",
  },
  CarGroupLabel: {
    "en-US": "Car Group:",
    "ar-AE": "مجموعة السيارات:",
    "gu-IN": "કાર જૂથ:",
  },
  Time: {
    "en-US": "Time",
    "ar-AE": "زمن",
    "gu-IN": "સમય",
  },
  CarDetails: {
    "en-US": "Car Details",
    "ar-AE": "تفاصيل السيارة",
    "gu-IN": "કાર વિગતો",
  },
  CarCategory: {
    "en-US": "Car Category",
    "ar-AE": "فئة السيارة",
    "gu-IN": "કાર કેટેગરી",
  },
  CarProvider: {
    "en-US": "Car Provider",
    "ar-AE": "مزود السيارة",
    "gu-IN": "કાર પ્રદાતા",
  },
  CarName: {
    "en-US": "Car Name",
    "ar-AE": "اسم السيارة",
    "gu-IN": "કારનું નામ",
  },
  _widgetAgeofDriverNote: {
    "en-US": "Driver age should be over 18 years.",
    "ar-AE": "يجب أن يكون عمر السائق أكثر من 18 عامًا.",
    "gu-IN": "ડ્રાઇવરની ઉંમર 18 વર્ષથી વધુ હોવી જોઈએ.",
  },
  _Free: {
    "en-US": "Free",
    "ar-AE": "مجانا",
    "gu-IN": "મફત",
  },
  _morelabel: {
    "en-US": "more",
    "ar-AE": "أكثر",
    "gu-IN": "વધુ",
  },
  GetPromotionofferslabel: {
    "en-US": "Get Promotion offers",
    "ar-AE": "احصل على عروض ترويجية",
    "gu-IN": "પ્રમોશન ઓફર મેળવો",
  },
  PromotionCodelabel: {
    "en-US": "Promotion Code",
    "ar-AE": "شفرة الترقية",
    "gu-IN": "પ્રમોશન કોડ",
  },
  noPromotionOffersFound: {
    "en-US": "No promotion offers found",
    "ar-AE": "لم يتم العثور على عروض ترويجية",
    "gu-IN": "કોઈ પ્રમોશન ઓફર મળી નથી",
  },
  _countryofresidence: {
    "en-US": "Country of Residence",
    "ar-AE": "بلد الإقامة",
    "gu-IN": "રેહ્ઠાણ નો દેશ",
  },
  _Description: {
    "en-US": "Description:",
    "ar-AE": "وصف",
    "gu-IN": "વર્ણન",
  },
  _Days: {
    "en-US": "Day(s)",
    "ar-AE": "أيام",
    "gu-IN": "દિવસ",
  },
  _service: {
    "en-US": "Service",
    "ar-AE": "الخدمات",
    "gu-IN": "સેવા",
  },
  _widgetgroundserviceMax: {
    "en-US": "Max.",
    "ar-AE": "ماكس.",
    "gu-IN": "મહત્તમ. 10",
  },
  _error_customer_insufficientBalance: {
    "en-US": "Customer have insufficient balance.",
    "ar-AE": "العميل لديه رصيد غير كاف.",
    "gu-IN": "ગ્રાહક પાસે અપૂરતું બેલેન્સ છે.",
  },
  _error_maximumcredit_limit: {
    "en-US": "Maximum credit limit must be greater than 0",
    "ar-AE": "يجب أن يكون الحد الأقصى للائتمان أكبر من 0",
    "gu-IN": "મહત્તમ ક્રેડિટ મર્યાદા 0 કરતા વધારે હોવી આવશ્યક છે",
  },
  _error_maximumcredit_limit_numeric: {
    "en-US": "Enter numeric value only",
    "ar-AE": "أدخل قيمة رقمية فقط",
    "gu-IN": "ફક્ત સંખ્યાત્મક મૂલ્ય દાખલ કરો",
  },
  _error_servicetaxregnumber_alpha_space: {
    "en-US": "Enter valid service tax registration number.",
    "ar-AE": "أدخل رقم تسجيل ضريبة خدمة صالح.",
    "gu-IN": "માન્ય સેવા કર નોંધણી નંબર દાખલ કરો.",
  },
  _label_allowcheckbal: {
    "en-US": "Allow check balance",
    "ar-AE": "السماح برصيد الشيك",
    "gu-IN": "ચેક બેલેન્સને મંજૂરી આપો",
  },
  _label_maxcreeditlimitwithstar: {
    "en-US": "Maximum Credit Limit *",
    "ar-AE": "حد الائتمان الأقصى *",
    "gu-IN": "મહત્તમ ક્રેડિટ મર્યાદા *",
  },
  _label_servicetaxregnumber: {
    "en-US": "Service Tax Registration Number",
    "ar-AE": "رقم تسجيل ضريبة الخدمة",
    "gu-IN": "સેવા કરવેરા નોંધણી નંબર",
  },
  _note_maxcreditandbal: {
    "en-US": "Note: Balance and Maximum Credit limit amount displayed here are in ##symbol## currency.",
    "ar-AE": "ملاحظة: الرصيد والحد الأقصى لمبلغ الائتمان المعروضين هنا ##symbol## عملة.",
    "gu-IN": "નોંધ: અહીં પ્રદર્શિત બેલેન્સ અને મહત્તમ ક્રેડિટ મર્યાદા રકમ ##symbol## ચલણમાં છે.",
  },
  _error_maxcreditlimit_require: {
    "en-US": "Maximum Credit Limit required",
    "ar-AE": "الحد الأقصى للائتمان المطلوب",
    "gu-IN": "મહત્તમ ક્રેડિટ મર્યાદા આવશ્યક છે",
  },
  _mutamerVisaFee: {
    "en-US": "Mutamer Visa Fee",
    "ar-AE": "رسوم تأشيرة المعتمد",
    "gu-IN": "",
  },
  _completeVisaProcess: {
    "en-US": "Complete Visa Process",
    "ar-AE": "استكمال إجراءات التأشيرة",
    "gu-IN": "",
  },
  _umrahPackageMessageOnCartPage1: {
    "en-US": "Valid Passports for All Travelling Passengers is Mandatory. We only accept passport for Umrah Visa.",
    "ar-AE": "جواز السفر ساري المفعول إلزامي لجميع المسافرين نقبل فقط جواز السفر لتأشيرة العمرة.",
    "gu-IN": "",
  },
  _umrahPackageMessageOnCartPage2: {
    "en-US": "Your passport should be valid for 6 months from the date of travel.",
    "ar-AE": "يجب أن يكون جواز سفرك صالحًا لمدة 6 أشهر من تاريخ السفر.",
    "gu-IN": "",
  },
  _umrahPackageMessageOnCartPage3: {
    "en-US": "Passport details are mandatory for issuing tickets to the US and Canada, and in some cases Europe as well.",
    "ar-AE": "تفاصيل جواز السفر إلزامية لإصدار التذاكر إلى الولايات المتحدة وكندا ، وفي بعض الحالات إلى أوروبا أيضًا.",
    "gu-IN": "",
  },
  _umrahPackageMessageOnCartPage4: {
    "en-US": "Some airlines require passport details to issue tickets. We recommend you share your passport detail to quicken the ticketing process. If your passport details aren't handy on you, we'll arrange a call back to take the details as fares are subject to change.",
    "ar-AE": "تطلب بعض شركات الطيران تفاصيل جواز السفر لإصدار التذاكر. نوصيك بمشاركة تفاصيل جواز سفرك لتسريع عملية إصدار التذاكر. إذا لم تكن تفاصيل جواز سفرك في متناول يدك ، فسنرتب اتصالاً مرة أخرى للحصول على التفاصيل حيث أن الأسعار عرضة للتغيير.",
    "gu-IN": "",
  },
  _umrahPackageMessageOnCartPage5: {
    "en-US": "Please ensure that the names of all passengers match with their travel document.",
    "ar-AE": "يرجى التأكد من تطابق أسماء جميع الركاب مع وثيقة السفر الخاصة بهم.",
    "gu-IN": "",
  },
  _mutamerName: {
    "en-US": "Mutamer Name",
    "ar-AE": "رسوم تأشيرة المعتمد",
    "gu-IN": "",
  },
  _visaInformation: {
    "en-US": "Visa Information",
    "ar-AE": "",
    "gu-IN": "",
  },
  _umrahReservation: {
    "en-US": "Umrah Reservation",
    "ar-AE": "",
    "gu-IN": "",
  },
  _umrahpackagearrivalairportcode: {
    "en-US": "Arrival Airport Code",
    "ar-AE": "",
    "gu-IN": "",
  },
  _umrahpackagearrivalflightnumber: {
    "en-US": "Arrival Flight Number",
    "ar-AE": "",
    "gu-IN": "",
  },
  _umrahpackagearrivaldate: {
    "en-US": "Arrival Date And Time",
    "ar-AE": "",
    "gu-IN": "",
  },
  _umrahpackagearrivaltime: {
    "en-US": "Arrival Time",
    "ar-AE": "",
    "gu-IN": "",
  },
  _umrahpackagedepartureairportcode: {
    "en-US": "Departure Airport Code",
    "ar-AE": "",
    "gu-IN": "",
  },
  _umrahpackagedepartureflightnumber: {
    "en-US": "Departure Flight Number",
    "ar-AE": "",
    "gu-IN": "",
  },
  _umrahpackagedeparturedate: {
    "en-US": "Departure Date And Time",
    "ar-AE": "",
    "gu-IN": "",
  },
  _umrahpackagedeparturetime: {
    "en-US": "Departure Time",
    "ar-AE": "",
    "gu-IN": "",
  },
  _error_arrivalairportcode_require: {
    "en-US": "Please selecte airport code for arrival trip",
    "ar-AE": "",
    "gu-IN": "",
  },
  _error_arrivalflightnumber_require: {
    "en-US": "Please selecte flight number for arrival trip",
    "ar-AE": "",
    "gu-IN": "",
  },
  _error_arrivaldate_require: {
    "en-US": "Please selecte arrival date and time for arrival trip",
    "ar-AE": "",
    "gu-IN": "",
  },
  _error_departureairportcode_require: {
    "en-US": "Please selecte airport code for departure trip",
    "ar-AE": "",
    "gu-IN": "",
  },
  _error_departureflightnumber_require: {
    "en-US": "Please selecte flight number for departure trip",
    "ar-AE": "",
    "gu-IN": "",
  },
  _error_departuredate_require: {
    "en-US": "Please selecte departure date and time for departure trip",
    "ar-AE": "",
    "gu-IN": "",
  },
  _MandatoryFeePolicyLineLabel: {
    "en-US": "I agree to Pay Mandatory Fee Policy For",
    "ar-AE": "أوافق على دفع سياسة الرسوم الإلزامية لـ",
    "gu-IN": "હું ફરજિયાત ફી નીતિ માટે ચૂકવણી માટે સંમત છું",
  },
  _airTripDirection_multidestination: {
    "en-US": "Multi destination",
    "ar-AE": "Multi destination",
    "gu-IN": "Multi destination",
  },
  _widgetAirChangeLocationAndDates: {
    "en-US": "Change Location And Dates",
    "ar-AE": "Change Location And Dates",
    "gu-IN": "Change Location And Dates",
  },
};

