import React, { Component } from "react";
import ViewBookingLoading from "../components/loading/view-booking-loading";
import { Trans } from "../helpers/translate";
import HtmlParser from "../helpers/html-parser";
import { apiRequester } from "../services/requester";
import ReactToPrint from "react-to-print";
import Form from "../components/common/form";
import SVGIcon from "../helpers/svg-icon";

class VoucherInvoice extends Form {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isError: false,
      results: null,
      isEmailSent: null,
      data: {
        emailTo: "",
        emailCC: ""
      },
      errors: {}
    };
  }

  getInvoiceData = () => {
    let reqURL = "api/v1/mybookings/invoice";
    let reqOBJ = null;

    if (this.props.match)
      reqOBJ = {
        Request: {
          itineraryID: this.props.match.params.itineraryid,
          bookingID: this.props.match.params.bookingid,
          businessShortDescription: this.props.match.params.businessName.toLowerCase(),
          isvoucher: this.props.match.params.mode === "voucher"
        }
      };
    else
      reqOBJ = {
        Request: {
          itineraryID: this.props.itineraryid,
          bookingID: this.props.bookingid,
          businessShortDescription: (this.props.businessName.toLowerCase() === "ground service" || this.props.businessName.toLowerCase() === "groundservice") ? "ground service" : this.props.businessName.toLowerCase(),
          isvoucher: this.props.mode === "voucher"
        }
      };

    apiRequester(
      reqURL,
      reqOBJ,
      function (data) {
        if (data.status.code === 0) {
          this.setState({
            //results: data.response,
            results:
              data.response.indexOf("<body") > -1
                ? /<body.*?>([\s\S]*)<\/body>/.exec(data.response)[1]
                : data.response,
            isLoading: false,
            isError: false
          });
        } else
          this.setState({
            isLoading: false,
            isError: true
          });
      }.bind(this)
    );
  };

  handleSubmit = () => {
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.handleSendEmail();
  };

  validate = () => {
    const errors = {};
    const { data } = this.state;
    if (!this.validateFormData(data.emailTo, "require"))
      errors.emailTo = Trans("_error_emailTo_require");
    else if (!this.validateFormData(data.emailTo, "email"))
      errors.emailTo = Trans("_error_emailTo_email");

    if (data.emailCC !== "") {
      let isValidemailCC = true;
      data.emailCC.split(",").forEach((item, index) => {
        if (!this.validateFormData(item, "email")) isValidemailCC = false;
      });
      if (!isValidemailCC) errors.emailCC = Trans("_error_emailCC_email");
    }
    return Object.keys(errors).length === 0 ? null : errors;
  };

  handleSendEmail = () => {
    this.setState({
      isEmailSent: null
    });
    let reqURL = "api/v1/mybookings/invoice/notification";
    let reqOBJ = null;
    if (this.props.match)
      reqOBJ = {
        Request: {
          itineraryID: this.props.match.params.itineraryid,
          bookingID: this.props.match.params.bookingid,
          businessShortDescription: this.props.match.params.businessName.toLowerCase(),
          isvoucher: this.props.match.params.mode === "voucher",
          email: this.state.data.emailTo,
          ccEmail: this.state.data.emailCC
        }
      };
    else
      reqOBJ = {
        Request: {
          itineraryID: this.props.itineraryid,
          bookingID: this.props.bookingid,
          businessShortDescription: this.props.businessName.toLowerCase(),
          isvoucher: this.props.mode === "voucher",
          email: this.state.data.emailTo,
          ccEmail: this.state.data.emailCC
        }
      };

    apiRequester(
      reqURL,
      reqOBJ,
      function (data) {
        if (data.status.code === 0) {
          this.setState({
            isEmailSent: true,
            data: {
              emailTo: "",
              emailCC: ""
            }
          });
        } else
          this.setState({
            isEmailSent: false,
            data: {
              emailTo: "",
              emailCC: ""
            }
          });
      }.bind(this)
    );
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getInvoiceData();
  }

  render() {
    return (
      <div>
        {!this.state.isLoading && !this.state.results && (
          <div className="alert alert-secondary m-5" role="alert">
            <h4 className="alert-heading">{Trans("_ooops")}</h4>
            <p className="mt-3">
              {Trans("_ooopsSomeThingWentWrongAfterBooking")}
            </p>
          </div>
        )}
        <div className="container">
          {!this.state.isLoading && this.state.results && (
            <div className="mt-3">
              <VoucherInvoiceContent
                results={this.state.results}
                ref={el => (this.componentRef = el)}
              />

              <div className="container">
                <div className="row mt-5">
                  <div className="col-2 text-right">
                    <div>{Trans("_emailTo")} :</div>
                  </div>
                  <div className="col-4 text-left">
                    {this.renderInput("emailTo", "")}
                  </div>
                </div>
                <div className="row">
                  <div className="col-2 text-right">
                    <div>{Trans("_emailCC")} :</div>
                  </div>
                  <div className="col-4 text-left">
                    {this.renderTextarea("emailCC", "")}
                    <span>
                      {"(" + Trans("_sendEmailTemplateMessage") + ")"}
                    </span>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-2 text-right">
                    <ReactToPrint
                      trigger={() => (
                        <button className="btn btn-primary btn-sm ml-2 pull-right">
                          <SVGIcon name="print" className="mr-1"></SVGIcon>
                          {Trans("_btnPrint")}
                        </button>
                      )}
                      content={() => this.componentRef}
                    />
                  </div>
                  <div className="col-3">
                    <button
                      className="btn btn-primary btn-sm pull-left"
                      onClick={this.handleSubmit}
                    >
                      {Trans("_sendEmail")}
                    </button>
                  </div>
                </div>
                {this.state.isEmailSent !== null && (
                  <div className="row mt-3">
                    <div className="col-2 text-right"></div>
                    <div className="col-3">
                      <small
                        className={
                          "alert mt-2 p-1 d-inline-block " +
                          (this.state.isEmailSent
                            ? "alert-success"
                            : "alert-danger ")
                        }
                      >
                        {this.state.isEmailSent
                          ? Trans("_emailSentSucccessfully")
                          : Trans("_ooopsSomeThingWentWrongAfterBooking")}
                      </small>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {this.state.isLoading && (
            <div className="mt-3">
              <ViewBookingLoading />
            </div>
          )}
        </div>
      </div>
    );
  }
}

class VoucherInvoiceContent extends Component {
  render() {
    return <HtmlParser text={this.props.results} />;
  }
}

export default VoucherInvoice;
