import React from "react";
import SVGIcon from "../../helpers/svg-icon";
import Date from "../../helpers/date";
import AuthorizeComponent from "../common/authorize-component";

const Quotationinfo = (props) => {
  return (
    <div className="quotation-info border bg-light d-flex p-2">
      <div className="mr-auto d-flex align-items-center">
        <SVGIcon
          name={"user-circle"}
          className="mr-2 d-flex align-items-center"
          width="22"
          type="fill"
        ></SVGIcon>
        <h6 className="font-weight-bold m-0 p-0">
          {props.type === "Quotation" ? "Quotation" : "Itinerary"} for {props.customerName} -{" "}
          {props.title}
          {props.type === "Itinerary" && (
            <React.Fragment>
              {" - "}
              <Date date={props.startDate} />
              {" to "}
              <Date date={props.endDate} />
            </React.Fragment>
          )}
        </h6>
      </div>
      <AuthorizeComponent title={"itinerary-details~edit-" + props.type.toLowerCase()} type="button">
        <button className="btn p-0 m-0" onClick={props.handleEdit}>
          <SVGIcon name={"pencil-alt"} className="mr-2" width="16" type="fill"></SVGIcon>
        </button>
      </AuthorizeComponent>
    </div>
  );
};

export default Quotationinfo;
