var appPath = "https://pixabay.com/api/?key=18872431-a70ed61d87c4eeb3181b7308c";

// API Requester for Getting Location Image
export function apiRequester_LocationImage(reqURL, callback) {
  var xhttp;
  xhttp = new XMLHttpRequest();
  xhttp.open("POST", appPath + "&q=" + reqURL + "&image_type=photo&pretty=true", true);
  xhttp.send();
  xhttp.onreadystatechange = function () {
    if (this.readyState === 4 && this.status === 200) {
      callback(this.responseText ? JSON.parse(this.responseText) : null);
    }
  };
}
