import React, { Component } from "react";
import { apiRequester } from "../services/requester";
import { apiRequester_quotation_api } from "../services/requester-quotation";
import SVGIcon from "../helpers/svg-icon";
import QuotationCreate from "../components/quotation/quotation-create";
import Quotationinfo from "../components/quotation/quotation-info";
import QuotationResults from "../components/quotation/quotation-results";
import QuotationDetails from "../components/quotation/quotation-details";
import QuotationSearch from "../components/quotation/quotation-search";
import QuotationEmail from "../components/quotation/quotation-email";
import { Trans } from "../helpers/translate";
import ItineraryDetails from "../components/quotation/itinerary-details";
import ItineraryEmail from "../components/quotation/itinerary-email";
import ItineraryAddOffline from "../components/quotation/itinerary-add-offline";
import ComingSoon from "../helpers/coming-soon";
import { getItem } from "../components/quotation/quotation-get-cart-item";
import moment from "moment";
import QuotationResultsMeta from "../components/quotation/quotation-results-meta";
import QuotationResultsMetaAir from "../components/quotation/quotation-results-meta-air";
import QuotationResultsMetaActivity from "../components/quotation/quotation-results-meta-activity";
import QuotationResultsMetaAirRoundTrip from "../components/quotation/quotation-results-meta-air-roundtrip";
import * as Global from "../helpers/global";
import AuthorizeComponent from "../components/common/authorize-component";

class Quotation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      businessName: "",
      isResults: false,
      resultKey: 1,
      items:
        localStorage.getItem("quotationItems") &&
          localStorage.getItem("quotationItems") !== "undefined"
          ? JSON.parse(localStorage.getItem("quotationItems")) &&
          JSON.parse(localStorage.getItem("quotationItems"))
          : [],
      isEmail: false,
      detailsKey: 1,
      isSaveSucessMsg: false,
      isBtnLoading: false,
      isBtnLoadingBookItinerary: false,
      type: this.props.match.path.split("/").includes("Itinerary")
        ? "Itinerary"
        : "Quotation",
      importItem: false,
      importItemKey: 1,
      isShowSearch: true,
      comingsoon: false,
      bookingItemCount: 0,
      isMetaResults: false,
      isErrorMsg: "",
    };
  }

  generateQuotation = (data) => {
    let inquiryDetails = JSON.parse(localStorage.getItem("quotationDetails"));
    let isfromInquiry = inquiryDetails?.from;

    if (isfromInquiry) {
      data.from = inquiryDetails.from;
      data.month = inquiryDetails.month;
      data.typetheme = inquiryDetails.typetheme;
      data.budget = inquiryDetails.budget;
      data.inclusions = inquiryDetails.inclusions;
      data.adults = inquiryDetails.adults;
      data.children = inquiryDetails.children;
      data.infant = inquiryDetails.infant;
    }

    localStorage.setItem("quotationDetails", JSON.stringify(data));
    this.props.match.params.mode === "Create" && this.createCart();
    this.props.match.params.mode === "Edit" && this.saveQuotation();

    this.props.history.push(
      "/" + this.state.type + (isfromInquiry ? "/DetailsInquiry" : "/Details")
    );
  };

  addCustomer = (reqData) => {
    this.setState({
      isBtnLoading: true,
      isErrorMsg: "",
    });
    var reqURL = "api/v1/customer/create";

    var reqOBJ = {
      Request: {
        UserDisplayName: reqData?.customerName,
        FirstName: reqData?.customerName.trim()?.split(" ")[0],
        LastName:
          reqData?.customerName.trim()?.split(" ")[1] !== undefined
            ? reqData?.customerName.trim()?.split(" ")[1]
            : reqData?.customerName.trim(),
        Location: {
          Id: "IN",
          CountryID: "IN",
          Country: "India",
        },
        ContactInformation: {
          PhoneNumber: reqData?.phone,
          PhoneNumberCountryCode: "",
          Email: reqData?.email,
        },
      },
      Flags: {
        validateEmailAndPhone: true,
        UseAgentDetailInEmail: Global.getEnvironmetKeyValue("UseAgentDetailInEmail", "cobrand") === "true" ? true : false
      },
    };

    apiRequester(reqURL, reqOBJ, (data) => {
      if (data?.response?.token) {
        sessionStorage.setItem("personateId", data.response.token);
      }
      if (data.status.code === 260031) {
        // && reqData.phoneNotoValidate !== reqData.phone
        this.setState({
          isBtnLoading: false,
          isErrorMsg:
            "Given phone number is associated with another customer. Kindly enter another phone number.",
        });
      } else {
        this.setState({ isBtnLoading: false }, this.generateQuotation(reqData));
      }
    });
  };

  createCart = () => {
    const quotationInfo = JSON.parse(localStorage.getItem("quotationDetails"));
    let reqURL = "quotation";
    let reqOBJ = {
      name: quotationInfo.title,
      owner: quotationInfo.customerName,
      isPublic: true,
      type: this.state.type,
      data: {
        name: quotationInfo.title,
        customerName: quotationInfo.customerName,
        email: quotationInfo.email,
        phone: quotationInfo.phone,
        terms: quotationInfo.terms,
        offlineItems: localStorage.getItem("quotationItems"),
        duration: quotationInfo.duration,
        startDate: moment(quotationInfo.startDate).format("YYYY-MM-DD"),
        endDate: moment(quotationInfo.endDate).format("YYYY-MM-DD"),
        type: this.state.type,
        createdDate: moment(quotationInfo.createdDate).format("YYYY-MM-DD"),
        status: "saved",
        agentName: localStorage.getItem("agentName") || "",
      },
    };
    apiRequester_quotation_api(reqURL, reqOBJ, (data) => {
      localStorage.setItem("cartLocalId", data.id);
      this.setState({
        savedCartId: localStorage.getItem("cartLocalId"),
        isBtnLoading: false,
      });
      let localQuotationInfo = JSON.parse(
        localStorage.getItem("quotationDetails")
      );
      localQuotationInfo.status = "saved";
      localStorage.setItem(
        "quotationDetails",
        JSON.stringify(localQuotationInfo)
      );
    });
  };

  handleEdit = () => {
    this.props.history.push("/" + this.state.type + "/Edit");
  };

  handleResults = (businessName, searchRequest) => {
    if (businessName === "air") {
      let isMetaResults = false;
      if (
        (searchRequest !== undefined &&
          searchRequest.fromLocation.countryID.toLowerCase() ===
          Global.getEnvironmetKeyValue("PortalCountryCode").toLowerCase()) ||
        searchRequest.toLocation.countryID.toLowerCase() ===
        Global.getEnvironmetKeyValue("PortalCountryCode").toLowerCase()
      ) {
        isMetaResults = true;
      }
      this.setState({
        isSearch: false,
        isResults: true,
        isMetaResults: isMetaResults,
        businessName: businessName,
      });
    } else {
      this.setState({
        isSearch: false,
        isResults: true,
        isMetaResults:
          businessName === "hotel" ||
            businessName === "air" ||
            businessName === "activity"
            ? true
            : false,
        businessName: businessName,
      });
    }
  };

  handleSearchRequest = (searchParam) => {
    this.setState({
      searchRequest: searchParam,
      resultKey: this.state.resultKey + 1,
      isResults: true,
      isMetaResults: true,
    });
    this.handleResults(searchParam.businessName, searchParam);
  };

  deleteResults = () => {
    this.setState({ isResults: false, isMetaResults: false });
  };

  bookQuotation = () => {
    this.handleComingSoon();
  };

  sendEmail = () => {
    this.setState({
      isEmail: !this.state.isEmail,
    });
  };

  addItem = (item) => {
    const quotationItems =
      localStorage.getItem("quotationItems") &&
      localStorage.getItem("quotationItems") !== "undefined" &&
      JSON.parse(localStorage.getItem("quotationItems"));
    let items = quotationItems ? quotationItems : [];
    items = items.filter(
      (x) =>
        (x.offlineItem && x.offlineItem.uuid) !==
        (item.offlineItem && item.offlineItem.uuid)
    );
    item.offlineItem && items.push(item);

    this.setState({
      items,
      detailsKey: this.state.detailsKey + 1,
      isShowSearch: false,
    });

    localStorage.setItem("quotationItems", JSON.stringify(items));

    (item.itemDtl ||
      item.itemDtlMeta ||
      item.business === "air" ||
      item.itemDtlEdit) &&
      this.handleImportItem(item);
    this.saveQuotation();
  };

  handleItemDelete = (item) => {
    const quotationItems = JSON.parse(localStorage.getItem("quotationItems"));
    let items = quotationItems ? quotationItems : [];

    items = items.filter(
      (x) =>
        (x.offlineItem && x.offlineItem.uuid) !==
        (item.offlineItem && item.offlineItem.uuid)
    );

    this.setState({ items, detailsKey: this.state.detailsKey + 1 });
    localStorage.setItem("quotationItems", JSON.stringify(items));
    this.saveQuotation();
  };

  changeQuotationTab = (businessName) => {
    this.setState({
      businessName,
      importItem: false,
      isShowSearch: true,
      isResults: false,
      isMetaResults: false,
    });
  };

  resetQuotation = () => {
    localStorage.removeItem("quotationDetails");
    localStorage.removeItem("quotationItems");
    localStorage.removeItem("cartLocalId");
    this.setState({ items: [] });
  };

  handleOffline = (item) => {
    this.addItem({ offlineItem: item });
  };

  saveQuotation = () => {
    // Save Quotation Changes
    this.setState({ isBtnLoading: true });
    const quotationInfo = JSON.parse(localStorage.getItem("quotationDetails"));
    let bookBefore = null;
    if (JSON.parse(localStorage.getItem("quotationItems"))
      .map(x => { return x.offlineItem.bookBefore ? new Date(x.offlineItem.bookBefore) : "" })
      .filter(Boolean).length > 0)
      bookBefore = new Date(
        Math.min.apply(
          null,
          JSON.parse(localStorage.getItem("quotationItems"))
            .map(x => { return x.offlineItem.bookBefore ? new Date(x.offlineItem.bookBefore) : "" })
            .filter(Boolean)
        )
      );
    var reqURL = "quotation/update";
    var reqOBJ = {
      id: localStorage.getItem("cartLocalId"),
      name: quotationInfo.title,
      owner: quotationInfo.customerName,
      isPublic: true,
      data: {
        name: quotationInfo.title,
        customerName: quotationInfo.customerName,
        email: quotationInfo.email,
        phone: quotationInfo.phone,
        terms: quotationInfo.terms,
        offlineItems: localStorage.getItem("quotationItems"),
        duration: quotationInfo.duration,
        startDate: moment(quotationInfo.startDate).format("YYYY-MM-DD"),
        endDate: moment(quotationInfo.endDate).format("YYYY-MM-DD"),
        type: this.state.type,
        createdDate: moment(quotationInfo.createdDate).format("YYYY-MM-DD"),
        status: "saved",
        agentName: localStorage.getItem("agentName") || "",
        inquiryId: quotationInfo.inquiryId,
      },
      bookBefore: bookBefore,
    };

    apiRequester_quotation_api(reqURL, reqOBJ, (data) => {
      this.setState({
        savedCartId: localStorage.getItem("cartLocalId"),
        isSaveSucessMsg: true,
        isBtnLoading: false,
      });
      let localQuotationInfo = JSON.parse(
        localStorage.getItem("quotationDetails")
      );
      localQuotationInfo.status = "saved";
      localStorage.setItem(
        "quotationDetails",
        JSON.stringify(localQuotationInfo)
      );

      setTimeout(() => {
        this.setState({ isSaveSucessMsg: false });
      }, 5000);

      // this.props.match.params.mode === "DetailsInquiry" &&
      //   this.saveInquiry(quotationInfo);
    });
  };

  saveInquiry = (quotationInfo) => {
    var reqURL = "inquiry/update";
    var reqOBJ = {
      title: quotationInfo.title,
      from: quotationInfo.customerName,
      fromEmail: quotationInfo.email,
      type: this.state.type,
      id: localStorage.getItem("inquiryId"),
      data: {
        name: quotationInfo.title,
        customerName: quotationInfo.customerName,
        email: quotationInfo.email,
        phone: quotationInfo.phone,
        terms: quotationInfo.terms,
        duration: quotationInfo.duration,
        startDate: moment(quotationInfo.startDate).format("YYYY-MM-DD"),
        endDate: moment(quotationInfo.endDat).format("YYYY-MM-DD"),
        type: this.state.type,
        createdDate: quotationInfo.createdDate,
        status: "saved",
        title: quotationInfo.title,
        destination: quotationInfo.destination,
        month: quotationInfo.month,
        typetheme: quotationInfo.typetheme,
        budget: quotationInfo.budget,
        inclusions: quotationInfo.inclusions,
        adults: quotationInfo.adults,
        children: quotationInfo.children,
        infant: quotationInfo.infant,
        requirements: quotationInfo.requirements,
        from: quotationInfo.from,
        offlineItems: localStorage.getItem("quotationItems"),
        agentName: localStorage.getItem("agentName") || "",
        cartLocalId: localStorage.getItem("cartLocalId"),
        inquirySource: quotationInfo.inquirySource,
        referenceby:
          quotationInfo.inquirySource === "Referred By" ||
            quotationInfo.inquirySource === "Social Media"
            ? quotationInfo.referenceby
            : "",
      },
      status: "active",
      startDate: "2021-01-22",
      endDate: "2021-01-26",
    };

    apiRequester_quotation_api(reqURL, reqOBJ, (data) => { });
  };

  handleImportItem = (item) => {
    this.setState({
      importItem: item,
      importItemKey: this.state.importItemKey + 1,
    });
  };

  handleQuotationImportItem = (item) => {
    let cartItem = getItem(item);
    cartItem.uuid = this.generateUUID();
    this.addItem({ offlineItem: cartItem });
  };

  generateUUID = () => {
    let dt = new Date().getTime();
    let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  };

  handleComingSoon = () => {
    this.setState({
      comingsoon: !this.state.comingsoon,
    });
  };

  getAuthToken = () => {
    var reqURL = "api/v1/user/token";
    var reqOBJ = {};
    apiRequester(reqURL, reqOBJ, (data) => {
      localStorage.setItem("userToken", data.response);
    });
  };

  viewDetailsMode = () => {
    let cartLocalId = localStorage.getItem("cartLocalId");
    window.open(`#/EmailView/${cartLocalId}`, "_blank");
    //this.props.history.push(`/EmailView/${cartLocalId}`);
  };

  setpersonate = (callback) => {
    let reqURL = "api/v1/callcenter/setpersonateforcustomer";
    let reqOBJ = {
      Request: JSON.parse(localStorage.getItem("quotationDetails")).email,
    };
    apiRequester(
      reqURL,
      reqOBJ,
      function (data) {
        sessionStorage.setItem("personateId", data.response);
        sessionStorage.setItem("callCenterType", "Customer");
        callback();
      }.bind(this)
    );
  };

  bookQuotationManually = () => {
    this.setState({ isBtnLoadingBookItinerary: true });

    /*this.bookQuotationManuallyCreate();*/
    if (
      localStorage.getItem("inquiryId") !== null ||
      localStorage.getItem("quotationDetails") !== null ||
      localStorage.getItem("quotationItems") !== null ||
      localStorage.getItem("inquiryId") !== null
    ) {
      this.setpersonate(this.bookQuotationManuallyCreate);
    } else {
      this.bookQuotationManuallyCreate();
    }
  };

  bookQuotationManuallyCreate = () => {
    let reqURL = "api/v1/cart/create";

    let reqOBJ = { Request: {} };

    apiRequester(reqURL, reqOBJ, (data) => {
      localStorage.setItem("cartLocalId", data.response);
      this.bookQuotationManuallyAdd(data.response);
    });
  };

  bookQuotationManuallyAdd = (manualCartId) => {
    let bookingTtems = [];
    let AllItems = JSON.parse(localStorage.getItem("quotationItems"))
      ? JSON.parse(localStorage.getItem("quotationItems"))
      : [];

    AllItems.map(
      (x) =>
        (x.offlineItem.business === "hotel" ||
          x.offlineItem.business === "air" ||
          x.offlineItem.business === "activity" ||
          x.offlineItem.business === "transfers" ||
          x.offlineItem.business === "custom") &&
        bookingTtems.push(x.offlineItem)
    );

    if (this.state.bookingItemCount < bookingTtems.length) {
      this.bookQuotationItemsManually(manualCartId, bookingTtems);
    } else {
      this.props.history.push(`/QuickBookCart`);
    }
  };

  bookQuotationItemsManually = (manualCartId, bookingTtems) => {
    let item = bookingTtems[this.state.bookingItemCount];

    let reqURL = "api/v1/cart/add";

    let reqOBJ = {
      Request: {
        CartID: manualCartId,
        Data: this.getRequestInfo(item, item.business),
      },
    };

    apiRequester(reqURL, reqOBJ, (data) => {
      this.setState(
        {
          bookingItemCount: this.state.bookingItemCount + 1,
        },
        () => this.bookQuotationManuallyAdd(manualCartId)
      );
    });
  };

  getbrnconfig = (item) => {
    let objconfig = [];
    objconfig = [
      {
        key: "SellPrice",
        value: item.sellPrice && item.sellPrice !== "" ? item.sellPrice : "1",
      },
      {
        key: "CostPrice",
        value: item.costPrice && item.costPrice !== "" ? item.costPrice : "1",
      },
      {
        key: "isOfflineItinerary",
        value: true,
      },
      {
        key: "pickupTime",
        value: item.pickupTime,
      },
      {
        key: "supplierCurrency",
        value: item.supplierCurrency && item.supplierCurrency !== "" ? item.supplierCurrency.split(' ')[0] : "INR",
      },
      {
        key: "conversionRate",
        value: item.conversionRate && item.conversionRate !== "" ? item.conversionRate : "1",
      },
      {
        key: "supplierCostPrice",
        value: item.supplierCostPrice && item.supplierCostPrice !== "" ? item.supplierCostPrice : "0",
      },
      {
        key: "supplierTaxPrice",
        value: item.supplierTaxPrice && item.supplierTaxPrice !== "" ? item.supplierTaxPrice : "0",
      },
      {
        key: "supplierCostPricePortalCurrency",
        value: item.supplierCostPrice && item.supplierCostPrice !== "" ? item.supplierCostPrice * (parseFloat(item.conversionRate && item.conversionRate !== "" ? item.conversionRate : "1")) : "0",
      },
      {
        key: "supplierTaxPricePortalCurrency",
        value: item.supplierTaxPrice && item.supplierTaxPrice !== "" ? item.supplierTaxPrice * (parseFloat(item.conversionRate && item.conversionRate !== "" ? item.conversionRate : "1")) : "0",
      },
      {
        key: "markupPrice",
        value: item.markupPrice && item.markupPrice !== "" ? item.markupPrice : "0",
      },
      {
        key: "discountPrice",
        value: item.discountPrice && item.discountPrice !== "" ? item.discountPrice : "0",
      },
      {
        key: "CGSTPrice",
        value: item.CGSTPrice && item.CGSTPrice !== "" ? item.CGSTPrice : "0",
      },
      {
        key: "SGSTPrice",
        value: item.SGSTPrice && item.SGSTPrice !== "" ? item.SGSTPrice : "0",
      },
      {
        key: "IGSTPrice",
        value: item.IGSTPrice && item.IGSTPrice !== "" ? item.IGSTPrice : "0",
      }
    ];

    if (item.brn && item.brn !== "") {
      objconfig.push({
        key: "BRN",
        value: item.brn,
      });
    }
    return objconfig;
  };

  getRequestInfo = (item, business) => {
    if (business === "hotel") {
      return [
        {
          ManualItem: {
            business: item.business,
            Name: item.name && item.name !== "" ? item.name : "Unnamed Hotel",
            Amount: item.sellPrice !== "" ? item.sellPrice : "1",
            CurrencyRefCode: "USD",
            LocationInfo: {
              FromLocation: {
                ID:
                  item.details?.locationInfo?.fromLocation.countryID ||
                  "Unnamed",
                City:
                  item.toLocation && item.toLocation !== ""
                    ? item.toLocation
                    : "Unnamed",
                Name:
                  item.toLocationCity !== "" ? item.toLocationCity : "Unnamed",
                CountryID:
                  item.details?.locationInfo?.fromLocation.countryID ||
                  "Unnamed",
                Type: "Location",
                Priority: 1,
              },
            },
            objectIdentifier: item.business,
            Rating: item.rating,
            RatingType: "Star",
            dateInfo: {
              startDate: moment(item.startDate).format("YYYY-MM-DD"),
              endDate: moment(item.endDate).format("YYYY-MM-DD"),
            },
            paxInfo: [
              ...Array(parseInt(item.noRooms === "" ? 1 : item.noRooms)).keys(),
            ].map((data) => {
              return {
                typeString: "ADT",
                quantity: 2,
                type: 0,
              };
            }),
            images: item.imgUrl
              ? [
                {
                  URL: item.imgUrl,
                  Type: "default",
                  Title: "Hotel",
                  IsDefault: true,
                },
              ]
              : [],
            config: this.getbrnconfig(item),
            vendors: [
              {
                item: {
                  name: item.vendor,
                },
              },
            ],
            items: [
              {
                item: [
                  ...Array(
                    parseInt(item.noRooms === "" ? 1 : item.noRooms)
                  ).keys(),
                ].map((data) => {
                  return {
                    name: (item.itemType && item.itemType) || "Unnamed Room",
                    id: (item.itemType && item.itemType) || "Unnamed Room",
                    business: item.business,
                    objectIdentifier: "room",
                  };
                }),
              },
            ],
          },
        },
      ];
    } else if (business === "air") {
      if (!item.isRoundTrip) {
        return [
          {
            ManualItem: {
              business: item.business,
              objectIdentifier: "flightOption",
              TripType: "oneway",
              Amount: item.sellPrice !== "" ? item.sellPrice : "1",
              CurrencyRefCode: "USD",
              totalDuration:
                item.departDuration !== ""
                  ? parseInt(item.departDuration.split(" ")[0].replace("h", ""))
                  : "0",
              TicketTimeLimit:
                moment(item.departEndDate).format("YYYY-MM-DD") +
                "T" +
                (item.departEndTime !== ""
                  ? item.departEndTime + ":00"
                  : "00:00:00"),
              Description: item.Description,
              LocationInfo: {
                FromLocation: {
                  ID: item.fromLocation || "Unnamed",
                  Name: item.fromLocationName || "Unnamed",
                  CountryID: item.fromLocation || "Unnamed",
                  Country: item.fromLocationCity || "Unnamed",
                  City: item.fromLocationCity || "Unnamed",
                  Type: "Location",
                },
                ToLocation: {
                  ID: item.toLocation || "Unnamed",
                  Name: item.toLocationName || "Unnamed",
                  CountryID: item.toLocation || "Unnamed",
                  Country: item.toLocationCity || "Unnamed",
                  City: item.toLocationCity || "Unnamed",
                  Type: "Location",
                },
              },
              dateInfo: {
                startDate:
                  moment(item.departStartDate).format("YYYY-MM-DD") +
                  "T" +
                  (item.departStartTime !== ""
                    ? item.departStartTime + ":00"
                    : "00:00:00"),
                endDate:
                  moment(item.departEndDate).format("YYYY-MM-DD") +
                  "T" +
                  (item.departEndTime !== ""
                    ? item.departEndTime + ":00"
                    : "00:00:00"),
              },
              paxInfo: [
                {
                  typeString: "ADT",
                  quantity: item.adult !== "" ? parseInt(item.adult) : 1,
                  type: 0,
                },
                {
                  typeString: "CHD",
                  quantity: item.child !== "" ? parseInt(item.child) : 0,
                  type: 1,
                },
                {
                  typeString: "INF",
                  quantity: item.infant !== "" ? parseInt(item.infant) : 0,
                  type: 2,
                },
              ],
              config: this.getbrnconfig(item),
              vendors: [
                {
                  item: {
                    name: item.vendor,
                  },
                },
              ],
              StopDetails: [item.departStops !== "" ? item.departStops : 0],
              tpExtension: [
                {
                  key: "durationHours",
                  value:
                    item.departDuration !== ""
                      ? parseInt(
                        item.departDuration.split(" ")[0].replace("h", "")
                      )
                      : "0",
                },
                {
                  key: "durationMinutes",
                  value:
                    item.departDuration !== ""
                      ? parseInt(
                        item.departDuration.split(" ")[1].replace("m", "")
                      )
                      : "0",
                },
              ],
              items: [
                {
                  totalDuration:
                    item.departDuration !== ""
                      ? parseInt(
                        item.departDuration.split(" ")[0].replace("h", "")
                      )
                      : "0",
                  dateInfo: {
                    startDate:
                      moment(item.departStartDate).format("YYYY-MM-DD") +
                      "T" +
                      (item.departStartTime !== ""
                        ? item.departStartTime + ":00"
                        : "00:00:00"),
                    endDate:
                      moment(item.departEndDate).format("YYYY-MM-DD") +
                      "T" +
                      (item.departEndTime !== ""
                        ? item.departEndTime + ":00"
                        : "00:00:00"),
                  },
                  LocationInfo: {
                    FromLocation: {
                      ID: item.fromLocation || "Unnamed",
                      Name: item.fromLocationName || "Unnamed",
                      CountryID: item.fromLocation || "Unnamed",
                      Country: item.fromLocationCity || "Unnamed",
                      City: item.fromLocationCity || "Unnamed",
                      Type: "Location",
                    },
                    ToLocation: {
                      ID: item.toLocation || "Unnamed",
                      Name: item.toLocationName || "Unnamed",
                      CountryID: item.toLocation || "Unnamed",
                      Country: item.toLocationCity || "Unnamed",
                      City: item.toLocationCity || "Unnamed",
                      Type: "Location",
                    },
                  },
                  tpExtension: [
                    {
                      key: "durationHours",
                      value:
                        item.departDuration !== ""
                          ? parseInt(
                            item.departDuration.split(" ")[0].replace("h", "")
                          )
                          : "0",
                    },
                    {
                      key: "durationMinutes",
                      value:
                        item.departDuration !== ""
                          ? parseInt(
                            item.departDuration.split(" ")[1].replace("m", "")
                          )
                          : "0",
                    },
                  ],
                  item: [
                    {
                      journeyDuration: item.totaldepartDuration,
                      vendors: [
                        {
                          type: "airline",
                          item: {
                            code: "6E",
                            name: item.departAirlineName,
                          },
                        },
                        {
                          type: "operatingAirline",
                          item: {
                            code: "6E",
                            name: item.departAirlineName,
                          },
                        },
                      ],
                      images: item.departImg
                        ? [
                          {
                            URL: item.departImg,
                            Type: "default",
                            IsDefault: true,
                          },
                        ]
                        : [],
                      Code: item.departFlightNumber,
                      business: "air",
                      objectIdentifier: "flight",
                      tpExtension: [
                        {
                          key: "cabinClass",
                          value: item.departClass,
                        },
                        {
                          key: "durationHours",
                          value:
                            item.departDuration !== ""
                              ? parseInt(
                                item.departDuration
                                  .split(" ")[0]
                                  .replace("h", "")
                              )
                              : "0",
                        },
                        {
                          key: "durationMinutes",
                          value:
                            item.departDuration !== ""
                              ? parseInt(
                                item.departDuration
                                  .split(" ")[1]
                                  .replace("m", "")
                              )
                              : "0",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          },
        ];
      } else {
        return [
          {
            ManualItem: {
              business: item.business,
              objectIdentifier: "flightOption",
              TripType: "roundtrip",
              Amount: item.sellPrice !== "" ? item.sellPrice : "1",
              CurrencyRefCode: "USD",
              totalDuration:
                item.departDuration !== ""
                  ? parseInt(item.departDuration.split(" ")[0].replace("h", ""))
                  : "0",
              TicketTimeLimit:
                moment(item.departEndDate).format("YYYY-MM-DD") +
                "T" +
                (item.departEndTime !== ""
                  ? item.departEndTime + ":00"
                  : "00:00:00"),
              Description: item.Description,
              LocationInfo: {
                FromLocation: {
                  ID: item.fromLocation || "Unnamed",
                  Name: item.fromLocationName || "Unnamed",
                  CountryID: item.fromLocation || "Unnamed",
                  Country: item.fromLocationCity || "Unnamed",
                  City: item.fromLocationCity || "Unnamed",
                  Type: "Location",
                },
                ToLocation: {
                  ID: item.toLocation || "Unnamed",
                  Name: item.toLocationName || "Unnamed",
                  CountryID: item.toLocation || "Unnamed",
                  Country: item.toLocationCity || "Unnamed",
                  City: item.toLocationCity || "Unnamed",
                  Type: "Location",
                },
              },
              dateInfo: {
                startDate:
                  moment(item.departStartDate).format("YYYY-MM-DD") +
                  "T" +
                  (item.departStartTime !== ""
                    ? item.departStartTime + ":00"
                    : "00:00:00"),
                endDate:
                  moment(item.departEndDate).format("YYYY-MM-DD") +
                  "T" +
                  (item.departEndTime !== ""
                    ? item.departEndTime + ":00"
                    : "00:00:00"),
              },
              paxInfo: [
                {
                  typeString: "ADT",
                  quantity: item.adult !== "" ? parseInt(item.adult) : 1,
                  type: 0,
                },
                {
                  typeString: "CHD",
                  quantity: item.child !== "" ? parseInt(item.child) : 0,
                  type: 1,
                },
                {
                  typeString: "INF",
                  quantity: item.infant !== "" ? parseInt(item.infant) : 0,
                  type: 2,
                },
              ],
              config: this.getbrnconfig(item),
              vendors: [
                {
                  item: {
                    name: item.vendor,
                  },
                },
              ],
              StopDetails: [
                item.departStops !== "" ? item.departStops : 0,
                item.returnStops !== "" ? item.returnStops : 0,
              ],
              tpExtension: [
                {
                  key: "durationHours",
                  value:
                    item.departDuration !== ""
                      ? parseInt(
                        item.departDuration.split(" ")[0].replace("h", "")
                      )
                      : "0",
                },
                {
                  key: "durationMinutes",
                  value:
                    item.departDuration !== ""
                      ? parseInt(
                        item.departDuration.split(" ")[1].replace("m", "")
                      )
                      : "0",
                },
              ],
              items: [
                {
                  totalDuration:
                    item.departDuration !== ""
                      ? parseInt(
                        item.departDuration.split(" ")[0].replace("h", "")
                      )
                      : "0",
                  dateInfo: {
                    startDate:
                      moment(item.departStartDate).format("YYYY-MM-DD") +
                      "T" +
                      (item.departStartTime !== ""
                        ? item.departStartTime + ":00"
                        : "00:00:00"),
                    endDate:
                      moment(item.departEndDate).format("YYYY-MM-DD") +
                      "T" +
                      (item.departEndTime !== ""
                        ? item.departEndTime + ":00"
                        : "00:00:00"),
                  },
                  LocationInfo: {
                    FromLocation: {
                      ID: item.fromLocation || "Unnamed",
                      Name: item.fromLocationName || "Unnamed",
                      CountryID: item.fromLocation || "Unnamed",
                      Country: item.fromLocationCity || "Unnamed",
                      City: item.fromLocationCity || "Unnamed",
                      Type: "Location",
                    },
                    ToLocation: {
                      ID: item.toLocation || "Unnamed",
                      Name: item.toLocationName || "Unnamed",
                      CountryID: item.toLocation || "Unnamed",
                      Country: item.toLocationCity || "Unnamed",
                      City: item.toLocationCity || "Unnamed",
                      Type: "Location",
                    },
                  },
                  tpExtension: [
                    {
                      key: "cabinClass",
                      value: item.departClass,
                    },
                    {
                      key: "durationHours",
                      value:
                        item.departDuration !== ""
                          ? parseInt(
                            item.departDuration.split(" ")[0].replace("h", "")
                          )
                          : "0",
                    },
                    {
                      key: "durationMinutes",
                      value:
                        item.departDuration !== ""
                          ? parseInt(
                            item.departDuration.split(" ")[1].replace("m", "")
                          )
                          : "0",
                    },
                  ],
                  item: [
                    {
                      journeyDuration: item.totaldepartDuration,
                      vendors: [
                        {
                          type: "airline",
                          item: {
                            code: "6E",
                            name: item.departAirlineName,
                          },
                        },
                        {
                          type: "operatingAirline",
                          item: {
                            code: "6E",
                            name: item.departAirlineName,
                          },
                        },
                      ],
                      images: [
                        {
                          URL: item.departImg,
                          Type: "default",
                          IsDefault: true,
                        },
                      ],
                      Code: item.departFlightNumber,
                      business: "air",
                      objectIdentifier: "flight",
                      tpExtension: [
                        {
                          key: "cabinClass",
                          value: item.departClass,
                        },
                        {
                          key: "durationHours",
                          value:
                            item.departDuration !== ""
                              ? parseInt(
                                item.departDuration
                                  .split(" ")[0]
                                  .replace("h", "")
                              )
                              : "0",
                        },
                        {
                          key: "durationMinutes",
                          value:
                            item.departDuration !== ""
                              ? parseInt(
                                item.departDuration
                                  .split(" ")[1]
                                  .replace("m", "")
                              )
                              : "0",
                        },
                      ],
                    },
                  ],
                },
                {
                  totalDuration:
                    item.returnDuration !== ""
                      ? parseInt(
                        item.returnDuration.split(" ")[0].replace("h", "")
                      )
                      : "0",
                  dateInfo: {
                    startDate:
                      moment(item.returnStartDate).format("YYYY-MM-DD") +
                      "T" +
                      (item.returnStartTime !== ""
                        ? item.returnStartTime + ":00"
                        : "00:00:00"),
                    endDate:
                      moment(item.returnEndDate).format("YYYY-MM-DD") +
                      "T" +
                      (item.returnEndTime !== ""
                        ? item.returnEndTime + ":00"
                        : "00:00:00"),
                  },
                  LocationInfo: {
                    FromLocation: {
                      ID: item.toLocation || "Unnamed",
                      Name: item.toLocationName || "Unnamed",
                      CountryID: item.toLocation || "Unnamed",
                      Country: item.toLocationCity || "Unnamed",
                      City: item.toLocationCity || "Unnamed",
                      Type: "Location",
                    },
                    ToLocation: {
                      ID: item.fromLocation || "Unnamed",
                      Name: item.fromLocationName || "Unnamed",
                      CountryID: item.fromLocation || "Unnamed",
                      Country: item.fromLocationCity || "Unnamed",
                      City: item.fromLocationCity || "Unnamed",
                      Type: "Location",
                    },
                  },
                  tpExtension: [
                    {
                      key: "cabinClass",
                      value: item.returnClass,
                    },
                    {
                      key: "durationHours",
                      value:
                        item.returnDuration !== ""
                          ? parseInt(
                            item.returnDuration.split(" ")[0].replace("h", "")
                          )
                          : "0",
                    },
                    {
                      key: "durationMinutes",
                      value:
                        item.returnDuration !== ""
                          ? parseInt(
                            item.returnDuration.split(" ")[1].replace("m", "")
                          )
                          : "0",
                    },
                  ],
                  item: [
                    {
                      journeyDuration: item.totalreturnDuration,
                      vendors: [
                        {
                          type: "airline",
                          item: {
                            code: "6E",
                            name: item.returnAirlineName,
                          },
                        },
                        {
                          type: "operatingAirline",
                          item: {
                            code: "6E",
                            name: item.returnAirlineName,
                          },
                        },
                      ],
                      images: item.returnImg
                        ? [
                          {
                            URL: item.returnImg,
                            Type: "default",
                            IsDefault: true,
                          },
                        ]
                        : [],
                      Code: item.returnFlightNumber,
                      business: "air",
                      objectIdentifier: "flight",
                      tpExtension: [
                        {
                          key: "cabinClass",
                          value: item.returnClass,
                        },
                        {
                          key: "durationHours",
                          value:
                            item.returnDuration !== ""
                              ? parseInt(
                                item.returnDuration
                                  .split(" ")[0]
                                  .replace("h", "")
                              )
                              : "0",
                        },
                        {
                          key: "durationMinutes",
                          value:
                            item.returnDuration !== ""
                              ? parseInt(
                                item.returnDuration
                                  .split(" ")[1]
                                  .replace("m", "")
                              )
                              : "0",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          },
        ];
      }
    } else if (business === "activity") {
      return [
        {
          ManualItem: {
            business: item.business,
            objectIdentifier: "activity",
            Name: item.name && item.name !== "" ? item.name : "Unnamed",
            Description: item.description,
            Amount: item.sellPrice !== "" ? item.sellPrice : "1",
            dateInfo: {
              startDate: moment(item.startDate).format("YYYY-MM-DD"),
              endDate: moment(item.startDate).format("YYYY-MM-DD"),
            },
            images: item.imgUrl
              ? [
                {
                  URL: item.imgUrl,
                  Type: "default",
                  IsDefault: true,
                },
              ]
              : [],
            TPExtension: [
              {
                Key: "duration",
                Value: item.duration,
              },
            ],
            paxInfo: [
              {
                typeString: "ADT",
                quantity: item.guests && item.guests !== "" ? item.guests : "1",
                type: 0,
              },
            ],
            CurrencyRefCode: "USD",
            LocationInfo: {
              FromLocation: {
                ID:
                  item.toLocation && item.toLocation !== ""
                    ? item.toLocation
                    : "Unnamed",
                City:
                  item.toLocation && item.toLocation !== ""
                    ? item.toLocation
                    : "Unnamed",
                Name:
                  item.toLocation && item.toLocation !== ""
                    ? item.toLocation
                    : "Unnamed",
                CountryID: "AE",
                Type: "Location",
                Priority: 1,
              },
            },
            vendors: [
              {
                item: {
                  name: item.vendor,
                },
              },
            ],
            config: this.getbrnconfig(item),
            items: [
              {
                item: [
                  {
                    name:
                      item.itemType
                        ? item.itemType
                        : "Unnamed",
                    business: "activity",
                    objectIdentifier: "activityOption",
                  },
                ],
              },
            ],
          },
        },
      ];
    } else if (business === "transfers") {
      return [
        {
          ManualItem: {
            tripType: !item.isRoundTrip ? "oneway" : "roundtrip",
            business: "transfers",
            objectIdentifier: "transfers",
            Description: item.description,
            Amount: item.sellPrice !== "" ? item.sellPrice : "1",
            dateInfo: {
              startDate:
                moment(item.startDate).format("YYYY-MM-DD") + "T00:00:00",
              endDate:
                moment(item.startDate).format("YYYY-MM-DD") + "T00:00:00",
              startTime:
                item.pickupTime && item.pickupTime !== ""
                  ? item.pickupTime.split(":")[0]
                  : "00",
              endTime:
                item.pickupTime && item.pickupTime !== ""
                  ? item.pickupTime.split(":")[1].split(" ")[0]
                  : "00",
              type: null,
            },
            images: item.imgUrl
              ? [
                {
                  URL: item.imgUrl,
                  Type: "default",
                  IsDefault: true,
                },
              ]
              : [],
            paxInfo: [
              {
                typeString: "ADT",
                quantity: item.guests !== "" ? item.guests : "1",
                type: 0,
              },
            ],
            tpExtension: [
              {
                Key: "adultCount",
                Value: item.guests !== "" ? item.guests : "1",
              },
            ],
            CurrencyRefCode: "USD",
            LocationInfo: {
              FromLocation: {
                ID: item.fromLocation || "Unnamed",
                Name: item.fromLocation || "Unnamed",
                CountryID: item.fromLocation || "Unnamed",
                Type:
                  item.pickupType && item.pickupType !== ""
                    ? item.pickupType
                    : "Airport",
              },
              ToLocation: {
                ID: (item.toLocation && item.toLocation) || "Unnamed",
                Name: (item.toLocation && item.toLocation) || "Unnamed",
                CountryID: (item.toLocation && item.toLocation) || "Unnamed",
                Type:
                  item.dropoffType && item.dropoffType !== ""
                    ? item.dropoffType
                    : "Airport",
              },
            },
            vendors: [
              {
                item: {
                  name: item.vendor,
                },
              },
            ],
            config: this.getbrnconfig(item),
            items: [
              {
                item: [
                  {
                    business: "transfers",
                    objectIdentifier: "transfersoption",
                    tpExtension: [
                      {
                        Key: "adultCount",
                        Value: item.guests !== "" ? item.guests : "2",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        },
      ];
    } else if (business === "custom") {
      return [
        {
          ManualItem: {
            business: "activity",
            objectIdentifier: "activity",
            flags: {
              isCustomBusiness: true,
            },
            Amount: item.sellPrice !== "" ? item.sellPrice : "1",
            paxInfo: [
              {
                typeString: "ADT",
                quantity: item.guests !== "" ? item.guests : "2",
                type: 0,
              },
            ],
            CurrencyRefCode: "USD",
            LocationInfo: {
              FromLocation: {
                ID:
                  item.toLocation && item.toLocation !== ""
                    ? item.toLocation
                    : "Unnamed",
                City:
                  item.toLocation && item.toLocation !== ""
                    ? item.toLocation
                    : "Unnamed",
                Name:
                  item.toLocation && item.toLocation !== ""
                    ? item.toLocation
                    : "Unnamed",
                CountryID: "AE",
                Type: "Location",
                Priority: 1,
              },
            },
            Name: item.name && item.name !== "" ? item.name : "Unnamed",
            Description: item.description,
            dateInfo: {
              startDate: moment(item.startDate).format("YYYY-MM-DD"),
              endDate: moment(item.startDate).format("YYYY-MM-DD"),
            },
            vendors: [
              {
                item: {
                  name: item.vendor,
                },
              },
            ],
            config: this.getbrnconfig(item),
            items: [
              {
                item: [
                  {
                    name: (item.itemType && item.itemType) || "Unnamed",
                    business: "activity",
                    objectIdentifier: "activityOption",
                  },
                ],
              },
            ],
          },
        },
      ];
    }
  };

  handleItemEdit = (item) => {
    //this.handleItemDelete(item);
    //localStorage.setItem("edititemuuid",item.offlineItem)
    this.addItem({ itemDtlEdit: item.offlineItem });
  };

  componentDidMount = () => {
    const { mode } = this.props.match.params;
    mode === "Create" && this.resetQuotation();
    this.getAuthToken();
  };

  render() {
    const {
      isResults,
      isMetaResults,
      businessName,
      searchRequest,
      resultKey,
      isEmail,
      items,
      detailsKey,
      isBtnLoadingBookItinerary,
      type,
      importItem,
      importItemKey,
      isShowSearch,
    } = this.state;
    const { mode } = this.props.match.params;
    const quotationInfo = JSON.parse(localStorage.getItem("quotationDetails"));

    return (
      <div className="quotation">
        <div className="title-bg pt-3 pb-3 mb-3">
          <div className="container">
            <h1 className="text-white m-0 p-0 f30">
              <SVGIcon
                name="file-text"
                width="24"
                height="24"
                className="mr-3"
              ></SVGIcon>

              {mode === "Create"
                ? type === "Itinerary"
                  ? localStorage.getItem("isUmrahPortal")
                    ? "Create Package"
                    : "Create Itinerary"
                  : Trans("_createQuotation")
                : mode === "Edit"
                  ? type === "Itinerary"
                    ? "Edit Itinerary"
                    : "Edit Quotation"
                  : type === "Itinerary"
                    ? mode !== "DetailsInquiry"
                      ? "Itinerary Details"
                      : "Inquiry Details"
                    : "Quotation Details"}

              {type === "Quotation" && mode !== "DetailsInquiry" && (
                <button
                  className="btn btn-sm btn-primary pull-right"
                  onClick={() => this.props.history.push(`/QuotationList`)}
                >
                  {localStorage.getItem('portalType') === 'B2C' ? "My Quotation" : Trans("_manageQuotation")}
                </button>
              )}

              {type === "Itinerary" &&
                mode !== "DetailsInquiry" &&
                !localStorage.getItem("isUmrahPortal") && (
                  <button
                    className="btn btn-sm btn-primary pull-right"
                    onClick={() => this.props.history.push(`/ItineraryList`)}
                  >
                    {localStorage.getItem('portalType') === 'B2C' ? "My Itineraries" : Trans("_manageItineraries")}
                  </button>
                )}

              {mode === "DetailsInquiry" && (
                <button
                  className="btn btn-sm btn-primary pull-right"
                  onClick={() => this.props.history.push(`/InquiryList`)}
                >
                  {Trans("Manage Inquiries")}
                </button>
              )}
            </h1>
          </div>
        </div>

        <div className="container">
          {mode === "Create" && (
            <QuotationCreate
              type={type}
              handleCreate={this.addCustomer}
              handleDateChange={this.props.setDate}
              isErrorMsg={this.state.isErrorMsg}
              isBtnLoading={this.state.isBtnLoading}
              mode={mode}
            />
          )}

          {mode === "Edit" && (
            <QuotationCreate
              type={type}
              {...quotationInfo}
              handleCreate={this.addCustomer}
              handleDateChange={this.props.setDate}
              isErrorMsg={this.state.isErrorMsg}
              isBtnLoading={this.state.isBtnLoading}
              mode={mode}
            />
          )}

          {(mode === "Details" ||
            mode === "Edit" ||
            mode === "DetailsInquiry") && (
              <React.Fragment>
                <Quotationinfo
                  type={type}
                  {...quotationInfo}
                  type={type}
                  handleEdit={this.handleEdit}
                />
                <AuthorizeComponent title="itinerary-details~search" type="button">
                  <React.Fragment>
                    <QuotationSearch
                      businessName={businessName}
                      history={this.props.history}
                      match={this.props.match}
                      handleResults={this.handleResults}
                      handleSearchRequest={this.handleSearchRequest}
                      changeQuotationTab={this.changeQuotationTab}
                      handleOffline={this.handleOffline}
                      type={type}
                      quotationInfo={quotationInfo}
                      isShowSearch={isShowSearch}
                    />

                    {isMetaResults && businessName == "hotel" && (
                      <QuotationResultsMeta
                        key={"resultKey" + resultKey}
                        searchRequest={searchRequest}
                        addItem={this.addItem}
                        deleteResults={this.deleteResults}
                        type={type}
                      />
                    )}

                    {isMetaResults &&
                      businessName == "air" &&
                      searchRequest.tripDirection.toLowerCase() !== "roundtrip" && (
                        <QuotationResultsMetaAir
                          key={"resultKey" + resultKey}
                          addItem={this.addItem}
                          searchRequest={searchRequest}
                          deleteResults={this.deleteResults}
                          type={type}
                        />
                      )}

                    {isMetaResults &&
                      businessName == "air" &&
                      searchRequest.tripDirection.toLowerCase() === "roundtrip" && (
                        <QuotationResultsMetaAirRoundTrip
                          key={"resultKey" + resultKey}
                          addItem={this.addItem}
                          searchRequest={searchRequest}
                          deleteResults={this.deleteResults}
                          type={type}
                          tripDirection={searchRequest.tripDirection.toLowerCase()}
                        />
                      )}

                    {isMetaResults && businessName == "activity" && (
                      <QuotationResultsMetaActivity
                        key={"resultKey" + resultKey}
                        addItem={this.addItem}
                        searchRequest={searchRequest}
                        deleteResults={this.deleteResults}
                        type={type}
                      />
                    )}

                    {isResults && !isMetaResults && (
                      <QuotationResults
                        key={"resultKey" + resultKey}
                        businessName={businessName}
                        addItem={this.addItem}
                        searchRequest={searchRequest}
                        deleteResults={this.deleteResults}
                        type={type}
                      />
                    )}
                  </React.Fragment>
                </AuthorizeComponent>
                {type === "Quotation" && (
                  <React.Fragment>
                    {importItem && (
                      <ItineraryAddOffline
                        type={type}
                        business={businessName}
                        handleOffline={this.handleOffline}
                        importItem={importItem}
                        key={"importItemKey" + importItemKey}
                      />
                    )}

                    {items.length > 0 && (
                      <React.Fragment>
                        <QuotationDetails
                          key={"detailsKey" + detailsKey}
                          items={items}
                          handleItemDelete={this.handleItemDelete}
                          handleItemEdit={this.handleItemEdit}
                        />

                        <div className="quotation-action-buttons mt-4 text-center">
                          <button
                            onClick={this.viewDetailsMode}
                            className="btn btn-sm btn-outline-primary ml-2 mr-2"
                          >
                            Preview Quotation
                          </button>
                          <AuthorizeComponent title="itinerary-details~send-quotation" type="button">
                            <button
                              className="btn btn-sm btn-outline-primary ml-2 mr-2"
                              onClick={this.sendEmail}
                            >
                              {Trans("_sendQuotation")}
                            </button>
                          </AuthorizeComponent>
                          <AuthorizeComponent title="itinerary-details~book-quotation" type="button">
                            <button
                              onClick={this.bookQuotationManually}
                              className="btn btn-sm btn-outline-primary ml-2 mr-2"
                            >
                              {isBtnLoadingBookItinerary && (
                                <span className="spinner-border spinner-border-sm mr-2"></span>
                              )}
                              {Trans("_bookQuotation")}
                            </button>
                          </AuthorizeComponent>
                        </div>

                        {isEmail && (
                          <QuotationEmail
                            {...this.props}
                            {...quotationInfo}
                            saveQuotation={this.saveQuotation}
                            sendEmail={this.sendEmail}
                            items={items}
                            type={type}
                          />
                        )}
                      </React.Fragment>
                    )}
                    {localStorage.getItem('portalType') === 'B2C' && type === "Quotation" && items.length === 0 && <div className="text-center mt-4"><b>No Item(s) added.</b></div>}
                  </React.Fragment>
                )}

                {type === "Itinerary" && (
                  <React.Fragment>
                    {importItem && (
                      <ItineraryAddOffline
                        type={type}
                        business={businessName}
                        handleOffline={this.handleOffline}
                        importItem={importItem}
                        key={"importItemKey" + importItemKey}
                      />
                    )}

                    <ItineraryDetails
                      {...quotationInfo}
                      items={items}
                      handleItemDelete={this.handleItemDelete}
                      handleItemEdit={this.handleItemEdit}
                      key={"detailsKey" + detailsKey}
                    />

                    <div className="quotation-action-buttons mt-4 text-center">
                      <button
                        onClick={this.viewDetailsMode}
                        className="btn btn-sm btn-outline-primary ml-2 mr-2"
                      >
                        Preview Itinerary
                      </button>
                      <AuthorizeComponent title="itinerary-details~send-itinerary" type="button">
                        <button
                          className="btn btn-sm btn-outline-primary ml-2 mr-2"
                          onClick={this.sendEmail}
                        >
                          {Trans("Send Itinerary")}
                        </button>
                      </AuthorizeComponent>
                      <AuthorizeComponent title="itinerary-details~book-itinerary" type="button">
                        <button
                          onClick={this.bookQuotationManually}
                          className="btn btn-sm btn-outline-primary ml-2 mr-2"
                        >
                          {isBtnLoadingBookItinerary && (
                            <span className="spinner-border spinner-border-sm mr-2"></span>
                          )}
                          Book Itinerary
                        </button>
                      </AuthorizeComponent>
                      <AuthorizeComponent title="itinerary-details~book-itinerary-manually" type="button">
                        <button
                          onClick={this.bookQuotationManually}
                          className="btn btn-sm btn-primary ml-2 mr-2"
                          style={{ display: "none" }}
                        >
                          Book Itinerary Manually
                        </button>
                      </AuthorizeComponent>
                    </div>

                    {isEmail && (
                      <ItineraryEmail
                        {...this.props}
                        {...quotationInfo}
                        saveQuotation={this.saveQuotation}
                        sendEmail={this.sendEmail}
                        items={items}
                        type={type}
                      />
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
        </div>

        {this.state.comingsoon && (
          <ComingSoon handleComingSoon={this.handleComingSoon} />
        )}
      </div>
    );
  }
}

// Tourwiz inquiry issues changes - Naitik
export default Quotation;
