const mapping = {
  "front desk": require("../../assets/images/svg/Front-desk-svg.png"),
  "room service": require("../../assets/images/svg/016-room-service-svg.png"),
  gym: require("../../assets/images/svg/038-gym-svg.png"),
  security: require("../../assets/images/svg/Security-svg.png"),
  "24-hour security": require("../../assets/images/svg/Security-svg.png"),
  restaurant: require("../../assets/images/svg/015-restaurant-svg.png"),
  "disabled friendly": require("../../assets/images/svg/disabled-svg.png"),
  parking: require("../../assets/images/svg/car-svg.png"),
  "yes Car park": require("../../assets/images/svg/car-svg.png"),
  "car park": require("../../assets/images/svg/car-svg.png"),
  "valet parking": require("../../assets/images/svg/car-svg.png"),
  pets: require("../../assets/images/svg/pets-svg.png"),
  "air condition": require("../../assets/images/svg/air-conditioner-svg.png"),
  transfers: require("../../assets/images/svg/car-svg.png"),
  atm: require("../../assets/images/svg/012-atm-svg.png"),
  pool: require("../../assets/images/svg/021-swimmer-2-svg.png"),
  "babysitting service": require("../../assets/images/svg/Babysitting-service-svg.png"),
  baggage: require("../../assets/images/svg/003-luggage-svg.png"),
  bar: require("../../assets/images/svg/bar-svg.png"),
  bathrobe: require("../../assets/images/svg/Bathrobes-svg.png"),
  bathtub: require("../../assets/images/svg/026-hot-tub-svg.png"),
  beach: require("../../assets/images/svg/039-scuba-diving-svg.png"),
  salon: require("../../assets/images/svg/Salon-svg.png"),
  "bottled water": require("../../assets/images/svg/bottled-water-svg.png"),
  breakfast: require("../../assets/images/svg/014-breakfast-svg.png"),
  "wi-fi": require("../../assets/images/svg/044-wifi-svg.png"),
  cot: require("../../assets/images/svg/Babysitting-service-svg.png"),
  exchange: require("../../assets/images/svg/Currency-exchange-svg.png"),
  "currency exchange facilities": require("../../assets/images/svg/Currency-exchange-svg.png"),
  housekeeping: require("../../assets/images/svg/007-housekeeping-svg.png"),
  "double bed": require("../../assets/images/svg/bed-svg.png"),
  elevator: require("../../assets/images/svg/Elevator-svg.png"),
  entertainment: require("../../assets/images/svg/tennis-court-svg.png"),
  family: require("../../assets/images/svg/family-room-svg.png"),
  fridge: require("../../assets/images/svg/Refrigerator-svg.png"),
  garden: require("../../assets/images/svg/garden-svg.png"),
  "gift shop": require("../../assets/images/svg/gift-svg.png"),
  "hair dryer": require("../../assets/images/svg/hair-dryer-svg.png"),
  safe: require("../../assets/images/svg/safety-box-svg.png"),
  "laundry service": require("../../assets/images/svg/Dry-cleaning-svg.png"),
  library: require("../../assets/images/svg/004-books-svg.png"),
  transfer: require("../../assets/images/svg/car-svg.png"),
  wifi: require("../../assets/images/svg/044-wifi-svg.png"),
  pet: require("../../assets/images/svg/pets-svg.png"),
  sailing: require("../../assets/images/svg/029-river-svg.png"),
  shower: require("../../assets/images/svg/shower-svg.png"),
  "wakeup service": require("../../assets/images/svg/Wake-up-svg.png"),
  "wake-up service": require("../../assets/images/svg/Wake-up-svg.png"),
  alarm: require("../../assets/images/svg/Wake-up-svg.png"),
  hairdryer: require("../../assets/images/svg/hair-dryer-svg.png"),
  "hot tub": require("../../assets/images/svg/026-hot-tub-svg.png"),
  "extra bed": require("../../assets/images/svg/bed-svg.png"),
  "secretarial service": require("../../assets/images/svg/009-concierge-svg.png"),
  toiletries: require("../../assets/images/svg/Toiletries-svg.png"),
  waterskiing: require("../../assets/images/svg/027-surf-svg.png"),
  "children playground": require("../../assets/images/svg/kids-club-svg.png"),
  blanket: require("../../assets/images/svg/blanket.png"),
  "emergency exit": require("../../assets/images/svg/emergency_exit.png"),
  "charging point": require("../../assets/images/svg/charging_point.png"),
  "current location": require("../../assets/images/svg/current_location.png"),
  "fire extinguisher": require("../../assets/images/svg/fire_extinguisher.png"),
  "hotel safe": require("../../assets/images/svg/fire_extinguisher.png"),
  "medical box": require("../../assets/images/svg/medical_box.png"),
  pillow: require("../../assets/images/svg/pillow.png"),
  "reading light": require("../../assets/images/svg/reading_light.png"),
  tv: require("../../assets/images/svg/tv.png"),
  "water bottle": require("../../assets/images/svg/water_bottle.png")
};

export default mapping;
