import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from '@sentry/browser';
import { HashRouter } from "react-router-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/style.css";
import "./assets/css/font-awesome.min.css";
import * as serviceWorker from "./serviceWorker";
import Config from "./config.json";
serviceWorker.unregister();

// Initialize sentry, add value only if required for development and inject values in deployment pipeline for production usage
Sentry.init({ dsn: Config.sentryDsn });

ReactDOM.render(
  <HashRouter>

    <App />
  </HashRouter>,
  document.getElementById("root")
);
