import React from "react";
import * as Global from "../../helpers/global";

const QuotationEmailFooter = (props) => {
  const { userInfo } = props;
  const { contactInformation, location, provider } = userInfo;

  return (
    <table cellPadding="0" cellSpacing="0" border="0" width="100%">
      <tbody>
        <tr>
          <td style={{ padding: "0px 0px 8px 0px", fontSize: "14px" }}>
            <br />
            <br />
            <span>Terms and Conditions: </span>
            <p style={{ whiteSpace: "pre-wrap" }}>{props.data.terms}</p>
          </td>
        </tr>
        <tr>
          <td style={{ padding: "0px 0px 8px 0px", fontSize: "14px" }}>
            <span>Thank You,</span>
          </td>
        </tr>
        <tr>
          <td style={{ padding: "16px 0px 8px 0px", fontSize: "14px" }}>
            {userInfo?.provider?.logo?.url ? (
              <img
                src={userInfo?.provider?.logo?.url}
                height="42px"
                style={{ height: "42px" }}
                alt=""
              />
            ) : (
              <div className="border p-2 d-inline-block rounded bg-light">
                <h6 className="m-0 p-0 font-weight-bold">{provider?.name}</h6>
              </div>
            )}
          </td>
        </tr>

        <tr>
          <td style={{ padding: "0px 0px 4px 0px", fontSize: "14px" }}>
            <b>{provider?.name}</b>
          </td>
        </tr>
        <tr>
          <td style={{ padding: "0px 0px 8px 0px", fontSize: "14px" }}>
            <span>{location?.address}</span>
          </td>
        </tr>
        <tr>
          <td style={{ padding: "0px 0px 8px 0px", fontSize: "14px" }}>
            <span>
              Phone :{" "}
              {(contactInformation?.phoneNumberCountryCode
                ? contactInformation?.phoneNumberCountryCode + " "
                : "") + contactInformation?.phoneNumber}
            </span>
          </td>
        </tr>
        <tr>
          <td style={{ padding: "0px 0px 8px 0px", fontSize: "14px" }}>
            <span>
              Email :{" "}
              <a
                href={
                  "mailto:" + Global.getEnvironmetKeyValue("customerCareEmail")
                }
              >
                {contactInformation?.email}
              </a>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default QuotationEmailFooter;
