import React, { Component } from "react";
import Form from "../common/form";
import * as Global from "../../helpers/global";
import { Trans } from "../../helpers/translate";
import CallCenter from "../../components/call-center/quotation-call-center";
import DateRangePicker from "../common/date-range";
import moment from "moment";
import SVGIcon from "../../helpers/svg-icon";
import Customerselection from "../../components/reports/customer-selection";
import { apiRequester } from "../../services/requester";
import { react } from "@babel/types";

class QuotationCreateCopy extends Form {
  state = {
    data: {
      customerName: "",
      email: "",
      phone: "",
      title: "",
      duration: "",
      startDate: "",
      endDate: "",
      dates: "",
      datesIsValid: "valid",
      cutOfDays: 1,
      stayInDays: 4,
      createdDate: "",
      status: "",
      phoneNotoValidate: "",
      fromCopy: true,
    },
    addcustomer: false,
    selectcustomer: true,
    customeraction: "selectcustomer",
    isCustomerSet: false,
    customerData: {},
    errors: {},
  };

  validate = () => {
    const errors = {};
    const { data } = this.state;
    if (!this.validateFormData(data.customerName, "require"))
      errors.customerName = "Customer Name required";

    if (!this.validateFormData(data.email, "require")) errors.email = "Email required";

    if (!this.validateFormData(data.phone, "require") || data.phone === "+91-") errors.phone = "Phone required";

    if (!this.validateFormData(data.title, "require")) errors.title = this.props.type === "Quotation" ? "Quotation title required" : this.props.type + " title required";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  handleCreate = () => {
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.props.handleCreate(this.state.data);
  };

  setData = () => {
    let defautData = { ...this.state.data };

    let bookingForInfo = JSON.parse(sessionStorage.getItem("bookingForInfo"));

    defautData.title = this.props.title ? this.props.title : "";

    // defautData.customerName = this.props.mode === "Edit" ? this.props.customerName :
    //   bookingForInfo && bookingForInfo.firstName
    //     ? bookingForInfo.firstName
    //     : this.props.customerName || "";

    // defautData.email = this.props.mode === "Edit" ? this.props.email :
    //   bookingForInfo && bookingForInfo.contactInformation
    //     ? bookingForInfo.contactInformation.email
    //     : this.props.email || "";

    defautData.phone = "";

    defautData.phoneNotoValidate = defautData.phone;

    defautData.startDate = this.props.mode === "Edit" ? this.props.startDate :
      bookingForInfo && bookingForInfo.startDate
        ? bookingForInfo.startDate
        : this.props.startDate || new Date();

    defautData.endDate = this.props.mode === "Edit" ? this.props.endDate :
      bookingForInfo && bookingForInfo.endDate
        ? bookingForInfo.endDate
        : this.props.endDate || new Date(new Date().getTime() + 4 * 24 * 60 * 60 * 1000);

    defautData.duration = this.GetDuration(defautData.startDate, defautData.endDate);

    defautData.createdDate = this.props.mode === "Edit" ? this.props.createdDate :
      bookingForInfo && bookingForInfo.createdDate
        ? bookingForInfo.createdDate
        : this.props.createdDate || new Date();

    if (defautData.createdDate === "0001-01-01T00:00:00")
      defautData.createdDate = new Date();
    defautData.status = this.props.status ? this.props.status : "";

    this.setState({ errors: {}, data: defautData });
  };

  setDate = (startDate, endDate) => {
    let newData = { ...this.state.data };
    newData.dates = { checkInDate: startDate, checkOutDate: endDate };
    newData.datesIsValid = "valid";
    newData.startDate = startDate;
    newData.endDate = endDate;
    newData.duration = this.GetDuration(startDate, endDate);
    this.setState({ data: newData });
  };

  GetDuration = (startDate, endDate) => {
    var tmpStartDate = moment([
      moment(startDate)._d.getFullYear(),
      moment(startDate)._d.getMonth(),
      moment(startDate)._d.getDate(),
    ]);
    var tmpEndDate = moment([
      moment(endDate)._d.getFullYear(),
      moment(endDate)._d.getMonth(),
      moment(endDate)._d.getDate(),
    ]);
    var totaldaysduration = tmpEndDate.diff(tmpStartDate, "days") + 1;
    return totaldaysduration;
  };

  componentDidMount() {
    this.setState({
      customerData: {}
    });
    this.setData();
  }

  handlecustomercheckbox = (e) => {
    let target = e.target;
    if (target.name === "selectcustomer") {
      if (target.checked === true) {
        let statedata = this.state.data;
        // statedata.customerName = this.props.customerName;
        statedata.customerName = "";
        statedata.email = "";
        statedata.phone = "+91-";
        this.setState({
          customerData: {},
          data: statedata,
          selectcustomer: true,
          addcustomer: false,
          customeraction: "selectcustomer",
          isCustomerSet: false
        });
      } else {
        let statedata = this.state.data;
        statedata.customerName = "";
        statedata.email = "";
        statedata.phone = "+91-";
        this.setState({
          data: statedata,
          selectcustomer: false,
          addcustomer: true,
          customeraction: "addcustomer",
        });
        sessionStorage.removeItem("reportCustomer");
      }
    }
  };

  selectCustomer = (data) => {
    let statedata = this.state.data;
    statedata.customerName = data.name;
    statedata.email = data.email;
    statedata.phone = data.cellPhone;
    statedata.title = this.props.title ? this.props.title : "";

    this.setState({ isCustomerSet: false }, () => {
      this.setState({ customerData: data, isCustomerSet: true, data: statedata }, () => {
      });
    });
  }

  render() {
    let isPersonateEnabled = Global.getEnvironmetKeyValue("isPersonateEnabled");
    const { type } = this.props;
    let emailId = "";//this.props.email;
    let agentID = this.props.userInfo.agentID;
    return (
      <div className="model-popup">
        <div className="modal fade show d-block" tabindex='-1'>
          <div
            className={"modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg"}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{"Copy " + this.props.type}</h5>
                <button
                  type="button"
                  className="close"
                  onClick={this.props.handleHide}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="quotation-create border p-3 mt-3 bg-white shadow-sm mb-3 position-relative">
                  <div className="row">
                    {type === "Quotation" && (
                      <React.Fragment>
                        <div className="col-lg-12 mb-4">
                          <div className="custom-control custom-switch d-inline-block col-lg-6">
                            <input
                              id="customer"
                              name="selectcustomer"
                              type="checkbox"
                              className="custom-control-input"
                              checked={this.state.selectcustomer}
                              onChange={this.handlecustomercheckbox}
                            />
                            <label className="custom-control-label" htmlFor="customer">
                              Select Customer
                            </label>
                          </div>
                          <div className="custom-control custom-switch d-inline-block col-lg-6">
                            <input
                              id="customer"
                              name="customer"
                              type="checkbox"
                              className="custom-control-input"
                              checked={this.state.addcustomer}
                              onChange={this.handlecustomercheckbox}
                            />
                            <label className="custom-control-label" htmlFor="customer">
                              Add Customer
                            </label>
                          </div>
                        </div>
                        {this.state.customeraction === "addcustomer" &&
                          <React.Fragment>
                            <div className="col-lg-12 position-relative">
                              {this.renderInput("customerName", "Customer * (e.g. Firstname Lastname)")}{" "}
                            </div>

                            <div className="col-lg-6">
                              {this.renderInput("email", Trans("_lblEmailWithStar"))}
                            </div>

                            <div className="col-lg-6">
                              {this.renderContactInput("phone", Trans("_lblContactPhoneWithStar"))}
                            </div>
                          </React.Fragment>
                        }

                        {this.state.customeraction !== "addcustomer" &&
                          <React.Fragment>
                            <div className="col-lg-12 position-relative mb-3">
                              <Customerselection email={emailId} agentID={agentID} selectCustomer={this.selectCustomer.bind(this)} customerData={this.state.customerData} isCustomerSet={this.state.isCustomerSet} ishidelabel={true} />
                            </div>
                            <div className="col-lg-6">
                              {this.renderInput("email", Trans("_lblEmailWithStar"))}
                            </div>

                            <div className="col-lg-6">
                              {this.renderContactInput("phone", Trans("_lblContactPhoneWithStar"))}
                            </div>
                          </React.Fragment>
                        }

                        <div className="col-lg-6">
                          {this.renderInput("title", Trans("_quotationTitleWithStar"))}
                        </div>

                        {((this.state.customeraction !== "addcustomer" && this.state.isCustomerSet) || this.state.customeraction == "addcustomer") &&
                          <div className="col-lg-6">
                            <div className="form-group phone">
                              <label className="d-block">&nbsp;&nbsp;</label>
                              {this.props.isBtnLoading &&
                                <button className="btn btn-primary w-100">
                                  <span className="spinner-border spinner-border-sm mr-2"></span>
                                  Copy {type}
                                </button>
                              }
                              {!this.props.isBtnLoading &&
                                <button onClick={this.handleCreate} className="btn btn-primary w-100">
                                  Copy {type}
                                </button>
                              }
                            </div>
                          </div>
                        }
                        {((this.state.customeraction !== "addcustomer" && !this.state.isCustomerSet)) &&
                          <div className="col-lg-6">
                            <div className="form-group phone">
                              <label className="d-block">&nbsp;&nbsp;</label>
                              {this.props.isBtnLoading &&
                                <button className="btn btn-secondary w-100">
                                  <span className="spinner-border spinner-border-sm mr-2"></span>
                                  Copy {type}
                                </button>
                              }
                              {!this.props.isBtnLoading &&
                                <button className="btn btn-secondary w-100">
                                  Copy {type}
                                </button>
                              }
                            </div>
                          </div>
                        }
                        {this.props.isErrorMsg && (
                          <div className="col-lg-12">
                            <h6 className="alert alert-danger mt-3 d-inline-block">
                              {this.props.isErrorMsg}
                            </h6>
                          </div>
                        )}
                      </React.Fragment>
                    )}

                    {type === "Itinerary" && (
                      <React.Fragment>
                        <div className="col-lg-12 mb-4">
                          <div className="custom-control custom-switch d-inline-block col-lg-6">
                            <input
                              id="customer"
                              name="selectcustomer"
                              type="checkbox"
                              className="custom-control-input"
                              checked={this.state.selectcustomer}
                              onChange={this.handlecustomercheckbox}
                            />
                            <label className="custom-control-label" htmlFor="customer">
                              Select Customer
                            </label>
                          </div>
                          <div className="custom-control custom-switch d-inline-block col-lg-6">
                            <input
                              id="customer"
                              name="customer"
                              type="checkbox"
                              className="custom-control-input"
                              checked={this.state.addcustomer}
                              onChange={this.handlecustomercheckbox}
                            />
                            <label className="custom-control-label" htmlFor="customer">
                              Add Customer
                            </label>
                          </div>
                        </div>
                        {this.state.customeraction === "addcustomer" &&
                          <React.Fragment>
                            <div className="col-lg-12 position-relative">
                              {this.renderInput("customerName", "Customer * (e.g. Firstname Lastname)")}{" "}
                            </div>

                            <div className="col-lg-6">
                              {this.renderInput("email", Trans("_lblEmailWithStar"))}
                            </div>

                            <div className="col-lg-6">
                              {this.renderContactInput("phone", Trans("_lblContactPhoneWithStar"))}
                            </div>
                          </React.Fragment>
                        }

                        {this.state.customeraction !== "addcustomer" &&
                          <React.Fragment>
                            <div className="col-lg-12 position-relative mb-3">
                              <Customerselection email={emailId} agentID={agentID} selectCustomer={this.selectCustomer.bind(this)} customerData={this.state.customerData} isCustomerSet={this.state.isCustomerSet} ishidelabel={true} selectCustomerEmail={this.props.email} />
                            </div>
                            <div className="col-lg-6">
                              {this.renderInput("email", Trans("_lblEmailWithStar"), "text", true)}
                            </div>

                            <div className="col-lg-6">
                              {this.renderContactInput("phone", Trans("_lblContactPhoneWithStar"))}
                            </div>
                          </React.Fragment>
                        }

                        <div className="col-lg-6">
                          {this.renderInput("title", Trans("Itinerary Title *"))}
                        </div>

                        {/* <div className="col-lg-2">
                          {this.renderCurrentDateWithDuration("startDate", Trans("_fromDate"), moment().format("YYYY-MM-DD"))}
                        </div>

                        <div className="col-lg-2">
                          {this.renderCurrentDateWithDuration("endDate", Trans("_toDate"), this.state.data.startDate)}
                        </div>

                        <div className="col-lg-2">
                          {this.renderInput("duration", Trans("Duration (Days)"), "text", true)}
                        </div> */}

                        {((this.state.customeraction !== "addcustomer" && this.state.isCustomerSet) || this.state.customeraction == "addcustomer") &&
                          <div className="col-lg-6">
                            <div className="form-group phone">
                              <label className="d-block">&nbsp;&nbsp;</label>
                              {this.props.isBtnLoading &&
                                <button className="btn btn-primary w-100">
                                  <span className="spinner-border spinner-border-sm mr-2"></span>
                                  Copy {type}
                                </button>
                              }
                              {!this.props.isBtnLoading &&
                                <button onClick={this.handleCreate} className="btn btn-primary w-100">
                                  Copy {type}
                                </button>
                              }
                            </div>
                          </div>
                        }
                        {((this.state.customeraction !== "addcustomer" && !this.state.isCustomerSet)) &&
                          <div className="col-lg-6">
                            <div className="form-group phone">
                              <label className="d-block">&nbsp;&nbsp;</label>
                              {this.props.isBtnLoading &&
                                <button className="btn btn-secondary w-100">
                                  <span className="spinner-border spinner-border-sm mr-2"></span>
                                  Copy {type}
                                </button>
                              }
                              {!this.props.isBtnLoading &&
                                <button className="btn btn-secondary w-100">
                                  Copy {type}
                                </button>
                              }
                            </div>
                          </div>
                        }
                        {this.props.isErrorMsg && (
                          <div className="col-lg-12">
                            <h6 className="alert alert-danger mt-3 d-inline-block">
                              {this.props.isErrorMsg}
                            </h6>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </div>

                  {this.props.customerName && !this.props.removedeletebutton && (
                    <button
                      className="btn btn-sm position-absolute p-1 border-left border-bottom rounded-0 bg-light"
                      style={{ top: "0px", right: "0px" }}
                      onClick={this.handleCreate}
                    >
                      <SVGIcon
                        name="times"
                        width="16"
                        height="16"
                        className="d-flex align-items-center text-secondary"
                      ></SVGIcon>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show"></div>
      </div>






    );
  }
}

export default QuotationCreateCopy;
