import React from "react";
import { Trans } from "../../helpers/translate";
import * as Global from "../../helpers/global";

const Footer = () => {
  var copyRightsText = Global.getEnvironmetKeyValue("poweredby", "cobrand");
  let content = copyRightsText ? Trans("_copyRightsByText") + copyRightsText : Trans("_copyRightsText")
  return (
    <footer className="border-top mt-4 bg-white">
      <div className="container">
        <div className="d-flex justify-content-center pt-3">
          <p className="small">{content}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;