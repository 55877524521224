import React, { Component } from "react";
import Form from "../common/form";
import * as Global from "../../helpers/global";
import { Trans } from "../../helpers/translate";
import CallCenter from "../../components/call-center/quotation-call-center";
import DateRangePicker from "../common/date-range";
import moment from "moment";
import SVGIcon from "../../helpers/svg-icon";
import { apiRequester } from "../../services/requester";

class QuotationCreate extends Form {
  state = {
    data: {
      customerName: "",
      email: "",
      phone: "",
      title: "",
      duration: "",
      startDate: "",
      endDate: "",
      dates: "",
      datesIsValid: "valid",
      cutOfDays: 1,
      stayInDays: 4,
      createdDate: "",
      status: "",
      phoneNotoValidate: ""
    },
    errors: {},
  };

  validate = () => {
    const errors = {};
    const { data } = this.state;

    if (!this.validateFormData(data.customerName, "require"))
      errors.customerName = "Customer Name required";

    if (!this.validateFormData(data.email, "require")) errors.email = "Email required";

    if (!this.validateFormData(data.phone, "require") || data.phone === "1-") errors.phone = "Phone required";

    if (!this.validateFormData(data.title, "require")) errors.title = this.props.type === "Quotation" ? "Quotation title required" : this.props.type + " title required";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  handleCreate = () => {
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.props.handleCreate(this.state.data);
  };

  setData = () => {
    let defautData = { ...this.state.data };

    let bookingForInfo = JSON.parse(sessionStorage.getItem("bookingForInfo"));

    defautData.title = this.props.title ? this.props.title : "";

    defautData.customerName = this.props.mode === "Edit" ? this.props.customerName :
      bookingForInfo && bookingForInfo.firstName
        ? bookingForInfo.firstName
        : this.props.customerName || "";

    defautData.email = this.props.mode === "Edit" ? this.props.email :
      bookingForInfo && bookingForInfo.contactInformation
        ? bookingForInfo.contactInformation.email
        : this.props.email || "";

    defautData.phone = this.props.mode === "Edit" ? this.props.phone :
      bookingForInfo && bookingForInfo.contactInformation
        ? bookingForInfo.contactInformation.phoneNumber
        : this.props.phone || "";

    defautData.phoneNotoValidate = defautData.phone;

    defautData.startDate = this.props.mode === "Edit" ? this.props.startDate :
      bookingForInfo && bookingForInfo.startDate
        ? bookingForInfo.startDate
        : this.props.startDate || new Date();

    defautData.endDate = this.props.mode === "Edit" ? this.props.endDate :
      bookingForInfo && bookingForInfo.endDate
        ? bookingForInfo.endDate
        : this.props.endDate || new Date(new Date().getTime() + 4 * 24 * 60 * 60 * 1000);

    defautData.duration = this.GetDuration(defautData.startDate, defautData.endDate);

    defautData.createdDate = this.props.mode === "Edit" ? this.props.createdDate :
      bookingForInfo && bookingForInfo.createdDate
        ? bookingForInfo.createdDate
        : this.props.createdDate || new Date();

    if (defautData.createdDate === "0001-01-01T00:00:00")
      defautData.createdDate = new Date();
    defautData.status = this.props.status ? this.props.status : "";

    this.setState({ data: defautData });
  };

  setDate = (startDate, endDate) => {
    let newData = { ...this.state.data };
    newData.dates = { checkInDate: startDate, checkOutDate: endDate };
    newData.datesIsValid = "valid";
    newData.startDate = startDate;
    newData.endDate = endDate;
    newData.duration = this.GetDuration(startDate, endDate);
    this.setState({ data: newData });
  };

  GetDuration = (startDate, endDate) => {
    var tmpStartDate = moment([
      moment(startDate)._d.getFullYear(),
      moment(startDate)._d.getMonth(),
      moment(startDate)._d.getDate(),
    ]);
    var tmpEndDate = moment([
      moment(endDate)._d.getFullYear(),
      moment(endDate)._d.getMonth(),
      moment(endDate)._d.getDate(),
    ]);
    var totaldaysduration = tmpEndDate.diff(tmpStartDate, "days") + 1;
    return totaldaysduration;
  };

  componentDidMount() {
    this.setData();
  }

  render() {
    let isPersonateEnabled = Global.getEnvironmetKeyValue("isPersonateEnabled");
    const { type } = this.props;

    return (
      <div className="quotation-create border p-3 mt-3 bg-white shadow-sm mb-3 position-relative">
        <div className="row">
          {type === "Quotation" && (
            <React.Fragment>
              <div className="col-lg-3 position-relative">
                {this.renderInput("customerName", "Customer * (e.g. Firstname Lastname)")}{" "}
                {isPersonateEnabled && (
                  <div className="position-absolute" style={{ right: "20px", top: "32px" }}>
                    <CallCenter />
                  </div>
                )}
              </div>

              <div className="col-lg-3">
                {this.renderInput("email", Trans("_lblEmailWithStar"))}
              </div>

              <div className="col-lg-2">
                {this.renderContactInput("phone", Trans("_lblContactPhoneWithStar"))}
              </div>

              <div className="col-lg-3">
                {this.renderInput("title", Trans("_quotationTitleWithStar"))}
              </div>

              <div className="col-lg-1">
                <div className="form-group phone">
                  <label>&nbsp;&nbsp;</label>
                  {this.props.isBtnLoading &&
                    <button className="btn btn-primary form-control ">
                      <span className="spinner-border spinner-border-sm mr-2"></span>
                    </button>
                  }
                  {!this.props.isBtnLoading &&
                    <button onClick={this.handleCreate} className="btn btn-primary">
                      {this.props.customerName ? Trans("_update") : Trans("_create")}
                    </button>
                  }
                </div>
              </div>
              {this.props.isErrorMsg && (
                <div className="col-lg-12">
                  <h6 className="alert alert-danger mt-3 d-inline-block">
                    {this.props.isErrorMsg}
                  </h6>
                </div>
              )}
            </React.Fragment>
          )}

          {type === "Itinerary" && (
            <React.Fragment>
              <div className="col-lg-4 position-relative">
                {this.renderInput("customerName", "Customer * (e.g. Firstname Lastname)")}{" "}
                {isPersonateEnabled && (
                  <div className="position-absolute" style={{ right: "20px", top: "32px" }}>
                    <CallCenter />
                  </div>
                )}
              </div>

              <div className="col-lg-4">
                {this.renderInput("email", Trans("_lblEmailWithStar"))}
              </div>

              <div className="col-lg-4">
                {this.renderContactInput("phone", Trans("_lblContactPhoneWithStar"))}
              </div>

              <div className="col-lg-4">
                {this.renderInput("title", Trans("Itinerary Title *"))}
              </div>

              <div className="col-lg-2">
                {this.renderCurrentDateWithDuration("startDate", Trans("_fromDate"), moment().format("YYYY-MM-DD"))}
              </div>

              <div className="col-lg-2">
                {this.renderCurrentDateWithDuration("endDate", Trans("_toDate"), this.state.data.startDate)}
              </div>

              <div className="col-lg-2">
                {this.renderInput("duration", Trans("Duration (Days)"), "text", true)}
              </div>

              <div className="col-lg-2">
                <div className="form-group phone">
                  <label className="d-block">&nbsp;&nbsp;</label>
                  {this.props.isBtnLoading &&
                    <button className="btn btn-primary w-100">
                      <span className="spinner-border spinner-border-sm mr-2"></span>
                      {this.props.customerName ? Trans("_update") : Trans("_create")} Itinerary
                    </button>
                  }
                  {!this.props.isBtnLoading &&
                    <button onClick={this.handleCreate} className="btn btn-primary w-100">
                      {this.props.customerName ? Trans("_update") : Trans("_create")} Itinerary
                    </button>
                  }
                </div>
              </div>
              {this.props.isErrorMsg && (
                <div className="col-lg-12">
                  <h6 className="alert alert-danger mt-3 d-inline-block">
                    {this.props.isErrorMsg}
                  </h6>
                </div>
              )}
            </React.Fragment>
          )}
        </div>

        {this.props.customerName && !this.props.removedeletebutton && (
          <button
            className="btn btn-sm position-absolute p-1 border-left border-bottom rounded-0 bg-light"
            style={{ top: "0px", right: "0px" }}
            onClick={this.handleCreate}
          >
            <SVGIcon
              name="times"
              width="16"
              height="16"
              className="d-flex align-items-center text-secondary"
            ></SVGIcon>
          </button>
        )}
      </div>
    );
  }
}

export default QuotationCreate;
