import React, { Component } from "react";
import $ from "jquery";
import SVGIcon from "../../helpers/svg-icon";
import * as Global from "../../helpers/global";
import Form from "../common/form";
import { apiRequester } from "../../services/requester";
import QuotationEmailItems from "./quotation-email-items";
import QuotationEmailFooter from "./quotation-email-footer";
import QuotationEmailHeader from "./quotation-email-header";
import QuotationEmailItemsOffline from "./quotation-email-items-offline";
import QuotationEmailItemsFromList from "./quotation-email-items-fromlist";
import Amount from "../../helpers/amount";
import ComingSoon from "../../helpers/coming-soon";

class ItineraryEmail extends Form {
  state = {
    isSucessMsg: false,
    isEmailPreview: false,
    data: {
      title: "",
      email: "",
      terms: "",
    },
    errors: {},
    saveMode: false,
    savedCartId: null,
    isHidePrice: true,
    hideItemizedPrices: true,
    comingsoon: false,
    ShowhideElementname: "hideItemizedPrices",
  };

  ajaxRequester = (reqURL, reqOBJ, callback) => {
    $.ajax({
      type: "POST",
      url: reqURL,
      data: reqOBJ,
      dataType: "JSON",
      success: function (data) {
        callback();
      },
      error: function (err) {
        callback();
        console.log("err", err);
      },
    });
  };

  handleComingSoon = () => {
    this.setState({
      comingsoon: !this.state.comingsoon,
    });
  };

  sendEmail = () => {
    const { data } = this.state;

    var emailBody = document.getElementById("emailHTML").outerHTML;

    let reqOBJ = {
      fromEmail: "info@yourtripplans.com",
      toEmail: data.email,
      replyToEmail: Global.getEnvironmetKeyValue("customerCareEmail"),
      subject: data.title,
      htmlBody: emailBody,
      textBody: emailBody,
    };

    let reqURL = "https://unified-api.dev.tourwizonline.com/tw/sendemail";
    var xhttp;
    xhttp = new XMLHttpRequest();
    xhttp.open("POST", reqURL, true);
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.send(JSON.stringify(reqOBJ));
    xhttp.onreadystatechange = () => {
      this.quotationSuccess();
      // if (this.readyState === 4 && this.status === 200) {
      // }
    };
  };

  validate = () => {
    const errors = {};
    const { data } = this.state;

    if (!this.validateFormData(data.title, "require"))
      errors.title = "Quotation title required";
    if (!this.validateFormData(data.email, "require"))
      errors.email = "Email required";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  handleSendEmail = (mode) => {
    const errors = this.validate();
    this.setState({ errors: errors || {}, saveMode: false });
    if (errors) return;
    this.sendEmail();

    const quotationInfo = JSON.parse(localStorage.getItem("quotationDetails"));
    quotationInfo.terms = this.state.data.terms;
    localStorage.setItem("quotationDetails", JSON.stringify(quotationInfo));
    this.props.saveQuotation();

    mode === "save" && this.saveQuotation();
  };

  saveQuotation = () => {
    this.setState({ saveMode: true });

    var reqURL = "api/v1/cart/persist";
    var reqOBJ = {
      Request: {
        metaData: {
          name: this.state.data.title,
          customerName: this.props.customerName,
          email: this.props.email,
          phone: this.props.phone,
          terms: this.state.data.terms,
          offlineItems: localStorage.getItem("quotationItems"),
        },
        cartID: localStorage.getItem("cartLocalId"),
      },
    };

    apiRequester(
      reqURL,
      reqOBJ,
      function (data) {
        this.setState({ savedCartId: data.info.token });
      }.bind(this)
    );
  };

  setData = () => {
    let defautData = { ...this.state.data };
    defautData.title = this.props.title ? this.props.title : "";
    defautData.email = this.props.email ? this.props.email : "";
    defautData.terms = this.props.terms ? this.props.terms : "";
    this.setState({ data: defautData });
  };

  quotationSuccess = () => {
    this.setState({
      isSucessMsg: !this.state.isSucessMsg,
    });
    setTimeout(() => {
      this.setState({ isSucessMsg: false });
    }, 5000);
  };

  quotationEmailPreview = () => {
    this.setState({
      isEmailPreview: !this.state.isEmailPreview,
    });
    const quotationInfo = JSON.parse(localStorage.getItem("quotationDetails"));
    quotationInfo.terms = this.state.data.terms;
    localStorage.setItem("quotationDetails", JSON.stringify(quotationInfo));
    this.props.saveQuotation();
  };

  handlePrices = (e) => {
    let target = e.target;
    if (target.name === "showAllPrices") {
      if (target.checked === true) {
        this.setState({
          showAllPrices: true,
          hideItemizedPrices: false,
          hideAllPrices: false,
          isHidePrice: false,
          ShowhideElementname: "showAllPrices",
        });
      } else {
        this.setState({
          showAllPrices: false,
          hideItemizedPrices: false,
          hideAllPrices: false,
          isHidePrice: false,
          ShowhideElementname: "",
        });
      }
    }

    if (target.name === "hideItemizedPrices") {
      if (target.checked === true) {
        this.setState({
          showAllPrices: false,
          hideItemizedPrices: true,
          hideAllPrices: false,
          isHidePrice: true,
          ShowhideElementname: "hideItemizedPrices",
        });
      } else {
        this.setState({
          showAllPrices: false,
          hideItemizedPrices: false,
          hideAllPrices: false,
          isHidePrice: false,
          ShowhideElementname: "",
        });
      }
    }
    if (target.name === "hideAllPrices") {
      if (target.checked === true) {
        this.setState({
          showAllPrices: false,
          hideItemizedPrices: false,
          hideAllPrices: true,
          isHidePrice: false,
          ShowhideElementname: "hideAllPrices",
        });
      } else {
        this.setState({
          showAllPrices: false,
          hideItemizedPrices: false,
          hideAllPrices: false,
          isHidePrice: false,
          ShowhideElementname: "",
        });
      }
    }
  };

  componentDidMount() {
    this.setData();
  }

  render() {
    const { items, type, duration } = this.props;
    const { isSucessMsg, isEmailPreview, saveMode, isHidePrice, ShowhideElementname } = this.state;

    const count = Math.ceil(duration);
    const totalDays = [...Array(count).keys()];
    let totalPrice = 0;
    items.map(
      (item) =>
        item.offlineItem &&
        (totalPrice = Number(totalPrice) + Number(item.offlineItem.sellPrice))
    );

    console.log(this.props.userInfo);

    return (
      <div className="quotation-email border shadow-sm mt-4">
        <div className="border-bottom bg-light d-flex p-2 pl-3 pr-3">
          <div className="mr-auto d-flex align-items-center">
            <SVGIcon
              className="mr-2 d-flex align-items-center"
              name={"envelope"}
              width="16"
              type="fill"
            ></SVGIcon>
            <h6 className="font-weight-bold m-0 p-0">{type} Email</h6>
          </div>

          <button
            className="btn btn-sm border bg-white"
            onClick={this.props.sendEmail}
          >
            <SVGIcon
              className="d-flex align-items-center"
              name="times"
              width="16"
              height="16"
            ></SVGIcon>
          </button>
        </div>

        <div className="p-3">
          <div className="quotation-email-form row">
            <div className="col-lg-6">
              {this.renderInput("title", "Itinerary Title *")}
              {this.renderInput("email", "Email *")}
            </div>
            <div className="col-lg-6 quotation-email">
              {this.renderTextarea("terms", "Terms and Conditions")}
            </div>

            <div className="col-lg-12 mb-3 mt-1">
              <div className="custom-control custom-switch d-inline-block mr-4">
                <input
                  id="hideItemizedPrices"
                  name="hideItemizedPrices"
                  type="checkbox"
                  className="custom-control-input"
                  checked={this.state.hideItemizedPrices}
                  onChange={this.handlePrices}
                />
                <label
                  className="custom-control-label"
                  htmlFor="hideItemizedPrices"
                >
                  Hide Itemized Prices
                </label>
              </div>

              <div className="custom-control custom-switch d-inline-block mr-4">
                <input
                  id="showAllPrices"
                  name="showAllPrices"
                  type="checkbox"
                  className="custom-control-input"
                  checked={this.state.showAllPrices}
                  onChange={this.handlePrices}
                />
                <label className="custom-control-label" htmlFor="showAllPrices">
                  Show All Prices
                </label>
              </div>

              <div className="custom-control custom-switch d-inline-block mr-4">
                <input
                  id="hideAllPrices"
                  name="hideAllPrices"
                  type="checkbox"
                  className="custom-control-input"
                  checked={this.state.hideAllPrices}
                  onChange={this.handlePrices}
                />
                <label className="custom-control-label" htmlFor="hideAllPrices">
                  Hide All Prices
                </label>
              </div>
            </div>
          </div>

          {isSucessMsg && (
            <h6 className="alert alert-success mt-3">
              {type} {saveMode && "Save and "} Sent Successfully!
            </h6>
          )}

          <div className="mt-2">
            <button
              className="btn btn-sm btn-primary mr-3"
              onClick={this.quotationEmailPreview}
            >
              Preview Email
            </button>

            <button
              className="btn btn-sm btn-primary mr-3"
              onClick={this.handleSendEmail}
            >
              Send {type}
            </button>
            <button
              className="btn btn-sm btn-primary mr-3"
              onClick={this.handleComingSoon}>
              Send {type} to Supplier
            </button>
          </div>

          {this.state.comingsoon && (
            <ComingSoon handleComingSoon={this.handleComingSoon} />
          )}
          <div style={{ display: !isEmailPreview && "none" }}>
            <div id="emailHTML" className="mt-4">
              <table
                cellPadding="0"
                cellSpacing="0"
                border="0"
                width="800px"
                style={{ border: "solid 2px #434C5B" }}
              >
                <tbody>
                  <tr>
                    <td>
                      <table
                        cellPadding="0"
                        cellSpacing="0"
                        border="0"
                        width="100%"
                      >
                        <tbody>
                          <tr>
                            <td>
                              <table
                                cellPadding="0"
                                cellSpacing="0"
                                border="0"
                                width="100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        background: "#434C5B",
                                        padding: "8px 16px",
                                        color: "#ffffff",
                                        fontSize: "24px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {this.state.data.title}

                                      {this?.props?.userInfo?.provider?.logo
                                        ?.url ? (
                                        <img
                                          src={
                                            this?.props?.userInfo?.provider
                                              ?.logo?.url
                                          }
                                          height="32px"
                                          style={{
                                            height: "32px",
                                            background: "#fff",
                                            borderRadius: "4px",
                                            float: "right",
                                            padding: "0px 4px",
                                          }}
                                          alt=""
                                        />
                                      ) : (
                                        <h5
                                          style={{
                                            background: "#fff",
                                            borderRadius: "4px",
                                            float: "right",
                                            padding: "4px 4px 6px 4px",
                                            color: "rgb(241, 130, 71)",
                                            margin: "0px",
                                          }}
                                        >
                                          {this.props.userInfo.provider.name}
                                        </h5>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>

                          <tr>
                            <td style={{ padding: "16px" }}>
                              <table
                                cellPadding="0"
                                cellSpacing="0"
                                border="0"
                                width="100%"
                              >
                                <tbody>
                                  <tr>
                                    <td>
                                      <QuotationEmailHeader {...this.props} />
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>
                                      <table
                                        cellPadding="0"
                                        cellSpacing="0"
                                        border="0"
                                        width="100%"
                                      >
                                        <tbody>
                                          {totalDays.map((day, key) => (
                                            <tr key={key}>
                                              <td>
                                                <br />
                                                <div
                                                  style={{
                                                    background: "#f1f1f1",
                                                    padding: "8px",
                                                    borderRadius: "4px",
                                                  }}
                                                >
                                                  <b>Day {day + 1}</b>
                                                </div>

                                                {items.map(
                                                  (item, key) =>
                                                    item.offlineItem && (
                                                      <React.Fragment key={key}>
                                                        {item.offlineItem
                                                          .business === "air" &&
                                                          Number(day + 1) ===
                                                          Number(
                                                            items &&
                                                            item.offlineItem
                                                              .dayDepart
                                                          ) && (
                                                            <QuotationEmailItemsOffline
                                                              handleItemDelete={
                                                                this
                                                                  .handleItemDelete
                                                              }
                                                              item={item}
                                                              isHidePrice={
                                                                isHidePrice
                                                              }
                                                              ShowhideElementname={ShowhideElementname}
                                                              type={
                                                                "ItineraryItem"
                                                              }
                                                              departFlight={
                                                                Number(
                                                                  day + 1
                                                                ) ===
                                                                Number(
                                                                  items &&
                                                                    item
                                                                      .offlineItem
                                                                      .dayReturn
                                                                    ? true
                                                                    : false
                                                                )
                                                              }
                                                              returnFlight={
                                                                Number(
                                                                  day + 1
                                                                ) !==
                                                                Number(
                                                                  items &&
                                                                    item
                                                                      .offlineItem
                                                                      .dayReturn
                                                                    ? true
                                                                    : false
                                                                )
                                                              }
                                                            />
                                                          )}

                                                        {item.offlineItem
                                                          .business === "air" &&
                                                          item.offlineItem
                                                            .isRoundTrip &&
                                                          Number(day + 1) ===
                                                          Number(
                                                            items &&
                                                            item.offlineItem
                                                              .dayReturn
                                                          ) && (
                                                            <QuotationEmailItemsOffline
                                                              handleItemDelete={
                                                                this
                                                                  .handleItemDelete
                                                              }
                                                              item={item}
                                                              isHidePrice={
                                                                isHidePrice
                                                              }
                                                              ShowhideElementname={ShowhideElementname}
                                                              type={
                                                                "ItineraryItem"
                                                              }
                                                              departFlight={
                                                                Number(
                                                                  day + 1
                                                                ) ===
                                                                Number(
                                                                  items &&
                                                                    item
                                                                      .offlineItem
                                                                      .dayReturn
                                                                    ? true
                                                                    : false
                                                                )
                                                              }
                                                              returnFlight={
                                                                Number(
                                                                  day + 1
                                                                ) !==
                                                                Number(
                                                                  items &&
                                                                    item
                                                                      .offlineItem
                                                                      .dayReturn
                                                                    ? true
                                                                    : false
                                                                )
                                                              }
                                                            />
                                                          )}

                                                        {item.offlineItem
                                                          .business !== "air" &&
                                                          Number(day + 1) ===
                                                          Number(
                                                            items &&
                                                            item.offlineItem
                                                              .day
                                                          ) && (
                                                            <QuotationEmailItemsOffline
                                                              handleItemDelete={
                                                                this
                                                                  .handleItemDelete
                                                              }
                                                              item={item}
                                                              isHidePrice={
                                                                isHidePrice
                                                              }
                                                              ShowhideElementname={ShowhideElementname}
                                                              type={
                                                                "ItineraryItem"
                                                              }
                                                            />
                                                          )}
                                                      </React.Fragment>
                                                    )
                                                )}
                                              </td>
                                            </tr>
                                          ))}

                                          <tr>
                                            <td>
                                              <br />
                                              <table
                                                cellPadding="8"
                                                cellSpacing="0"
                                                border="0"
                                                width="100%"
                                                style={{
                                                  background: "#f1f1f1",
                                                  borderRadius: "4px",
                                                }}
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td>
                                                      <b
                                                        style={{
                                                          fontSize: "20px",
                                                        }}
                                                      >
                                                        {this.state.data.title}
                                                      </b>
                                                    </td>
                                                    {ShowhideElementname !== "hideAllPrices" && (
                                                      <td align="right">
                                                        <b
                                                          style={{
                                                            fontSize: "20px",
                                                            color:
                                                              "rgb(241, 130, 71)",
                                                          }}
                                                        >
                                                          Total Price :{" "}
                                                          <Amount
                                                            amount={totalPrice}
                                                          />
                                                        </b>
                                                      </td>
                                                    )}
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>
                                      <QuotationEmailFooter
                                        {...this.props}
                                        {...this.state}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ItineraryEmail;
