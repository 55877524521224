import React, { Component } from "react";
import SVGIcon from "../helpers/svg-icon";
import { apiRequester_quotation_api } from "../services/requester-quotation";
import { apiRequester_LocationImage } from "../services/requester-location-img";
import QuotationItineraryDetailsItem from "../components/quotation/quotation-itinerary-details-item";
import Date from "../helpers/date";
import Loader from "../components/common/loader";
import ComingSoon from "../helpers/coming-soon";
import Amount from "../helpers/amount";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
class ItineraryEmailView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: "",
      offlineItems: "",
      locationImg: "",
      copyUrl: false,
      comingsoon: false,
    };
  }

  getDetails = () => {
    var reqURL = "quotation?id=" + this.props.match.params.cartId;
    var reqOBJ = {};

    apiRequester_quotation_api(
      reqURL,
      reqOBJ,
      (data) => {
        this.getItineraryDetails(data);
      },
      "GET"
    );
  };

  getItineraryDetails = (cartDetails) => {
    let details = cartDetails.data;
    let info = {
      name: details.name,
      duration: details.duration,
      startDate: details.startDate,
      endDate: details.endDate,
      customerName: details.customerName,
      email: details.email,
      phone: details.phone,
      type: details.type,
    };

    let offlineItems =
      details?.offlineItems && JSON.parse(details.offlineItems);
    this.setState({ info, offlineItems });
    this.getLocationImage();
  };

  getLocationImage = () => {
    let location = this?.state?.offlineItems?.find(
      (x) => x.offlineItem.business === "hotel"
    );
    var reqURL = location
      ? location.offlineItem
        ? location.offlineItem.toLocationCity
        : "travel"
      : "travel";

    apiRequester_LocationImage(reqURL, (data) => {
      this.setState({ locationImg: data.hits[0].largeImageURL });
    });
  };

  copyToClipboard = () => {
    var textField = document.createElement("textarea");
    textField.innerText = window.location.href;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    this.setState({ copyUrl: true });
    setTimeout(() => {
      this.setState({ copyUrl: false });
    }, 5000);
  };

  handleComingSoon = () => {
    this.setState({
      comingsoon: !this.state.comingsoon,
    });
  };
  generatePDF = (type) => {
    html2canvas(document.querySelector("#preview"), {
      imageTimeout: 150000,
      logging: false,
      useCORS: true,
      width: 1366,
      windowWidth:1366,
      scale:1,
    })
      .then((canvas) => {        
        var imgData = canvas.toDataURL('image/png');
        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;
        var doc = new jsPDF('p', 'mm');
        var position = 0; // give some top padding to first page

        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position += heightLeft - imgHeight; // top padding for other pages
          doc.addPage();
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        if(type === "print"){
          doc.autoPrint();
          window.open(doc.output('bloburl'), '_blank');
        }
        else{
          const { name } = this.state.info;
          doc.save(name+'.pdf');
        }
      });
    
  }
  componentDidMount() {
    this.getDetails();
  }

  render() {
    const { info, offlineItems, copyUrl } = this.state;
    const items = offlineItems;
    const count = Math.ceil(info.duration) || "";
    const totalDays = [...Array(count).keys()];
    let dayArr = [];
    totalDays.map((day) => {
      dayArr.push({ day: day + 1, isHotel: [] });
    });

    let totalPrice = 0;
    offlineItems &&
      offlineItems.map(
        (item) =>
          item.offlineItem &&
          (totalPrice = Number(totalPrice) + Number(item.offlineItem.sellPrice))
      );

    const css = `
    header, footer, .agent-login, .landing-pg {
        display: none;
    }`;

    return (
      <div id="preview">
        <style>{css}</style>
        {info && (
          <React.Fragment>
            <div className="quotation itinerary-landing-page">
              <div className="itinerary-landing-coverBg pt-4 pb-4 mb-3 d-flex align-items-center">
                <div className="container">
                  <h1 className="text-white m-0 p-0 text-center">
                    {info.name && info.name}
                  </h1>
                  {info.type !== "Quotation" &&
                  <div className="text-white mt-3 text-center">
                    <small>
                      Start Date:{" "}
                      <b>
                        <Date date={info.startDate}></Date>
                      </b>
                    </small>
                    <small className="ml-4">
                      End Date:{" "}
                      <b>
                        <Date date={info.endDate}></Date>
                      </b>
                    </small>
                    <small className="ml-4">
                      No of Days: <b>{info.duration}</b>
                    </small>
                  </div>
                    }
                  <div className="text-center text-white mt-3">
                    <h5
                      className="border d-inline-block rounded"
                      style={{
                        background: "rgba(0,0,0,0.4)",
                        padding: "6px 16px",
                      }}
                    >
                      Price: <Amount amount={totalPrice} />
                    </h5>
                  </div>

                  <div data-html2canvas-ignore
                    className="text-center mt-3"
                    style={{ position: "absolute", right: "8px", top: "0px" }}
                  >
                    <button
                      className="btn btn-sm btn-outline-primary text-white border mr-2"
                      onClick={this.copyToClipboard}
                    >
                      Copy Link
                    </button>

                    <button
                      className="btn btn-sm btn-outline-primary text-white border mr-2"
                      onClick={() => this.generatePDF("pdf")}
                    >
                      Download PDF
                    </button>

                    <button
                      className="btn btn-sm btn-outline-primary text-white border mr-2"
                      onClick={() => this.generatePDF("print")}
                    >
                      Print
                    </button>
                    {copyUrl && (
                      <div
                        className="alert alert-sm alert-success mt-2 p-0"
                        style={{ fontSize: "0.9rem" }}
                      >
                        Copied Link URL to your clipboard
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="quotation-details border shadow-sm mt-3 mb-5">
                  {info && info.type === "Itinerary" && (
                    <div className="dayview pt-4 pl-3 pr-3">
                      <ul className="list-unstyled dayview-days">
                        {totalDays.map((day, key) => (
                          <li
                            className="dayview-day position-relative"
                            key={key}
                          >
                            <div className="d-flex align-items-center">
                              <h4>{day + 1}</h4>
                              <h5>
                                Day {day + 1} -
                                <b className="text-primary ml-2">
                                  <Date
                                    date={info.startDate}
                                    format="longDate"
                                    add={day}
                                  ></Date>
                                </b>
                              </h5>
                            </div>

                            <div className="dayview-items">
                              {items &&
                                items.map(
                                  (item, key) =>
                                    item.offlineItem && (
                                      <React.Fragment key={key}>
                                        {item.offlineItem.business === "air" &&
                                          Number(day + 1) ===
                                            Number(
                                              items &&
                                                item.offlineItem.dayDepart
                                            ) && (
                                            <QuotationItineraryDetailsItem
                                              handleItemDelete={
                                                this.handleItemDelete
                                              }
                                              item={item}
                                              departFlight={
                                                Number(day + 1) ===
                                                Number(
                                                  items &&
                                                    item.offlineItem.dayReturn
                                                    ? true
                                                    : false
                                                )
                                              }
                                              returnFlight={
                                                Number(day + 1) !==
                                                Number(
                                                  items &&
                                                    item.offlineItem.dayReturn
                                                    ? true
                                                    : false
                                                )
                                              }
                                            />
                                          )}

                                        {item.offlineItem.business === "air" &&
                                          item.offlineItem.isRoundTrip &&
                                          Number(day + 1) ===
                                            Number(
                                              items &&
                                                item.offlineItem.dayReturn
                                            ) && (
                                            <QuotationItineraryDetailsItem
                                              handleItemDelete={
                                                this.handleItemDelete
                                              }
                                              item={item}
                                              departFlight={
                                                Number(day + 1) ===
                                                Number(
                                                  items &&
                                                    item.offlineItem.dayReturn
                                                    ? true
                                                    : false
                                                )
                                              }
                                              returnFlight={
                                                Number(day + 1) !==
                                                Number(
                                                  items &&
                                                    item.offlineItem.dayReturn
                                                    ? true
                                                    : false
                                                )
                                              }
                                            />
                                          )}

                                        {item.offlineItem.business !== "air" &&
                                          Number(day + 1) ===
                                            Number(
                                              items && item.offlineItem.day
                                            ) && (
                                            <QuotationItineraryDetailsItem
                                              handleItemDelete={
                                                this.handleItemDelete
                                              }
                                              item={item}
                                            />
                                          )}
                                      </React.Fragment>
                                    )
                                )}
                            </div>
                          </li>
                        ))}

                        <li className="dayview-day dayview-day-total">
                          <div>
                            <div className="d-flex align-items-center">
                              <h4></h4>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  )}

                  {info && info.type === "Quotation" && (
                    <div className="dayview pt-4 pl-3 pr-3">
                      {items &&
                        items.map(
                          (item, key) =>
                            item.offlineItem && (
                              <QuotationItineraryDetailsItem
                                handleItemDelete={this.handleItemDelete}
                                item={item}
                                key={key}
                                isIndividualPrice={true}
                              />
                            )
                        )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="itinerary-landing-coverImg">
              <img src={this.state.locationImg} alt="" />
            </div>
          </React.Fragment>
        )}

        {!info && !offlineItems && (
          <div className="itinerary-landing-loader">
            <Loader />
          </div>
        )}

        {this.state.comingsoon && (
          <ComingSoon handleComingSoon={this.handleComingSoon} />
        )}
      </div>
    );
  }
}

export default ItineraryEmailView;
