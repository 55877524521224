import React, { Component } from 'react';
import CustomerSelectionPopUp from "./customer-selection-popup";
import { apiRequester_unified_api } from "../../services/requester-unified-api";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
export class Customerselection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowPopup: false,
            data: this.props.customerData,
            isCustomerSelected: Object.keys(this.props.customerData).length > 0,
            results: Object.keys(this.props.customerData).length > 0 ? [this.props.customerData] : [],
            isLoading: false,
            isDirty: false
        };
    }
    closePopup() {
        this.setState({ isShowPopup: false })
    }
    selectCustomer = (item) => {
        let results = [{ ...item }];
        this.setState({ data: item, isCustomerSelected: true, results });
        sessionStorage.setItem("reportCustomer", JSON.stringify(results));
        this.props.selectCustomer(item);
    }
    componentDidMount() {
        if (sessionStorage.getItem("reportCustomer")) {
            const results = JSON.parse(sessionStorage.getItem("reportCustomer"));
            if (results.length > 0) {
                const Customer = results[0];
                if (Customer.agentID === parseInt(this.props.agentID) && Customer.customerID != this.state.data.customerID)
                    this.selectCustomer(Customer);
            }
        }
        else if (this.props.email) {
            this.getCustomer(this.props.email);
        }
    }
    change = (item) => {
        if (item && item.length > 0) {
            this.selectCustomer(item[0]);
        }
    }
    getCustomer(query) {
        this.setState({ isLoading: true })
        let reqURL = "reconciliation/customer/list?createdfromdate=2020-01-01&createdtodate=2050-12-31&agentid=" + this.props.agentID;
        reqURL = query ? reqURL + "&" + (this.props.email ? "emailid" : "customername") + "=" + query : reqURL;

        let reqOBJ = {}
        apiRequester_unified_api(
            reqURL,
            reqOBJ,
            function (data) {
                this.setState({ results: data.response, isLoading: false })
                if (localStorage.getItem('portalType') === 'B2C' &&
                    data.response && data.response.length > 0) {
                    this.selectCustomer(data.response[0]);
                }

                // if (this.props.ishidelabel && data.response && data.response.length > 0) {
                //     this.selectCustomer(data.response[0]);
                // }

            }.bind(this),
            "GET"
        );
    }
    onInputChange = () => {
        this.setState({ isCustomerSelected: false, isDirty: true })
    }
    render() {
        const { data, isCustomerSelected, isShowPopup, results, isLoading, isDirty } = this.state
        return (
            <React.Fragment>
                {localStorage.getItem('portalType') !== 'B2C' &&
                    <div className="form-row">
                        {!this.props.ishidelabel &&
                            <div className="col-auto d-flex flex-column justify-content-center align-items-center">
                                <span className="">Customer Selection</span>
                            </div>
                        }
                        <div className={!this.props.ishidelabel ? "col-md-6" : "col-md-12"}>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">
                                        <i className="fa fa-user" aria-hidden="true"></i>
                                    </div>
                                </div>
                                {/* <input className="form-control" disabled value={data.name ? data.name + " - " + data.cellPhone : ""} type="text"></input> */}
                                <AsyncTypeahead
                                    emptyLabel={"Type here to search customer"}
                                    id="asyncTypeaheadControl"
                                    isLoading={isLoading}
                                    isInvalid={!isCustomerSelected && isDirty}
                                    defaultSelected={isCustomerSelected ? [data] : []}
                                    selected={isCustomerSelected ? [data] : []}
                                    options={results && results.length === 0 ? [] : results}
                                    onChange={this.change}
                                    useCache={false}
                                    onInputChange={this.onInputChange}
                                    searchText={"Searching customer"}
                                    labelKey={option =>
                                        `${option.name}`
                                    }
                                    placeholder={
                                        "Type here to search customer"
                                    }
                                    onSearch={query => {
                                        this.getCustomer(query)
                                    }}
                                    renderMenuItemChildren={(option, props) => (
                                        <div>
                                            <span>{option.name + " " + option.cellPhone}</span>
                                        </div>
                                    )}
                                />
                                <div className="input-group-append">
                                    <button
                                        className="btn btn-primary reset-btn "
                                        onClick={() => { this.setState({ isShowPopup: true }) }}
                                    >
                                        {"Select"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {isShowPopup && (
                    <CustomerSelectionPopUp closePopup={this.closePopup.bind(this)} selectCustomer={this.selectCustomer.bind(this)} agentID={this.props.agentID} />
                )}
            </React.Fragment>
        )
    }
}



export default Customerselection


