import { Component } from "react";
import { authRequester, apiRequester } from "../services/requester";
import * as Global from "../helpers/global";
import Config from "../config.json";

class Environment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
            isLoginMenu: false,
            isLoginBox: false,
            isUserMenu: false,
            isB2BLoggedIn: null,
            userInfo: "",
            global: "",
        };
    }

    appAuth = () => {
        authRequester(
            function () {
                !localStorage.getItem("portalType") && this.getEnvironment();                
                // Config.CMSPortalURL != undefined && 
                // localStorage.getItem("isAllowedCMS") != null &&
                // localStorage.getItem("isAllowedCMS") === "CMS" &&
                //     this.getCMSPortalDetails();
                this.getLoginDetails();
            }.bind(this)
        );
    };

    getLoginDetails = (callback) => {
        var reqURL = "api/v1/user/details";
        let isPersonateEnabled = Global.getEnvironmetKeyValue("isPersonateEnabled");
        isPersonateEnabled = isPersonateEnabled === null ? false : isPersonateEnabled;
        var reqOBJ = {
            Request: "",
            Flags: { usecallcenterinfo: isPersonateEnabled },
        };
        apiRequester(
            reqURL,
            reqOBJ,
            function (data) {
                data.response !== undefined
                    ? this.setState({
                        isLoginMenu: true,
                        isLoggedIn: true,
                        userInfo: data.response,
                        isB2BLoggedIn: true,
                    })
                    : this.setState({
                        isLoginMenu: true,
                        isB2BLoggedIn: false,
                    });
                if (callback)
                    callback(data);
            }.bind(this)
        );
    };

    handleLoginBox = (data) => {
        if(data?.continueAsGuest){
            this.setState({
                isLoginBox: true,
                isLoggedIn: false,
                userInfo: data.response,
            });
        } else {
            (localStorage.getItem("portalType") === "B2B" ||
                localStorage.getItem("portalType") === "BOTH") &&
                this.getEnvironment();
            (data === undefined || !data.response)
                ? this.state.isLoginBox
                    ? this.setState({
                        isLoginBox: false,
                    })
                    : this.setState(
                        {
                            isLoginBox: true,
                        },
                        () => window.scrollTo(0, 0)
                    )
                : this.setState({
                    isLoginBox: false,
                    isLoggedIn: true,
                    userInfo: data.response,
                });
        }
    };

    handleUserMenu = (data) => {
        if (data !== undefined)
            this.setState({
                isUserMenu: false,
            });
        else {
            this.setState({
                isUserMenu: !this.state.isUserMenu,
            });
        }
    };

    handleLogOut = () => {
        localStorage.removeItem("cartLocalId");
        var reqURL = "api/v1/user/logout";
        var reqOBJ = {
            Request: "",
        };

        apiRequester(
            reqURL,
            reqOBJ,
            function (data) {
                sessionStorage.removeItem("personateId");
                sessionStorage.removeItem("callCenterType");
                sessionStorage.removeItem("bookingFor");
                localStorage.removeItem("cartLocalId");
                localStorage.removeItem("afUserType")
                this.setState({
                    isLoggedIn: false,
                    isUserMenu: false,
                    isB2BLoggedIn: false,
                    userInfo: "",
                });
                if (Global.getEnvironmetKeyValue("portalType") === 'B2C')
                    window.location.href = window.location.origin;
            }.bind(this)
        );
    };

    handleLangChange = () => {
        let currentLang =
            localStorage.getItem("lang") === null
                ? "ar-AE"
                : localStorage.getItem("lang") === "en-US"
                    ? "ar-AE"
                    : "en-US";
        localStorage.setItem("lang", currentLang);
        window.location.reload();
    };

    getEnvironment = () => {
        var reqURL = "api/v1/application/environment";
        var reqOBJ = {
            Request: {},
        };

        apiRequester(
            reqURL,
            reqOBJ,
            function (data) {
                if (
                    data.response.cobrandDetails.find(
                        (x) => x.shortDesc.toLowerCase() === "isumrahportal".toLowerCase()
                    ) &&
                    data.response.cobrandDetails
                        .find((x) => x.shortDesc.toLowerCase() === "isumrahportal")
                        .value.toLowerCase() === "true"
                )
                    localStorage.setItem("isUmrahPortal", "true");
                //localStorage.setItem("isUmrahPortal", "true");
                localStorage.setItem("portalType", data.response.portalType);
                localStorage.setItem("environment", JSON.stringify(data.response));
                this.setState({
                    global: data.response,
                });
            }.bind(this)
        );
    };
    
    // getCMSPortalDetails = (callback) => {
    //     var reqURL = `cms/portal/details?siteurl=${Config.CMSPortalURL != undefined ? Config.CMSPortalURL.replace(/^http:\/\//i, '').replace(/^https:\/\//i, '') : ""}`;
    //     let isPersonateEnabled = Global.getEnvironmetKeyValue("isPersonateEnabled");
    //     isPersonateEnabled =
    //     isPersonateEnabled === null ? false : isPersonateEnabled;
    //     var reqOBJ = {
    //     Request: "",
    //     Flags: { usecallcenterinfo: isPersonateEnabled },
    //     };
    //     apiRequesterCMS(
    //     reqURL,
    //     reqOBJ,
    //     (data) => {
    //         console.log(JSON.stringify(data.response[0]));
    //         if (data.error) {
    //             data.response = [];
    //         }
    //         if (data.response.length > 0) {
    //             localStorage.setItem(
    //             "CMSPortalDetails",
    //             JSON.stringify(data.response[0])
    //             );
    //             localStorage.setItem("cmsPortalID", data.response[0].portalID)
    //         }
    //     },
    //     "GET"
    //     );
    // };



    postMessage(message) {
        (window.parent ?? window).postMessage(message, "*");
    }

    componentDidMount() {
        this.appAuth();
        this.postMessage(window.location.href.toString());
        window.addEventListener("hashchange", () => this.postMessage(window.location.href.toString()));
    }

    componentDidUpdate() {
        let environment = JSON.parse(localStorage.getItem("environment"));
        if (environment?.portalName) {
            document.title = localStorage.getItem("isAllowedCMS") != null &&
            localStorage.getItem("isAllowedCMS") === "CMS"
              ? Global.getEnvironmetKeyValue("portalName")
              : environment?.portalName;
            const favicon = document.getElementById("favicon");
            var hrefFavicon =
              localStorage.getItem("isAllowedCMS") != null &&
              localStorage.getItem("isAllowedCMS") === "CMS"
                ? (Config.CMSPortalURL + "/Portals/"+localStorage.getItem("cmsPortalID")+"/favicon.ico")
                : (environment?.portalLogo);
            if (favicon) favicon.href = hrefFavicon;
            if (favicon)
                favicon.href = environment?.portalLogo;
        }
    }
}

export default Environment;
