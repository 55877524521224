import React, { Component } from "react";
import Date from "../../helpers/date";
import { Trans } from "../../helpers/translate";

class CoTravelerList extends Component {
  render() {
    const { items } = this.props;

    if (items === "undefined" || items === null || items.length === 0) {
      return (
        <div className="col-6">
          <ul className="list-unstyled p-4 ">
            <div className="w-100">
              {this.props.isCustomerPage
                ? Trans("_viewNoCustomerFound")
                : Trans("_viewNoCoTravellerFound")}
            </div>
          </ul>
        </div>
      );
    }

    return items.map((traveler, index) => {
      return (
        <div className="col-4" key={index}>
          <ul className="list-unstyled p-4 ">
            <div className="w-100">
              <ul className="list-group">
                <li className="list-group-item">
                  <div>
                    <label>{Trans("_viewName") + " : "}</label>{" "}
                    <label>
                      {traveler.userDisplayName !== undefined &&
                      traveler.userDisplayName !== ""
                        ? traveler.userDisplayName
                        : traveler.firstName + " " + traveler.lastName}
                    </label>
                  </div>
                  <div>
                    <label>{Trans("_viewGender") + " : "}</label>{" "}
                    <label>{traveler.genderDesc}</label>
                  </div>
                  <div>
                    <label>{Trans("_viewBirthday") + " : "}</label>{" "}
                    <label>
                      {traveler.birthDate === "0001-01-01T00:00:00" ? (
                        "---"
                      ) : (
                        <Date date={traveler.birthDate} />
                      )}
                    </label>
                  </div>
                  <div className="mt-2 float-right">
                    <span>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() =>
                          this.props.onEdit(
                            this.props.isCustomerPage
                              ? traveler.entityID
                              : traveler.customerID
                          )
                        }
                      >
                        {Trans("_viewBtnEdit")}
                      </button>
                    </span>
                    <span> </span>
                    {!this.props.isCustomerPage && (
                      <span>
                        <button
                          className="btn btn-secondary btn-sm"
                          onClick={() => this.props.onDelete(traveler)}
                        >
                          {Trans("_viewBtnDelete")}
                        </button>
                      </span>
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </ul>
        </div>
      );
    });
  }
}

export default CoTravelerList;
